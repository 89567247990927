import React from 'react';
import "./UserSection.css";
import { FiSearch } from "react-icons/fi";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import user from '../../Assets/Images/user1.jpg';
import { GiHamburgerMenu } from "react-icons/gi";
import VideoTabs from '../VideoTabs/VideoTabs';
import { GiStarShuriken } from "react-icons/gi";
import { RiEditCircleFill } from "react-icons/ri";



function UserSection() {
    return (
        <>
        <div className="search-user">
            <FiSearch className="usersearch-icon"/>
            <input className="input-search" type="text" placeholder="Search..."/>
        </div>

        <div className="not-details">
                
                        <BsDot className="not-dot"/>
                        <BsFillEnvelopeFill className="message-icon"/>
                   
                        <BsDot className="not-dot2"/>
                        <BsBellFill className="message-icon"/>
        </div>
        <div className="not-image">
            <img src={user} className="not-image-user" alt="logo" />
            <p className="not-image-text">John Doe</p>
        </div>
        <GiHamburgerMenu className="hamburger"/>

        {/* <div className="user-videos-tab">
        <div className="tabs-bottom-usersection"></div>
            <VideoTabs/>
        </div> */}

<div className="tab-nav">
              <div className="tab1">
                <p>Video Upload</p>
              </div>
            </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>Video Watch</p>
            </div>
          </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>Wish List</p>
            </div>
          </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>Item Purchase</p>
            </div>
          </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>User Notes</p>
            </div>
          </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>Subscription</p>
            </div>
          </div>

          <div className="tab-nav">
            <div className="tab1">
              <p>-
              </p>
            </div>
          </div>

          <div className="tab1-border"></div>

          <div className="video-card">
          <p className="video-views">Total Views: 576K</p>
          <p className="video-points">
            <GiStarShuriken className="star-points" /> 454 Points
          </p>
          <img src={user} className="video-user" alt="logo" />
          <div className="videouser-info">
            <RiEditCircleFill className="edit-btn-page" />
            <h3>@john78</h3>
            <p>Active</p>
          </div>
          <div className="name-details">
            <p className="first-name">First Name:</p>
            <p className="user-name-video">John</p>
          </div>
          <div className="name-details">
            <p className="first-name">Last Name:</p>
            <p className="user-name-video">Doe</p>
          </div>
          <div className="name-details">
            <p className="first-name">Email:</p>
            <p className="user-name-video2">johndoe@xyz.com</p>
          </div>
          <div className="name-details">
            <p className="first-name">Contact:</p>
            <p className="user-name-video3">+123 456 789</p>
          </div>
          <div className="name-details">
            <p className="first-name">From:</p>
            <p className="user-name-video4">USA</p>
          </div>
          <div className="name-details">
            <p className="first-name">Havazues balance:</p>
            <p className="user-name-video5">6,907$</p>
          </div>
          <button className="user-activities">Activities</button>
          <button className="user-contact">Contact</button>
          <button className="user-suspend">Suspension</button>
          <button className="user-date">
            1 Jan 2021<span className="date-border"></span>
            31 Jan 2021
          </button>
          <button className="user-delete">Delete</button>

          <div className="video-border"></div>

          <div className="user-followers">
            <p className="followers">Media Followers:</p>
            <p className="followers-num">7,890</p>
          </div>
          <div className="user-followers">
            <p className="followers">Media Following:</p>
            <p className="followers-num">500</p>
          </div>
          <div className="user-followers-store">
            <p className="followers">Store Following:</p>
            <p className="followers-num">500</p>
          </div>

          <button className="user-verification">
            Havazuse plus verification
          </button>
        </div>

        </>
    );
}

export default UserSection;