import React, { useState, useEffect } from "react";
import logo from "../../Assets/Images/logo.jpeg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigation } from "react-minimal-side-navigation";
import { ReactComponent as HomeIcon } from "../../Assets/Images/home.svg";
import { ReactComponent as ProductImg } from "../../Assets/Images/product.svg";
import { ReactComponent as UserOutlineIcon } from "../../Assets/Images/user-outline.svg";
import { ReactComponent as SettingsIcon } from "../../Assets/Images/settings.svg";
import { ReactComponent as DataTableIcon } from "../../Assets/Images/data-table.svg";
import { ReactComponent as SellingIcon } from "../../Assets/Images/selling.svg";
import { ReactComponent as MediaIcon } from "../../Assets/Images/media.svg";
import { ReactComponent as NotificationIcon } from "../../Assets/Images/bell.svg";
import "./Navigation.css";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

const viewHeight = window.outerHeight;

const NavigationS = () => {
  const hide = useSelector((state) => state.Reducer.hide);
  const vendor = useSelector((state) => state.Reducer.vendorEnable);
  const history = useHistory();
  const location = useLocation();
  const [activePath, setActivePath] = useState("/");

  useEffect(() => {
    const itemId = `/${location.pathname.split("/")[1]}`;
    localStorage.setItem("activePath", JSON.stringify(itemId));
    const activePath = localStorage.getItem("activePath");
    setActivePath(JSON.parse(activePath));
  }, [location]);

  return (
    <div className="nav-area2" style={{ display: hide === true && "none" }}>
      <div>
        <div href="#" className="logo">
          <Link to={vendor ? "/analytics" : "/"}>
            <img src={logo} className="logo-image" alt="logo" />
          </Link>
        </div>

        <Navigation
          style={{ marginTop: 30 }}
          // you can use your own router's api to get pathname
          activeItemId={activePath}
          onSelect={({ itemId }) => {
            if (
              itemId === "//" ||
              itemId === "///" ||
              itemId === "////" ||
              itemId === "/////" ||
              itemId === "//////"
            ) {
              console.log("Hello");
            } else {
              setActivePath(itemId);
              history.push(itemId);
            }
          }}
          items={[
            {
              title: "Home",
              itemId: "/",
              elemBefore: () => <HomeIcon />,
            },
            {
              title: "Products",
              itemId: "//",
              elemBefore: () => <ProductImg />,
              subNav: [
                {
                  title: "All Products",
                  itemId: "/All",
                },
                {
                  title: "Add Product",
                  itemId: "/addproduct",
                },
                {
                  title: "Category",
                  itemId: "/category",
                },
                {
                  title: "Attribute",
                  itemId: "/attributes",
                },
              ],
              // you can use your own custom Icon component as well
              // icon is optional
              // elemBefore: () => <Icon name="inbox" />,
            },
            {
              title: "Users",
              itemId: "///",
              elemBefore: () => <UserOutlineIcon />,

              subNav: [
                {
                  title: "Users",
                  itemId: "/employee",
                },
                {
                  title: "Admin",
                  itemId: "/admins",
                },
                {
                  title: "Customer",
                  itemId: "/customerservice",
                },
                {
                  title: "Vendor",
                  itemId: "/vendorservice",
                },
              ],
            },

            {
              title: "Settings",
              itemId: "////",
              elemBefore: () => <SettingsIcon />,

              subNav: [
                {
                  title: "Payment",
                  itemId: "/payment",
                },
                {
                  title: "Store SEO",
                  itemId: "/seo",
                },
              ],
            },
            {
              title: "Data Table",
              itemId: "/////",
              elemBefore: () => <DataTableIcon />,
              subNav: [
                {
                  title: "Add Expenses",
                  itemId: "/AddExpense",
                },
                {
                  title: "View Expenses",
                  itemId: "/ViewExpense",
                },
              ],
            },
            {
              title: "Selling",
              itemId: "/selling",
              elemBefore: () => <SellingIcon />,
              // elemBefore: () => <Icon name="users" />,
            },

            {
              title: "General Media",
              itemId: "//////",
              elemBefore: () => <MediaIcon />,
              subNav: [
                {
                  title: "Short Video",
                  itemId: "/short-video",
                },
                {
                  title: "Live",
                  itemId: "/live-video",
                },
                // {
                //   title: "Live Schedule",
                //   itemId: "/live-schedule",
                // },
              ],
            },
            {
              title: "InStock Notifier",
              itemId: "/searchList",
              elemBefore: () => <NotificationIcon />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default NavigationS;
