import React from "react";
import "./RefundScreen.css";
import product from "../../Assets/Images/phone.png";
import { AiFillStar } from "react-icons/ai";

function RefundScreen() {
    return(

        <>
        
            <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list">
                     <li className="refund-list-name">Name</li>
                     <li>Attr1</li>
                     <li>Attr2</li>
                     <li>CF1</li>
                     <li>CF2</li>
                     <li>Rating</li>
                     <li>Price</li>
                 </ul>
            </div>

            <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list2">
                     <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
                     <li>Product Name</li>
                     <li>Term 3</li>
                     <li>Term B</li>
                     <li>value 3</li>
                     <li>75000</li>
                     <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/></li>
                     <li className="refund-price">$800</li>
                     <li className="refund-screen-input"><input type="text" value="1"/></li>
                     <li className="refund-screen-btn"><button>Refund</button></li>
                 </ul>
            </div>

             <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list2">
                     <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
                     <li>Product Name</li>
                     <li>Term 3</li>
                     <li>Term B</li>
                     <li>value 3</li>
                     <li>75000</li>
                     <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
                     <li className="refund-screen-input"><input type="text" value="1"/></li>
                     <li className="refund-screen-btn"><button>Refund</button></li>
                 </ul>
            </div>

            <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list2">
                     <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
                     <li>Product Name</li>
                     <li>Term 3</li>
                     <li>Term B</li>
                     <li>value 3</li>
                     <li>75000</li>
                     <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
                     <li className="refund-screen-input"><input type="text" value="1"/></li>
                     <li className="refund-screen-btn"><button>Refund</button></li>
                 </ul>
            </div>

            <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list2">
                     <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
                     <li>Product Name</li>
                     <li>Term 3</li>
                     <li>Term B</li>
                     <li>value 3</li>
                     <li>75000</li>
                     <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
                     <li className="refund-screen-input"><input type="text" value="1"/></li>
                     <li className="refund-screen-btn"><button>Refund</button></li>
                 </ul>
            </div>

            <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
                 <ul className="refund-screen-list2">
                     <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
                     <li>Product Name</li>
                     <li>Term 3</li>
                     <li>Term B</li>
                     <li>value 3</li>
                     <li>75000</li>
                     <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
                     <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
                     <li className="refund-screen-input"><input type="text" value="1"/></li>
                     <li className="refund-screen-btn"><button>Refund</button></li>
                 </ul>
            </div>
        
        </>

    );
}

export default RefundScreen;