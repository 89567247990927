import React, { useEffect, useRef, useState } from "react";
import "./AddProduct.css";
import { FaUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import Toggle from "../ToggleSwitch/ToggleSwitch";
import "bootstrap/dist/css/bootstrap.min.css";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import AddImage from "../AddImage/AddImage";
import { Link } from "react-router-dom";
import { ProductImage } from "../ProductImage/ProductImage";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import { ReactComponent as AddIcon } from "../../Assets/Images/addicon.svg";
import { ReactComponent as ImageBlackIcon } from "../../Assets/Images/image_black.svg";
import { ReactComponent as MinusOutline } from "../../Assets/Images/minus-outline.svg";
import { db } from "../../firebase/firebase";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../store/actions/actions";
import { useHistory } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import UpdateThanks from "../UpdateThanks/UpdateThanks";
import { AddProductImage } from "../ProductImage/AddProductImage";
import ImageUploader from "../ProductImage/ImageUploader";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import CustomDropdown from "../Dropdown";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import DatePicker from "react-date-picker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { Toaster } from "../../utils/constant";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AddProduct = () => {
  const history = useHistory();
  const catnames = useSelector((state) => state.Reducer.categories);

  const dispatch = useDispatch();
  const [cat, setCat] = useState("");

  const [publics, setPublics] = useState(false);
  const [show, setShow] = useState(false);
  const [seo, setSeo] = useState(false);
  const [images, setImages] = useState([
    {
      id: 1,
      data_url: "",
    },
  ]);
  const [length, setLength] = useState(3);
  const [pname, setPname] = useState("");
  const [sku, setSku] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [whole, setWhole] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [des, setDes] = useState("");
  const [location, setLocation] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [productImages, setProductImages] = useState([
    { id: 1, image_url: null, image_photo: null },
  ]);

  const roleRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!roleRef?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [roleRef]);

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, "NNNNNNNNNN");
    setImages(imageList);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addNewProduct = async () => {
    let productUrls = [];
    let uploadArray = [];
    let newArray = [...productImages];
    let pid = Math.floor(Math.random() * 1000000000);
    newArray.map((item) => {
      uploadArray.push(item.image_photo);
    });

    const storage = getStorage();
    // const storageRef = ref(
    //   storage,
    //   `/Havause/productImages/${userDetails?.profilePicture?.name}`
    // );

    const promises = [];
    productImages.map((file) => {
      const sotrageRef = ref(
        storage,
        `/Havause/productImages/${pid}/${file?.image_url?.name}`
      );
      promises.push(
        new Promise((resolve) => {
          const uploadTask = uploadBytesResumable(sotrageRef, file.image_url);
          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              // Handle unsuccessful uploads
              console.log("error:-", error);
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then(
                (downloadURLs) => {
                  resolve(downloadURLs);
                }
              );
            }
          );
        })
      );
    });
    // console.log("Promise.all(promises)", Promise.all(promises));
    Promise.all(promises)
      .then(async (images) => {
        const productDetails = {
          Catid: cat,
          Pid: `${pid}`,
          Pname: pname,
          Price: regularPrice,
          salePrice: salePrice,
          wholeSalePrice: whole,
          Quantity: Quantity,
          location: location,
          description: des,
          Pimages: images,
        };
        await setDoc(doc(db, "Products", `${pid}`), productDetails)
          .then(() => {
            Toaster({
              type: "success",
              message: "Product Created Successfully!",
            });
            dispatch(addProduct(productDetails));
            history.push("/All");
          })
          .catch(() => {
            Toaster({
              type: "error",
              message: "Something went wrong!",
            });
          });
      })
      .catch(async (error) => {
        console.log("error", error);
      });
  };

  const addProductImages = (event, index) => {
    const data = event?.currentTarget?.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(data);

    fileReader.onload = () => {
      setProductImages((cloneProductImages) =>
        cloneProductImages.map((imageData, imageIndex) =>
          index === imageIndex
            ? { ...imageData, image_photo: fileReader.result, image_url: data }
            : imageData
        )
      );
    };
  };
  return (
    <>
      <div className="add-product-section">
        <div className="add-product-header">
          <h3>New Product</h3>
          <div className="new-user">
            <FaUserCircle className="user-icon" />
            <select className="user-select">
              <option value="A">admin</option>
              <option value="B">admin</option>
              <option value="C">admin</option>
            </select>
          </div>

          {/* 
      <div className="save-product-section">
        <div className="product-back">
          <BiArrowBack className="back-arrow" />
          <p className="back-text">Back</p>
        </div>
      </div> */}
          <div>
            <div className="clearfix"></div>
            <div className="enable-product">
              <h5>Public</h5>
              <p>[website]</p>
            </div>
            <Toggle />

            {/* <div className="enable-product2">
              <h5>Havazuse</h5>
            </div>
            <Toggle /> */}

            {/* <div className="enable-product2">
              <h5>HPlus</h5>
            </div>
            <Toggle /> */}

            {/* <div className="enable-product2">
              <h5>HBemo</h5>
            </div>
            <Toggle /> */}
            <div className="clearfix"></div>
          </div>

          <div className="edit-name">
            <h5>
              Product Name <span style={{ color: "red" }}>*</span>
            </h5>
            <p>[store view]</p>
          </div>
          <input
            className="input-product"
            type="text"
            value={pname}
            onChange={(event) => setPname(event.target.value)}
          />

          <div className="clearfix"></div>

          <div className="edit-name2">
            <h5>
              SKU <span style={{ color: "red" }}>*</span>
            </h5>
            <p>[global]</p>
          </div>
          <input
            className="input-product"
            type="text"
            value={sku}
            onChange={(event) => setSku(event.target.value)}
          />

          <div className="clearfix"></div>

          <div className="edit-name10">
            <h5>
              Location <span style={{ color: "red" }}>*</span>
            </h5>
          </div>
          <input
            className="input-product2"
            type="text"
            value={location}
            onChange={(event) => setLocation(event.target.value)}
          />

          <div className="clearfix"></div>

          <div className="edit-name3">
            <h5>
              Regular Price <span style={{ color: "red" }}>*</span>
            </h5>
            <p>[global]</p>
          </div>
          <input
            className="input-product2"
            type="text"
            value={regularPrice}
            onChange={(event) => setRegularPrice(event.target.value)}
          />
          <a href="#">
            <p className="advance">Advanced Pricing</p>
          </a>

          <div className="clearfix"></div>

          <div className="edit-name10">
            <h5>
              Sale Price <span style={{ color: "red" }}>*</span>
            </h5>
            <p>[global]</p>
          </div>
          <input
            className="input-product2"
            type="text"
            value={salePrice}
            onChange={(event) => setSalePrice(event.target.value)}
          />
          <a href="#">
            <p className="advance">Advanced Pricing</p>
          </a>

          <div className="clearfix"></div>

          <div className="edit-name-wholesale">
            <h5>
              Wholesale Price <span style={{ color: "red" }}>*</span>
            </h5>
            <p>[global]</p>
          </div>
          <input
            className="input-product2"
            type="text"
            value={whole}
            onChange={(event) => setWhole(event.target.value)}
          />
          <a href="#">
            <p className="advance">Advanced Pricing</p>
          </a>

          <div className="clearfix"></div>

          <div className="edit-name4">
            <h5>Tax Class</h5>
            <p>[website]</p>
          </div>
          <select className="input-product2">
            <option value="A">Taxable Goods</option>
            <option value="B">Taxable Goods</option>
            <option value="C">Taxable Goods</option>
          </select>

          <div className="clearfix"></div>

          <div className="edit-name4">
            <h5>Quantity</h5>
            <p>[global]</p>
          </div>
          <input
            className="input-product2"
            type="text"
            value={Quantity}
            onChange={(event) => setQuantity(event.target.value)}
          />
          <a href="#">
            <p className="advance">Advanced Inventory</p>
          </a>

          <div className="clearfix"></div>

          <div className="edit-name5">
            <h5>Stock Status</h5>
            <p>[global]</p>
          </div>
          <select className="input-product2">
            <option value="A">In Stock</option>
            <option value="B">Out of Stock</option>
          </select>

          <div className="clearfix"></div>

          <div className="edit-name6">
            <h5>Length</h5>
            <p>[store view]</p>
          </div>
          <input className="input-product2" type="number" placeholder="in" />

          <div className="clearfix"></div>

          <div className="edit-name7">
            <h5>Width</h5>
            <p>[store view]</p>
          </div>
          <input className="input-product2" type="number" placeholder="in" />

          <div className="clearfix"></div>

          <div className="edit-name8">
            <h5>Height</h5>
            <p>[store view]</p>
          </div>
          <input className="input-product2" type="number" placeholder="in" />

          <div className="clearfix"></div>

          <div className="edit-name8">
            <h5>Weight</h5>
            <p>[global]</p>
          </div>
          <input className="input-product2" type="number" placeholder="lbs" />
          <select className="input-product2">
            <option value="A">This item has weight</option>
            <option value="B">This item has weight</option>
          </select>

          <div className="clearfix"></div>

          <div className="edit-name4">
            <h5>Category</h5>
            <p>[global]</p>
          </div>
          <select
            defaultValue={"None"}
            style={{ marginLeft: 40 }}
            className="input-product2"
            onChange={(event) => setCat(event.target.value)}
          >
            {catnames.map((item) => (
              <option value={item.Catid}>{item.Catname}</option>
            ))}
            <option value={"None"}>None</option>
          </select>

          <div className="clearfix"></div>

          <div className="edit-name9">
            <h5>Description</h5>
          </div>
          <textarea
            className="input-product"
            type="text"
            style={{ height: 150 }}
            value={des}
            onChange={(event) => setDes(event.target.value)}
          />

          <div className="clearfix"></div>
          <div className="edit-name9">
            <h5>Set Product as New From</h5>
            <p>[website]</p>
          </div>
          <div style={{ float: "left" }}>
            <DatePicker className="product-date" />
            <p className="product-to">To</p>
            <DatePicker className="product-date2" />
          </div>

          <div className="enable-product">
            <h5>SP</h5>
          </div>
          <Toggle />
          <div className="clearfix"></div>

          <div className="edit-name9">
            <h5>Add Images</h5>
          </div>

          <div className="product-images-boxes">
            {/* New changes Add more */}
            {productImages && productImages.length ? productImages?.map((image, index) => {
              return (
                <div className="row" key={image.id}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                        <Form.Group controlId="validationCategoryName">
                          <label className="CatInput upload-Label">
                            {image?.image_url ? (
                              <div className="Upload-name">
                                {image?.image_url?.name}
                              </div>
                            ) : (
                              "Upload Image"
                            )}
                            <UploadIcon className="upload-icon" />
                            <Form.Control
                              type="file"
                              name={`profilePicture-${image.id}`}
                              placeholder="Upload Image"
                              className="upload-file-new"
                              onChange={(event) =>
                                addProductImages(event, index)
                              }
                            />
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                        <Form.Group
                          controlId="validationCategoryName"
                          className="d-flex flex-column"
                        >
                          <div className="d-flex">
                            <label className="upload-Label w-auto padding-none">
                              {index === 0 ? (
                                <Tooltip title="Add" placement="top">
                                  <AddIcon
                                    onClick={() =>
                                      setProductImages([
                                        ...productImages,
                                        {
                                          id: productImages[index]?.id + 1,
                                          image_url: null,
                                          image_photo: null,
                                        },
                                      ])
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Remove" placement="top">
                                  <MinusOutline
                                    onClick={() => {
                                      productImages.splice(index, 1);
                                      setProductImages([...productImages]);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </label>
                            <label className="CatInput upload-Label w-auto">
                              {image.image_url ? (
                                <img
                                  width={24}
                                  height={24}
                                  src={image.image_photo}
                                  alt={`p_image-${image.id}`}
                                />
                              ) : (
                                <ImageBlackIcon />
                              )}
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 "></div>
                    </div>
                  </div>
                </div>
              );
            }) : null}
            {/* {images.map((item, ind) => {
              return (
                <div
                  style={{ marginLeft: "20px", float: "left", marginRight: 10 }}
                >
                  <AddProductImage
                    url={
                      images[ind].data_url.length ? images[ind].data_url : null
                    }
                    setHandler={(values) => {
                      let newArray = [...images];
                      let newData = newArray[ind];

                      newData.data_url = values[0].data_url;
                      setImages(newArray);
                    }}
                    images={images}
                    key={item}
                    minusHandler={() => {
                      let newData = [...images];
                      newData.splice(ind, 1);

                      console.log("Yes", newData);

                      setImages(newData);
                    }}
                    length={ind + 1 === images.length}
                    minus={ind + 1 != 1}
                    addHandler={() => {
                      if (images.length != 100) {
                        let newData = [...images];

                        newData.push({
                          id: images.length + 1,
                          data_url: "",
                        });

                        setImages(newData);
                        console.log("Yes", images);
                      }
                    }}
                  />
                </div>
              );
            })} */}
          </div>

          <div className="clearfix"></div>
          <button className="seo-btn" onClick={() => setSeo(!seo)}>
            Add SEO
          </button>

          {seo ? (
            <>
              <div className="seo-keyphrase">
                <h6>Focus Keyphrase</h6>
                <input className="keyphrase-input" type="text" />
              </div>

              <div className="seo-keyphrase">
                <h6>SEO Title</h6>
                <input className="keyphrase-input" type="text" />
              </div>

              <div className="seo-keyphrase">
                <h6>Meta Description</h6>
                <textarea className="keyphrase-text" type="text" />
              </div>
            </>
          ) : null}

          <div className="btn-section">
            <button className="inv-btn">
              <Link
                to="inventory"
                style={{ textDecoration: "none", color: "white" }}
              >
                {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
                Inventory
              </Link>
            </button>
            <button className="prod-btn">
              <Link
                to="productattribute"
                style={{ textDecoration: "none", color: "white", fontSize: 15 }}
              >
                {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
                Product Attributes
              </Link>
            </button>
          </div>

          <button
            className="product-save"
            onClick={() => {
              // console.log(imagess);
              addNewProduct();
            }}
          >
            Save
          </button>
          <Modal className="world-map-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="App">
                <UpdateThanks title="Added" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/">
                <Button variant="secondary" onClick={handleClose}>
                  Done
                </Button>
              </Link>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      {/* Refactor code */}
      {/* <div className="add-category-section add-product">
        <div className="d-flex justify-content-between align-items-center search-table-header">
          <div className="d-flex align-items-center">
            <BreadcrumbMenu path={[]} activeTab="Add Product" isBack={false} />
          </div>
        </div>
        <div className="d-flex  align-items-center search-table-header">
          <FormControlLabel
            control={<Checkbox color="success" />}
            label="Public"
            labelPlacement="start"
          />
          <FormControlLabel
            control={<Checkbox color="success" />}
            label="Havazuse"
            labelPlacement="start"
          />
          <FormControlLabel
            control={<Checkbox color="success" />}
            label="HPlus"
            labelPlacement="start"
          />
          <FormControlLabel
            control={<Checkbox color="success" />}
            label="HBemo"
            labelPlacement="start"
          />
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Product Name <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                placeholder="Enter Product Name"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Product Name is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                SKU <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                placeholder="Enter SKU"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                SKU is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Location <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Location "
                placeholder="Enter Location"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Location is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Regular Price <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Regular Price "
                placeholder="Enter Product Name"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Regular Price is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Sale Price <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Sale Price"
                placeholder="Enter Sale Price"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Sale Price is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Wholesale Price <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Wholesale Price  "
                placeholder="Enter Wholesale Price"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Wholesale Price is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Tax Class</Form.Label>
              <CustomDropdown
                option={["Taxable Goods", "Taxable Goods", "Taxable Goods"]}
                placeholder="Select Tax Class"
                width="100%"
                borders={true}
                fullArrow={true}
                dropdownContentHeight={"auto !important"}
                name="role"
                handleChangeRowsPerPage
                searchBar={true}
                isActive={isActive}
                setIsActive={setIsActive}
                ref={roleRef}
              />
              <Form.Control.Feedback type="invalid">
                Tax Class is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                placeholder="Enter Quantity"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Quantity is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Stock Status</Form.Label>
              <CustomDropdown
                option={["Taxable Goods", "Taxable Goods", "Taxable Goods"]}
                placeholder="Select Stock Status"
                width="100%"
                borders={true}
                fullArrow={true}
                dropdownContentHeight={"auto !important"}
                name="role"
                handleChangeRowsPerPage
                searchBar={true}
                isActive={isActive}
                setIsActive={setIsActive}
                ref={roleRef}
              />
              <Form.Control.Feedback type="invalid">
                Stock is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Length</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                placeholder="Enter Length"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Length Name is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Width</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                placeholder="Enter Width"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Width is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Height <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Height "
                placeholder="Enter Height"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Height is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="text"
                name="Weight"
                placeholder="Enter Weight"
                className="CatInput"
              />
              <Form.Control.Feedback type="invalid">
                Weight is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Category</Form.Label>
              <CustomDropdown
                option={["Taxable Goods", "Taxable Goods", "Taxable Goods"]}
                placeholder="Select Category"
                width="100%"
                borders={true}
                fullArrow={true}
                dropdownContentHeight={"auto !important"}
                name="role"
                handleChangeRowsPerPage
                searchBar={true}
                isActive={isActive}
                setIsActive={setIsActive}
                ref={roleRef}
              />
              <Form.Control.Feedback type="invalid">
                Category is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Shipping Status</Form.Label>
              <CustomDropdown
                option={["Taxable Goods", "Taxable Goods", "Taxable Goods"]}
                placeholder="Select Shipping Status"
                width="100%"
                borders={true}
                fullArrow={true}
                dropdownContentHeight={"auto !important"}
                name="role"
                handleChangeRowsPerPage
                searchBar={true}
                isActive={isActive}
                setIsActive={setIsActive}
                ref={roleRef}
              />
              <Form.Control.Feedback type="invalid">
                Shipping Status is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="searchSection d-flex justify-content-end">
            <button className="searchButton searchButton-table __padding-create">
              Create
            </button>
          </div>
          <Form.Group className="mb-3" controlId="validationCategoryName">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              placeholder="Enter description"
              className="CatInput"
              style={{ height: "100px" }}
            />
          </Form.Group>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>Set Product as New From</Form.Label>
                <div className="searchSection d-flex">
                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <DatePicker
                      label="Select From Date"
                      // value={dateFilter.from}
                      className="orders-date-input"
                      onChange={(newValue) => {
                        // setDateFilter({ ...dateFilter, from: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>To</Form.Label>
                <div className="searchSection d-flex">
                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <DatePicker
                      label="Select To Date"
                      // value={dateFilter.to}
                      onChange={(newValue) => {
                        // setDateFilter({ ...dateFilter, to: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label></Form.Label>
                <FormControlLabel
                  control={<Checkbox color="success" />}
                  className="d-flex justify-content-end"
                  label="SP"
                  labelPlacement="start"
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-12">
            <Form.Label className="attribute-form-label">
              Product Image
            </Form.Label>
          </div>
          {productImages?.map((image, index) => (
            <div className="row" key={image.id}>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <Form.Group controlId="validationCategoryName">
                      <label className="CatInput upload-Label">
                        Upload Image
                        <UploadIcon className="upload-icon" />
                        <Form.Control
                          type="file"
                          name="profilePicture"
                          placeholder="Upload Image"
                          className="upload-file-new"
                          // onChange={(event) =>
                          //   setFieldValue(
                          //     "profilePicture",
                          //     event?.currentTarget?.files[0]
                          //   )
                          // }
                          // isInvalid={!!errors.profilePicture }
                        />
                      </label>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <Form.Group
                      controlId="validationCategoryName"
                      className="d-flex flex-column"
                    >
                      <div className="d-flex">
                        <label className="upload-Label w-auto padding-none">
                          {index === 0 ? (
                              <Tooltip title="Add" placement="top">
                            <AddIcon
                              onClick={() =>
                                setProductImages([
                                  ...productImages,
                                  {
                                    id: productImages[index]?.id + 1,
                                    image_url: null,
                                  },
                                ])
                              }
                            />
                              </Tooltip>
                          ) : (
                              <Tooltip title="Remove" placement="top">
                            <MinusOutline
                              onClick={() => {
                                productImages.splice(index, 1);
                                setProductImages([...productImages]);
                              }}
                            />
                              </Tooltip>
                          )}
                        </label>
                        <label className="CatInput upload-Label w-auto">
                          {image.image_url ? (
                            <img
                              src={image.image_url}
                              alt={`p_image-${image.id}`}
                            />
                          ) : (
                            <ImageBlackIcon />
                          )}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 "></div>
                </div>
              </div>
            </div>
          ))}
          <div className="searchSection d-flex justify-content-end">
            <button className="searchButton searchButton-table __padding-create">
              Add SEO
            </button>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type="text"
                  name="Regular Price "
                  placeholder="Enter SEO Title"
                  className="CatInput"
                />
                <Form.Control.Feedback type="invalid">
                  SEO Title is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>Focus Keyphrase</Form.Label>
                <Form.Control
                  type="text"
                  name="Sale Price"
                  placeholder="Enter Focus Keyphrase"
                  className="CatInput"
                />
                <Form.Control.Feedback type="invalid">
                  Focus Keyphrase is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12"></div>
          </div>
          <Form.Group className="mb-3" controlId="validationCategoryName">
            <Form.Label>Meta Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              placeholder="Enter Meta Description"
              className="CatInput"
              style={{ height: "100px" }}
            />
          </Form.Group>

          <div className="row">
            <div className="col-8"></div>
            <div className="col-4">
              <div className="row">
                <div className="col-4">
                  <button className="searchButton searchButton-table w-100 font-size-btn">
                    Inventory
                  </button>
                </div>
                <div className="col-4">
                  <button className="searchButton searchButton-table font-size-btn">
                    Product Attributes
                  </button>
                </div>
                <div className="col-4">
                  <button className="searchButton searchButton-table w-100 font-size-btn ">
                    Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddProduct;
