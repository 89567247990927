import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import { FaUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import Toggle from "../ToggleSwitch/ToggleSwitch";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import AddImage from "../AddImage/AddImage";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductImage } from "../ProductImage/ProductImage";
import UpdateThanks from "../UpdateThanks/UpdateThanks";
import { Button, Form, Modal } from "react-bootstrap";
import { db } from "../../firebase/firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { AddProductImage } from "../ProductImage/AddProductImage";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import { ReactComponent as AddIcon } from "../../Assets/Images/addicon.svg";
import { ReactComponent as ImageBlackIcon } from "../../Assets/Images/image_black.svg";
import { ReactComponent as MinusOutline } from "../../Assets/Images/minus-outline.svg";
import Tooltip from "@mui/material/Tooltip";
import { updateProduct } from "../../store/actions/actions";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getPathStorageFromUrl, Toaster } from "../../utils/constant";

function UpdateProduct() {
  const catnames = useSelector((state) => state.Reducer.categories);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const [cat, setCat] = useState("");

  const [publics, setPublics] = useState(false);

  const [images, setImages] = useState([]);
  const [length, setLength] = useState(3);
  const [pname, setPname] = useState("");
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [whole, setWhole] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [productImages, setProductImages] = useState([
    { id: 1, image_url: null, image_photo: null },
  ]);
  const [existingRemovePImages, setExistingRemovePImages] = useState([]);
  const item = useSelector((state) => state.Reducer.selectedProduct);

  useEffect(() => {
    let newImage = [];
    item &&
      item?.Pimages &&
      item?.Pimages?.length > 0 &&
      item.Pimages.map((itt, ind) => {
        newImage.push({
          id: ind + 1,
          data_url: itt,
          image_url: itt,
          image_photo: itt,
        });
      });
    // setImages(newImage);
    setProductImages(newImage.length ? [...newImage] : productImages);
    setCat(item.Catid);
    setPname(item.Pname);
    setPrice(item.Price);
    setSalePrice(item.sellingPrice);
    setWhole(item.wholeSale);
    setQuantity(item.quantity);
    setDescription(item.description);
    setLocation(item.location);
  }, []);

  const addNewProduct = async () => {
    const productRef = doc(db, "Products", item.Pid);
    const storage = getStorage();
    const promises = [];
    let allPromises = [];
    const existingImages = [];
    existingRemovePImages && existingRemovePImages.length && existingRemovePImages.forEach(async image =>{
      allPromises.push(new Promise(async (resolve) =>{
        const storageReference = ref(
          storage,
          `${getPathStorageFromUrl(image.data_url)}`
        );
        resolve(deleteObject(storageReference));
      }))
    })
    // const storageReference = ref(storage, `Havause/productImages/${item.Pid}/`);

    // listAll(storageReference).then((listResults) => {
    //   allPromises.push(
    //     new Promise((resolve) => {
    //       listResults.items.forEach(async (imageUrlRef) => {
    //         const data = await getMetadata(imageUrlRef);
    //         const url = await getDownloadURL(imageUrlRef);
    //         Promise.all([data, url]) //Wait all api request
    //           .then(function (res) {
    //             if (
    //               productImages.some((images) => {
    //                 return (
    //                   images?.image_url?.name === (res && res[0].name) ||
    //                   images?.data_url === (res && res[1])
    //                 );
    //               })
    //             )
    //               resolve(deleteObject(imageUrlRef));
    //           });
    //       });
    //     })
    //   );
    // });
    Promise.all(allPromises).then(() => {
      productImages &&
        productImages.length &&
        productImages.map((file) => {
          const storageRef = ref(
            storage,
            `/Havause/productImages/${item.Pid}/${file?.image_url?.name}`
          );
          if (file?.image_url?.name) {
            promises.push(
              new Promise((resolve) => {
                const uploadTask = uploadBytesResumable(
                  storageRef,
                  file.image_url
                );
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const prog = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                  },
                  (error) => console.log(error),
                  async () => {
                    await getDownloadURL(uploadTask.snapshot.ref).then(
                      (downloadURLs) => {
                        resolve(downloadURLs);
                      }
                    );
                  }
                );
              })
            );
          } else {
            existingImages.push(file?.image_url);
          }
        });
      Promise.all(promises)
        .then(async (images) => {
          const totalImages = existingImages.concat(images);
          const productDetails = {
            Pid: item.Pid,
            Pname: pname,
            Price: price,
            // salePrice: salePrice,
            // wholeSalePrice: whole,
            // Quantity: Quantity,
            Pimages: totalImages,
            description: description,
            location: location,
          };
          updateDoc(productRef, productDetails)
            .then(() => {
              Toaster({
                type: "success",
                message: "Product updated Successfully!",
              });
              dispatch(updateProduct(productDetails));
              history.push("/All");
            })
            .catch(() => {
              Toaster({
                type: "error",
                message: "Product is not updated",
              });
            });
        })
        .catch(async (error) => {
          console.log("error", error);
        });
    });

    // const pRef = doc(db, "Products", `${item.Pid}`);
    // let uploadArray = [];
    // let newArray = [...images];
    // newArray.map((item) => {
    //   uploadArray.push(item.data_url);
    // });

    // const data = {
    //   Pid: item.Pid,
    //   Pname: pname,
    //   Price: price,
    //   // salePrice: salePrice,
    //   // wholeSalePrice: whole,
    //   // Quantity: Quantity,
    //   Pimages: uploadArray,
    //   description: description,
    //   location: location,
    // };
    // dispatch(updateProduct(data));
    // await updateDoc(pRef, data);
    // toast("Product Updated Successfully");
  };

  const addProductImages = (event, index) => {
    const data = event?.currentTarget?.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(data);

    fileReader.onload = () => {
      setProductImages((cloneProductImages) =>
        cloneProductImages.map((imageData, imageIndex) =>
          index === imageIndex
            ? { ...imageData, image_photo: fileReader.result, image_url: data }
            : imageData
        )
      );
    };
  };

  return (
    <div className="add-product-section">
      <div className="add-product-header">
        <h3>Update Product</h3>
        <div className="new-user">
          <FaUserCircle className="user-icon" />
          <select className="user-select">
            <option value="A">admin</option>
            <option value="B">admin</option>
            <option value="C">admin</option>
          </select>
        </div>
        <div className="clearfix"></div>
        <div className="enable-product">
          <h5>Public</h5>
          <p>[website]</p>
        </div>
        <Toggle />
        <div className="clearfix"></div>

        <div className="edit-name">
          <h5>
            Product Name <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[store view]</p>
        </div>
        <input
          className="input-product"
          type="text"
          value={pname}
          onChange={(event) => setPname(event.target.value)}
        />
        <div className="clearfix"></div>

        <div className="edit-name2">
          <h5>
            SKU <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product"
          type="text"
          value={item.Pid}
          onChange={(event) => setSku(event.target.value)}
        />
        <div className="clearfix"></div>

        <div className="edit-name10">
          <h5>
            Location <span style={{ color: "red" }}>*</span>
          </h5>
        </div>
        <input
          className="input-product2"
          type="text"
          value={location}
          onChange={(event) => setLocation(event.target.value)}
        />

        <div className="clearfix"></div>

        <div className="edit-name3">
          <h5>
            Regular Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={price}
          onChange={(event) => setPrice(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name10">
          <h5>
            Sale Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={salePrice}
          onChange={(event) => setSalePrice(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name-wholesale">
          <h5>
            Wholesale Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={whole}
          onChange={(event) => setWhole(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Tax Class</h5>
          <p>[website]</p>
        </div>
        <select className="input-product2">
          <option value="A">Taxable Goods</option>
          <option value="B">Taxable Goods</option>
          <option value="C">Taxable Goods</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Quantity</h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={Quantity}
          onChange={(event) => setQuantity(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Inventory</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name5">
          <h5>Stock Status</h5>
          <p>[global]</p>
        </div>
        <select className="input-product2">
          <option value="A">In Stock</option>
          <option value="B">Out of Stock</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name6">
          <h5>Length</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name7">
          <h5>Width</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name8">
          <h5>Height</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name8">
          <h5>Weight</h5>
          <p>[global]</p>
        </div>
        <input className="input-product2" type="number" placeholder="lbs" />
        <select className="input-product2">
          <option value="A">This item has weight</option>
          <option value="B">This item has weight</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Category</h5>
          <p>[global]</p>
        </div>
        <select
          className="input-product2"
          value={cat}
          onChange={(event) => setCat(event.target.value)}
        >
          {catnames.map((item) => (
            <option value={item.Catid}>{item.Catname}</option>
          ))}
        </select>

        <div className="clearfix"></div>
        <div className="edit-name9">
          <h5>Description</h5>
        </div>
        <textarea
          className="input-product"
          type="text"
          style={{ height: 150 }}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />

        <div className="clearfix"></div>

        <div className="edit-name9">
          <h5>Set Product as New From</h5>
          <p>[website]</p>
        </div>
        <div>
          <DatePicker className="product-date" />
          <p className="product-to">To</p>
          <DatePicker className="product-date2" />
        </div>
        <div className="clearfix"></div>

        <div className="edit-name9">
          <h5>Add Images</h5>
        </div>
        <div className="product-images-boxes">
          {/* New changes Add more */}
          {productImages && productImages.length
            ? productImages?.map((image, index) => {
                return (
                  <div className="row" key={image.id}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                          <Form.Group controlId="validationCategoryName">
                            <label className="CatInput upload-Label">
                              {image?.image_url ? (
                                <div className="Upload-name">
                                  {image?.image_url?.name}
                                </div>
                              ) : (
                                "Upload Image"
                              )}
                              <UploadIcon className="upload-icon" />
                              <Form.Control
                                type="file"
                                name={`profilePicture-${image.id}`}
                                placeholder="Upload Image"
                                className="upload-file-new"
                                onChange={(event) =>
                                  addProductImages(event, index)
                                }
                              />
                            </label>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                          <Form.Group
                            controlId="validationCategoryName"
                            className="d-flex flex-column"
                          >
                            <div className="d-flex">
                              <label className="upload-Label w-auto padding-none">
                                {index === 0 ? (
                                  <Tooltip title="Add" placement="top">
                                    <AddIcon
                                      onClick={() =>
                                        setProductImages([
                                          ...productImages,
                                          {
                                            id: productImages[index]?.id + 1,
                                            image_url: null,
                                            image_photo: null,
                                          },
                                        ])
                                      }
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Remove" placement="top">
                                    <MinusOutline
                                      onClick={() => {
                                        setExistingRemovePImages([...productImages?.filter(imageUrl =>imageUrl?.data_url === image?.data_url)]);
                                        productImages.splice(index, 1);
                                        setProductImages([...productImages]);
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </label>
                              <label className="CatInput upload-Label w-auto">
                                {image.image_url ? (
                                  <img
                                    width={24}
                                    height={24}
                                    src={image.image_photo}
                                    alt={`p_image-${image.id}`}
                                  />
                                ) : (
                                  <ImageBlackIcon />
                                )}
                              </label>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 "></div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          {/* {images.map((item, ind) => {
            console.log(item, "HJHJHJHJHJHJ");
            return (
              <div
                style={{ marginLeft: "20px", float: "left", marginRight: 10 }}
              >
                <ProductImage
                  url={
                    images[ind].data_url.length ? images[ind].data_url : null
                  }
                  setHandler={(values) => {
                    let newArray = [...images];
                    let newData = newArray[ind];

                    newData.data_url = values[0].data_url;
                    setImages(newArray);
                  }}
                  imagess={[images[ind]]}
                  key={item}
                  minusHandler={() => {
                    let newData = [...images];
                    newData.splice(ind, 1);

                    console.log("Yes", newData);

                    setImages(newData);
                  }}
                  length={ind + 1 === images.length}
                  minus={ind + 1 != 1}
                  addHandler={() => {
                    if (images.length != 100) {
                      let newData = [...images];

                      newData.push({
                        id: images.length + 1,
                        data_url: "",
                      });

                      setImages(newData);
                      console.log("Yes", images);
                    }
                  }}
                />
              </div>
            );
          })} */}
        </div>
      </div>
      <div className="clearfix"></div>

      <button className="seo-btn" onClick={() => setShow(!show)}>
        Add SEO
      </button>

      {show ? (
        <>
          <div className="seo-keyphrase">
            <h6>Focus Keyphrase</h6>
            <input className="keyphrase-input" type="text" />
          </div>

          <div className="seo-keyphrase">
            <h6>SEO Title</h6>
            <input className="keyphrase-input" type="text" />
          </div>

          <div className="seo-keyphrase">
            <h6>Meta Description</h6>
            <textarea className="keyphrase-text" type="text" />
          </div>
        </>
      ) : null}

      <div className="btn-section">
        <button className="inv-btn">
          <Link
            to="inventory"
            style={{ textDecoration: "none", color: "white" }}
          >
            {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
            Inventory
          </Link>
        </button>
        <button className="prod-btn">
          <Link
            to="productattribute"
            style={{ textDecoration: "none", color: "white", fontSize: 15 }}
          >
            {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
            Product Attributes
          </Link>
        </button>
      </div>

      <button onClick={() => addNewProduct()} className="product-save">
        Save
      </button>

      {/* <Modal className="world-map-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="App">
            <UpdateThanks />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="secondary" onClick={handleClose}>
              Done
            </Button>
          </Link>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default UpdateProduct;
