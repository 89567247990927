import React from "react";
import "./BannerInner.css";
import banner from "../../Assets/Images/banner.gif";
import product from "../../Assets/Images/order.jpg";
import { useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
function BannerInner() {
  const products = useSelector((state) => state.Reducer.products);

  console.log(products, "HHHH");
  return (
    <>
      <div>
        <div className="mobile1">
          <div className="speaker"></div>
          <AliceCarousel
            autoPlay
            autoPlayInterval="3000"
            disableButtonsControls
            disableDotsControls
            mouseTracking
          >
            <img
              src={
                "https://www.kullabs.com/uploads/Product-Review-Writing-Services.jpg"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://www.pngkey.com/png/detail/247-2475713_ecommerce-product-data-entry-shopping-cart-with-products.png"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://cdn.pixabay.com/photo/2019/07/13/13/42/watch-4334815_960_720.jpg"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={"https://i.ytimg.com/vi/ETsekJKsr3M/maxresdefault.jpg"}
              className="sliderimg"
              alt=""
            />
          </AliceCarousel>
          {/* <div
            className="banner-image"
            style={{
              backgroundImage: `url(${banner})`,
              height: "250px",
              backgroundSize: "contain",
            }}
          >
            <h2>HAVAZUSE</h2>
          </div> */}

          <div className="products-scroll">
            {products.slice(10, 50).map((item) => (
              <div className="mob-product1">
                <img src={item.Pimages[0]} style={{ height: 70 }} />
                <div className="mob-product-details">
                  <p>{item.Pname}</p>
                  <p>$100</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mobile1">
          <div className="speaker"></div>
          <AliceCarousel
            autoPlay
            autoPlayInterval="3000"
            disableButtonsControls
            disableDotsControls
            mouseTracking
          >
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwCP24CO6WEGAUhkfVg4ozqfeve3pvkhEEZg&usqp=CAU"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuWVktLDbwlBQuQ9FSJa7czNVtQLXwt-Qo0Q&usqp=CAU"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0oZDOQajoYmuL2-BmV2w3IGdwSGkK71X3kQ&usqp=CAU"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://mk0skincareheroxsv6n.kinstacdn.com/wp-content/uploads/2020/02/best-the-ordinary-products-for-acne.jpg"
              }
              className="sliderimg"
              alt=""
            />
          </AliceCarousel>
          <div className="products-scroll">
            {products.slice(50, 70).map((item) => (
              <div className="mob-product1">
                <img src={item.Pimages[0]} style={{ height: 70 }} />
                <div className="mob-product-details">
                  <p>{item.Pname}</p>
                  <p>$100</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mobile1">
          <div className="speaker"></div>
          <AliceCarousel
            autoPlay
            autoPlayInterval="3000"
            disableButtonsControls
            disableDotsControls
            mouseTracking
          >
            <img
              src={
                "https://i01.appmifile.com/webfile/globalimg/0320/TO-B/New-Product-Banner/Redmi-Note-10S-banner-en.jpg"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://media.allure.com/photos/5df935d211a3650009205978/16:9/w_400%2Cc_limit/DryScalp_Lede_Social.jpg"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuuMYch7xkePWs2FQ0GGJDo1qvIbkWWoCfRQ&usqp=CAU"
              }
              className="sliderimg"
              alt=""
            />
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbSznhZGhXShCR0dSMrS9MZux9ZZf_v7KcdQ&usqp=CAU"
              }
              className="sliderimg"
              alt=""
            />
          </AliceCarousel>

          <div className="products-scroll">
            {products.slice(60, 90).map((item) => (
              <div className="mob-product1">
                <img src={item.Pimages[0]} style={{ height: 70 }} />
                <div className="mob-product-details">
                  <p>{item.Pname}</p>
                  <p>$100</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerInner;
