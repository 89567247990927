const {
  GET_ALL_PRODUCTS,
  GET_ALL_USERS,
  GET_ALL_CATEGORIES,
  GET_ALL_VENDOR,
  HIDE,
  SELETECTED_PRODUCT,
  GET_ALL_ORDERS,
  GET_ALL_SHORTS,
  GET_USER,
  SHOW_LOADING,
  HIDE_LOADING,
  GET_ALL_LIVE_STREAMS,
  GET_ALL_LIVE_SCHEDULES,
  UPDATE_USER,
  DELETE_FEED,
  DELETE_FEED_LIVE,
  DELETE_FEED_SCHEDULE,
  GET_ALL_LIKES_DISLIKES,
  ADD_LIKES,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_HISTORY,
  USER_HIS,
  FEED_BACK_REPORT,
  GET_USER_MESSAGE,
  GET_SINGLE_USER,
  ADD_MSG,
  RESOLVE,
  ADDCAT,
  GETCATEGORYBYID,
  DELETECAT,
  EDITCAT,
  BANNERS,
  getAllSupportChat,
  GET_ATTRIBUTES,
  SET_ATT,
  SET_VENDOR,
  ALL_CUSTOMER_SERVICE,
  ALL_ADMINS,
  GET_EVENTS,
  REDEEM,
  AdminUser,
  GET_REDEEM,
  GET_PENDING,
  GET_VENDOR_PRODUCTS,
  GET_VENDOR_PRODUCTS_PENDING,
  LOGOUT,
  ADMIN_ENABLE,
  SER_ENABLE,
  DELETE_PRODUCT,
  TABLE_MENU_ACTION,
  SET_COMPLETED_PROFILE,
} = require("../actions/actionTypes");

const initialState = {
  products: [],
  users: [],
  categories: [],
  getOneCategory: {},
  vendors: [],
  hide: false,
  selectedProduct: {},
  orders: [],
  feeds: [],
  userDetails: {},
  loading: true,
  liveStreams: [],
  liveSchedules: [],
  historyHava: [],
  userHistory: [],
  feedreports: [],
  userMessages: [],
  singleUser: {},
  banners: {},
  likesDislikes: [],
  userChats: [],
  attributes: [],
  attItem: {},
  vendorEnable: false,
  adminEnable: false,
  customerEnable: false,
  customerService: [],
  admins: [],
  events: [],
  redeem: [],
  adminUser: {},
  redeemProducts: [],
  pendingProducts: [],
  vendorProducts: [],
  vendorPendingProducts: [],
  tableMenu: { open: false, data: null },
};

const Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,

        products: state.products.filter((ques) => ques.Pid !== payload),
      };
    case GET_VENDOR_PRODUCTS:
      return {
        ...state,
        vendorProducts: payload,
      };

    case GET_VENDOR_PRODUCTS_PENDING:
      return {
        ...state,
        vendorPendingProducts: payload,
      };

    case GET_PENDING:
      return {
        ...state,
        pendingProducts: payload,
      };
    case AdminUser:
      return {
        ...state,
        adminUser: payload,
      };
    case REDEEM:
      return {
        ...state,
        redeem: payload,
      };
    case GET_EVENTS:
      return {
        ...state,
        events: payload,
      };
    case ALL_ADMINS:
      return {
        ...state,
        admins: payload,
      };
    case ALL_CUSTOMER_SERVICE:
      return {
        ...state,
        customerService: payload,
      };
    case SET_ATT:
      return {
        ...state,
        attItem: payload,
      };
    case GET_ATTRIBUTES:
      return {
        ...state,
        attributes: payload,
      };
    case getAllSupportChat:
      return {
        ...state,
        userChats: payload,
      };

    case BANNERS:
      return {
        ...state,
        banners: payload,
      };
    case RESOLVE:
      return {
        ...state,

        feedreports: state.feedreports.map((ques) =>
          ques.reportid === payload ? { ...ques, resolved: true } : ques
        ),
      };
    case ADD_MSG:
      return {
        ...state,
        userMessages: state.userMessages.concat(payload),
      };
    case ADDCAT:
      return {
        ...state,
        categories: state.categories.concat(payload),
      };
    case GETCATEGORYBYID:
      return {
        ...state,
        getOneCategory: payload,
      };
    case DELETECAT:
      return {
        ...state,
        categories: [
          ...state?.categories?.filter((ques) => ques.Catid != payload),
        ],
      };
    case EDITCAT:
      return {
        ...state,
        categories: payload,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: payload,
      };
    case GET_USER_MESSAGE:
      return {
        ...state,
        userMessages: payload,
      };
    case GET_ALL_LIKES_DISLIKES:
      return {
        ...state,
        likesDislikes: payload,
      };
    case FEED_BACK_REPORT:
      return {
        ...state,
        feedreports: payload,
      };
    case USER_HIS:
      return {
        ...state,
        userHistory: payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: state.products.push(payload),
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.Pid === payload.Pid
            ? {
                ...product,
                Price: payload.Price,
                Pimages: payload.Pimages,
                description: payload.description,
                location: payload.location,
              }
            : product
        ),
      };

    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };

    case GET_ALL_USERS:
      return {
        ...state,
        users: payload,
      };
    case GET_ALL_VENDOR:
      return {
        ...state,
        vendors: payload,
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case GET_ALL_LIVE_STREAMS:
      return {
        ...state,
        liveStreams: payload,
      };
    case GET_ALL_LIVE_SCHEDULES:
      return {
        ...state,
        liveSchedules: payload,
      };
    case GET_ALL_SHORTS:
      return {
        ...state,
        feeds: payload,
      };
    case GET_PRODUCT_HISTORY:
      return {
        ...state,
        historyHava: payload,
      };
    case HIDE:
      return {
        ...state,
        hide: payload,
      };
    case SET_COMPLETED_PROFILE:
      return {
        ...state,
        profileComplete: payload,
      };
    case SET_VENDOR:
      return {
        ...state,
        vendorEnable: payload,
      };
    case ADMIN_ENABLE:
      return {
        ...state,
        adminEnable: payload,
      };
    case SER_ENABLE:
      return {
        ...state,
        customerEnable: payload,
      };
    case GET_USER:
      return {
        ...state,
        userDetails: payload,
      };
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SELETECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        // selectedProduct: payload,
      };

    case DELETE_FEED:
      return {
        ...state,

        feeds: state.feeds.filter((ques) => ques.videoid !== payload),
      };
    case DELETE_FEED_LIVE:
      return {
        ...state,

        liveStreams: state.liveStreams.filter(
          (ques) => ques.videoid !== payload
        ),
      };
    case DELETE_FEED_SCHEDULE:
      return {
        ...state,

        liveSchedules: state.liveSchedules.filter(
          (ques) => ques.videoid !== payload
        ),
      };
    case TABLE_MENU_ACTION:
      return {
        ...state,
        tableMenu: payload,
      };
    case LOGOUT:
      return state;

    default:
      return state;
  }
};

export default Reducer;
