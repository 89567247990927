import React from "react";
import "./Payment.css";
import visa from "../../Assets/Images/visa.png";
import master from "../../Assets/Images/master.jpg";

function Payment() {
  return (
    <>
      <div className="payment-section">
        <div style={{ width: "100%", marginLeft: "30px", marginTop: "30px" }}>
          <div>
            <p className="card-text-payment">Card Type</p>
            <input className="card-check" type="checkbox" value="checked" />
            <img className="visa" src={visa} />
            <input className="card-check" type="checkbox" />
            <img className="master" src={master} />
            <div className="clearfix"></div>
            <p className="card-text-payment">Card Number</p>
            <input type="text" value="4000 0000 0000 0004" />
            <div className="clearfix"></div>

            <p className="card-text-payment">Expiry</p>
            <input className="expiry-date" type="number" value="08" />
            <input className="expiry-date" type="number" value="21" />
            <div className="clearfix"></div>

            <p className="card-text-payment">CVV</p>
            <input className="expiry-date" type="number" value="082" />
            <div className="clearfix"></div>
            <p className="card-text-payment">Card Holder's Name</p>
            <input type="text" value="John Doe" />

            <div className="clearfix"></div>
            <p className="card-text-payment">Email Address</p>
            <input type="text" value="johndoe@gmail.com" />

            <div className="clearfix"></div>
            <p className="card-text-payment">Country</p>
            <input type="text" value="Australia" />

            <div className="clearfix"></div>
            <p className="card-text-payment">Billing Address Line 1</p>
            <input type="text" value="H.No#6, St#7, Park Lane" />

            <div className="clearfix"></div>
            <p className="card-text-payment">Billing Address Line 2</p>
            <input type="text" value="H.No#6, St#7, Park Lane" />
          </div>
          <div></div>

          {/* <div>
                    <p>Expiry</p>
                    <input type="number" value="08" />
                    <input type="number" value="21" />
                </div> */}
        </div>
      </div>
    </>
  );
}

export default Payment;
