import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SaleHistory.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import user from "../../Assets/Images/product.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import OrderMenu from "../OrderMenu/OrderMenu";
import { FiDivideSquare } from "react-icons/fi";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function VendorSalesHistory({ allorders }) {
  const history = useHistory();
  const dateSet = (item) => {
    var dateString = item;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(0, 4).join(" ");
    return dateString;
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIdsC, setSelectedIdsC] = useState([]);

  const handleSelectionMultiple = (item) => {
    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.OrderId)) {
      selectedIdss = selectedIds.filter((_id) => _id !== item.OrderId);
    } else {
      selectedIdss.push(item.OrderId);
    }
    setSelectedIds(selectedIdss);
  };
  const handleSelectionMultipleCompany = (item) => {
    var selectedIdss = [...selectedIdsC]; // clone state
    if (selectedIdss.includes(item.OrderId)) {
      selectedIdss = selectedIdsC.filter((_id) => _id !== item.OrderId);
    } else {
      selectedIdss.push(item.OrderId);
    }
    setSelectedIdsC(selectedIdss);
  };

  return (
    <>
      <div className="sale-card">
        <ul className="history-contentSale">
          <li>Order#</li>
          <li>Name</li>
          <li>Payment</li>
          <li>Location</li>

          <li>Date</li>
          <li>Shipping</li>
          <li>Status</li>
          <li>Total</li>
          <li>Action</li>
        </ul>
      </div>

      {allorders.map((item) => (
        <div className="sale-card">
          <p style={{ width: 60, marginLeft:"22px" }}>#{item.OrderId}</p>
          <div style={{ display: "flex", width: 180 }}>
            <img
              src={item.ProductList[0].Pimage}
              className="history-imageSale"
              alt="logo"
            />
            <p className="user-nameSale">{item.ProductList[0].Pname}</p>
          </div>

          <div>
            {selectedIdsC.includes(item.OrderId) ? (
              <input className="deliverySale" style={{}} />
            ) : (
              <p className="deliverySale" style={{ color: "#000" }}>
                Google Pay
              </p>
            )}

          </div>

          <p className="deliverySale" style={{ color: "#000" }}>
            Location
          </p>

          <p style={{ width: 120 }} className="eta">
            {dateSet(item.Date)}
          </p>

          <div>
            <p className="deliverySale">Company</p>

           
          </div>

          <div>
            {selectedIds.includes(item.OrderId) ? (
              <input className="delivery2Sale" style={{}} />
            ) : (
              <p className="delivery2Sale">{item.OrderStatus}</p>
            )}

          
          </div>
          <div>
            <p className="delivery3Sale" style={{ color: "#000" }}>
              ${item.ServiceFees}
            </p>

            {/* <p
              onClick={() => handleSelectionMultipleCompany(item)}
              style={{
                marginTop: 2,
                fontSize: 12,
                color: "blue",
                marginBottom: 0,
                fontWeight: "bold",
              }}
            >
              {selectedIdsC.includes(item.OrderId) ? "Done" : "Edit"}
            </p> */}
          </div>

          {/* <BiDotsVerticalRounded className="more-options" /> */}
          <OrderMenu />
        </div>
      ))}
    </>
  );
}

export default VendorSalesHistory;
