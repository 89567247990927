import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  hide,
  setAdmin,
  setVendor,
  setCustomer,
} from "../../store/actions/actions";
import "./ForgotPassword.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../firebase/firebase";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import Forgot from "../../../src/Assets/Images/passreset.jpg";

const viewHeight = window.outerHeight;

function Register(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [store, setStore] = useState("");

  useEffect(() => {
    dispatch(hide(true));
    dispatch(setVendor(false));
    dispatch(setAdmin(false));
    dispatch(setCustomer(false));
  }, []);

  const updateData = async () => {
    console.log(props.match.params.id);
    if (props.match.params.id) {
      if (first.length) {
        // // Signed in

        const pRef = doc(db, "CustomerService", `${props.match.params.id}`);
        let data = {
          password: first,
        };
        await updateDoc(pRef, data);
        history.push("/customerserviceLogin");

        // ...

        // setLogin(true);
      } else {
        toast("Password is not valid!");
      }
    }
  };
  return (
    <>
      <div className="register-card" style={{ height: viewHeight }}>
        <div className="dark-overlay" style={{ height: viewHeight }}></div>
        <img src={Forgot} style={{ width: "70%", opacity: 0.4 }} />
        <div style={{ float: "left", width: "100%" }}>
          <p
            style={{
              fontSize: 28,
              fontWeight: 800,
              marginTop: 120,
              marginLeft: 40,
            }}
          >
            Forgot Your Password
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "85%",
            }}
          >
            <div
              style={{
                width: "100%",
                marginLeft: "40px",
                marginTop: "110px",
                marginBottom: "50px",
              }}
            >
              <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                New Password
              </p>
              <input
                className="register-field"
                type="text"
                value={first}
                onChange={(event) => setFirst(event.target.value)}
              />
            </div>
          </div>
          {/* <div
        style={{
          width: "100%",
          maxWidth: "100%",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <input
          className="register-name"
          type="text"
          placeholder="First Name"
          value={first}
          onChange={(event) => setFirst(event.target.value)}
        />
        <input
          className="register-lastname"
          type="text"
          placeholder="Last Name"
          value={last}
          onChange={(event) => setLast(event.target.value)}
        />
      </div> */}

          {/* <div
        style={{
          float: "left",
          width: "100%",
          maxWidth: "100%",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <input
          className="register-password"
          type="text"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div> */}

          {/* <div
        style={{
          float: "left",
          width: "100%",
          maxWidth: "100%",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <input
          className="register-confirm-password"
          type="text"
          placeholder="Confirm Password"
          value={confirm}
          onChange={(event) => setConfirm(event.target.value)}
        />
      </div> */}

          <div
            style={{
              float: "left",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <button
              className="pass-change-button"
              onClick={() => {
                updateData();
              }}
            >
              <p
                style={{
                  marginTop: 10,
                  color: "#FFFFFF",
                  marginBottom: "12px",
                }}
              >
                CHANGE PASSWORD
              </p>
            </button>
          </div>
          <div
            style={{
              float: "left",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {login ? (
              <button className="register-button">
                <Link to="/login">Login</Link>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Register;
