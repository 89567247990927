import React from "react";
import "./Shipping.css";

function Shipping() {
    return(
        <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"85%"}}>
                <div className="shipping-section">
                    <h3>Shipping Methods</h3>
                    <button className="shipping-btn">Save</button>
                    <div style={{display:"flex", flexDirection:'row', justifyContent:"space-between", width:"100%"}}>
                        <div className="shipping-card">
                            <ul className="shipping-list">
                                <li><input type="checkbox"/></li>
                                <li className="pos">Pos.</li>
                                <li className="list-name">Name</li>
                                <li className="deli-time">Delivery Time</li>
                                <li className="lbs">Weight limit(lbs)</li>
                                <li className="ship-status">Status</li>
                                <li className="ship-status">Address</li>
                            </ul>
                            <div className="clearfix"></div>
                            <ul className="shipping-list2">
                                <li><input type="checkbox"/></li>
                                <li className="pos"><input className="pos-field" type="text" value="01"/></li>
                                <li className="list-name">USPS Media Mart</li>
                                <li className="deli-time"><input className="deliver-input" type="text" value="1-2 days"/></li>
                                <li className="lbs"><input className="weight-input" type="text" value="0.00" /> - 
                                <input className="weight-input" type="text" value="70.00" /></li>
                                <li className="ship-status"><select>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                    </select></li>
                                    <li className="list-name2">H#6, St#11, Boulevard United States</li>

                            </ul>

                            <div className="clearfix"></div>
                            <ul className="shipping-list3">
                                <li><input type="checkbox"/></li>
                                <li className="pos">0</li>
                                <li className="list-name">UPS 3day Select</li>
                                <li className="deli-time">3 days</li>
                                <li className="lbs">0.00 - 
                                </li>
                                <li className="ship-status"><select>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                    </select></li>
                                    <li className="list-name2">H#6, St#11, Boulevard United States</li>

                            </ul>

                            
                            <div className="clearfix"></div>
                            <ul className="shipping-list3">
                                <li><input type="checkbox"/></li>
                                <li className="pos">0</li>
                                <li className="list-name">FedEx 2nd Day</li>
                                <li className="deli-time">2 days</li>
                                <li className="lbs">0.00 - 
                                </li>
                                <li className="ship-status"><select>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                    </select></li>
                                    <li className="list-name2">H#6, St#11, Boulevard United States</li>

                            </ul>

                            <div className="clearfix"></div>
                            <ul className="shipping-list3">
                                <li><input type="checkbox"/></li>
                                <li className="pos">0</li>
                                <li className="list-name">Custom Shipping Method</li>
                                <li className="deli-time">3-5 days</li>
                                <li className="lbs">0.00 - 
                                </li>
                                <li className="ship-status"><select>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                    </select></li>
                                    <li className="list-name2">H#6, St#11, Boulevard United States</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Shipping;