import React, { useState } from "react";
import product from "../../Assets/Images/phone.png";
import { AiFillStar } from "react-icons/ai";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import CommonTable from "../Table";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import TableFilterBar from "../TableFilterBar";
import { useHistory } from "react-router-dom";
import { ReactComponent as ReturnIcon } from "../../Assets/Images/return.svg";
import "./ReturnScreen.css";
import { Modal } from "react-bootstrap";

const returnProductList = [
  {
    id: 1,
    productName: "Iphone 12 Pro",
    name: null,
    email: "test@gmail.com",
    address: "hello world",
    rating: 5,
    price: "$500",
  },
  {
    id: 2,
    productName: "Iphone 12 Pro",
    name: null,
    email: "test@gmail.com",
    address: "hello world",
    rating: 5,
    price: "$500",
  },
];
function ReturnScreen() {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    record: {},
  });
  const [returnProducts, setReturnProducts] = useState([]);

  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      // selectValue = category?.categories?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };

  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    {
      field: "productName",
      headerName: "Product",
      width: 300,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            <img
              className="user-profile-pic"
              src={params?.row?.profilePicture || product}
              alt={params?.row?.name}
              width={26}
              height={26}
            />
            {params?.row?.name || "Iphone 12 Pro"}
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 300,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            <img
              className="user-profile-pic"
              src={params?.row?.profilePicture || product}
              alt={params?.row?.name}
              width={26}
              height={26}
            />
            {params?.row?.name || "John Doe"}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 165 },
    { field: "address", headerName: "Address", width: 210 },
    { field: "rating", headerName: "Rating", width: 160 },
    { field: "price", headerName: "Price", width: 120 },
    {
      field: "Action",
      headerName: "Actions",
      width: 210,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = async (e, type) => {
          e.stopPropagation();
          if (type === "evidence") {
            setConfirmDialog({
              open: !confirmDialog.open,
              record: params?.row,
            });
          }
        };

        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Reset" placement="top">
              <ReturnIcon onClick={(e) => onClick(e, "return")} />
            </Tooltip>
            <Button
              className="login-btn add-user-btn e-button"
              onClick={(e) => onClick(e, "evidence")}
            >
              Evidence
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    // <>

    //     <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list">
    //              <li className="refund-list-name">Name</li>
    //              <li>Attr1</li>
    //              <li>Attr2</li>
    //              <li>CF1</li>
    //              <li>CF2</li>
    //              <li>Rating</li>
    //              <li>Price</li>
    //          </ul>
    //     </div>

    //     <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list2">
    //              <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
    //              <li>Product Name</li>
    //              <li>Term 3</li>
    //              <li>Term B</li>
    //              <li>value 3</li>
    //              <li>75000</li>
    //              <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/></li>
    //              <li className="refund-price">$800</li>
    //              <li className="refund-screen-input"><input type="text" value="1"/></li>
    //              <li className="refund-screen-btn"><button>Return</button></li>
    //          </ul>
    //     </div>

    //      <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list2">
    //              <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
    //              <li>Product Name</li>
    //              <li>Term 3</li>
    //              <li>Term B</li>
    //              <li>value 3</li>
    //              <li>75000</li>
    //              <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
    //              <li className="refund-screen-input"><input type="text" value="1"/></li>
    //              <li className="refund-screen-btn"><button>Return</button></li>
    //          </ul>
    //     </div>

    //     <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list2">
    //              <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
    //              <li>Product Name</li>
    //              <li>Term 3</li>
    //              <li>Term B</li>
    //              <li>value 3</li>
    //              <li>75000</li>
    //              <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
    //              <li className="refund-screen-input"><input type="text" value="1"/></li>
    //              <li className="refund-screen-btn"><button>Return</button></li>
    //          </ul>
    //     </div>

    //     <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list2">
    //              <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
    //              <li>Product Name</li>
    //              <li>Term 3</li>
    //              <li>Term B</li>
    //              <li>value 3</li>
    //              <li>75000</li>
    //              <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
    //              <li className="refund-screen-input"><input type="text" value="1"/></li>
    //              <li className="refund-screen-btn"><button>Return</button></li>
    //          </ul>
    //     </div>

    //     <div style={{display:"flex", flexDirection:"rown", jusitfyContent:"space-between", width:"85%"}}>
    //          <ul className="refund-screen-list2">
    //              <li className="refund-img-list"><img className="refund-list-image" src={product} /></li>
    //              <li>Product Name</li>
    //              <li>Term 3</li>
    //              <li>Term B</li>
    //              <li>value 3</li>
    //              <li>75000</li>
    //              <li>5.0 <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/><AiFillStar style={{color:"yellow"}}/>
    //              <AiFillStar style={{color:"yellow"}}/></li>                     <li className="refund-price">$800</li>
    //              <li className="refund-screen-input"><input type="text" value="1"/></li>
    //              <li className="refund-screen-btn"><button>Return</button></li>
    //          </ul>
    //     </div>

    // </>
    <div className="add-category-section attributes-section">
      <BreadcrumbMenu
        path={[
          { title: "Home", navLink: "/" },
          { title: "Return Products", navLink: "/returnscreen" },
        ]}
        activeTab="Return Products"
        isBack={false}
      />
      <TableFilterBar
        selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <CommonTable
        columns={columns}
        rows={returnProductList || []}
        className="attributeTable"
        rowsPerPage={rowsPerPage?.pageSize}
      />
      <Modal
        show={confirmDialog.open}
        className="e-dialog"
        centered={true}
        onHide={() =>
          setConfirmDialog({ open: !confirmDialog.open, record: {} })
        }
      >
        <Modal.Header className="" closeButton>
          Evidence
        </Modal.Header>

        <Modal.Body>
          <div className="e-img">
            <h6>Reason</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="e-img e-img-flex">
            <img src={product} alt="img" />
            <img src={product} alt="img" />
            <img src={product} alt="img" />
          </div>
          <div className="footer-button">
            <Button
              autoFocus
              onClick={() =>
                setConfirmDialog({ open: !confirmDialog.open, record: {} })
              }
            >
              Accept Return
            </Button>
            <Button>Decline Return</Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Dialog
        className="e-dialog"
        open={confirmDialog.open}
        onClose={() =>
          setConfirmDialog({ open: !confirmDialog.open, record: {} })
        }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Evidence
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="e-img">
              <h6>Reason</h6>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className="e-img e-img-flex">
              <img src={product} alt="img" />
              <img src={product} alt="img" />
              <img src={product} alt="img" />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() =>
              setConfirmDialog({ open: !confirmDialog.open, record: {} })
            }
          >
            Accept Return
          </Button>
          <Button>Decline Return</Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

export default ReturnScreen;
