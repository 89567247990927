import React, {useState} from 'react';
import "./UserProfile.css";
import cover from '../../Assets/Images/cover.jpg';
import user from '../../Assets/Images/product.png';
import ProfileTabs from '../ProfileTabs/ProfileTabs';
import Serve from '../Serve/Serve';

function UserProfile() {
    const [show,setShow]=useState(false);
    return (
        <>

        <div className="user-heading">
            <h3>User Profile</h3>
        </div>


        <div className="profile-card">
            <img src={cover} className="profile-cover" alt="logo" />
            <img src={user} className="profile-image" alt="logo" />
            <ul className="profile-details">
                <li>Jane Doe, 28</li>
                <li className="profile-designation">Lorem Ipsum</li>
                <button onClick={()=>setShow(true)} className="profile-btn">Serve</button>

                <ul className="product-options">
                    <li>Return</li>
                    <li className="refund-btn">Refund</li>
                    <li className="reorder-btn">Reorder</li>
                    <li className="track-btn">Track Order</li>
                    <li className="i-btn">Investigation</li>
                    <li className="i-btn2">User Info</li>
                  </ul>


            </ul>
        </div>

{ show?
        <div className="details-card">
        <div style={{marginTop:25, marginLeft:25}}>
            <Serve/>
        </div>
        </div>
        :null
}


        </>
    );
}

export default UserProfile;

