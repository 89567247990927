import React from "react";
import ImageUploading from "react-images-uploading";
import "./EditImage.css";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";

function EditImage({
  url,
  length,
  index,
  minus,
  addHandler,
  minusHandler,

  setHandler,
}) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    setHandler(imageList);
  };

  return (
    <div className="App">
      {url ? (
        <div className="upload__image-wrapper" style={{ marginLeft: 10 }}>
          <img className="edit-image-uploaded" src={url} alt="" width="100" />
        </div>
      ) : (
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  zIndex: 99999,
                  top: 10,
                }}
              >
                {minus ? (
                  <AiFillMinusCircle
                    className="fere"
                    style={{ right: 9 }}
                    onClick={minusHandler}
                  />
                ) : (
                  <AiFillMinusCircle
                    className="fere2"
                    style={{ right: 9 }}
                    color="transparent"
                  />
                )}

                {length ? (
                  <AiFillPlusCircle
                    className="fere"
                    style={{ left: 15 }}
                    onClick={addHandler}
                  />
                ) : (
                  <AiFillPlusCircle
                    onClick={addHandler}
                    className="fere2"
                    style={{ left: 15 }}
                    color="transparent"
                  />
                )}
              </div>
              <button
                className="edit-upload-btn"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                +
              </button>
              &nbsp;
              {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img
                    className="edit-image-uploaded"
                    src={image["data_url"]}
                    alt=""
                    width="100"
                  />
                  <div className="image-item__btn-wrapper">
                    <button
                      className="edit-image-remove"
                      onClick={() => onImageRemove(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
      )}
    </div>
  );
}

export default EditImage;
