import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./History.css";
import Tabs from "../Tabs/Tabs";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  resolve,
  tableMenuIcon,
} from "../../store/actions/actions";
import { AiOutlineClockCircle } from "react-icons/ai";
import OrderMenu from "../OrderMenu/OrderMenu";
import SaleHistory from "../SaleHistory/SaleHistory";
import { useHistory, useLocation } from "react-router-dom";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TabMenu from "../NewTab";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import TableFilterBar from "../TableFilterBar";
import CommonTable from "../Table";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@material-ui/core";
import user from "../../Assets/Images/user1.jpg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { ReactComponent as PencilIcon } from "../../Assets/Images/pencil.svg";

const tabs = [
  {
    id: "1",
    name: "All Orders",
  },
  {
    id: "2",
    name: "Completed",
  },
  {
    id: "3",
    name: "Cancelled",
  },
  {
    id: "4",
    name: "Add New",
  },
  {
    id: "5",
    name: "Return",
  },
  {
    id: "6",
    name: "Refund",
  },
  {
    id: "7",
    name: "Investigate",
  },
];

const dateSet = (item) => {
  var dateString = item;
  dateString = new Date(dateString).toUTCString();
  dateString = dateString.split(" ").slice(0, 4).join(" ");
  return dateString;
};

function History({ allorders }) {
  const orders = useSelector((state) => state.Reducer.orders);
  const tableMenu = useSelector((state) => state.Reducer.tableMenu);
  console.log("tableMenu", tableMenu);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("1");
  const [selectedTab, setSelectedTab] = useState("1");
  const [selected, setSelected] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [ordersData, setOrdersData] = useState({ orders: [], list: [] });
  const [item, setItem] = useState("");
  const [action, setAction] = useState({ open: false, data: null });

  // const handleSelection = (item) => {
  //   var selectedId = selected;

  //   if (selectedId === item.id) {
  //     setSelected(item.id);
  //   } else setSelected(item.id);
  // };
  const [dateFilter, setDateFilter] = React.useState({ from: null, to: null });

  const handleChange = (newValue) => {
    setDateFilter(newValue);
  };

  useEffect(() => {
    // if (location.state != null && location.state != undefined) {
    //   if (location.state.screenName === "Return") {
    //     setSelectedTab("5");
    //   } else {
    //     setSelectedTab("6");
    //   }
    // }
    const users = collection(db, "SimpleUsers");
    let orderList = [];
    const promises = [];

    orders &&
      orders.forEach((order, index) => {
        promises.push(
          new Promise(async (resolve) => {
            const userDetails = await getDocs(
              query(users, where("userid", "==", order.UserId))
            );
            const userDetail = userDetails?.docs?.map((doc) => doc.data());
            const user =
              (userDetail && userDetail.length && userDetail[0]) || {};
            resolve({
              id: index + 1,
              OrderId: order.OrderId,
              name: `${user?.firstName || order?.lastName || ""}`,
              Date: dateSet(order["Date"]),
              OrderDate: order?.OrderDate,
              ShippingTime: order?.ShippingTime,
              OrderStatus: order?.OrderStatus,
              timeRemaining: "13 min",
              profilePicture: user?.ProfileImage || "",
              total: `$${order?.ServiceFees}`,
              userId: user?.userid,
              // total: `$${
              //   Number(
              //     order?.ProductList?.reduce(
              //       (accum, item) => accum + item.Price,
              //       0
              //     ) || 0
              //   ) + Number(order?.ServiceFees || 0)
              // }`,
              productList: order?.productList || [],
            });
          })
        );
      });
    Promise.all(promises).then((values) => {
      orderList = values || [];
      switch (activeKey) {
        case "2":
          values = values.filter((order) => order.OrderStatus === "delivered");
        case "3":
          values = values.filter((order) => order.OrderStatus === "cancelled");
      }
      setOrdersData({ orders: [...values], list: [...values] });
    });
  }, [orders, activeKey]);
  const handleSelectionTab = (id) => {
    var selectedId = selectedTab;

    if (selectedId === id) {
      setSelectedTab(id);
    } else setSelectedTab(id);
  };

  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };

  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 70,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    {
      field: "OrderId",
      headerName: "Order ID",
      width: 200,
      sorting: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            #{params?.row?.OrderId}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            <img
              className="user-profile-pic"
              src={params?.row?.profilePicture || user}
              alt={params?.row?.name}
              width={26}
              height={26}
            />
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "timeRemaining",
      headerName: "Time Remaining",
      width: 200,
      sorting: true,
    },
    {
      field: "Date",
      headerName: "Order Date & Time",
      width: 200,
      sorting: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            Delivery
            {/* <Tooltip title="Quick Edit" placement="top">
              <PencilIcon className="cursor-pointer"/>
            </Tooltip> */}
          </div>
        );
      },
    },
    { field: "OrderStatus", headerName: "Status", width: 150 },
    { field: "total", headerName: "Total", width: 150 },
    {
      field: "Action",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,

      renderCell: (params) => {
        return (
          <Tooltip title="More Option" placement="top">
            <MenuIcon
              onClick={
                () =>
                  dispatch(
                    tableMenuIcon({
                      open: tableMenu?.open ? false : true,
                      data: params?.row,
                    })
                  )
                // setAction({ open: !action.open, data: params?.row })
              }
            />
          </Tooltip>
        );
      },
    },
  ];

  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      selectValue = ordersData?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };

  return (
    // <>
    //   <div className="history-section">
    //     <h4>Order</h4>
    //     <div className="history-date2">
    //       <DatePicker className="order-date" />
    //       <p className="order-to">To</p>
    //       <DatePicker className="order-date2" />
    //     </div>
    //   </div>

    //   {tabs.map((item) => (
    //     <div
    //       className="tab-navFF"
    //       onClick={() => {
    //         handleSelectionTab(item.id);
    //       }}
    //     >
    //       <div
    //         className="tabhistory"
    //         style={{
    //           backgroundColor:
    //             selectedTab === item.id ? "white" : "rgba(42, 143, 86, 1)",
    //           borderColor: selectedTab === item.id ? "#3FAD72" : "white",
    //           borderStyle: "solid",
    //         }}
    //       >
    //         <p
    //           style={{
    //             color: selectedTab === item.id ? "#3FAD72" : "white",
    //           }}
    //         >
    //           {item.name}
    //         </p>
    //       </div>
    //     </div>
    //   ))}

    //   <div className="ordertabborder"></div>

    //   {/* selected === item.videoid */}

    //   {selectedTab === "1" && (
    //     <div
    //       style={{ width: "80.5%", display: "flex", flexDirection: "column" }}
    //     >
    //       <SaleHistory allorders={orders} />
    //     </div>
    //   )}
    //   {selectedTab === "2" && (
    //     <div
    //       style={{ width: "80.5%", display: "flex", flexDirection: "column" }}
    //     >
    //       <SaleHistory allorders={orders} />
    //     </div>
    //   )}

    //   {selected === "3" && (
    //     <div
    //       style={{ width: "80.5%", display: "flex", flexDirection: "column" }}
    //     >
    //       <SaleHistory allorders={orders} />
    //     </div>
    //   )}
    // </>
    <div className="add-category-section orders-list">
      <BreadcrumbMenu
        path={[
          { title: "Home", navLink: "/" },
          { title: "Orders", navLink: "/order" },
        ]}
        activeTab="Orders"
        isBack={false}
      />
      <div className="d-flex justify-content-evenly align-items-center search-table-header nav-tab-main">
        <TabMenu
          activeKey={activeKey}
          tabs={tabs}
          setActiveKey={setActiveKey}
        />
      </div>
      <TableFilterBar
        selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        <div className="searchSection d-flex">
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <DatePicker
              label="Select From Date"
              value={dateFilter.from}
              className="orders-date-input"
              onChange={(newValue) => {
                setDateFilter({ ...dateFilter, from: newValue });
                // setOrdersData({ orders: orders.filter((order) => order.) })
                if (newValue && dateFilter.to) {
                  setOrdersData({
                    ...ordersData,
                    orders: ordersData?.list?.filter(
                      (order) =>
                        new Date(dateFilter.to).getTime() >=
                          new Date(order["Date"]).getTime() &&
                        new Date(newValue).getTime() <=
                          new Date(order["Date"]).getTime()
                    ),
                  });
                } else {
                  setOrdersData({
                    ...ordersData,
                    orders: [...ordersData.list],
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="Select To Date"
              value={dateFilter.to}
              onChange={(newValue) => {
                setDateFilter({ ...dateFilter, to: newValue });
                if (dateFilter.from) {
                  setOrdersData({
                    ...ordersData,
                    orders: ordersData?.list?.filter(
                      (order) =>
                        new Date(newValue).getTime() >=
                          new Date(order["Date"]).getTime() &&
                        new Date(dateFilter.from).getTime() <=
                          new Date(order["Date"]).getTime()
                    ),
                  });
                } else {
                  setOrdersData({
                    ...ordersData,
                    orders: [...ordersData.list],
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </TableFilterBar>
      <CommonTable
        columns={columns}
        rows={ordersData?.orders || []}
        className="usersTable"
        rowsPerPage={rowsPerPage?.pageSize}
      />
    </div>
  );
}

export default History;
