import React,{useEffect} from 'react';
import { Tick } from 'react-crude-animated-tick';

const UpdateThanks =({minimize,handler})=>{




    return(
        <div  className= {minimize ? "thankyou-tick-tab" : "thankyou-tick"}>
        <div>
            <Tick size={100} />
        </div>
        <h2>Product Updated Successfully</h2>
        </div> 
    );

}

export default UpdateThanks;