import React from "react";
import "./Revenue.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import RevenueGraph from "../RevenueGraph/RevenueGraph";
import RevenuePie from "../RevenuePie/RevenuePie";
import { PieChart } from 'react-minimal-pie-chart';
import BarChart from "../BarChart/BarChart";
import RevenueChart from "../RevenueChart/RevenueChart";
import ExpenseGraph from "../ExpenseGraph/ExpenseGraph";


function Revenue() {
    return (

        <>

<div className="search-section">
<div className="search-user">
        {/* <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." /> */}
      </div>

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img src={user} className="not-image-user" alt="logo" />
        <p className="not-image-text">John Doe</p>
      </div>
      {/* <GiHamburgerMenu className="hamburger" /> */}

</div>

        <h3 className="revenue">Revenue</h3>

        <div className="active-members-box">
            <h2 className="num-members">1,241</h2>
            <p className="mem-text">Active Members</p>
        </div>

        <div className="active-members-box">
            <h2 className="num-members">$3.5M</h2>
            <p className="mem-text">Total Revenue</p>
        </div>

        <div className="active-members-box">
            <h2 className="num-members">21.4%</h2>
            <p className="mem-text">Net Gain</p>
        </div>

        <div className="active-members-box">
            <h2 className="num-members">25</h2>
            <p className="mem-text">New Members</p>
        </div>

<div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width:"85%"}}> 
        <RevenueGraph/>


        {/* <div>
        <RevenuePie/>
        </div> */}

      <h3 className="revenue">Expense</h3>

        <ExpenseGraph/>
        </div>

        {/* <RevenuePie/> */}

        </>

    );
}

export default Revenue;