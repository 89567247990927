import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import "./Attribute.css";

const AddNewChild = () => {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const attributeForm = useFormik({
    initialValues: {
      name: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      resetForm({ name: "" });
    },
  });
  const { handleSubmit, values, errors, handleChange } = attributeForm;
  return (
    <div className="add-new-child-attributes">
      <div className="child-heading">Hello</div>
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <Form.Label className="attribute-form-label">
              Child Attribute Name
            </Form.Label>
            <Form.Group controlId="validationCategoryName">
              <Form.Control
                type="text"
                name="Catname"
                value={values.name}
                placeholder="Enter Child Attribute Name"
                className="CatInput"
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                Category name is required
              </Form.Control.Feedback>
            </Form.Group>
            <div className=" color-info">
              The name is how it appears on your site
            </div>
          </div>
          <div className="col-md-2">
            <button className="add-button attribute-submit">Add Child Attribute</button>
          </div>
          <div></div>
        </div>
      </Form>
    </div>
  );
};

export default AddNewChild;
