import React from "react";
import havause from "../../Assets/Images/havause.png";
import code from "../../Assets/Images/code.png";
import "./invoice.css";
const Invoice = () => {
  return (
    <div className="invoice-main">
      <div className="row justify-content-center m-0 p-0">
        <div className="col-4">
          <div className="invoice-box">
            <div className="invoice-logo">
              <img src={havause} alt="logo" />
            </div>
            <div className="invoice-info invoice-info1">
              <div className="d-flex add-info add-info1">
                <div className="info-box box1">
                  <h1>From</h1>
                  <p>Company Name</p>
                </div>
                <div className="info-box box2">
                  <h1>Adress</h1>
                  <p>Street #2, Veals Town, Bluff Parkway, USA</p>
                </div>
              </div>
              <div className="d-flex add-info add-info1">
                <div className="info-box box1">
                  <h1>To</h1>
                  <p>Company Name</p>
                </div>
                <div className="info-box box2">
                  <h1>Adress</h1>
                  <p>Street #2, Veals Town, Bluff Parkway, USA</p>
                </div>
              </div>
            </div>
            <div className="invoice-info-code">
              <div className="qr-number">
                <h5>Queue/order number. : </h5>
                <span>123456/7890</span>
              </div>
              <img src={code} alt="barcode" className="img-fluid" />
            </div>
            <div className="invoice-info">
              <div className="d-flex add-info">
                <div className="info-box box1 box-flex text-center">
                  <h1>Item Number:</h1>
                  <p>12345GT567</p>
                </div>
                <div className="info-box box2 box-flex">
                  <h1>Location:</h1>
                  <p>1245</p>
                </div>
              </div>
            </div>
            <div className="invoice-info-code invoice-info-code-new">
              <div className="qr-number">
                <h5>Queue/order number. : </h5>
                <span>123456/7890</span>
              </div>
              <img src={code} alt="barcode" className="img-fluid" />
              <div className="info-box info-box-last">
                <h1>Delivery Instructions:</h1>
                <p>123456/7890</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
