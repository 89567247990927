import React from 'react';
import "./Counter.css";

class Counter extends React.Component {
  state = { count: 0 }

  increment = () => {
    this.setState({
      count: this.state.count + 1
    });
  }

  decrement = () => {
    this.setState({
      count: this.state.count - 1
    });
  }

  render() {
    return (
        <div className="count-btns">
          <button className="increment-btn" onClick={this.decrement}>-</button>
          <span>{this.state.count}</span>
          <button className="decrement-btn" onClick={this.increment}>+</button>
        </div>
    )
  }
}

export default Counter;
