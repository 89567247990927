import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loading = () => (
  <div className="loaderback">
    <Loader
      type="Puff"
      color="#3FAD72"
      className="loader"
      height={100}
      width={100}
    />
  </div>
);
export default Loading;
