import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { db } from "../../firebase/firebase";
import * as yup from "yup";
import forgotPassCanvas from "../../Assets/Images/forgot-pass-canvas.svg";
import havause from "../../Assets/Images/havause.png";
import logo from "../../Assets/Images/logo.jpeg";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { Toaster } from "../../utils/constant";

const ForgotPass = () => {
    const [loading, setLoading] = useState(false);
  const history = useHistory();
  const schema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required(),
  });
  const userForm = useFormik({
    initialValues: { email: "" },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
        setLoading(true);
      let arr = [];
      const q = query(
        collection(db, "AdminUsers"),
        where("email", "==", values.email)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          arr.push(doc.data());
          console.log(doc.id, " => ", doc.data());
        }
      });
      if (arr.length) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          data: {
            email: values.email,
            name: "Admin",
            message: `Please reset your password on this link https://sandstrip.com/#/resetPasswordAdmin/${arr[0].userid}`,
          },
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            Toaster({
                type: "success",
                message: "Password reset Link in sent to your email.",
              });
            resetForm({email: ""})
          })
          .catch((error) => console.log("error", error));
      } else {
        Toaster({
            type: "error",
            message: "Email is not Registered",
          });
      }
      setLoading(false);
    },
  });
  const { handleSubmit, values, errors, handleChange, touched, handleBlur } =
    userForm;

  return (
    <div className="admin-login">
      <div className="login-container">
        <div className="row login-layout">
          <div className="col-md-7 login-canvas">
            <div className="d-flex justify-content-center align-items-center">
              <img src={havause} width={149} height={23} />
              <img src={logo} width={212} height={56} />
            </div>
            <div className="forgetPass-img mx-auto">
              <img src={forgotPassCanvas} width={564} height={289} />
            </div>
          </div>
          <div className="col-md-5 px-5 align-self-center">
            <div className="title">Forgot Password</div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder="Enter email"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <LoadingButton
                type="submit"
                loading={loading}
                className="login-btn d-block add-user-btn"
              >
                Change Password
              </LoadingButton>
            </Form>
            <p
              className="forgetPassword"
              onClick={() => history.push("/login")}
            >
              Back to Login
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
