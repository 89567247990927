import React, { useState, useEffect } from "react";
import "./CalenderEvents.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import event from "./event";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { collection, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

function CalenderEvents() {
  const [eve, setEve] = useState([]);
  const [use, setUse] = useState("");
  let data = useSelector((state) => state.Reducer.events);
  let users = useSelector((state) => state.Reducer.users);
  let vendors = useSelector((state) => state.Reducer.vendors);
  let admins = useSelector((state) => state.Reducer.admins);
  let customerService = useSelector((state) => state.Reducer.customerService);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const addEvent = async (event) => {
    const docRef = doc(db, "Events", "allevents");

    await updateDoc(docRef, {
      events: event,
    });
  };

  const getData = async () => {};
  useEffect(() => {
    let newArray = [];
    data.map((item) => {
      newArray.push({
        allday: item.allday,
        start: item.start.toDate().toDateString(),
        end: item.end.toDate().toDateString(),
        title: item.title,
      });
    });
    setEve(newArray);
  }, []);

  const handleSelect = ({ start, end }) => {
    console.log("FFFFF", start);
    let allday = true;
    const title = window.prompt("New Event name");
    if (title) {
      let newArray = [...eve];
      newArray.push({
        start,
        end,
        title,
      });
      console.log(newArray);
      setEve(newArray);
      addEvent(newArray);
      toast("Event Created Successfully");
    }
  };
  return (
    <>
      <h3 className="events-heading">Add Events</h3>
      <div className="event-select-user">
        <h4 className="events-user">Add User:</h4>
        <select
          onChange={(event) => {
            if (!selectedUsers.includes(event.target.value)) {
              if (event.target.value === "Single Email") {
                setSelectedUsers([]);
                setUse(event.target.value);
              }
              if (event.target.value === "All Customer") {
                setUse("");

                setSelectedUsers(users);
              }
              if (event.target.value === "All Employees") {
                setUse("");

                setSelectedUsers(customerService);
              }
              if (event.target.value === "All Vendors") {
                setUse("");

                setSelectedUsers(vendors);
              }
              if (event.target.value === "All Admins") {
                setUse("");

                setSelectedUsers(admins);
              }
              // let newArray = [...selectedUsers];
              // newArray.push(event.target.value);
              // setSelectedUsers(newArray);
              // console.log(selectedUsers);
            }
          }}
        >
          <option>Select Emails</option>
          {/* {newData.map((item) => ( */}
          <option value={"Single Email"}>{"Single Email"}</option>
          <option value={"All Customer"}>{"All Customer"}</option>
          <option value={"All Employees"}>{"All Employees"}</option>
          <option value={"All Vendors"}>{"All Vendors"}</option>
          <option value={"All Admins"}>{"All Admins"}</option>

          {/* ))} */}
        </select>

        {use === "Single Email" ? (
          <div className="fazzy">
            <h5>Enter Email</h5>
            <input
              className="coupon-code"
              type="text"
              // value={emailInput}
              // onChange={(event) => setEmailInput(event.target.value)}
            />
          </div>
        ) : null}
      </div>

      {selectedUsers.length ? (
        <div
          style={{
            float: "left",
            marginLeft: 201,
            marginTop: 20,
            border: "1px solid #ccc",
            width: "50%",
            padding: 10,
          }}
        >
          {selectedUsers.map((item) => (
            <div
              style={{
                backgroundColor: "#3fad72",
                height: 30,
                //   width: 100,
                marginLeft: 10,
                float: "left",
                borderRadius: 5,
                marginBottom: 10,
              }}
            >
              {item.email ? (
                <div>
                  <p style={{ color: "white", padding: 5 }}>{item.email}</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      <div className="fazzy">
        <h5>Enter Note</h5>
        <textarea
          className="coupon-code"
          type="text"
          // value={emailInput}
          // onChange={(event) => setEmailInput(event.target.value)}
        />
      </div>
      <div className="fazzy">
        <button
          style={{
            marginLeft: 110,
            background: "#3fad72",
            borderColor: "#3fad72",
            color: "white",
            padding: 8,
            border: "1px solid #3FAD73",
          }}
        >
          Submit
        </button>
      </div>
      <Calendar
        selectable
        className="event-calendar"
        localizer={localizer}
        events={eve}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={(event) => {
          console.log(event, "GGG");
          alert(event.title);
        }}
        onSelectSlot={(event) => handleSelect(event)}
      />
      {/* <div className="date-events">
        <div className="day1-event">
          <p>
            15 <span className="day-event">SUNDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            16 <span className="day-event">MONDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            17 <span className="day-event">TUESDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            18 <span className="day-event">WEDNESDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            19 <span className="day-event">THURSDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            20 <span className="day-event">FRIDAY</span>
          </p>
        </div>

        <div className="day1-event">
          <p>
            21 <span className="day-event">SATURDAY</span>
          </p>
        </div>
      </div> */}
      <ToastContainer />
    </>
  );
}

export default CalenderEvents;
