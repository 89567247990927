import React from "react";
import "./EditOrder.css";
import order from "../../Assets/Images/order.png";

function EditOrder() {
    return(

        <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"85%"}}>
                <div className="product-order-edit">
                    <img src={order}/>
                    <p>Segate 1TB Hard Drive</p>
                </div>
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"85%", marginTop:"50px"}}>
                <div className="edit-order-fields">
                    <p className="payment-field-text">Payment</p>
                    <input className="edit-payment-field" type="text" value="Google Pay"/> 
                    <p className="payment-field-edit">Edit</p>

                    <div className="clearfix"></div>

                    <p className="payment-field-text">Location</p>
                    <input className="edit-payment-field" type="text" value="Australia"/> 
                    <p className="payment-field-edit">Edit</p>
                    
                    <div className="clearfix"></div>

                    <p className="payment-field-text">Shipping</p>
                    <input className="edit-payment-field" type="text" value="Location"/> 
                    <p className="payment-field-edit">Edit</p>

                    <div className="clearfix"></div>

                    <p className="payment-field-text">Status</p>
                    <input className="edit-payment-field" type="text" value="Cancelled"/> 
                    <p className="payment-field-edit">Edit</p>
                </div>
            </div>
        </>

    );
}

export default EditOrder