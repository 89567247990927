import React, { useState, useEffect } from "react";
import "./Navigation.css";
import logo from "../../Assets/Images/logo.jpeg";
import { Col } from "react-bootstrap";
import { AiOutlineHome, AiFillBell, AiFillBoxPlot } from "react-icons/ai";
import { FiBox } from "react-icons/fi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import SettingMenu from "../SettingMenu/SettingMenu";
import ProductMenu from "../ProductMenu/ProductMenu";
import { AiFillDatabase } from "react-icons/ai";
import DataTable from "../DataTable/DataTable";
import UserMenu from "../UserMenu/UserMenu";
import { MdPermMedia } from "react-icons/md";
import DataMenu from "../DataMenu/DataMenu";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { IoSettings } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";

const viewHeight = window.outerHeight;

const NavigationS = ({ url }) => {
  const hide = useSelector((state) => state.Reducer.hide);
  const vendor = useSelector((state) => state.Reducer.vendorEnable);
  const history = useHistory();
  const [user, setUser] = useState(false);
  const [product, setProduct] = useState(null);
  const [setting, setSetting] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {}, [url]);
  return (
    <div className="nav-area2" style={{ display: hide === true && "none" }}>
      <div>
        <div style={{ marginBottom: 40 }}>
          {/* <div href="#" className="logo">
            <Link to={vendor ? "/analytics" : "/"}>
              <img src={logo} className="logo-image" alt="logo" />
            </Link>
          </div> */}
        </div>

        <Navigation
          style={{ marginTop: 30 }}
          // you can use your own router's api to get pathname

          onSelect={({ itemId }) => {
            if (
              itemId === "//" ||
              itemId === "///" ||
              itemId === "////" ||
              itemId === "/////"
            ) {
              console.log("Hello");
            } else {
              history.push(itemId);
            }
          }}
          items={[
            {
              title: "Home",
              itemId: "/analytics",
              elemBefore: () => <AiOutlineHome className="user-gear" />,
            },
            {
              title: "Products",
              itemId: "//",
              elemBefore: () => (
                <FiBox
                  className="product-box"
                  aria-controls="setting-menu"
                  aria-haspopup="true"
                />
              ),

              subNav: [
                {
                  title: "Active Products",
                  itemId: "/AllVendoractive",
                },
                {
                  title: "Pending Products",
                  itemId: "/AllVendorpending",
                },
                {
                  title: "Add Product",
                  itemId: "/addproductVendor",
                },
                {
                  title: "Add Category",
                  itemId: "/vendorcategory",
                },
                {
                  title: "Create Attribute",
                  itemId: "/vendorattribute",
                },
              ],
              // you can use your own custom Icon component as well
              // icon is optional
              // elemBefore: () => <Icon name="inbox" />,
            },

            {
              title: "Settings",
              itemId: "analytics",
              elemBefore: () => (
                <IoSettings
                  className="setting-gear"
                  aria-controls="setting-menu"
                  aria-haspopup="true"
                />
              ),

              subNav: [
                {
                  title: "Payment",
                  itemId: "analytics",
                },
                {
                  title: "Store SEO",
                  itemId: "analytics",
                },
              ],
            },
            {
              title: "Data Table",
              itemId: "analytics",
              elemBefore: () => <AiFillDatabase className="user-gear" />,
              subNav: [
                {
                  title: "Add Expenses",
                  itemId: "/AddExpense",
                },
                {
                  title: "View Expenses",
                  itemId: "/ViewExpense",
                },
              ],
            },
            {
              title: "Selling",
              itemId: "analytics",
              elemBefore: () => <AiOutlineShoppingCart className="user-gear" />,
              // elemBefore: () => <Icon name="users" />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default NavigationS;
