import React from "react";
import { Line } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import "./RevenueGraph.css";
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
class RevenueGraph extends React.Component {
  state = {
    dataLine: {
      labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      datasets: [
        {
          label: "Revenue Per Month    ",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "#3FAD72",
          borderColor: "#3FAD72",
          borderCapStyle: "linear",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40]
        },
        
      ]
    }
  };

  render() {
    return (
      <MDBContainer>
        <Line data={this.state.dataLine} options={{ responsive: true }} />
      </MDBContainer>
    );
  }
}

export default RevenueGraph;