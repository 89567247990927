import React from 'react';
import './Download.css';
import { MdDownloadForOffline } from "react-icons/md";

function Cart() {
    return (
        <>
        <div className="download-section">
            {/* <FaDownload className="cart-icon"/> */}
        <MdDownloadForOffline className="cart-icon"/>
            <div className="order-numbers">
                <p className="order-text">Downloads</p>
            </div>
             {/* <div className="download-border">
             <p className="border-text">Lorem</p>
                </div> */}
                
        </div>

        </>
    );
}

export default Cart;