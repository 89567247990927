import React from "react";
import "./Inventory.css";
import { FaUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';

function Inventory() {
    return (
        <>
            
            <div className="add-product-section">

                <div className="add-product-header">

                    <h3>Inventory</h3>
                    <div className="new-user">
                        <FaUserCircle className="user-icon"/>
                        <select className="user-select">
                            <option value="A">admin</option>
                            <option value="B">admin</option>
                            <option value="C">admin</option>
                        </select>
                    </div>

                    <div className="save-product-section">
                        <button className="product-save">
                            Save
                        </button>

                        <div className="product-back">
                            <BiArrowBack className="back-arrow"/>
                            <p className="back-text">Back</p>
                        </div>
                    </div>
            </div>

        <div style={{marginLeft:30}}>
            <div className="edit-name-inventory">
                <h5>SKU</h5>
            </div>
            <input className="input-product" type="text"/>
            <div className="clearfix"></div>

            <div className="edit-name">
                <h5>Manage Stock</h5>
            </div>

                <Form.Group className="mb-3 inventory-check" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Enable stock management at product level" />
                </Form.Group>

            <div className="clearfix"></div>

            <div className="edit-name-stock">
                <h5>Stock Quantity</h5>
            </div>
            <input className="input-product" type="text"/>

            <div className="clearfix"></div>


            <div className="edit-stock-back">
                    <h5>Allow back-orders?</h5>
                    </div>
                    <select className="input-product2">
                        <option value="A">Do not allow</option>
                        <option value="B">Allow, but notify customer</option>
                        <option value="C">Allow</option>
                    </select>

            <div className="clearfix"></div>

            <div className="edit-stock-back">
                    <h5>Low stock threshold</h5>
                    </div>
                    <input className="input-product" type="text"/>


            <div className="clearfix"></div>

            <div className="edit-stock">
                    <h5>Stock Status</h5>
                    </div>
                    <select className="input-product2">
                        <option value="A">In Stock</option>
                        <option value="B">Out of Stock</option>
                    </select>

                    <div className="clearfix"></div>

                    <div className="edit-sold">
                        <h5>Sold Individually</h5>
                    </div>

                <Form.Group className="mb-3 inventory-check" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Enable this to only allow one of this item 
                    to be bought in a single order" />
                </Form.Group>

            <div className="clearfix"></div>
            </div>
            </div>
       
        </>
    );
}

export default Inventory;