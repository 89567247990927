import React, { useEffect, useRef, useState } from "react";
import CustomDropdown from "../Dropdown";
import "./TableFilterBar.css";
const TableFilterBar = ({ handleChangeRowsPerPage, selectValue, children }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  return (
    <div className="d-flex justify-content-between align-items-center search-table-header">
      <div className="d-flex align-items-center">
        <div className="table-header-title">Show</div>
        <div className="table-header-title-drop">
          <CustomDropdown
            option={[10, 25, 50, 100]}
            width={"57px"}
            justifyContent={"center"}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            selectedValue={selectValue}
            isActive={isActive}
            setIsActive={setIsActive}
            ref={ref}
          />
        </div>
        <div className="table-header-title">entries</div>
      </div>
      {children}
    </div>
  );
};

export default TableFilterBar;
