import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
class PieChartNew extends React.Component {
state = {
  dataDoughnut: {
    labels: ["Completed", "Incomplete"],
    datasets: [
      {
        data: [300, 50],
        backgroundColor: ["blue", "#F7464A"],
        hoverBackgroundColor: [
          "blue",
          "red",
        ]
      }
    ]
  }
}

render() {
    return (
    <MDBContainer>
      <Doughnut data={this.state.dataDoughnut} options={{ responsive: true }} />
    </MDBContainer>
    );
  }
}

export default PieChartNew;