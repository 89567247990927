import React from "react";
import "./OrderInfo.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import user from "../../Assets/Images/user1.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import shirt from "../../Assets/Images/shirt.png";
import TrackPop from "../TrackPop/TrackPop";
import { useHistory } from "react-router-dom";

function OrderInfo() {
  const history = useHistory();
  return (
    <div className="order-account-info">
      {/* <div className="search-user">
            <FiSearch className="usersearch-icon"/>
            <input className="input-search" type="text" placeholder="Search..."/>
        </div>

        <div className="not-details2">
                
                        <BsDot className="not-dot"/>
                        <BsFillEnvelopeFill className="message-icon"/>
                   
                        <BsDot className="not-dot2"/>
                        <BsBellFill className="message-icon"/>
        </div>
        <div className="not-image">
            <img src={user} className="not-image-user" alt="logo" />
            <p className="not-image-text">John Doe</p>
        </div> */}

      <div className="order-info">
        <h5>Order & Account Information</h5>

        <ul className="order-nav">
          <li className="active-nav">Home</li>
          <li>Pages</li>
          <li>Create Invoice</li>
        </ul>
      </div>

      <div className="item-order">
        <p className="order-items">Items</p>
        <ul className="item-nav">
          <li>Quantity</li>
          <li>Unit Price</li>
          <li>Total</li>
        </ul>
      </div>

      <div className="item-shirt">
        <img src={shirt} alt="logo" />
        <ul className="item-total">
          <li>08</li>
          <li>$84.50</li>
          <li style={{ color: "rgba(127, 99, 244, 1)" }}>$676.00</li>
        </ul>
      </div>
      <div className="item-shirt">
        <img src={shirt} alt="logo" />
        <ul className="item-total">
          <li>08</li>
          <li>$84.50</li>
          <li style={{ color: "rgba(127, 99, 244, 1)" }}>$676.00</li>
        </ul>
      </div>
      <div className="item-shirt">
        <img src={shirt} alt="logo" />
        <ul className="item-total">
          <li>08</li>
          <li>$84.50</li>
          <li style={{ color: "rgba(127, 99, 244, 1)" }}>$676.00</li>
        </ul>
      </div>

      <div className="bill-total">
        <ul className="order-bill">
          <li>Sub-Total Amount</li>
          <li className="total-price">$1520.00</li>
        </ul>
      </div>

      <div className="bill-total2">
        <ul className="order-bill2">
          <li>Shipping Fee</li>
          <li className="total-price">$03.84</li>
        </ul>
      </div>

      <div className="shipping-border"></div>

      <div className="bill-total">
        <ul className="order-bill3">
          <li>Grand Total</li>
          <li className="total-price">$1523.84</li>
        </ul>
      </div>

      <div>
        <div className="invoice-details">
          <div>
            <p className="invoice">Invoice No:</p>
            <input
              type="text"
              placeholder="Order # 0000000008 (The order confirmation email 
                was sent)"
              className="invoice-input2"
            />
            <h5 className="account-info">
              Account Information (
              <span style={{ color: "rgba(42, 143, 86, 1)" }}>
                Edit Customer)
              </span>
            </h5>
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Order Date:</p>
            <input
              type="text"
              placeholder="March 20 2018"
              className="invoice-input2"
            />
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Order Status:</p>
            <input
              type="text"
              placeholder="Processing"
              className="invoice-input2"
            />
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Purchased From:</p>
            <input
              type="text"
              placeholder="Mainwebsite, Main Store"
              className="invoice-input2"
            />
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Placed From Id:</p>
            <input
              type="text"
              placeholder="10.222.456.2"
              className="invoice-input2"
            />
          </div>
        </div>
      </div>

      <div className="customer-details">
        <div className="invoice-details2">
          <div style={{ float: "left", marginTop: "45px" }}>
            <p className="invoice">Customer Name:</p>
            <input
              type="text"
              placeholder="Full Name"
              className="invoice-input3"
            />
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Email:</p>
            <input
              type="text"
              placeholder="example@mail.com"
              className="invoice-input3"
            />
          </div>
        </div>

        <div className="invoice-details2">
          <div>
            <p className="invoice">Customer Group:</p>
            <input
              type="text"
              placeholder="Regular"
              className="invoice-input3"
            />
          </div>
        </div>

        <div className="account-buttons">
          <button className="print-btn">Print Invoice</button>
          <button className="label-btn" onClick={() =>history.push('/invoice')}>Print Label</button>
        </div>
      </div>

      <h5 className="address-info">Address Information</h5>
      <div className="address-border"></div>

      <div className="billing-detail">
        <h5>
          Billing Address (
          <span style={{ color: "rgba(42, 143, 86, 1)" }}>Edit</span>)
        </h5>
        <p className="bill-address">
          Street #2, Veals Town
          <br />
          Bluff Parkway, USA
        </p>
      </div>

      <div className="billing-detail2">
        <h5>
          Shipping Address (
          <span style={{ color: "rgba(42, 143, 86, 1)" }}>Edit</span>)
        </h5>
        <p className="bill-address">
          Street #2, Veals Town
          <br />
          Bluff Parkway, USA
        </p>

        <div className="account-buttons2">
          <TrackPop />
          {/* <button className="print-btn2">Track Order</button> */}
          <button className="label-btn2" onClick={() =>history.push('/invoice')}>Print Label</button>
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
