import React, { useState } from "react";
import "./AttributeInner.css";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
function AttributeInner() {
  const [child, setChild] = useState("");
  const [at, setAt] = useState(useSelector((state) => state.Reducer.attItem));
  console.log(at, "HHHH");
  const updateAt = async () => {
    let newData = { ...at };
    newData.childs.push({
      name: child,
    });
    console.log(newData, "KKK");
    setAt(newData);
    const pRef = doc(db, "ProductAttributes", `${at.atid}`);
    await updateDoc(pRef, {
      childs: newData.childs,
    });
    setChild("");
  };

  return (
    <div className="attribute-section">
      <div className="attribute-heading">
        <h3>{at.name}</h3>

        <h6 className="new-att">Add New Child</h6>
        <div className="name-att">
          <p className="edit-att">Name</p>
          <input
            type="text"
            value={child}
            onChange={(event) => setChild(event.target.value)}
          />
          <p className="att-desc">The name is how it appears on your site</p>
        </div>

        <button className="att-save-btn" onClick={updateAt}>
          Save
        </button>
      </div>

      <div className="att-card-border">
        <ul className="att-details4">
          <li>
            <Form.Group
              className="mb-3 check-allproducts"
              controlId="formBasicCheckbox"
            >
              <Form.Check type="checkbox" label="Name" />
            </Form.Group>
          </li>
          <li className="desc">Description</li>
          <li className="desc">Slug</li>
          <li className="desc">Count</li>
        </ul>

        {at.childs.length ? (
          <>
            {at.childs.map((item) => (
              <div className="added-att">
                <ul className="att-details3">
                  <li>
                    <Form.Group
                      className="mb-3 check-allproducts3"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check type="checkbox" label={item.name} />
                    </Form.Group>
                  </li>
                  <li className="size-att2">{item.name}</li>
                  <li className="count-att">0</li>
                </ul>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default AttributeInner;
