import React from "react";
import { Form } from "react-bootstrap";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import * as yup from "yup";
import { useFormik } from "formik";
import DummyProfile from "../../Assets/Images/dummyProfile.jpg";
import "./editProfile.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { Toaster } from "../../utils/constant";

const userInitValue = {
  FirstName: "",
  LastName: "",
  email: "",
  profilePicture: null,
};
const suser = localStorage.getItem("AdminUser");
const EditProfile = () => {
  const schema = yup.object().shape({
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    email: yup.string().email("Please enter a valid email").required(),
  });

  const userForm = useFormik({
    initialValues: userInitValue,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const pRef = doc(db, "AdminUsers", `${JSON.parse(suser).userid}`);
      await updateDoc(pRef, values);
      Toaster({
        type: "success",
        message: "Successfully updated profile.",
      });
      resetForm({});
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
  } = userForm;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        className="add-category-section add-product"
      >
        <div>
          <div className="d-flex justify-content-between align-items-center search-table-header">
            <div className="d-flex align-items-center">
              <BreadcrumbMenu
                path={[]}
                activeTab="Edit Profile"
                isBack={false}
              />
            </div>
          </div>

          <div className="d-flex  align-items-center  justify-content-center search-table-header"></div>

          <Form.Group
            controlId="validationCategoryName"
            className="d-flex align-items-center justify-content-center"
          >
            <label className="upload-Label h-auto d-flex flex-column align-items-center">
              <img src={DummyProfile} className="profile-img" alt="no-img" />
              <p className="profile-name-text">John Doe</p>
              <Form.Control
                type="file"
                name="profilePicture"
                placeholder="Upload Image"
                className="upload-file-new w-100"
                onChange={(event) =>
                  setFieldValue(
                    "profilePicture",
                    event?.currentTarget?.files[0]
                  )
                }
              />
            </label>
          </Form.Group>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Enter Email"
                value={values.email}
                className="CatInput"
                onBlur={handleBlur}
                isInvalid={!!errors.email && touched.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                value={values.FirstName}
                placeholder="Enter FirstName"
                className="CatInput"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.FirstName && touched.FirstName}
              />
              <Form.Control.Feedback type="invalid">
                First name is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label> Last Name </Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                value={values.LastName}
                placeholder="Enter Last Name"
                className="CatInput"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.LastName && touched.LastName}
              />
              <Form.Control.Feedback type="invalid">
                Last name is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4">
            <div className="row">
              <div className="col-6">
                <button className="searchButton searchButton-table w-100 font-size-btn">
                  Change Password
                </button>
              </div>
              <div className="col-6">
                <button className="searchButton searchButton-table w-100 font-size-btn">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditProfile;
