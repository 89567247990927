import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCAMeiyvJKGVlU0hFXz_9Qvz_OrnKL2ZII",
  authDomain: "havazuse-376c7.firebaseapp.com",
  databaseURL: "https://havazuse-376c7-default-rtdb.firebaseio.com",
  projectId: "havazuse-376c7",
  storageBucket: "havazuse-376c7.appspot.com",
  messagingSenderId: "898312628945",
  appId: "1:898312628945:web:c14537d1b84e5f22c473f0",
  measurementId: "G-NLLYTEF9MB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storageRef = getStorage(app);
export const database = getDatabase(app);
