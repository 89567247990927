import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import "./Marketplace.css";
import { FiSearch } from "react-icons/fi";
import { BsFilter } from "react-icons/bs";
import product from "../../Assets/Images/product.png";
import EditProduct from "../EditProduct/EditProduct";
import EditMarket from "../EditMarket/EditMarket";
import { useDispatch, useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  getAllProducts,
  getAllCategories,
  selectProduct,
  deleteProduct,
} from "../../store/actions/actions";
import { doc, deleteDoc } from "firebase/firestore";
import Dynamic from "../DynamicModal/DynamicModal";
import { db } from "../../firebase/firebase";
const viewHeight = window.outerHeight;

function Marketplace() {
  const dispatch = useDispatch();
  const [pro, setPro] = useState([]);
  const [search, setSearch] = useState("z4tZeVD35u53rEq3LSvZ");
  const [catSearch, setCatSearch] = useState("");
  const [data, setData] = useState([]);
  const products = useSelector((state) => state.Reducer.products);
  const catnames = useSelector((state) => state.Reducer.categories);
  const [real, setReal] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [dynamic, setDynamic] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [item, setItem] = useState(null);
  const handleSelectionMultiple = (item) => {
    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.userid)) {
      selectedIdss = selectedIds.filter((_id) => _id !== item.Pid);
    } else {
      selectedIdss.push(item.Pid);
    }
    setSelectedIds(selectedIdss);
  };

  useEffect(() =>{
    dispatch(getAllProducts());
  }, []);

  useEffect(() => {
    let newPro = [];
    products && products.length && products.map((item) => {
      if (item.Optionid === search) {
        newPro.push(item);
      }
    });
    setData([...newPro]);
  }, [products, search]);

  const getCatName = (item) => {
    let name = catnames.filter((data) => data.Catid === item.Catid);
    return name[0] != undefined ? name[0].Catname : "Unknown";
  };

  const onChangeSearch = () => {
    let newA = products && products.length && products.filter((product) =>
      product.Pname.toLowerCase().includes(real.toLowerCase())
    );
    console.log(newA, "trtrtrt");
    setData(newA);
  };

  return (
    <>
      <div className="all-products">
        <div className="products-heading">
          <h3>All Products</h3>
          <p>{data.length} Products</p>
        </div>
        <Col className="search-area">
          <div>
            <FiSearch className="search-icon2" onClick={onChangeSearch} />
            <input
              className="search-bar2"
              placeholder="Search"
              value={real}
              onChange={(event) => setReal(event.target.value)}
            />
          </div>
        </Col>
        <div className="product-border"></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Group
            className="mb-3 check-allproducts"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              checked={selectAll}
              type="checkbox"
              label="Select All Products"
              onClick={() => {
                setSelectAll(!selectAll);
                if (selectAll) {
                  setSelectedIds([]);
                } else {
                  let newArray = [];
                  data.map((item) => {
                    newArray.push(item.Pid);
                  });
                  setSelectedIds(newArray);
                }
              }}
            />
          </Form.Group>

          <Link to="/showredeem">
            <div>
              <button className="pending-btn2">Redeem Products</button>
            </div>
          </Link>

          <Link to="/pendingproduct">
            <div>
              <button className="pending-btn2">Pending Products</button>
            </div>
          </Link>

          <select
            className="category"
            value={catSearch}
            onChange={(event) => {
              setCatSearch(event.target.value);
              let newPro = [];
              products && products.length && products.map((item) => {
                if (item.Catid === event.target.value) {
                  newPro.push(item);
                }
              });
              setData(newPro);
            }}
          >
            {catnames.map((item) => (
              <option value={item.Catid}>{item.Catname}</option>
            ))}
          </select>

          <select
            className="category2"
            value={search}
            onChange={(event) => {
              console.log(event.target.value, "bemo");
              setSearch(event.target.value);
            }}
          >
            <option value="z4tZeVD35u53rEq3LSvZ">Havazuse</option>
            <option value="CjRug8sVa2x79rZOmBmL">Havazuse Bemo</option>
            <option value="lxpkMyDz8aMzGvU6vxbN">Havazuse Plus</option>
          </select>
          <select className="category">
            <option value="A">More Filters</option>
            <option value="B">Public</option>
            <option value="C">Private</option>
          </select>
        </div>
        <div style={{ height: viewHeight, overflowX: "scroll" }}>
          {data && data.length ? data.map((item) => (
            <div key={item.Pid} className="product-card">
              <Form.Group
                className="mb-3 check-allproducts2"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  checked={selectedIds.includes(item.Pid) ? true : false}
                  onClick={() => handleSelectionMultiple(item)}
                />
              </Form.Group>

              <Link
                onClick={() => {
                  dispatch(selectProduct(item));
                }}
                to="/updateProduct"
                style={{ color: "black", textDecoration: "none" }}
              >
                <img
                  src={item?.Pimages?.length ? item?.Pimages[0] : null}
                  className="product-image"
                  alt="logo"
                />
                <div className="product-desc">
                  <p className="name">Product Name</p>
                  <h6 className="product-name">{item.Pname}</h6>
                </div>
              </Link>
              <div className="product-desc">
                <Link to="productreviews">
                  <button className="review-btn">Reviews & Comments</button>
                  {/* <p className="name">Marketplace</p>
                <h6 className="product-name">Trendyol, Joom</h6>
                <p className="name">4 more places</p> */}
                </Link>
              </div>

              <div className="product-desc">
                <p className="name">Inventory</p>
                <h6 className="product-name">0 in stock for</h6>
                <p className="name">0 variants</p>
              </div>

              <div className="product-desc">
                <p className="name">Location</p>

                <h6 className="product-name">
                  {item.location ? item.location : "No Location"}
                </h6>
              </div>

              <div className="product-desc">
                <p className="name">Category Name</p>

                <h6 className="product-name">{getCatName(item)}</h6>
              </div>

              <div className="product-desc">
                <p className="name">Parent SKU</p>
                <h6 className="product-name">GM1609982</h6>
              </div>

              {/* <button onClick={()=>setShow(true)} className="quick-btn">Quick Edit</button> */}
              <div>
                {item && <EditMarket item={item} />}
              </div>

              <FaTrash
                className="trash-product"
                onClick={() => {
                  setItem(item);
                  setDynamic(true);
                }}
              />
            </div>
          )) : <div>No products found</div>}
        </div>
        <Dynamic
          show={dynamic}
          buttonTitle={"Delete"}
          account={""}
          text={"Do you want to delete this product? "}
          close={() => {
            setDynamic(false);
          }}
          saveChanges={async () => {
            console.log("pressed");
            await deleteDoc(doc(db, "Products", `${item.Pid}`));
            dispatch(deleteProduct(item.Pid));
            let newProduct = data.filter((ques) => ques.Pid !== item.Pid);
            setData(newProduct);
            setDynamic(false);
          }}
        />
      </div>
    </>
  );
}

export default Marketplace;
