import React, { useEffect, useState } from "react";
import "./Chat.css";
import { BsFillChatDotsFill } from "react-icons/bs";
import user from "../../Assets/Images/product.png";
import { IoIosArrowUp } from "react-icons/io";
import chat from "../../Assets/Images/chat.png";
import { GoPrimitiveDot } from "react-icons/go";
import { GrAttachment } from "react-icons/gr";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { RiSendPlane2Fill } from "react-icons/ri";
import UserCards from "../UserCards/UserCards";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import calling from "../../Assets/Images/calling.png";
import { AiFillPhone } from "react-icons/ai";
import { FaHome } from "react-icons/fa";

import {
  getSingleUSer,
  getUserMessages,
  hide,
  addMessage,
  getAllSupportUser,
  setCustomer,
  setAdmin,
  setVendor,
} from "../../store/actions/actions";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [selected, setSelected] = useState("");
  const [sel, setSel] = useState({});
  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.user.userid) {
      setSel(item);
      setSelected(item.user.userid);
    } else {
      setSel(item);

      setSelected(item.user.userid);
    }
  };

  const userMessages = useSelector((state) => state.Reducer.userChats);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.match.params.id != "allchat" &&
      props.match.params.id != undefined
    ) {
      setSelected(props.match.params.id);
      let newData = userMessages.filter(
        (it) => it.user.userid != props.match.params.id
      );
      setSel(newData[0]);
    } else {
      setSelected("5usDYPd0XUYuPATwl4VafFVsioo1");
      setSel(userMessages[0]);
    }
  }, []);

  const sendMessges = async () => {
    if (userMessages.length) {
      dispatch(
        addMessage({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        })
      );
      const chatMessages = doc(
        db,
        "CustomerSupportChat",
        props.match.params.id
      );

      await updateDoc(chatMessages, {
        messages: arrayUnion({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        }),
      });
      setMsg("");
    } else {
      dispatch(
        addMessage({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        })
      );
      const chatMessages = doc(
        db,
        "CustomerSupportChat",
        props.match.params.id
      );

      await setDoc(chatMessages, {
        messages: [
          {
            reciverId: props.match.params.id,
            senderId: "Admin",
            message: msg,
          },
        ],
      });
      setMsg("");
    }
  };

  useEffect(() => {
    // dispatch(getAllSupportUser());

    dispatch(hide(true));
  }, []);
  return (
    <>
      <div className="chat-section">
        <div className="active-section">
          <Link to="/inner">
            <FaHome className="call-homeicon" />
          </Link>
          <Link to="/vcalls">
            <div className="msgCall">
              {/* <img src={calling} className="call-icon2" /> */}
              <AiFillPhone className="call-phone" />
              <div className="call-numbers">
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "white",
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                >
                  Live Call
                </p>
              </div>
            </div>{" "}
          </Link>
          <div className="chat-messenger">
            <h6>QuickChat</h6>
          </div>
          <div className="search-area-product1">
            <div>
              <FiSearch className="search-icon-chat" />
              <input className="search-bar-chat" placeholder="Search" />
            </div>
          </div>
          {selected.length && selected === sel.user.userid ? (
            <Link to={`/listings/${sel.user.userid}`}>
              <div className="chat-intro">
                <img
                  src={sel.user.ProfileImage}
                  className="user-chat-image"
                  alt="logo"
                />
                <h6>{sel.user.firstName}</h6>
                <p>{sel.user.lastName}</p>
                {/* <p className="online">Active</p> */}
              </div>
            </Link>
          ) : null}
          <div className="active-chats">
            <h6>Active Conversations</h6>
          </div>
          <div className="online-chats">
            <p className="num-chats">0</p>

            <IoIosArrowUp className="collapse-icon" />
          </div>
          {userMessages.map((item) => {
            return (
              <div
                className="active-image active3"
                onClick={() => {
                  handleSelection(item);
                }}
              >
                            <Link to={`/listings/${sel.user.userid}`}>

                {item.user.ProfileImage ? (
                  <img
                    src={item.user.ProfileImage}
                    className="active-user1"
                    alt="logo"
                  />
                ) : null}

                <p>{item.user.firstName}</p>
                </Link>
              </div>
            );
          })}
        </div>
        {selected.length && selected === sel.user.userid ? (
          <div className="chat-section2">
            <div style={{ overflowY: "scroll", height: 600 }}>
              {sel.messages.map((item) => {
                return (
                  <div className="receiver">
                    <img
                      src={
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC_GTSOMv5MWxOHBLROf2ExOHFKpZpaDqQRw&usqp=CAU"
                      }
                      className="receiver-image"
                      alt="logo"
                    />
                    <GoPrimitiveDot className="online-dot" />
                    <div className="receiver-chat-border">
                      <p>{item.message}</p>
                    </div>
                    <p className="chat-ago">9h ago</p>
                  </div>
                );
              })}
            </div>
            <div className="send-button-border">
              <GrAttachment className="attach" />
              <input
                className="message-input"
                type="text"
                value={msg}
                onChange={(event) => setMsg(event.target.value)}
              />
              <HiOutlineEmojiHappy className="emoji" />
              <button className="chat-send-btn" onClick={sendMessges}>
                Send
                <RiSendPlane2Fill className="send-icon" />
              </button>
            </div>
          </div>
        ) : null}

        <div className="usercards-section">
          <UserCards />
        </div>
      </div>
    </>
  );
}

export default Chat;
