import React, { useState } from "react";
import "./AddProduct.css";
import { FaUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import Toggle from "../ToggleSwitch/ToggleSwitch";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import AddImage from "../AddImage/AddImage";
import { Link } from "react-router-dom";
import { ProductImage } from "../ProductImage/ProductImage";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import { db } from "../../firebase/firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../store/actions/actions";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import UpdateThanks from "../UpdateThanks/UpdateThanks";
import { AddProductImage } from "../ProductImage/AddProductImage";
import ImageUploader from "../ProductImage/ImageUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const AddProductVendor = () => {
  const suser = localStorage.getItem("VendorUser");

  const history = useHistory();
  const catnames = useSelector((state) => state.Reducer.categories);
  const VendorUser = useSelector((state) => state.Reducer.adminUser);

  const dispatch = useDispatch();
  const [cat, setCat] = useState("");

  const [publics, setPublics] = useState(false);
  const [show, setShow] = useState(false);
  const [seo, setSeo] = useState(false);
  const [images, setImages] = useState([
    {
      id: 1,
      data_url: "",
    },
  ]);
  const [length, setLength] = useState(3);
  const [pname, setPname] = useState("");
  const [sku, setSku] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [whole, setWhole] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [des, setDes] = useState("");
  const [location, setLocation] = useState("");

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, "NNNNNNNNNN");
    setImages(imageList);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addNewProduct = async () => {
    console.log(JSON.parse(suser));

    let pid = Math.floor(Math.random() * 1000000000);
    let uploadArray = [];
    let newArray = [...images];
    newArray.map((item) => {
      uploadArray.push(item.data_url);
    });

    const data = {
      Catid: cat,
      Pid: pid,
      Pname: pname,
      Price: regularPrice,
      salePrice: salePrice,
      wholeSalePrice: whole,
      Quantity: Quantity,
      location: location,
      productBy: VendorUser.storeName,
      productByUid: VendorUser.userid,
      description: des,
      Pimages: uploadArray,
    };
    await setDoc(doc(db, "VendorPending", `${pid}`), data);
    history.push("/analytics");
    // dispatch(addProduct(data));
  };

  return (
    <div className="add-product-section">
      <div className="add-product-header">
        <h3>New Product</h3>
        <div className="new-user">
          <FaUserCircle className="user-icon" />
          <select className="user-select">
            <option value="A">vendor</option>
          </select>
        </div>

        {/* 
        <div className="save-product-section">
          <div className="product-back">
            <BiArrowBack className="back-arrow" />
            <p className="back-text">Back</p>
          </div>
        </div> */}
        <div>
          <div className="clearfix"></div>
          <div className="enable-product">
            <h5>Public</h5>
            <p>[website]</p>
          </div>
          <Toggle />

          <div className="enable-product2">
            <h5>Havazuse</h5>
          </div>
          <Toggle />

          <div className="enable-product2">
            <h5>HPlus</h5>
          </div>
          <Toggle />

          <div className="enable-product2">
            <h5>HBemo</h5>
          </div>
          <Toggle />
          <div className="clearfix"></div>
        </div>

        <div className="edit-name">
          <h5>
            Product Name <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[store view]</p>
        </div>
        <input
          className="input-product"
          type="text"
          value={pname}
          onChange={(event) => setPname(event.target.value)}
        />

        <div className="clearfix"></div>

        <div className="edit-name2">
          <h5>
            SKU <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product"
          type="text"
          value={sku}
          onChange={(event) => setSku(event.target.value)}
        />

        <div className="clearfix"></div>

        <div className="edit-name10">
          <h5>
            Location <span style={{ color: "red" }}>*</span>
          </h5>
        </div>
        <input
          className="input-product2"
          type="text"
          value={location}
          onChange={(event) => setLocation(event.target.value)}
        />

        <div className="clearfix"></div>

        <div className="edit-name3">
          <h5>
            Regular Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={regularPrice}
          onChange={(event) => setRegularPrice(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name10">
          <h5>
            Sale Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={salePrice}
          onChange={(event) => setSalePrice(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name-wholesale">
          <h5>
            Wholesale Price <span style={{ color: "red" }}>*</span>
          </h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={whole}
          onChange={(event) => setWhole(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Pricing</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Tax Class</h5>
          <p>[website]</p>
        </div>
        <select className="input-product2">
          <option value="A">Taxable Goods</option>
          <option value="B">Taxable Goods</option>
          <option value="C">Taxable Goods</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Quantity</h5>
          <p>[global]</p>
        </div>
        <input
          className="input-product2"
          type="text"
          value={Quantity}
          onChange={(event) => setQuantity(event.target.value)}
        />
        <a href="#">
          <p className="advance">Advanced Inventory</p>
        </a>

        <div className="clearfix"></div>

        <div className="edit-name5">
          <h5>Stock Status</h5>
          <p>[global]</p>
        </div>
        <select className="input-product2">
          <option value="A">In Stock</option>
          <option value="B">Out of Stock</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name6">
          <h5>Length</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name7">
          <h5>Width</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name8">
          <h5>Height</h5>
          <p>[store view]</p>
        </div>
        <input className="input-product2" type="number" placeholder="in" />

        <div className="clearfix"></div>

        <div className="edit-name8">
          <h5>Weight</h5>
          <p>[global]</p>
        </div>
        <input className="input-product2" type="number" placeholder="lbs" />
        <select className="input-product2">
          <option value="A">This item has weight</option>
          <option value="B">This item has weight</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name4">
          <h5>Category</h5>
          <p>[global]</p>
        </div>
        <select
          defaultValue={"None"}
          style={{ marginLeft: 40 }}
          className="input-product2"
          onChange={(event) => setCat(event.target.value)}
        >
          {catnames.map((item) => (
            <option value={item.Catid}>{item.Catname}</option>
          ))}
          <option value={"None"}>None</option>
        </select>

        <div className="clearfix"></div>

        <div className="edit-name9">
          <h5>Description</h5>
        </div>
        <textarea
          className="input-product"
          type="text"
          style={{ height: 150 }}
          value={des}
          onChange={(event) => setDes(event.target.value)}
        />

        <div className="clearfix"></div>
        <div className="edit-name9">
          <h5>Set Product as New From</h5>
          <p>[website]</p>
        </div>
        <div style={{ float: "left" }}>
          <DatePicker className="product-date" />
          <p className="product-to">To</p>
          <DatePicker className="product-date2" />
        </div>

        <div className="enable-product">
          <h5>SP</h5>
        </div>
        <Toggle />
        <div className="clearfix"></div>

        <div className="edit-name9">
          <h5>Add Images</h5>
        </div>

        <div className="product-images-boxes">
          {images.map((item, ind) => {
            return (
              <div
                style={{ marginLeft: "20px", float: "left", marginRight: 10 }}
              >
                <AddProductImage
                  url={
                    images[ind].data_url.length ? images[ind].data_url : null
                  }
                  setHandler={(values) => {
                    let newArray = [...images];
                    let newData = newArray[ind];

                    newData.data_url = values[0].data_url;
                    setImages(newArray);
                  }}
                  images={images}
                  key={item}
                  minusHandler={() => {
                    let newData = [...images];
                    newData.splice(ind, 1);

                    console.log("Yes", newData);

                    setImages(newData);
                  }}
                  length={ind + 1 === images.length}
                  minus={ind + 1 != 1}
                  addHandler={() => {
                    if (images.length != 100) {
                      let newData = [...images];

                      newData.push({
                        id: images.length + 1,
                        data_url: "",
                      });

                      setImages(newData);
                      console.log("Yes", images);
                    }
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="clearfix"></div>
        <button className="seo-btn" onClick={() => setSeo(!seo)}>
          Add SEO
        </button>

        {seo ? (
          <>
            <div className="seo-keyphrase">
              <h6>Focus Keyphrase</h6>
              <input className="keyphrase-input" type="text" />
            </div>

            <div className="seo-keyphrase">
              <h6>SEO Title</h6>
              <input className="keyphrase-input" type="text" />
            </div>

            <div className="seo-keyphrase">
              <h6>Meta Description</h6>
              <textarea className="keyphrase-text" type="text" />
            </div>
          </>
        ) : null}

        <div className="btn-section">
          <button className="inv-btn">
            <Link
              to="inventory"
              style={{ textDecoration: "none", color: "white" }}
            >
              {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
              Inventory
            </Link>
          </button>
          <button className="prod-btn">
            <Link
              to="productattribute"
              style={{ textDecoration: "none", color: "white", fontSize: 15 }}
            >
              {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
              Product Attributes
            </Link>
          </button>
        </div>

        <button
          className="product-save"
          onClick={() => {
            // console.log(imagess);
            addNewProduct();
          }}
        >
          Save
        </button>
        <Modal className="world-map-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="App">
              <UpdateThanks title="Added" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/">
              <Button variant="secondary" onClick={handleClose}>
                Done
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddProductVendor;
