import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import { ReactComponent as AddIcon } from "../../Assets/Images/addicon.svg";
import { ReactComponent as ImageBlackIcon } from "../../Assets/Images/image_black.svg";
import { ReactComponent as MinusOutline } from "../../Assets/Images/minus-outline.svg";
import { Dropdown, Form } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosAddCircle } from "react-icons/io";
import AddImage from "../AddImage/AddImage";
import { db } from "../../firebase/firebase";
import "./EditMarket.css";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ProductImage } from "../ProductImage/ProductImage";

import EditImage from "../EditImage/EditImage";
import { useDispatch } from "react-redux";
import { resolve, updateProduct } from "../../store/actions/actions";
import { Tooltip } from "@material-ui/core";
import { getPathStorageFromUrl, Toaster } from "../../utils/constant";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { async } from "@firebase/util";
import { refFromURL } from "firebase/database";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function SwipeableTemporaryDrawer({ product, item }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const [images, setImages] = React.useState([]);
  const [price, setPrice] = React.useState("");
  const [regular, setRegular] = useState("");
  const [sell, setSell] = useState("");
  const [description, setDiscription] = useState("");
  const [name, setName] = useState("");
  const [compare, setCompare] = useState("");
  const [existingRemovePImages, setExistingRemovePImages] = useState([]);
  const [productImages, setProductImages] = useState([
    { id: 1, image_url: null, image_photo: null },
  ]);
  useEffect(() => {
    let newImage = [];
    item &&
      item?.Pimages &&
      item?.Pimages?.length > 0 &&
      item.Pimages.map((itt, ind) => {
        newImage.push({
          id: ind + 1,
          data_url: itt,
          image_url: itt,
          image_photo: itt,
        });
      });
    // setImages(newImage);
    setProductImages(newImage.length ? [...newImage] : productImages);
    setPrice(item.Price);
    setDiscription(item.description);
    setName(item.Pname);
    setCompare(item.comparePrice ? item.comparePrice : 0);
    setSell(item.Price ? item.Price : 0);
  }, [item]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const addProductImages = (event, index) => {
    const data = event?.currentTarget?.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(data);

    fileReader.onload = () => {
      setProductImages((cloneProductImages) =>
        cloneProductImages.map((imageData, imageIndex) =>
          index === imageIndex
            ? { ...imageData, image_photo: fileReader.result, image_url: data }
            : imageData
        )
      );
    };
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <AiFillCloseCircle className="close-icon" />

      <List>
        <>
          <div className="edit-section">
            <div className="edit-heading">
              <h3>Edit Products</h3>
            </div>
            <div className="attention">
              <h6 className="attn-heading">Attention Please!</h6>
              <p className="warning">
                The operations make here will will affect all product variants
              </p>
            </div>
            <div className="add-image-section">
              <p className="add-image">Product Images</p>
              <div
                style={{
                  marginLeft: "20px",
                  float: "left",
                  marginRight: 10,
                }}
              >
                {productImages?.map((image, index) => {
                  return (
                    <div className="row" key={image.id}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12 mb-2">
                            <Form.Group controlId="validationCategoryName">
                              <label className="CatInput upload-Label">
                                {image?.image_url ? (
                                  <div className="Upload-name">
                                    {image?.image_url?.name || ""}
                                  </div>
                                ) : (
                                  "Upload Image"
                                )}
                                <UploadIcon className="upload-icon" />
                                <Form.Control
                                  type="file"
                                  name="profilePicture"
                                  placeholder="Upload Image"
                                  className="upload-file-new"
                                  onChange={(event) =>
                                    addProductImages(event, index)
                                  }
                                />
                              </label>
                            </Form.Group>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 mb-2">
                            <Form.Group
                              controlId="validationCategoryName"
                              className="d-flex flex-column"
                            >
                              <div className="d-flex">
                                <label className="upload-Label w-auto padding-none">
                                  {index === 0 ? (
                                    <Tooltip title="Add" placement="top">
                                      <AddIcon
                                        onClick={() => {
                                          setProductImages([
                                            ...productImages,
                                            {
                                              id:
                                                productImages[
                                                  productImages.length - 1
                                                ]?.id + 1,
                                              image_url: null,
                                              image_photo: null,
                                            },
                                          ]);
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Remove" placement="top">
                                      <MinusOutline
                                        onClick={() => {
                                          setExistingRemovePImages([...productImages?.filter(imageUrl =>imageUrl?.data_url === image?.data_url)]);
                                          productImages.splice(index, 1);
                                          setProductImages([...productImages]);
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </label>
                                <label className="CatInput upload-Label w-auto">
                                  {image.image_url ? (
                                    <img
                                      width={24}
                                      height={24}
                                      src={image.image_photo}
                                      alt={`p_image-${image.id}`}
                                    />
                                  ) : (
                                    <ImageBlackIcon />
                                  )}
                                </label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 "></div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* {images.map((item, ind) => {
                  return (
                    <div
                      style={{
                        marginLeft: "20px",
                        float: "left",
                        marginRight: 10,
                      }}
                    >
                      <ProductImage
                        url={
                          images[ind].data_url.length
                            ? images[ind].data_url
                            : null
                        }
                        setHandler={(values) => {
                          let newArray = [...images];
                          let newData = newArray[ind];

                          newData.data_url = values[0].data_url;
                          setImages(newArray);
                        }}
                        imagess={[images[ind]]}
                        key={item}
                        minusHandler={() => {
                          let newData = [...images];
                          newData.splice(ind, 1);

                          console.log("Yes", newData);

                          setImages(newData);
                        }}
                        length={ind + 1 === images.length}
                        minus={ind + 1 != 1}
                        addHandler={() => {
                          if (images.length != 100) {
                            let newData = [...images];

                            newData.push({
                              id: images.length + 1,
                              data_url: "",
                            });

                            setImages(newData);
                            console.log("Yes", images);
                          }
                        }}
                      />
                    </div>
                  );
                })} */}
              </div>

              <p className="add-name">Product Name</p>
              <input
                className="name-field"
                label="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />

              <p className="add-name">Product Description</p>
              <textarea
                className="name-field2"
                label="Name"
                value={description}
                onChange={(e) => setDiscription(e.target.value)}
              />
            </div>
            <div className="add-image-section">
              <h5 className="price-heading">Price</h5>
              <p className="add-name">Purchase Price</p>
              <input
                className="name-field"
                label="Name"
                value={price}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
              />

              <div className="compare-prices">
                <p className="add-name">Selling Price</p>
                <input
                  className="selling-field"
                  label="Name"
                  type="number"
                  value={sell}
                  onChange={(e) => setSell(e.target.value)}
                />
              </div>

              <div className="compare-prices">
                <p className="add-name">Compare Price</p>
                <input
                  className="selling-field"
                  label="Name"
                  value={compare}
                  type="number"
                  onChange={(e) => setCompare(e.target.value)}
                />
              </div>
            </div>
            <div onClick={toggleDrawer(anchor, false)}>
              <button
                className="save-change-btn"
                onClick={() => {
                  const users = doc(db, "Products", item.Pid);
                  const storage = getStorage();
                  const promises = [];
                  let allPromises = [];
                  const existingImages = [];
                  existingRemovePImages && existingRemovePImages.length && existingRemovePImages.forEach(async image =>{
                    allPromises.push(new Promise(async (resolve) =>{
                      const storageReference = ref(
                        storage,
                        `${getPathStorageFromUrl(image.data_url)}`
                      );
                      resolve(deleteObject(storageReference));
                    }))
                  })
                  // listAll(storageReference).then((listResults) => {
                  //   allPromises.push(
                  //     new Promise((resolve) => {
                  //       listResults.items.forEach(async (imageUrlRef) => {
                  //         const data = await getMetadata(imageUrlRef);
                  //         const url = await getDownloadURL(imageUrlRef);
                  //         Promise.all([data, url]) //Wait all api request
                  //           .then(function (res) {
                  //             if (
                  //               productImages.some((images) => {
                  //                 return (
                  //                   images?.image_url?.name ===
                  //                     (res && res[0].name) ||
                  //                   images?.data_url === (res && res[1])
                  //                 );
                  //               })
                  //             )
                  //               resolve(deleteObject(imageUrlRef));
                  //           });
                  //       });
                  //     })
                  //   );
                  // });
                  Promise.all(allPromises).then(() => {
                    productImages &&
                      productImages.length &&
                      productImages.map((file) => {
                        const storageRef = ref(
                          storage,
                          `/Havause/productImages/${item.Pid}/${file?.image_url?.name}`
                        );
                        if (file?.image_url?.name) {
                          promises.push(
                            new Promise((resolve) => {
                              const uploadTask = uploadBytesResumable(
                                storageRef,
                                file.image_url
                              );
                              uploadTask.on(
                                "state_changed",
                                (snapshot) => {
                                  const prog = Math.round(
                                    (snapshot.bytesTransferred /
                                      snapshot.totalBytes) *
                                      100
                                  );
                                },
                                (error) => console.log(error),
                                async () => {
                                  await getDownloadURL(
                                    uploadTask.snapshot.ref
                                  ).then((downloadURLs) => {
                                    resolve(downloadURLs);
                                  });
                                }
                              );
                            })
                          );
                        } else {
                          existingImages.push(file?.image_url);
                        }
                      });
                    Promise.all(promises)
                      .then(async (images) => {
                        const totalImages = existingImages.concat(images);
                        const productDetails = {
                          Pname: name.length ? name : item.Pname,
                          Pid: item.Pid,
                          Price: price.length ? price : item.Price,
                          Pimages: totalImages,
                          description: description.length
                            ? description
                            : item.description,
                          sellingPrice: sell.length ? sell : item.Price,
                          comparePrice: compare.length ? compare : item.compare,
                        };
                        updateDoc(users, {
                          Price: price,
                          Pimages: totalImages,
                          description: description,
                          sellingPrice: sell,
                          Pname: name,
                          // comparePrice: compare.length ? compare : item.compare,
                        })
                          .then(() => {
                            Toaster({
                              type: "success",
                              message: "Product updated Successfully!",
                            });
                            dispatch(updateProduct(productDetails));
                          })
                          .catch(() => {
                            Toaster({
                              type: "error",
                              message: "Product is not updated",
                            });
                          });
                      })
                      .catch(async (error) => {
                        console.log("error", error);
                      });
                  });
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <button className="quick-btn" onClick={toggleDrawer(anchor, true)}>
            Quick Edit
          </button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            // onOpen={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
