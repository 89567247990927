import React from "react";
import "./HomeAnalytics.css";
import { BsFilter } from "react-icons/bs";
import { AiTwotoneCalendar } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
// import HorizontalBarGraph from '@chartiful/react-horizontal-bar-graph'
import BarAnalytics from "../BarAnalytics/BarAnalytics";

function HomeAnalytics() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "82%",
        }}
      >
        <div className="analytics-filter">
          <button className="home-filter">
            <BsFilter className="filter-icon-home" />
            Filter
          </button>

          <div className="calender-filter">
            <AiTwotoneCalendar className="calender-icon" />
            <select className="analytics-duration">
              <option>Last 7 days</option>
              <option>Last 28 days</option>
              <option>Last year</option>
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "82%",
        }}
      >
        <div className="home-section1">
          <BarAnalytics />
        </div>

        <div className="home-section2">
          <h6 className="section2-text">Event Trends</h6>
          <div className="section2-headings">
            <p className="section2-lorem">Lorem</p>
            <p className="section2-num">0</p>
          </div>
          <div className="section2-headings">
            <p className="section2-lorem">Ipsum</p>
            <p className="section2-num">0</p>
          </div>

          <div className="trend-borders">
            <div className="trend-border1"></div>
          </div>

          <div className="trend-borders2">
            <div className="trend-border1"></div>
          </div>

          <div className="trend-borders2">
            <div className="trend-border1"></div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <div className="dash-user-section">
          <div className="analytics-filter2">
            <p>Search by User ID</p>

            <button className="home-filter">
              <BsFilter className="filter-icon-home" />
              Filter
            </button>

            <div className="calender-filter">
              <BsThreeDotsVertical className="menu-dots" />
            </div>
          </div>
          <div className="dash-border"></div>

          <div
            className="list-dash"
            style={{ float: "left", width: "100%", maxWidth: "100%" }}
          >
            <p style={{ marginLeft: "30px", width: "35%" }}>Lorem</p>
            <p>Lorem</p>
            <p>Lorem</p>
            <p>Lorem</p>
            <p>Lorem</p>
          </div>
          <div style={{ height: "400px" }}></div>
        </div>
      </div>
    </>
  );
}

export default HomeAnalytics;
