import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./Videos.css";
import { makeStyles } from "@material-ui/core/styles";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import VideopageTabs from "../VideopageTabs/VideopageTabs";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import { ReactComponent as ProductImg } from "../../Assets/Images/product.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { ReactComponent as ThumbUpIcon } from "../../Assets/Images/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../Assets/Images/thumb_down.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/View.svg";
import { ReactComponent as ChatIcon } from "../../Assets/Images/chat_black.svg";
import { ReactComponent as PauseIcon } from "../../Assets/Images/puse-video.svg";
import { useSelector, useDispatch } from "react-redux";
import CustomDropdown from "../Dropdown";
import DummyProfile from "../../Assets/Images/dummyProfile.jpg";
import {
  hide,
  getAllOrders,
  getAllShorts,
  RemoveFeed,
  RemoveFeedLive,
  RemoveFeedSchedule,
  getLikesDislikes,
  addLikesDisLikes,
  showLoading,
  hideLoading,
} from "../../store/actions/actions";
import iphone from "../../Assets/Images/iphone.png";
import { AiTwotoneSetting, AiFillPlayCircle } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { doc, deleteDoc } from "firebase/firestore";

import { BsEye } from "react-icons/bs";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import TableFilterBar from "../TableFilterBar";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import moment from "moment";

// const tabs = [
//   {
//     id: "1",
//     name: "Short Video",
//   },
//   {
//     id: "2",
//     name: "Live",
//   },
//   {
//     id: "3",
//     name: "Live Schedule",
//   },
// ];

function Videos() {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const feeds = useSelector((state) => state.Reducer.feeds);
  const likesDislikes = useSelector((state) => state.Reducer.likesDislikes);
  const adminEnable = useSelector((state) => state.Reducer.adminUser);
  const [com, setCom] = useState(false);
  const comhandleClose = () => setCom(false);
  const comhandleShow = () => setCom(true);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [show, setShow] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selected, setSelected] = useState("");
  const [selectedTab, setSelectedTab] = useState("1");

  const [item, setItem] = useState("");
  const [videoPlay, setVideoPlay] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [menuIcon, setMenuIcon] = React.useState(null);
  const open = Boolean(menuIcon);
  const menuIconClose = () => {
    setMenuIcon(null);
  };
  const roleRef = useRef();
  useEffect(() => {
    // dispatch(showLoading());
    dispatch(getAllShorts());
    dispatch(getLikesDislikes());
    // dispatch(hideLoading());
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!roleRef?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [roleRef]);

  const handleSelectionTab = (item) => {
    setSelected("");
    console.log(item, "GTGTGTGT");

    var selectedId = selectedTab;

    if (selectedId === item.id) {
      setSelectedTab(item.id);
    } else setSelectedTab(item.id);
  };

  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };

  useEffect(() => {
    setData([...feeds]);
  }, [feeds]);

  const actionPerform = async (action, data) => {
    // for (let i = 0; i < localStorage.length; i++) {
    //   const localKey = localStorage.key(i);
    // }
    switch (action) {
      case "like":
        return dispatch(
          addLikesDisLikes(
            {
              userId: adminEnable?.userid?.toString(),
              VideoId: data?.videoid,
              Like: true,
              Dislike: false,
            },
            adminEnable?.userid?.toString(),
            data?.videoid,
            likesDislikes,
            feeds,
            "short"
          )
        );
      case "unlike":
        return dispatch(
          addLikesDisLikes(
            {
              userId: adminEnable?.userid?.toString(),
              VideoId: data?.videoid,
              Like: false,
              Dislike: true,
            },
            adminEnable?.userid?.toString(),
            data?.videoid,
            likesDislikes,
            feeds,
            "short"
          )
        );
      default:
        return "";
    }
  };
  const requestSearch = (searchedVal) => {
    let filteredRows = [];
    if (searchedVal.length) {
      filteredRows =
        data &&
        data.length &&
        data.filter((row) => {
          return (
            // row.likes.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.thumbnail?.toLowerCase()?.includes(searchedVal?.toLowerCase())
          );
        });
    } else {
      filteredRows = [...feeds];
    }
    setData(filteredRows);
  };
  return (
    // <>
    //   {tabs.map((item) => (
    //     <div
    //       style={{ maxWidth: "100%" }}
    //       className="tab-nav"
    //       onClick={() => handleSelectionTab(item)}
    //     >
    //       <div
    //         className="tabvideo"
    //         style={{
    //           backgroundColor:
    //             selectedTab === item.id ? "white" : "rgba(42, 143, 86, 1)",
    //           borderColor: selectedTab === item.id ? "#3FAD72" : "white",
    //           borderStyle: "solid",
    //         }}
    //       >
    //         <p
    //           style={{
    //             color: selectedTab === item.id ? "#3FAD72" : "white",
    //           }}
    //         >
    //           {item.name}
    //         </p>
    //       </div>
    //     </div>
    //   ))}

    //   <div className="fazzy22"></div>
    //   <>
    //     {selectedTab === "1" && (
    //       <>
    //         {feeds.map((item) => (
    //           <div
    //             className="video-detail-carder"
    //             style={{ height: selected.length ? 360 : "100%" }}
    //           >
    //             <div className="video1-border-mac-new">
    //               <div
    //                 onClick={() => {
    //                   setItem(item);
    //                   handleShow();
    //                 }}
    //                 style={{
    //                   backgroundImage: `url(${
    //                     item.thumbnail ? item.thumbnail : iphone
    //                   })`,
    //                   height: "182px",
    //                   backgroundSize: "auto",
    //                   backgroundRepeat: "no",
    //                 }}
    //               >
    //                 <div style={{ maxWidth: "100%" }}>
    //                   <img
    //                     className="video-uploader-user"
    //                     src={item.ProfileImage}
    //                   />
    //                 </div>
    //                 <div className="video-length-mac2">
    //                   <span>
    //                     {new Date(item.duration * 1000)
    //                       .toISOString()
    //                       .substr(14, 5)}
    //                   </span>
    //                 </div>
    //                 <div className="clearfix"></div>

    //                 <div className="setting-gear-mac">
    //                   <AiTwotoneSetting
    //                     className="video-setting-mac2"
    //                     onClick={() => {
    //                       handleSelection(item);
    //                     }}
    //                   />
    //                 </div>
    //               </div>

    //               {selected === item.videoid ? (
    //                 <>
    //                   <div className="video-desc-mac">
    //                     <p className="video-title-mac">{item.caption}</p>
    //                     <p className="video-date-mac">21 JAN 2021</p>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "right",
    //                       width: "37%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="view-details-mac">
    //                       <button className="view-btn-mac">
    //                         {item.views} <BsEye />
    //                       </button>
    //                     </div>

    //                     <div
    //                       className="view-delete-mac"
    //                       onClick={() => {
    //                         setSelected("");
    //                         dispatch(RemoveFeed(item.videoid));
    //                       }}
    //                     >
    //                       <button className="delete-btn-mac">Delete</button>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "left",
    //                       width: "63%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="video-hearts">
    //                       <GiHearts className="hearts" />
    //                       <p className="num-hearts">{item.likes}</p>
    //                     </div>

    //                     <div className="video-dislike">
    //                       <AiFillDislike className="dislike" />
    //                       <p className="num-hearts">
    //                         {item.dislikes ? item.dislikes : 0}
    //                       </p>
    //                     </div>

    //                     <Link to={`/comment/${item.videoid}`}>
    //                       <div
    //                         className="video-dislike video-responsive"
    //                         onClick={comhandleShow}
    //                       >
    //                         <BsChatFill className="chat" />
    //                         <p className="num-hearts">1</p>
    //                       </div>
    //                     </Link>
    //                     <div
    //                       style={{
    //                         float: "left",
    //                         width: "100%",
    //                         maxWidth: "100%",
    //                       }}
    //                     >
    //                       <Link to={`listings/${item.userid}`}>
    //                         <img
    //                           className="video-uploader-usernew"
    //                           src={item.ProfileImage}
    //                         />

    //                         <p style={{ float: "left", marginTop: "5px" }}>
    //                           anoynomus
    //                         </p>
    //                       </Link>
    //                     </div>
    //                     <Modal
    //                       className="modal-video"
    //                       show={com}
    //                       onHide={comhandleClose}
    //                     >
    //                       <Modal.Body>
    //                         <div className="not-image-comments">
    //                           <img
    //                             src={item.ProfileImage ? item.ProfileImage : ""}
    //                             className="not-image-user-comments"
    //                             style={{ width: "10%" }}
    //                             alt="logo"
    //                           />
    //                           <div style={{ float: "left" }}>
    //                             <p
    //                               style={{
    //                                 marginTop: 13,
    //                                 marginLeft: 15,
    //                                 width: 200,
    //                                 fontWeight: 700,
    //                                 float: "left",
    //                               }}
    //                             >
    //                               Havazuse Plus
    //                             </p>
    //                             <p
    //                               style={{
    //                                 marginTop: 13,
    //                                 float: "left",
    //                                 borderRight: "1px solid #888",
    //                                 height: 25,
    //                                 paddingRight: 10,
    //                               }}
    //                             >
    //                               3 weeks, 1 day ago
    //                             </p>

    //                             <p
    //                               style={{
    //                                 marginTop: 13,
    //                                 float: "left",
    //                                 marginLeft: 10,
    //                                 color: "red",
    //                               }}
    //                             >
    //                               Delete
    //                             </p>
    //                           </div>

    //                           <IoIosArrowDown
    //                             style={{
    //                               marginTop: 18,
    //                               float: "left",
    //                               marginLeft: 20,
    //                             }}
    //                           />

    //                           <p
    //                             style={{
    //                               float: "left",
    //                               width: "96%",
    //                               maxWidth: "100%",
    //                               marginLeft: 23,
    //                               marginTop: 10,
    //                             }}
    //                           >
    //                             Contrary to popular belief, Lorem Ipsum is not
    //                             simply random text. It has roots in a piece of
    //                             classical Latin literature from 45 BC, making it
    //                             over 2000 years old.
    //                           </p>
    //                         </div>
    //                       </Modal.Body>
    //                     </Modal>
    //                   </div>
    //                 </>
    //               ) : null}
    //             </div>
    //           </div>
    //         ))}
    //       </>
    //     )}
    //   </>
    //   <>
    //     {selectedTab === "2" && (
    //       <>
    //         {live.map((item) => (
    //           <div
    //             className="video-detail-carder"
    //             style={{ height: selected.length ? 360 : "100%" }}
    //           >
    //             <div className="video1-border-mac-new">
    //               <div
    //                 onClick={() => {
    //                   setItem(item);
    //                   handleShow();
    //                 }}
    //                 style={{
    //                   backgroundImage: `url(${
    //                     item.thumbnail ? item.thumbnail : iphone
    //                   })`,
    //                   height: "182px",
    //                   backgroundSize: "auto",
    //                   backgroundRepeat: "no",
    //                 }}
    //               >
    //                 <div style={{ maxWidth: "100%" }}>
    //                   <img
    //                     className="video-uploader-user"
    //                     src={item.ProfileImage}
    //                   />
    //                 </div>
    //                 <div className="video-length-mac2">
    //                   <span>2:34</span>
    //                 </div>
    //                 <div className="clearfix"></div>

    //                 <div className="setting-gear-mac">
    //                   <AiTwotoneSetting
    //                     className="video-setting-mac2"
    //                     onClick={() => {
    //                       handleSelection(item);
    //                     }}
    //                   />
    //                 </div>
    //               </div>

    //               {selected === item.videoid ? (
    //                 <>
    //                   <div className="video-desc-mac">
    //                     <p className="video-title-mac">Live</p>
    //                     <p className="video-date-mac">21 JAN 2021</p>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "right",
    //                       width: "37%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="view-details-mac">
    //                       <button className="view-btn-mac">
    //                         576K <BsEye />
    //                       </button>
    //                     </div>

    //                     <div
    //                       className="view-delete-mac"
    //                       onClick={() => {
    //                         setSelected("");
    //                         dispatch(RemoveFeed(item.videoid));
    //                       }}
    //                     >
    //                       <button className="delete-btn-mac">Delete</button>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "left",
    //                       width: "63%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="video-hearts">
    //                       <GiHearts className="hearts" />
    //                       <p className="num-hearts">3.67k</p>
    //                     </div>

    //                     <div className="video-dislike">
    //                       <AiFillDislike className="dislike" />
    //                       <p className="num-hearts">1.3k</p>
    //                     </div>

    //                     <div className="video-dislike video-responsive">
    //                       <BsChatFill className="chat" />
    //                       <p className="num-hearts">2.3k</p>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "left",
    //                       width: "100%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <Link to={`listings/${item.userid}`}>
    //                       <img
    //                         className="video-uploader-usernew"
    //                         src={item.ProfileImage}
    //                       />

    //                       <p style={{ float: "left", marginTop: "5px" }}>
    //                         anoynomus
    //                       </p>
    //                     </Link>
    //                   </div>
    //                 </>
    //               ) : null}
    //             </div>
    //           </div>
    //         ))}
    //       </>
    //     )}
    //   </>
    //   <>
    //     {selectedTab === "3" && (
    //       <>
    //         {schedules.map((item) => (
    //           <div
    //             className="video-detail-carder"
    //             style={{ height: selected.length ? 360 : "100%" }}
    //           >
    //             <div className="video1-border-mac-new">
    //               <div
    //                 onClick={() => {
    //                   setItem(item);
    //                   handleShow();
    //                 }}
    //                 style={{
    //                   backgroundImage: `url(${
    //                     item.thumbnail ? item.thumbnail : iphone
    //                   })`,
    //                   height: "182px",
    //                   backgroundSize: "auto",
    //                   backgroundRepeat: "no",
    //                 }}
    //               >
    //                 <div style={{ maxWidth: "100%" }}>
    //                   <img
    //                     className="video-uploader-user"
    //                     src={item.ProfileImage}
    //                   />
    //                 </div>
    //                 <div className="video-length-mac2">
    //                   <span>2:34</span>
    //                 </div>
    //                 <div className="clearfix"></div>

    //                 <div className="setting-gear-mac">
    //                   <AiTwotoneSetting
    //                     className="video-setting-mac2"
    //                     onClick={() => {
    //                       handleSelection(item);
    //                     }}
    //                   />
    //                 </div>
    //               </div>

    //               {selected === item.videoid ? (
    //                 <>
    //                   <div className="video-desc-mac">
    //                     <p className="video-title-mac">Scheduled</p>
    //                     <p className="video-date-mac">21 JAN 2021</p>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "right",
    //                       width: "37%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="view-details-mac">
    //                       <button className="view-btn-mac">
    //                         576K <BsEye />
    //                       </button>
    //                     </div>

    //                     <div
    //                       className="view-delete-mac"
    //                       onClick={() => {
    //                         setSelected("");
    //                         dispatch(RemoveFeed(item.videoid));
    //                       }}
    //                     >
    //                       <button className="delete-btn-mac">Delete</button>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "left",
    //                       width: "63%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <div className="video-hearts">
    //                       <GiHearts className="hearts" />
    //                       <p className="num-hearts">3.67k</p>
    //                     </div>

    //                     <div className="video-dislike">
    //                       <AiFillDislike className="dislike" />
    //                       <p className="num-hearts">1.3k</p>
    //                     </div>

    //                     <div className="video-dislike video-responsive">
    //                       <BsChatFill className="chat" />
    //                       <p className="num-hearts">2.3k</p>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       float: "left",
    //                       width: "100%",
    //                       maxWidth: "100%",
    //                     }}
    //                   >
    //                     <Link to={`listings/${item.userid}`}>
    //                       <img
    //                         className="video-uploader-usernew"
    //                         src={item.ProfileImage}
    //                       />

    //                       <p style={{ float: "left", marginTop: "5px" }}>
    //                         anoynomus
    //                       </p>
    //                     </Link>
    //                   </div>
    //                 </>
    //               ) : null}
    //             </div>
    //           </div>
    //         ))}
    //       </>
    //     )}
    //   </>

    //   <Modal className="modal-video" show={show} onHide={handleClose}>
    //     <Modal.Body>
    //       <div className="not-image">
    //         <Link to={`listings/${item.userid}`} style={{ color: "black" }}>
    //           <img
    //             src={item.ProfileImage ? item.ProfileImage : ""}
    //             className="not-image-user"
    //             style={{ width: "35%" }}
    //             alt="logo"
    //           />
    //           <p style={{ marginTop: 13, marginLeft: 70, width: 200 }}>
    //             anoynomus
    //           </p>
    //         </Link>
    //       </div>

    //       <ReactPlayer
    //         className="vid-player"
    //         playing
    //         playIcon={
    //           <AiFillPlayCircle
    //             color="#3FAD72"
    //             style={{
    //               height: 100,
    //               width: 100,
    //             }}
    //           />
    //         }
    //         light={item.thumbnail}
    //         url={item.feedurl}
    //       />
    //     </Modal.Body>
    //   </Modal>
    // </>
    <>
      <div className="add-category-section short-videos">
        <div className="d-flex justify-content-between align-items-center search-table-header">
          <div className="d-flex align-items-center">
            <BreadcrumbMenu path={[]} activeTab="Short Video" isBack={false} />
          </div>
          <div className="d-flex video-filter">
            <CustomDropdown
              option={["Admin", "Vendor", "Customer Service"]}
              placeholder="Select Role"
              width="100%"
              borders={true}
              fullArrow={true}
              dropdownContentHeight={"auto !important"}
              name="role"
              selectedValue="Filter"
              // handleChangeRowsPerPage={async (value) =>
              //   userForm.setFieldValue("role", value)
              // }
              searchBar={true}
              isActive={isActive}
              setIsActive={setIsActive}
              ref={roleRef}
            />
            <div className="searchSection d-flex">
              <FiSearch className="search-icon" />
              <input
                className="searchBar searchbar-table"
                placeholder="Search"
                type="text"
                onChange={(e) => requestSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {data && data.length ? (
            data.map((video, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-12">
                <div className="video-cards">
                  <div className="video-main">
                    <ReactPlayer
                      className="vid-player"
                      width={"unset"}
                      height={264}
                      onClick={() => {
                        setVideoPlay(true);
                        setSelectedDetails(video);
                        handleShow();
                      }}
                      playIcon={
                        <PauseIcon
                          className="PauseIcon"
                          color="#3FAD72"
                          style={{
                            height: 50,
                            width: 50,
                          }}
                        />
                      }
                      light={video.thumbnail}
                      url={video.feedurl}
                    />
                    {/* <video
                      width="100%"
                      height="auto"
                      className="video"
                      controls
                    >
                      <source
                        src="https://youtu.be/5fTVslrPsL0"
                        type="video/mp4"
                      />
                    </video> */}
                    {/* <PauseIcon className="PauseIcon" /> */}
                    <div className="profile-thumb d-flex">
                      <div className="profile-photo">
                        <img
                          src={video?.ProfileImage ? video.ProfileImage : DummyProfile}
                          alt={`user_pic_${index}`}
                        />
                      </div>
                      <h6>{video?.uploaderName || ""}</h6>
                    </div>
                    <div className="video-timer">
                      <p>{video?.duration || "00:00"}</p>
                    </div>
                  </div>
                  <div className="generalMedia">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="d-flex m-0 p-0">{video?.caption || ""}</p>
                      <Dropdown
                        drop={"start"}
                        className="menuIcon-dropdown-start"
                      >
                        <Dropdown.Toggle
                          style={{ all: "unset", cursor: "pointer" }}
                        >
                          <MenuIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="product-item" onClick={() => {

                          }}>
                            <ProductImg /> Product Detail
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="delete-item"
                            onClick={() => {
                              dispatch(RemoveFeed(video.videoid));
                            }}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="upload-date">
                      {video?.createdAt ? moment(
                        new Date(
                          video?.createdAt * 1000
                        ).toLocaleDateString("en-US")
                      ).format("DD MMM YYYY") : ''}
                    </div>
                    <div
                      className="d-flex justify-content-around social-icon mx-auto"
                      style={{ width: "90%" }}
                    >
                      <div className="text-center icon-like">
                        <div>{video?.likes || "0"}</div>
                        <ThumbUpIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("like", video)}
                        />
                      </div>
                      <div className="text-center icon-like">
                        <div>{video?.dislikes || "0"}</div>
                        <ThumbDownIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("unlike", video)}
                        />
                      </div>
                      <div className="text-center icon-like">
                        <div>{video?.comments || 0}</div>
                        <Tooltip title="User Message" placement="top">
                          <Link to={`/videos/short/comments/${video?.videoid}`}>
                            <ChatIcon className="cursor-pointer" />
                          </Link>
                        </Tooltip>
                      </div>
                      <div className="text-center icon-like">
                        <div>{video?.views || "0"}</div>
                        <ViewIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("views", video)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No videos found</div>
          )}
        </div>
      </div>
      <Modal
        className="modal-video"
        show={show}
        centered={true}
        onHide={handleClose}
      >
        <Modal.Header className="video-modal-header" closeButton />

        <Modal.Body>
          <div className="model-user-info d-flex">
            {/* <Link to={`listings/${selectedDetails.userid}`} style={{ color: "black" }}>
             
            </Link> */}
            {/* <div className="profile-pic">
              <img
                src={
                  selectedDetails?.ProfileImage
                    ? selectedDetails?.ProfileImage
                    : user
                }
                alt="logo"
              />
            </div>
            <p>{selectedDetails?.uploaderName || ""}</p> */}
          </div>

          <ReactPlayer
            className="vid-player"
            playing={videoPlay}
            controls={true}
            width="100%"
            height={452}
            onPause={() => setVideoPlay(false)}
            playIcon={
              <PauseIcon
                className="PauseIcon"
                color="#3FAD72"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            }
            light={selectedDetails?.thumbnail}
            url={selectedDetails?.feedurl}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Videos;
