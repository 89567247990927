import React from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillWarning } from "react-icons/ai";
import "./DynamicModal.css";
import { RiAlarmWarningFill } from "react-icons/ri";

const Dynamic = ({
  show,
  close,
  title,
  text,
  account,
  buttonTitle,
  saveChanges,
}) => {
  return (
    <Modal id="dynamic-modal" class="date-modal" show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <RiAlarmWarningFill className="warning-modal"/>

        <div>
          {/* <AiFillWarning
            style={{ color: "red", marginRight: 10, height: 30, width: 30 }}
          /> */}
          <h6 style={{ color: "red", marginTop: 25, textAlign:"center" }}>{text}</h6>
        </div>
        {/* <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 5,
            height: 60,
            flexDirection: "column",
            marginTop: 20,
            display: "flex",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <p>User Account</p>
          <h6 style={{ color: "#000", marginBottom: 5 }}>{account}</h6>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="light" onClick={close}>
          Cancel
        </Button> */}
        <Button className="danger-btn" variant="danger" onClick={saveChanges}>
          {buttonTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Dynamic;
