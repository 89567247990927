export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_VENDOR = "GET_ALL_VENDOR";
export const HIDE = "HIDE";
export const SELETECTED_PRODUCT = "SELETECTED_PRODUCT";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_SHORTS = "GET_ALL_SHORTS";
export const GET_USER = "GET_USER";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const GET_ALL_LIVE_STREAMS = "GET_ALL_LIVE_STREAMS";
export const GET_ALL_LIVE_SCHEDULES = "GET_ALL_LIVE_SCHEDULES";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_FEED = "DELETE_FEED";
export const DELETE_FEED_LIVE = "DELETE_FEED_LIVE";
export const DELETE_FEED_SCHEDULE = "DELETE_FEED_SCHEDULE";
export const GET_ALL_LIKES_DISLIKES = "GET_ALL_LIKES_DISLIKES";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const GET_PRODUCT_HISTORY = "GET_PRODUCT_HISTORY";
export const USER_HIS = "USER_HIS";
export const FEED_BACK_REPORT = "FEED_BACK_REPORT";
export const GET_USER_MESSAGE = "GET_USER_MESSAGE";
export const ADD_MSG = "ADD_MSG";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const RESOLVE = "RESOLVE";
export const EDITCAT = "EDITCAT";
export const DELETECAT = "DELETECAT";
export const ADDCAT = "ADDCAT";
export const GETCATEGORYBYID = "GETCATEGORYBYID";
export const BANNERS = "BANNERS";
export const getAllSupportChat = "getAllSupportChat";
export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const SET_ATT = "SET_ATT";
export const SET_COMPLETED_PROFILE = "SET_COMPLETED_PROFILE";
export const SET_VENDOR = "SET_VENDOR";
export const ALL_ADMINS = "ALL_ADMINS";
export const ALL_CUSTOMER_SERVICE = "ALL_CUSTOMER_SERVICE";
export const GET_EVENTS = "GET_EVENTS";
export const REDEEM = "REDEEM";
export const AdminUser = "AdminUser";
export const GET_REDEEM = "GET_REDEEM";
export const GET_PENDING = "GET_PENDING";
export const GET_VENDOR_PRODUCTS = "GET_VENDOR_PRODUCTS";
export const GET_VENDOR_PRODUCTS_PENDING = "GET_VENDOR_PRODUCTS_PENDING";
export const LOGOUT = "LOGOUT";
export const SER_ENABLE = "SER_ENABLE";
export const ADMIN_ENABLE = "ADMIN_ENABLE";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const TABLE_MENU_ACTION = "TABLE_MENU_ACTION";
