import React from "react";
import "./VideoTabs.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GiStarShuriken } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import iphone from "../../Assets/Images/iphone.png";
import { AiTwotoneSetting } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Video Upload" {...a11yProps(0)} />
          <Tab label="Video Watch" {...a11yProps(1)} />
          <Tab label="Wish List" {...a11yProps(2)} />
          <Tab label="Item Purchase" {...a11yProps(3)} />
          <Tab label="User Notes" {...a11yProps(4)} />
          <Tab label="Subscription" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="video-card">
          <p className="video-views">Total Views: 576K</p>
          <p className="video-points">
            <GiStarShuriken className="star-points" /> 454 Points
          </p>
          <img src={user} className="video-user" alt="logo" />
          <div className="videouser-info">
            <RiEditCircleFill className="edit-btn-page" />
            <h3>@john78</h3>
            <p>Active</p>
          </div>
          <div className="name-details">
            <p className="first-name">First Name:</p>
            <p className="user-name-video">John</p>
          </div>
          <div className="name-details">
            <p className="first-name">Last Name:</p>
            <p className="user-name-video">Doe</p>
          </div>
          <div className="name-details">
            <p className="first-name">Email:</p>
            <p className="user-name-video2">johndoe@xyz.com</p>
          </div>
          <div className="name-details">
            <p className="first-name">Contact:</p>
            <p className="user-name-video3">+123 456 789</p>
          </div>
          <div className="name-details">
            <p className="first-name">From:</p>
            <p className="user-name-video4">USA</p>
          </div>
          <div className="name-details">
            <p className="first-name">Havazues balance:</p>
            <p className="user-name-video5">6,907$</p>
          </div>
          <button className="user-activities">Activities</button>
          <button className="user-contact">Contact</button>
          <button className="user-suspend">Suspension</button>
          <button className="user-date">
            1 Jan 2021<span className="date-border"></span>
            31 Jan 2021
          </button>
          <button className="user-delete">Delete</button>

          <div className="video-border"></div>

          <div className="user-followers">
            <p className="followers">Media Followers:</p>
            <p className="followers-num">7,890</p>
          </div>
          <div className="user-followers">
            <p className="followers">Media Following:</p>
            <p className="followers-num">500</p>
          </div>
          <div className="user-followers-store">
            <p className="followers">Store Following:</p>
            <p className="followers-num">500</p>
          </div>

          <button className="user-verification">
            Havazuse plus verification
          </button>
        </div>

        <div className="video-detail-card-pager">
          <div className="video1-border-page">
            <div
              style={{
                backgroundImage: `url(${iphone})`,
                height: "182px",
                backgroundSize: "contain",
              }}
            >
              <div className="video-length-page">
                <span>2:34</span>
              </div>
              <div className="clearfix"></div>

              <div className="setting-gear-page">
                <AiTwotoneSetting className="video-setting-page" />
              </div>
            </div>
            {/* <img src={iphone} className="video1-image" alt="logo" /> */}

            <div className="video-desc-page">
              <p className="video-title-page">Iphone 12 Pro</p>
              <p className="video-date-page">21 JAN 2021</p>
            </div>
            <div className="view-details-page">
              <button className="view-btn-page">
                576K <BsEye />
              </button>
            </div>

            <div className="view-delete-page">
              <button className="delete-btn-page">Delete</button>
            </div>

            <div className="video-hearts">
              <GiHearts className="hearts" />
              <p className="num-hearts">3.67k</p>
            </div>

            <div className="video-dislike">
              <AiFillDislike className="dislike" />
              <p className="num-hearts">1.3k</p>
            </div>

            <div className="video-dislike">
              <BsChatFill className="chat" />
              <p className="num-hearts">2.3k</p>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="video-card">
          <p className="video-views">Total Views: 576K</p>
          <p className="video-points">
            <GiStarShuriken className="star-points" /> 454 Points
          </p>
          <img src={user} className="video-user" alt="logo" />
          <div className="videouser-info">
            <h3>@jane78</h3>
            <p>Active</p>
          </div>
          <div className="name-details">
            <p className="first-name">First Name:</p>
            <p className="user-name-video">Jane</p>
          </div>
          <div className="name-details">
            <p className="first-name">Last Name:</p>
            <p className="user-name-video">Doe</p>
          </div>
          <div className="name-details">
            <p className="first-name">Email:</p>
            <p className="user-name-video2">janedoe@xyz.com</p>
          </div>
          <div className="name-details">
            <p className="first-name">Contact:</p>
            <p className="user-name-video3">+123 456 789</p>
          </div>
          <div className="name-details">
            <p className="first-name">From:</p>
            <p className="user-name-video4">USA</p>
          </div>
          <div className="name-details">
            <p className="first-name">Havazues balance:</p>
            <p className="user-name-video5">6,907$</p>
          </div>
          <button className="user-activities">Activities</button>
          <button className="user-contact">Contact</button>
          <button className="user-suspend">Suspension</button>
          <button className="user-date">
            1 Jan 2021<span className="date-border"></span>
            31 Jan 2021
          </button>
          <button className="user-delete">Delete</button>

          <div className="video-border"></div>

          <div className="user-followers">
            <p className="followers">Media Followers:</p>
            <p className="followers-num">7,890</p>
          </div>
          <div className="user-followers">
            <p className="followers">Media Following:</p>
            <p className="followers-num">500</p>
          </div>
          <div className="user-followers-store">
            <p className="followers">Store Following:</p>
            <p className="followers-num">500</p>
          </div>

          <button className="user-verification">
            Havazuse plus verification
          </button>
        </div>

        <div className="video-detail-card-page">
          <div className="video1-border-page">
            <div
              style={{
                backgroundImage: `url(${iphone})`,
                height: "182px",
                backgroundSize: "contain",
              }}
            >
              <div className="video-length-page">
                <span>2:34</span>
              </div>
              <div className="clearfix"></div>

              <div className="setting-gear-page">
                <AiTwotoneSetting className="video-setting-page" />
              </div>
            </div>
            {/* <img src={iphone} className="video1-image" alt="logo" /> */}

            <div className="video-desc-page">
              <p className="video-title-page">Iphone 12 Pro</p>
              <p className="video-date-page">21 JAN 2021</p>
            </div>
            <div className="view-details-page">
              <button className="view-btn-page">
                576K <BsEye />
              </button>
            </div>

            <div className="view-delete-page">
              <button className="delete-btn-page">Delete</button>
            </div>

            <div className="video-hearts">
              <GiHearts className="hearts" />
              <p className="num-hearts">3.67k</p>
            </div>

            <div className="video-dislike">
              <AiFillDislike className="dislike" />
              <p className="num-hearts">1.3k</p>
            </div>

            <div className="video-dislike">
              <BsChatFill className="chat" />
              <p className="num-hearts">2.3k</p>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="video-card">
          <p className="video-views">Total Views: 576K</p>
          <p className="video-points">
            <GiStarShuriken className="star-points" /> 454 Points
          </p>
          <img src={user} className="video-user" alt="logo" />
          <div className="videouser-info">
            <h3>@jane78</h3>
            <p>Active</p>
          </div>
          <div className="name-details">
            <p className="first-name">First Name:</p>
            <p className="user-name-video">Jane</p>
          </div>
          <div className="name-details">
            <p className="first-name">Last Name:</p>
            <p className="user-name-video">Doe</p>
          </div>
          <div className="name-details">
            <p className="first-name">Email:</p>
            <p className="user-name-video2">janedoe@xyz.com</p>
          </div>
          <div className="name-details">
            <p className="first-name">Contact:</p>
            <p className="user-name-video3">+123 456 789</p>
          </div>
          <div className="name-details">
            <p className="first-name">From:</p>
            <p className="user-name-video4">USA</p>
          </div>
          <div className="name-details">
            <p className="first-name">Havazues balance:</p>
            <p className="user-name-video5">6,907$</p>
          </div>
          <button className="user-activities">Activities</button>
          <button className="user-contact">Contact</button>
          <button className="user-suspend">Suspension</button>
          <button className="user-date">
            1 Jan 2021<span className="date-border"></span>
            31 Jan 2021
          </button>
          <button className="user-delete">Delete</button>

          <div className="video-border"></div>

          <div className="user-followers">
            <p className="followers">Media Followers:</p>
            <p className="followers-num">7,890</p>
          </div>
          <div className="user-followers">
            <p className="followers">Media Following:</p>
            <p className="followers-num">500</p>
          </div>
          <div className="user-followers-store">
            <p className="followers">Store Following:</p>
            <p className="followers-num">500</p>
          </div>

          <button className="user-verification">
            Havazuse plus verification
          </button>
        </div>

        <div className="video-detail-card-page">
          <div className="video1-border-page">
            <div
              style={{
                backgroundImage: `url(${iphone})`,
                height: "182px",
                backgroundSize: "contain",
              }}
            >
              <div className="video-length-page">
                <span>2:34</span>
              </div>
              <div className="clearfix"></div>

              <div className="setting-gear-page">
                <AiTwotoneSetting className="video-setting-page" />
              </div>
            </div>
            {/* <img src={iphone} className="video1-image" alt="logo" /> */}

            <div className="video-desc-page">
              <p className="video-title-page">Iphone 12 Pro</p>
              <p className="video-date-page">21 JAN 2021</p>
            </div>
            <div className="view-details-page">
              <button className="view-btn-page">
                576K <BsEye />
              </button>
            </div>

            <div className="view-delete-page">
              <button className="delete-btn-page">Delete</button>
            </div>

            <div className="video-hearts">
              <GiHearts className="hearts" />
              <p className="num-hearts">3.67k</p>
            </div>

            <div className="video-dislike">
              <AiFillDislike className="dislike" />
              <p className="num-hearts">1.3k</p>
            </div>

            <div className="video-dislike">
              <BsChatFill className="chat" />
              <p className="num-hearts">2.3k</p>
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
}
