import React from "react";
import { Link } from "react-router-dom";
import "./UserSupport.css";
import Pin from "../../Assets/Images/product.png";
import {
  BsFillChatDotsFill,
  BsFillPhoneVibrateFill,
  BsFillEyeFill,
} from "react-icons/bs";

function UserSupport() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "33%",
          marginLeft: 240,
        }}
      >
        <Link to="/unpinned">
          <div className="support-btn">
            <p className="request-text">Pinned</p>
            <p className="request-num">202</p>
          </div>
        </Link>

        <Link to="/uninvestigate">
          <div className="support-btn2">
            <p className="request-text">Investigate</p>
            <p className="request-num">20</p>
          </div>
        </Link>

        <Link to="/unsuspend">
          <div className="support-btn3">
            <p className="request-text">Suspended</p>
            <p className="request-num">10</p>
          </div>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          marginLeft: 20,
        }}
      >
        <div style={{ marginLeft: "50px", marginTop: "50px", width: "100%" }}>
          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>
              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          marginLeft: 20,
        }}
      >
        <div style={{ marginLeft: "50px", marginTop: "50px", width: "100%" }}>
          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <BsFillChatDotsFill className="pinn-icon1" />
              <BsFillPhoneVibrateFill className="pinn-icon2" />
              <BsFillEyeFill className="pinn-icon3" />
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Ipsum</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSupport;
