import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomDropdown from "../Dropdown";
import { ReactComponent as PlusOutLine } from "../../Assets/Images/plus_outline.svg";
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import { ReactComponent as MinusOutline } from "../../Assets/Images/minus-outline.svg";
import { Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const colorChild = [
  { color: "Blue", price: "$100", stock: "Instock", code: "#375ffa" },
  { color: "Red", price: "$200", stock: "stock", code: "#04984a" },
  { color: "Black", price: "$500", stock: "Instock", code: "#000000" },
];
const sizeChild = [
  { size: "10", price: "$100", stock: "Instock" },
  { size: "22", price: "$200", stock: "stock" },
  { size: "33", price: "$500", stock: "Instock" },
];
const modalChild = [
  { name: "ABC", price: "$100", stock: "Instock" },
  { name: "KKK", price: "$200", stock: "stock" },
  { name: "RRR", price: "$500", stock: "Instock" },
];
const AddAttribute = () => {
  const [colorIsActive, setColorIsActive] = useState(false);
  const [sizeIsActive, setSizeIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelIsActive, setModelIsActive] = useState(false);
  // const [parentCategory, setParentCategory] = useState(false);
  const colorIsActiveRef = useRef();
  const sizeIsActiveRef = useRef();
  const modelIsActiveRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      const colorRef = !colorIsActiveRef?.current?.contains(event.target);
      const sizeRef = !sizeIsActiveRef?.current?.contains(event.target);
      const modelRef = !modelIsActiveRef?.current?.contains(event.target);
      if (colorRef) {
        setColorIsActive(false);
      }
      if (sizeRef) {
        setSizeIsActive(false);
      }
      if (modelRef) {
        setModelIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [colorIsActiveRef, sizeIsActiveRef, modelIsActiveRef]);
  const schema = yup.object().shape({
    ColorName: yup.string().required(),
    ParentCategory: yup.string().required(),
  });

  const attributeForm = useFormik({
    initialValues: {
      ColorName: { name: "", price: "", stock: "", image: "" },
      Size: { name: "", price: "", stock: "", image: "" },
      Model: { name: "", price: "", stock: "", image: "" },
      ParentCategory: "",
      description: "",
    },
    enableReinitialize: true,
    // validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      resetForm({});
      setLoading(false);
    },
  });
  const { handleSubmit, values, errors, handleChange, setFieldValue } =
    attributeForm;
  return (
    <div className="add-attribute">
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <Form.Label className="attribute-form-label">
              Color
              {/* <span className="asterisk">*</span> */}
            </Form.Label>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="ColorName.name"
                    value={values.ColorName.name}
                    placeholder="Enter Attribute Name"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.ColorName}
                  />
                  <Form.Control.Feedback type="invalid">
                    ColorName Name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="ColorName.price"
                    value={values.ColorName.price}
                    placeholder="Price"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <CustomDropdown
                    option={[]}
                    placeholder="Select Stock"
                    width="100%"
                    borders={true}
                    fullArrow={true}
                    dropdownContentHeight={"500px"}
                    error={!!errors.ParentCategory}
                    name="ColorName.stock"
                    isActive={colorIsActive}
                    handleChangeRowsPerPage={async (value) =>
                      attributeForm.setFieldValue("ColorName.stock", value)
                    }
                    setIsActive={setColorIsActive}
                    ref={colorIsActiveRef}
                    selectedValue={values.ColorName.stock}
                    // handleChangeRowsPerPage={async (value) =>categoryForm.setFieldValue('ParentCategory', value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <label className="CatInput upload-Label">
                    Upload Image
                    <UploadIcon className="upload-icon" />
                    <Form.Control
                      type="file"
                      name="Catname"
                      value={values.Catname}
                      placeholder="Upload Image"
                      className="upload-file-new"
                      onChange={handleChange}
                      isInvalid={!!errors.Catname}
                    />
                  </label>
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-1 add-child-attributes">
                <Link
                  to={{
                    pathname: "/attributes/addNewChildAttribute",
                    state: { isAddMore: true },
                  }}
                >
                  <Tooltip title="Add" placement="top">
                    <PlusOutLine />
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 color-info">
            Name for the attribute (shown on the front-end)
          </div>
        </div>
        {colorChild.map((color, index) => (
          <div className="row attribute-row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {color.color}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="CatInput add-attribute-color">
                    {color.price}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {color.stock}
                  </div>
                </div>
                <div className="col-md-1 d-flex align-self-center">
                  <div className="color-box">
                    <div style={{ backgroundColor: color.code }}></div>
                  </div>
                  <div className="col-md-1 align-self-center">
                    <MinusOutline />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="row attribute-row">
          <div className="col-12">
            <Form.Label className="attribute-form-label">
              Size
              {/* <span className="asterisk">*</span> */}
            </Form.Label>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="Size.name"
                    value={values.Size.name}
                    placeholder="Enter Attribute Name"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="Size.price"
                    value={values.Size.price}
                    placeholder="Price"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <CustomDropdown
                    option={[]}
                    placeholder="Select Stock"
                    width="100%"
                    borders={true}
                    fullArrow={true}
                    isActive={sizeIsActive}
                    setIsActive={setSizeIsActive}
                    ref={sizeIsActiveRef}
                    dropdownContentHeight={"500px"}
                    error={!!errors.ParentCategory}
                    name="Size.stock"
                    selectedValue={values.Size.stock}
                    handleChangeRowsPerPage={async (value) =>
                      attributeForm.setFieldValue("Size.stock", value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <label className="CatInput upload-Label">
                    Upload Image
                    <UploadIcon className="upload-icon" />
                    <Form.Control
                      type="file"
                      name="Catname"
                      value={values.Catname}
                      placeholder="Upload Image"
                      className="upload-file-new"
                      onChange={handleChange}
                      isInvalid={!!errors.Catname}
                    />
                  </label>
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-1 add-child-attributes">
                <Link
                  to={{
                    pathname: "/attributes/addNewChildAttribute",
                    state: { isAddMore: true },
                  }}
                >
                  <Tooltip title="Add" placement="top">
                    <PlusOutLine />
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 color-info">
            Name for the attribute (shown on the front-end)
          </div>
        </div>
        {sizeChild.map((size, index) => (
          <div key={index} className="row attribute-row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {size.size}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="CatInput add-attribute-color">
                    {size.price}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {size.stock}
                  </div>
                </div>
                <div className="col-md-1 d-flex align-self-center">
                  <div className="col-md-1 align-self-center">
                    <MinusOutline />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="row attribute-row">
          <div className="col-12">
            <Form.Label className="attribute-form-label">
              Model
              {/* <span className="asterisk">*</span> */}
            </Form.Label>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="Model.name"
                    value={values.Model.name}
                    placeholder="Enter Attribute Name"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="Model.price"
                    value={values.Model.price}
                    placeholder="Price"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <CustomDropdown
                    option={[]}
                    placeholder="Select Stock"
                    width="100%"
                    borders={true}
                    isActive={modelIsActive}
                    setIsActive={setModelIsActive}
                    ref={modelIsActiveRef}
                    fullArrow={true}
                    dropdownContentHeight={"500px"}
                    error={!!errors.ParentCategory}
                    name="Model.stock"
                    selectedValue={values.Model.stock}
                    handleChangeRowsPerPage={async (value) =>
                      attributeForm.setFieldValue("Model.stock", value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <label className="CatInput upload-Label">
                    Upload Image
                    <UploadIcon className="upload-icon" />
                    <Form.Control
                      type="file"
                      name="Catname"
                      value={values.Catname}
                      placeholder="Upload Image"
                      className="upload-file-new"
                      onChange={handleChange}
                      isInvalid={!!errors.Catname}
                    />
                  </label>
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-1 add-child-attributes">
                <Link
                  to={{
                    pathname: "/attributes/addNewChildAttribute",
                    state: { isAddMore: true },
                  }}
                >
                  <Tooltip title="Add" placement="top">
                    <PlusOutLine />
                  </Tooltip>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 color-info">
            Name for the attribute (shown on the front-end)
          </div>
        </div>
        {modalChild.map((modal, index) => (
          <div key={index} className="row attribute-row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {modal.name}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="CatInput add-attribute-color">
                    {modal.price}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CatInput add-attribute-color">
                    {modal.stock}
                  </div>
                </div>
                <div className="col-md-1 d-flex align-self-center">
                  <div className="col-md-1 align-self-center">
                    <MinusOutline />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="row attribute-row">
          <div className="col-12">
            <Form.Label className="attribute-form-label">
              Slug
              {/* <span className="asterisk">*</span> */}
            </Form.Label>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3">
                <Form.Group controlId="validationCategoryName">
                  <Form.Control
                    type="text"
                    name="Catname"
                    value={values.Catname}
                    placeholder="Enter Category"
                    className="CatInput"
                    onChange={handleChange}
                    isInvalid={!!errors.Catname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Category name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-12 color-info">
            Unique slug/reference for the attribute
          </div>
        </div>
        <div className="searchSection d-flex add-attribute-btn">
          <LoadingButton
            type="submit"
            loading={loading}
            className="searchButton searchButton-table d-block add-user-btn"
          >
            Add Attribute
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
};

export default AddAttribute;
