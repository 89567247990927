import React, { useState, useEffect } from "react";
// import "./EmployeeUser.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill, BsThreeDotsVertical } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import product from "../../Assets/Images/product.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NewMenu from "../NewMenu/NewMenu";
import DatePicker from "react-date-picker";
import { db } from "../../firebase/firebase";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import Dynamic from "../DynamicModal/DynamicModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import CommonTable from "../Table";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { ReactComponent as PasswordIcon } from "../../Assets/Images/lock.svg";
import { ReactComponent as ResetIcon } from "../../Assets/Images/reset.svg";
import { ReactComponent as DateIcon } from "../../Assets/Images/date.svg";
import { ReactComponent as SuspensionIcon } from "../../Assets/Images/suspension.svg";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TableFilterBar from "../TableFilterBar";
import { getAllAdmins } from "../../store/actions/actions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Toaster } from "../../utils/constant";

const dialogInit = {
  open: false,
  record: {},
  title: null,
  description: null,
  type: null,
  btnText: "OK",
};
function EmployeeUser() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [dynamic, setDynamic] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [adminList, setAdminList] = useState({ admin: [], list: [] });
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  let users = useSelector((state) => state.Reducer.users);
  let vendors = useSelector((state) => state.Reducer.vendors);
  let admins = useSelector((state) => state.Reducer.admins);
  let customerService = useSelector((state) => state.Reducer.customerService);
  const [selectedIds, setSelectedIds] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(dialogInit);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSelectionMultiple = (item) => {
    const users = doc(db, "SimpleUsers", item.userid);

    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.userid)) {
      setButtonTitle("Unsuspend");
      setTitle("Activate Account");
      setText("Do you want to reactivate this account ?");
      setDynamic(true);
    } else {
      setButtonTitle("Suspend");
      setTitle("Suspend Account");
      setText("After you Sespend an account,it will no longer in use.");
      setDynamic(true);
    }
  };
  useEffect(() => {
    let newData = [];

    users.map((item) => {
      if (item.suspension) {
        newData.push(item.userid);
      }
    });
    setSelectedIds(newData);
  }, []);
  useEffect(() => {
    dispatch(getAllAdmins());
  }, []);

  useEffect(() => {
    const newArray = [...admins];
    let dataList = [];
    newArray &&
      newArray.length &&
      newArray.map((user, index) => {
        dataList.push({
          id: index + 1,
          name: `${user?.FirstName || user?.firstName || ""} ${
            user?.LastName || user?.lastName || ""
          }`,
          adminAccess: user.adminAccess || "",
          email: user.email || "-",
          password: user.password || null,
          userid: user.userid || null,
          profilePicture: user?.profilePicture || user?.ProfileImage,
        });
      });
    setAdminList({ admin: [...dataList], list: [...dataList] });
    setData(newArray);
  }, [admins]);
  const onChange = (event) => {
    const q = event.target.value.toLowerCase();
    let users = [...data];

    users = users.filter((item) => item.email === q);
    setData(users);
  };
  const removeRecord = async () => {
    const collectionType = "AdminUsers";

    await deleteDoc(doc(db, collectionType, `${confirmDialog?.record?.userid}`))
      .then(() => {
        dispatch(getAllAdmins());
        setConfirmDialog(dialogInit);
        Toaster({
          type: "success",
          message: "User deleted successfully.",
        });
      })
      .catch(() => {
        Toaster({
          type: "error",
          message: "Something went wrong!",
        });
      });
  };

  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 450,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            <img
              className="user-profile-pic"
              src={params?.row?.profilePicture || user}
              alt={params?.row?.name}
              width={26}
              height={26}
            />
            {params?.row?.name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 400, sorting: true },
    { field: "role", headerName: "Role", width: 350 },
    {
      field: "Action",
      headerName: "Actions",
      width: 215,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = async (e, type) => {
          e.stopPropagation();
          switch (type) {
            case "remove":
              return setConfirmDialog({
                open: !confirmDialog.open,
                record: params?.row,
                title: "Delete",
                description: `Are you sure you want to delete this - ${params?.row?.name}?`,
                type,
                btnText: "DELETE",
              });
            case "reset":
              return setConfirmDialog({
                open: !confirmDialog.open,
                record: params?.row,
                title: "Reset Password",
                description: `Do you want to send a change password link to the ${params?.row?.name} account?`,
                type,
                btnText: "RESET",
              });
            default:
              return null;
          }
        };

        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Suspension" placement="top">
              <SuspensionIcon />
            </Tooltip>
            <Tooltip title="Date" placement="top">
              <DateIcon />
            </Tooltip>
            <Tooltip title="Reset" placement="top">
              <ResetIcon />
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon onClick={(e) => onClick(e, "remove")} />
            </Tooltip>
            <Tooltip title="Password" placement="top">
              <PasswordIcon />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const resetPassword = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast("Password reset email sent");
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      // selectValue = category?.categories?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };
  const requestSearch = (searchedVal) => {
    const data = [...admins];
    let dataList = [];
    data &&
      data.length &&
      data.map((user, index) => {
        dataList.push({
          id: index + 1,
          name: `${user?.FirstName || user?.firstName || ""} ${
            user?.LastName || user?.lastName || ""
          }`,
          adminAccess: user.adminAccess || "",
          email: user.email || "-",
          password: user.password || null,
          userid: user.userid || null,
          role: user.role || null,
          profilePicture: user?.profilePicture || user?.ProfileImage,
        });
      });
    let filteredRows = [];
    if (searchedVal.length) {
      filteredRows =
        dataList &&
        dataList.length &&
        dataList.filter((row) => {
          return (
            row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.email.toLowerCase().includes(searchedVal.toLowerCase())
          );
        });
    } else {
      filteredRows = dataList;
    }

    setAdminList({
      ...adminList,
      admin: filteredRows,
    });
  };

  const onRemoveRecord = async () => {
    console.log("confirmDialog?.record", confirmDialog?.record);
    switch (confirmDialog?.type) {
      case "remove":
        return removeRecord();
      case "reset":
        return console.log("reset");
    }
  };

  return (
    // <>
    //   <div className="search-user">
    //     <FiSearch className="usersearch-icon" onClick={onChange} />
    //     <input
    //       className="input-search"
    //       type="text"
    //       placeholder="Search..."
    //       value={search}
    //       onChange={(event) => setSearch(event.target.value)}
    //     />
    //   </div>

    //   <div className="new-users-sectionUsers">
    //     <div className="users-headings">
    //       <ul className="headingss-list">
    //         <li>Username</li>
    //         <li>Name</li>
    //         <li>Email</li>
    //         <li>Role</li>
    //         <li>Actions</li>
    //       </ul>
    //     </div>
    //     {data.map((item) => {
    //       return (
    //         <div className="user-section1">
    //           <Link
    //             to={`listings/${item.userid}`}
    //             style={{ textDecorationColor: "white" }}
    //           >
    //             <img
    //               src={item.ProfileImage ? item.ProfileImage : user}
    //               className="eusernewimage"
    //               alt="logo"
    //             />
    //           </Link>

    //           <p className="efullname">
    //             {item.FirstName ? item.FirstName : "No Name"}
    //           </p>
    //           <p className="eemail">{item.email}</p>
    //           <p className="eadmin">{"Admin"}</p>
    //           <div className="action-btns">
    //             <button
    //               style={{
    //                 backgroundColor: selectedIds.includes(item.userid)
    //                   ? "red"
    //                   : "#f6c262",
    //               }}
    //               onClick={() => handleSelectionMultiple(item)}
    //               className="new-menu"
    //               // style={{
    //               //   backgroundColor: selectedIds.includes(item.userid)
    //               //     ? "red"
    //               //     : "#f6c262",
    //               // }}
    //               // onClick={() => handleSelectionMultiple(item)}
    //             >
    //               Suspension
    //             </button>

    //             {/* <p className="new-menu-date">02 Dec 21 | 04 Dec 21</p> */}
    //             <button
    //               className="user-button4 new-menu-date"
    //               onClick={handleShow}
    //             >
    //               01 Jan 21 | 31 Jan 21
    //             </button>
    //             <Modal class="date-modal" show={show} onHide={handleClose}>
    //               <Modal.Header closeButton>
    //                 <Modal.Title>Select Date</Modal.Title>
    //               </Modal.Header>
    //               <Modal.Body>
    //                 <div className="date-modal-from">
    //                   <h5>From</h5>
    //                   <DatePicker
    //                     selected={startDate}
    //                     onChange={(date) => setStartDate(date)}
    //                   />
    //                 </div>

    //                 <div className="date-modal-to">
    //                   <h5>To</h5>
    //                   <DatePicker />
    //                 </div>
    //               </Modal.Body>
    //               <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleClose}>
    //                   Close
    //                 </Button>
    //                 <Button variant="primary" onClick={handleClose}>
    //                   Save Changes
    //                 </Button>
    //               </Modal.Footer>
    //             </Modal>
    //             <Dynamic
    //               show={dynamic}
    //               buttonTitle={buttonTitle}
    //               account={item.email}
    //               text={text}
    //               close={() => {
    //                 setDynamic(false);
    //               }}
    //               saveChanges={async () => {
    //                 const users = doc(db, "AdminUsers", `${item.userid}`);
    //                 if (title === "Admin Access") {
    //                   updateDoc(users, {
    //                     adminAccess: true,
    //                   });
    //                 }
    //                 if (title === "Delete Account") {
    //                 }
    //                 if (title === "Reset Password") {
    //                   var myHeaders = new Headers();
    //                   myHeaders.append("Content-Type", "application/json");

    //                   var raw = JSON.stringify({
    //                     data: {
    //                       email: item.email,

    //                       name: "Admin",
    //                       message: `Please reset your password on this link  https://sandstrip.com/#/resetPasswordAdmin/${item.userid}`,
    //                     },
    //                   });

    //                   var requestOptions = {
    //                     method: "POST",
    //                     headers: myHeaders,
    //                     body: raw,
    //                     redirect: "follow",
    //                   };

    //                   fetch(
    //                     "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
    //                     requestOptions
    //                   )
    //                     .then((response) => response.text())
    //                     .then((result) => {
    //                       setDynamic(false);
    //                     })
    //                     .catch((error) => console.log("error", error));
    //                 }
    //                 if (title === "Activate Account") {
    //                   console.log("SSSS");
    //                   var selectedIdss = [...selectedIds]; // clone state

    //                   selectedIdss = selectedIds.filter(
    //                     (_id) => _id !== item.userid
    //                   );
    //                   updateDoc(users, {
    //                     suspension: false,
    //                   });
    //                   setSelectedIds(selectedIdss);

    //                   setDynamic(false);
    //                 }
    //                 if (title === "Suspend Account") {
    //                   console.log("SSSAAAS");

    //                   var selectedIdss = [...selectedIds]; // clone state

    //                   selectedIdss.push(item.userid);
    //                   updateDoc(users, {
    //                     suspension: true,
    //                   });
    //                   setSelectedIds(selectedIdss);

    //                   setDynamic(false);
    //                 }
    //               }}
    //             />
    //             <p
    //               className="new-menu-pass"
    //               onClick={() => {
    //                 setButtonTitle("Send");
    //                 setTitle("Reset Password");
    //                 setText(
    //                   "Do you want to send a change password link to the user's account?."
    //                 );
    //                 setDynamic(true);
    //               }}
    //             >
    //               Reset Password
    //             </p>

    //             <p
    //               className="new-menu-hist"
    //               onClick={() => {
    //                 setButtonTitle("Delete");
    //                 setTitle("Delete Account");
    //                 setText(
    //                   "After you delete an account,it's permanently deleted."
    //                 );
    //                 setDynamic(true);
    //               }}
    //             >
    //               Delete
    //             </p>
    //           </div>
    //         </div>
    //       );
    //     })}
    //     <ToastContainer />
    //   </div>
    // </>
    <>
      <div className="add-category-section admin">
        <BreadcrumbMenu
          path={[
            { title: "Users", navLink: "/employee" },
            { title: "Admins", navLink: "/admins" },
          ]}
          activeTab="Admins"
          isBack={false}
        />
        <TableFilterBar
          selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <div className="searchSection d-flex">
            <FiSearch className="search-icon" />
            <input
              className="searchBar searchbar-table"
              placeholder="Search"
              type="text"
              onChange={(e) => requestSearch(e.target.value)}
            />
          </div>
        </TableFilterBar>

        <CommonTable
          columns={columns}
          rows={adminList.admin || []}
          className="categoryTable"
          rowsPerPage={rowsPerPage?.pageSize}
        />
        <Dialog
          open={confirmDialog.open}
          onClose={() =>
            setConfirmDialog({ open: !confirmDialog.open, record: {} })
          }
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this -{" "}
              {confirmDialog?.record?.name} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() =>
                setConfirmDialog({ open: !confirmDialog.open, record: {} })
              }
            >
              Cancel
            </Button>
            <Button onClick={() => onRemoveRecord()}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default EmployeeUser;
