import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "./DataTable.css";
import AddImage from '../AddImage/AddImage';
import ReceiptMenu from '../ReceiptMenu/ReceiptMenu';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(firstname, lastname, birthdate, companymoney, itemmoney, reason, date, info, role, picture ) {
  return { firstname, lastname, birthdate, companymoney, itemmoney, reason, date, info, role, picture };
}

const rows = [
  createData('John', 'Doe', '03/04/88', '$1000', '$500', 'Maintenance', '10/02/2020', 'xyz', 'store', ''),
  createData('Jane', 'Doe', '5/7/90', '$700', '$300', 'Admin', '14/09/21', 'abc', 'seller', ''),

];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function DataTable() {
  const classes = useStyles();

  return (
    <TableContainer className="data-table" component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell >First Name</StyledTableCell>
            <StyledTableCell >Last Name</StyledTableCell>
            <StyledTableCell align="right">Date of birth</StyledTableCell>
            <StyledTableCell align="right">Company money spent on</StyledTableCell>
            <StyledTableCell align="right">Item money spent on</StyledTableCell>
            <StyledTableCell align="right">Reason for spending</StyledTableCell>
            <StyledTableCell align="right">Date of Spending</StyledTableCell>
            <StyledTableCell align="right">Contact Information</StyledTableCell>
            <StyledTableCell align="right">Role</StyledTableCell>
            <StyledTableCell align="right">Receipt</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.firstname}>
              <StyledTableCell component="th" scope="row">
                {row.firstname}
              </StyledTableCell>
              <StyledTableCell align="right">{row.lastname}</StyledTableCell>
              <StyledTableCell align="right">{row.birthdate}</StyledTableCell>
              <StyledTableCell align="right">{row.companymoney}</StyledTableCell>
              <StyledTableCell align="right">{row.itemmoney}</StyledTableCell>
              <StyledTableCell align="right">{row.reason}</StyledTableCell>
              <StyledTableCell align="right">{row.date}</StyledTableCell>
              <StyledTableCell align="right">{row.info}</StyledTableCell>
              <StyledTableCell align="right">{row.role}</StyledTableCell>
              <StyledTableCell align="right">{row.picture}<ReceiptMenu/></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
