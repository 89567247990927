import React from "react";
import "./UserActivity.css";
import iphone from "../../Assets/Images/iphone.png";
import DatePicker from "react-date-picker";

function UserActivity() {
  return (
    <>
      <div className="activity-header">
                <p className="activity-show">Showing 1 to 10 of 10 records</p>
                <div className="activity-type">
                    <p>USER</p>
                    <input type="text" placeholder="Type a name or id"></input>
                </div>

                <div className="activity-log">
                    <p>LOG TYPE</p>
                    <select>
                        <option></option>
                        <option>Lorem Ipsum</option>
                    </select>
                </div>

                <div className="activity-log2">
                    <p>FROM DATE</p>
                    <DatePicker className="activity-date"/>

                </div>

                <div className="activity-log2">
                    <p>TO DATE</p>
                    <DatePicker className="activity-date"/>

                </div>

                <button className="activity-button">FILTER</button>


            </div>

            <div className="activity-detail-section">
                <div className="activity-headings">
                    <p className="activity-id">ID</p>
                    <p className="activity-user-date">DATE</p>
                    <p className="activity-user-log">LOG TYPE</p>
                    <p className="activity-user-done">DONE BY</p>
                    <p className="activity-user-action">ACTION</p>
                </div>
            </div>
            <div className="activity-details-inner">
                <div className="activity-inner">
                <p className="activity-id2">10</p>
                <p className="activity-user-date2">2021-11-9 12:49:15 - 5 seconds ago</p>
                <img src={iphone} className="activity-image" alt="logo" />
                <div className="activity-done-by">
                    <p className="activity-username">John</p>
                    <p className="activity-email">john@doe.com</p>
                </div>
                <button className="activity-show-button">SHOW</button>

                </div>
            </div>

            <div className="activity-details-inner">
                <div className="activity-inner">
                <p className="activity-id2">10</p>
                <p className="activity-user-date2">2021-11-9 12:49:15 - 5 seconds ago</p>
                <img src={iphone} className="activity-image" alt="logo" />
                <div className="activity-done-by">
                    <p className="activity-username">John</p>
                    <p className="activity-email">john@doe.com</p>
                </div>
                <button className="activity-show-button">SHOW</button>

                </div>
            </div>

            <div className="activity-details-inner">
                <div className="activity-inner">
                <p className="activity-id2">10</p>
                <p className="activity-user-date2">2021-11-9 12:49:15 - 5 seconds ago</p>
                <img src={iphone} className="activity-image" alt="logo" />
                <div className="activity-done-by">
                    <p className="activity-username">John</p>
                    <p className="activity-email">jo</p>
                </div>
                <button className="activity-show-button">SHOW</button>

                </div>
            </div>

            <div className="activity-details-inner">
                <div className="activity-inner">
                <p className="activity-id2">10</p>
                <p className="activity-user-date2">2021-11-9 12:49:15 - 5 seconds ago</p>
                <img src={iphone} className="activity-image" alt="logo" />
                <div className="activity-done-by">
                    <p className="activity-username">John</p>
                    <p className="activity-email">john@doe.com</p>
                </div>
                <button className="activity-show-button">SHOW</button>

                </div>
            </div>

            <button className="activity-del-btn">Delete</button>
            <p className="activity-del-text">Delete data older than 7 days</p>
    </>
  );
}

export default UserActivity;
