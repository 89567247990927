import React from "react";
import { Link } from "react-router-dom";
import "./Calender.css";
import calender from "../../Assets/Images/calender.jpg";
import circular from "../../Assets/Images/circular.jpg";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

function Calender() {
  return (
    <>
      <div className="calender-heading">
        <h3 className="calender-text">April 2021</h3>
        <div className="picker">
          <select className="day-pick">
            <option value="A">Day</option>
            <option value="B">Day</option>
            <option value="C">Day</option>
          </select>

          <select className="day-pick">
            <option value="A">Month</option>
            <option value="B">Month</option>
            <option value="C">Month</option>
          </select>

          <select className="day-pick">
            <option value="A">Year</option>
            <option value="B">Year</option>
            <option value="C">Year</option>
          </select>
        </div>
      </div>
      <div></div>
      <div className="calender-section">
        <Link to="/events">
          <button className="calender-event-btn">Add Event</button>
        </Link>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={false}
          events={[
            { title: "event 1", date: "2021-10-04" },
            { title: "event 2", date: "2021-10-06" },
          ]}
        />{" "}
      </div>
      {/* <img className="circular-image" src={circular} alt="logo" /> */}
    </>
  );
}

export default Calender;
