import React from "react";
import "./DataForm.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import AddImage from "../AddImage/AddImage";

function DataForm() {
  return (
    <div className="add-expense">
      <div className="search-user">
        <h2 style={{ marginLeft: 20 }}>Add Expense</h2>
      </div>

      <div className="data-form">
        <div className="data-fields">
          <p>First Name</p>
          <input type="text" />
        </div>

        <div className="data-fields2">
          <p>Last Name</p>
          <input type="text" />
        </div>

        <div className="data-fields">
          <p>Date of Birth</p>
          <input type="text" />
        </div>

        <div className="data-fields2">
          <p>Company Money Spent On</p>
          <input type="text" />
        </div>

        <div className="data-fields">
          <p>Item Money Spent On</p>
          <input type="text" />
        </div>

        <div className="data-fields2">
          <p>Reason for Spending</p>
          <input type="text" />
        </div>

        <div className="data-fields">
          <p>Date of Spending</p>
          <input type="text" />
        </div>

        <div className="data-fields2">
          <p>Contact Information</p>
          <input type="text" />
        </div>

        <div className="data-fields">
          <p>Role</p>
          <input type="text" />
        </div>

        <div className="data-fields2">
          <p>Payment Method</p>
          <input type="text" />
        </div>

        <div className="data-fields">
          <p>Add Image of Receipt</p>
          <AddImage />
        </div>

        <button className="data-save-btn">Save</button>
      </div>
    </div>
  );
}

export default DataForm;
