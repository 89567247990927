import React, { useEffect, useState } from "react";
import "./Home.css";
import Section from "../Section/Section";
import Welcome from "../Welcome/Welcome";
import Homebar from "../Homebar/Homebar";
import Search from "../Search/Search";
import Users from "../Users/Users";
import Message from "../Message/Message";
import Cart from "../Cart/Cart";
import Download from "../Download/Download";
import Map from "../Map/Map";
import Vendor from "../Vendor/Vendor";
import Percentages from "../Percentages/Percentages";
import Coupon from "../Coupon/Coupon";
import Calender from "../Calender/Calender";
import CircularProgress from "../CircularProgress/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import ProfileUploader from "./ProfileUploader";

import {
  hide,
  getAllOrders,
  getAllShorts,
  getUser,
  getAllCategories,
  getAllProducts,
  getAllLive,
  getAllLiveSchedules,
  getAllUsers,
  getAllHistory,
  getAllFeedbackReports,
  getAllBanners,
  getAllSupportUser,
  getAllAttributes,
  setVendor,
  getAllAdmins,
  getAllCustomerService,
  getAllevents,
  adminUser,
  getAllRedemm,
  getAllPending,
  setAdmin,
  setCustomer,
  showLoading,
  hideLoading,
} from "../../store/actions/actions";
import BannerButton from "../BannerButton/BannerButton";
import BannerButton2 from "../BannerButton/BannerButton2";
import user from "../../Assets/Images/user1.jpg";
import logout from "../../Assets/Images/logout.png";
import { getDatabase, ref, child, get, onValue } from "firebase/database";
import { database, db } from "../../firebase/firebase";
import { getAllSupportChat } from "../../store/actions/actionTypes";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import MapPercent from "../MapPercent/MapPercent";
import Record from "../Record/Record";
import HomeAnalytics from "../HomeAnalytics/HomeAnalytics";
import { Button, Modal } from "react-bootstrap";
import profile from "../../Assets/Images/product.png";
import { AiFillCamera } from "react-icons/ai";
import ToggleProfile from "../ToggleProfile/ToggleProfile";
import RequestBtn from "../RequestBtn/RequestBtn";
import ReturnBtn from "../ReturnBtn/ReturnBtn";
import Progress from "../Progress/Progress";
import { BsDot } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import ProgressSection from "../Progress/Progress";

import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  getDoc,
} from "firebase/firestore";
import UserButton from "../UserSupport/UserButton";

const auth = getAuth();
const userSignin = auth.currentUser;

const Home = () => {
  const [show, setShow] = useState(false);
  const [change, setChange] = useState(false);
  const [images, setImages] = useState([]);
  const [pro, setPro] = useState("");

  const location = useLocation();
  const history = useHistory();
  const suser = localStorage.getItem("AdminUser");
  const dispatch = useDispatch();
  const feeds = useSelector((state) => state.Reducer.feeds);
  const users = useSelector((state) => state.Reducer.adminUser);

  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(async () => {
    localStorage.removeItem("VendorUser");
    localStorage.removeItem("ServiceUser");

    let usr = localStorage.getItem("AdminUser");

    if (usr) {
      const pRef = doc(db, "AdminUsers", `${JSON.parse(suser).userid}`);
      const snap = await getDoc(pRef);
      console.log("testing", "Hello", snap.data());
      dispatch(showLoading());
      dispatch(setAdmin(true));
      dispatch(setCustomer(false));

      dispatch(adminUser(snap.data()));
      setPro(snap.data().profilePicture);
      setPassword(snap.data().password);
      setFirst(snap.data().FirstName);
      setLast(snap.data().LastName);
      dispatch(getAllBanners());
      dispatch(getAllevents());
      dispatch(getAllPending());
      dispatch(getAllAdmins());
      dispatch(getAllCustomerService());
      dispatch(getAllUsers());
      // dispatch(getAllShorts());
      dispatch(hide(false));
      dispatch(getAllFeedbackReports());
      // dispatch(getAllOrders());
      // dispatch(getAllCategories());
      dispatch(getAllProducts());
      dispatch(getAllRedemm());

      // dispatch(getAllLive());
      // dispatch(getAllLiveSchedules());
      // dispatch(getAllHistory());
      dispatch(getAllSupportUser());
      dispatch(getAllAttributes());
      dispatch(setVendor(false));
      dispatch(hideLoading());
    } else {
      history.push("/Login");
    }
  }, []);

  const updateUserProfile = async () => {
    const pRef = doc(db, "AdminUsers", `${JSON.parse(suser).userid}`);
    let data = {
      profilePicture: pro,
      FirstName: first,
      LastName: last,
      password: password,
    };
    await updateDoc(pRef, data);

    const snap = await getDoc(pRef);

    dispatch(adminUser(snap.data()));
    setPro(snap.data().profilePicture);
    setPassword(snap.data().password);
    setFirst(snap.data().FirstName);
    setLast(snap.data().LastName);
    setShow(false);
  };

  return (
    <div className="dashboard">
      {/* <Section/>  */}
      {/* <div className="welcome-card">
        <div style={{ position: "absolute", marginLeft: "20px" }}>
          <Link to="notifications">
            <BsDot className="not-dot2" />
          </Link>

          <BsBellFill className="message-icon" />
        </div>
        <img
          src={pro ? pro : user}
          alt="logo"
          className="welcome-user"
          onClick={handleShow}
        />
        <Link to="login">
          <img
            src={logout}
            style={{ height: 40, width: 40, float: "right", marginRight: 10 }}
            alt="logo"
          />
        </Link>

        <p className="welcome-user-text">Welcome Back! {users.FirstName}</p>

        <Modal class="date-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="pic-section">
                <img src={pro ? pro : user} />
                <ProfileUploader
                  value={images}
                  setHandler={async (values) => {
                    let newImage = values[0].data_url;

                    setPro(newImage);
                  }}
                />
                <p>John Doe</p>
              </div>

              <div className="details-section">
                <div className="profile-fields">
                  <input
                    className="email-field"
                    type="text"
                    placeholder="Email"
                    value={JSON.parse(suser)?.email}
                  />

                  <input
                    className="email-field"
                    type="text"
                    placeholder="First Name"
                    value={first}
                    onChange={(e) => setFirst(e.target.value)}
                  />

                  <input
                    className="email-field"
                    type="text"
                    placeholder="Last Name"
                    value={last}
                    onChange={(e) => setLast(e.target.value)}
                  />

                  <ToggleProfile
                    onChange={() => {
                      setChange(!change);
                    }}
                  />

                  {change ? (
                    <>
                      <input
                        className="email-field"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <input
                        className="email-field"
                        type="password"
                        placeholder="Confirm Password"
                        value={passwordC}
                        onChange={(e) => setPasswordC(e.target.value)}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateUserProfile}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div> */}
      <Homebar />
      <Users />
      <Message
        hide={() => {
          dispatch(hide(true));
        }}
      />
      <Cart />
      <Download />
      <BannerButton2 />
      <Section />
      <BannerButton />
      {/* <BannerButton /> */}

      <Percentages feeds={feeds} />

      <Vendor />
      <div className="request-button">
        <Link to="/returnscreen">
        <p
          className="request-text"
          onClick={() => {
            history.push({
              pathname: "/order",

              state: { screenName: "Return" },
            });
          }}
        >
          Return Request
        </p>
        <p className="request-num">1012</p>
        </Link>
      </div>

      <div className="refund-button">
      <Link to="/refundscreen">
        <p
          className="request-text"
          onClick={() => {
            history.push({
              pathname: "/order",

              state: { screenName: "Refund" },
            });
          }}
        >
          Refund Request
        </p>
        <p className="request-num">2012</p>
        </Link>
      </div>
      <Coupon />
      <ProgressSection />

      <Calender />
      <Link to="/usersupport">
        <UserButton />
      </Link>
      <Map />
      <Record />
      <HomeAnalytics />
    </div>
  );
};

export default Home;
