import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IoSettings } from "react-icons/io5";
import "./CallMenu.css";
import { Link } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Timeline from "../Timeline/Timeline";
import DelPop from "../DelPop/DelPop";
import Location from "../Location/Location";
import { makeStyles } from "@material-ui/core/styles";
import { GrClose } from "react-icons/gr";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function CallMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const id = open ? "track-pop" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IoSettings
        className="call-product-setting"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="call-menu"
        // anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className="return-menu" onClick={handleClose}>
          <Link
            to="/refund/7brAGglGWiU58qTeDUaf0Np97y43"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Return
          </Link>
        </MenuItem>
        <MenuItem className="refund-menu" onClick={handleClose}>
          <Link
            to="/refund/7brAGglGWiU58qTeDUaf0Np97y43"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Refund
          </Link>
        </MenuItem>
        <MenuItem className="reorder-menu" onClick={handleClose}>
          <Link
            to="/refund/7brAGglGWiU58qTeDUaf0Np97y43"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Reorder
          </Link>
        </MenuItem>
        <MenuItem
          className="track-menu"
          onClick={() => {
            setOpen(true);
          }}
          aria-describedby={id}
        >
          Track Order
        </MenuItem>
        <Popover
          id={"track-pop"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <GrClose className="close-pop" onClick={() => setOpen(false)} />

          <Typography className={classes.typography}>
            <p className="track-order">Order Details</p>
            <p className="track-code">
              Your Order Code:
              <span style={{ color: "rgba(61, 193, 98, 1)" }}>#123456</span>
            </p>

            <div className="g-map">
              <Location />
            </div>
            <div className="order-item">
              <p className="item-count">1 ITEM</p>
            </div>
            <div className="order-item2">
              <p className="item-count">$20</p>
            </div>
            <Timeline />
            {/* <DelPop /> */}
            {/* <button className="del-status">Delivery Status</button> */}
          </Typography>
        </Popover>
        <MenuItem className="inv-menu" onClick={handleClose}>
          <Link
            to="/usersupport"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Investigation
          </Link>
        </MenuItem>
        <MenuItem className="case-menu" onClick={handleClose}>
          Case Solve
        </MenuItem>
      </Menu>
    </div>
  );
}
