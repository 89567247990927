export const sendMailService = (data) => {
  console.log(
    "process.env.EMAIL_SEND_ENDPOINT",
    process.env.REACT_APP_EMAIL_SEND_ENDPOINT
  );
  return new Promise(async (resolve, reject) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
      //   redirect: "follow",
    };

    fetch(process.env.REACT_APP_EMAIL_SEND_ENDPOINT, requestOptions)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
