import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import "./History.css";
import Tabs from "../Tabs/Tabs";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, hide } from "../../store/actions/actions";
import { AiOutlineClockCircle } from "react-icons/ai";
import OrderMenu from "../OrderMenu/OrderMenu";
import callincome from "../../Assets/Images/callincome.png";
import eye from "../../Assets/Images/eye.png";
import caller from "../../Assets/Images/product.png";
import UserCards from "../ProductCard/ProductCard";
import { BsPlayCircle } from "react-icons/bs";
import reject from "../../Assets/Images/reject.png";
import accept from "../../Assets/Images/accept.png";
import { MdNotificationsActive } from "react-icons/md";
import { BsFillChatFill, BsFillPlayFill } from "react-icons/bs";
import { AiFillForward } from "react-icons/ai";
import { BiRewind } from "react-icons/bi";
import ReactAudioPlayer from "react-audio-player";
import { HiPhoneIncoming } from "react-icons/hi";
import { RiMessage2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import music from "../../Assets/Images/test.mpeg";
import pause from "../../Assets/Images/pause.png";
import mute from "../../Assets/Images/mute.png";
import menu from "../../Assets/Images/menu.png";
import CallCard from "../CallCard/CallCard";

const tabs = [
  {
    id: "1",
    name: "All",
  },
  {
    id: "2",
    name: "Filter",
  },
];

function History({ allorders }) {
  const orders = useSelector((state) => state.Reducer.orders);
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [selectedTab, setSelectedTab] = useState("1");
  const [selected, setSelected] = useState("");

  const [item, setItem] = useState("");

  useEffect(() => {
    dispatch(hide(false));
  }, []);
  // const handleSelection = (item) => {
  //   var selectedId = selected;

  //   if (selectedId === item.id) {
  //     setSelected(item.id);
  //   } else setSelected(item.id);
  // };

  const handleSelectionTab = (id) => {
    var selectedId = selectedTab;

    if (selectedId === id) {
      setSelectedTab(id);
    } else setSelectedTab(id);
  };

  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };

  return (
    <div>
      <div className="newUi">
        <Link to="/vcalls">
          <img
            src={callincome}
            style={{
              marginTop: 28,
              marginLeft: 30,
              marginRight: 10,
              float: "left",
              height: 20,
              width: 20,
            }}
          />
          <h6
            style={{
              marginLeft: 35,
              marginTop: 28,
              fontWeight: 700,
            }}
          >
            Active Calls
          </h6>
        </Link>

        <Link to="/vcallhistory">
          <img
            src={callincome}
            style={{
              marginTop: 28,
              marginLeft: 30,
              marginRight: 10,
              float: "left",
              height: 20,
              width: 20,
              marginBottom: 20,
            }}
          />
          <h6
            style={{
              marginLeft: 35,
              marginTop: 28,
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Call History
          </h6>
        </Link>
        <div className="product-call-card">
          <div className="ftr">
            <input placeholder="Search" className="fftr" />
            <FiSearch className="ftricon" />
          </div>
          <CallCard className="call-productcard" />
        </div>
      </div>
      <Link to="/ChatSupportCare/allchat">
        <RiMessage2Fill className="chat-call" />
      </Link>
      <div>
        <div className="history-section2">
          <h5
            style={{
              marginLeft: 35,
              marginTop: 28,
              fontWeight: 700,
            }}
          >
            Active Calls
          </h5>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {show ? (
              <div className="calling-section">
                <div className="caller-user">
                  <img src={caller} />
                  <div className="call-icons">
                    <img className="reject-icon" src={reject} />
                    <img src={pause} className="not-msg" />
                    <img src={menu} className="not-msg" />
                    <img src={mute} className="not-msg" />
                    {/* <img className="accept-icon" src={accept} /> */}
                  </div>
                  <h5>John Doe</h5>
                </div>
              </div>
            ) : null}
          </div>
          {tabs.map((item) => (
            <div
              className="tab-navFF"
              onClick={() => {
                handleSelectionTab(item.id);
              }}
            >
              <div
                className="tabhistory2"
                style={{
                  backgroundColor:
                    selectedTab === item.id ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === item.id ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === item.id ? "#3FAD72" : "white",
                  }}
                >
                  {item.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="history-card2">
        <ul className="history-content2">
          <li>Type</li>
          <li>Status</li>
          <li>From</li>
          <li>To</li>
          {/* <li>Via Number</li> */}
          <li>Created</li>
          <li>Ended</li>
          <li>Time</li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
        <ul className="history-content2" style={{ backgroundColor: "white" }}>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Incoming</p>
            </div>
          </li>
          <li>
            <div
              style={{
                height: 30,
                backgroundColor: "#3FAD72",
                borderRadius: 20,
                display: "flex",
              }}
            >
              <HiPhoneIncoming className="incoming" />
              <p className="incoming-text">Answer</p>
            </div>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li>
          {/* <li>
            <p
              style={{
                margin: 10,
              }}
            >
              03219393939
            </p>
          </li> */}
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              15/12/2021
            </p>
          </li>
          <li>
            <p
              style={{
                margin: 10,
              }}
            >
              09:00
            </p>
          </li>
        </ul>
      </div>
      {/* selected === item.videoid */}

      {selected === "1" && (
        <>
          <ul className="history-content2">
            <li>Type</li>
            <li>Status</li>
            <li>From</li>
            <li>To</li>
            <li>Via Number</li>
            <li>Created</li>
            <li>Ended</li>
            <li>Time</li>

            <li>Action</li>
          </ul>
        </>
      )}
      {selected === "2" && (
        <>
          {orders.map((item) => {
            if (item.OrderStatus === "dilevered") {
              return (
                <div className="id-card">
                  <p>#{item.OrderId}</p>
                  <div>
                    <img
                      src={item.ProductList[0].Pimage}
                      className="history-image"
                      alt="logo"
                    />
                    <p className="user-name">Product</p>
                    <p className="user-cash">null object</p>
                    <div className="product-eta">
                      <AiOutlineClockCircle className="clock" />
                      <p className="eta">13 min</p>
                    </div>
                    <p className="delivery">Delivery</p>

                    <p className="delivery2">{item.OrderStatus}</p>

                    <p className="delivery3">${item.ServiceFees}</p>
                    {/* <BiDotsVerticalRounded className="more-options" /> */}
                    <OrderMenu />
                  </div>
                </div>
              );
            }
          })}
        </>
      )}
    </div>
  );
}

export default History;
