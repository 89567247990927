import React, { useState } from "react";
import "./VendorMonthlyOrders.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import VendorSalesHistory from "../SaleHistory/VendorSalesHistory";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";

const tabs = [
  {
    id: "1",
    name: "All Orders",
  },
  {
    id: "2",
    name: "Completed",
  },
  {
    id: "3",
    name: "Cancelled",
  },
  {
    id: "4",
    name: "Add New",
  },
  {
    id: "5",
    name: "Return",
  },
  {
    id: "6",
    name: "Refund",
  },
  {
    id: "7",
    name: "Investigate",
  },
];

function VendorMonthlyOrders() {
  const orders = useSelector((state) => state.Reducer.orders);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("1");
  const [selected, setSelected] = useState("");

  const [item, setItem] = useState("");

  const handleSelectionTab = (id) => {
    var selectedId = selectedTab;

    if (selectedId === id) {
      setSelectedTab(id);
    } else setSelectedTab(id);
  };

  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };
  return (
    <>


      <div className="history-section">
        <h4>Order</h4>
        <div className="history-date2">
          <DatePicker className="order-date" />
          <p className="order-to">To</p>
          <DatePicker className="order-date2" />
        </div>
       
      </div>

      {tabs.map((item) => (
        <div
          className="tab-navFF"
          onClick={() => {
            handleSelectionTab(item.id);
          }}
        >
          <div
            className="tabhistory"
            style={{
              backgroundColor:
                selectedTab === item.id ? "white" : "rgba(42, 143, 86, 1)",
              borderColor: selectedTab === item.id ? "#3FAD72" : "white",
              borderStyle: "solid",
            }}
          >
            <p
              style={{
                color: selectedTab === item.id ? "#3FAD72" : "white",
              }}
            >
              {item.name}
            </p>
          </div>
        </div>
      ))}

      <div className="ordertabborder"></div>

      <div
        style={{ float: "left", width: "80%", marginTop: 20, marginLeft: 20 }}
      >
        <VendorSalesHistory allorders={orders} />
      </div>
    </>
  );
}

export default VendorMonthlyOrders;
