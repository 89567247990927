import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";
import "./ProductImage.css";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";

export function AddProductImage({
  url,
  length,
  index,
  minus,
  addHandler,
  minusHandler,
  listImages,
  setHandler,
}) {
  const [images, setImages] = React.useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex, "fazzy");
    setImages(imageList);

    setHandler(imageList);
  };
  useEffect(() => {
    setImages(listImages);
  }, []);
  return (
    <div className="App">
      <ImageUploading onChange={onChange} value={images} dataURLKey="data_url">
        {({ onImageUpload, imageList }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                zIndex: 99999,
                top: 10,
              }}
            >
              {minus ? (
                <AiFillMinusCircle
                  className="fere"
                  style={{ right: 9 }}
                  onClick={minusHandler}
                />
              ) : (
                <AiFillMinusCircle
                  className="fere2"
                  style={{ right: 9 }}
                  color="transparent"
                />
              )}

              {length ? (
                <AiFillPlusCircle
                  className="fere"
                  style={{ left: 15 }}
                  onClick={addHandler}
                />
              ) : (
                <AiFillPlusCircle
                  onClick={addHandler}
                  className="fere2"
                  style={{ left: 15 }}
                  color="transparent"
                />
              )}
            </div>
            <button className="product-upload-btn" onClick={onImageUpload}>
              +
            </button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img
                  className="product-image-uploaded"
                  src={url}
                  alt=""
                  width="100"
                />
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
