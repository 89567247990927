import React from "react";
import { Nav } from "react-bootstrap";
import "./tab.css";

const TabMenu = ({ tabs, activeKey, setActiveKey }) => {
    console.log("tabs", tabs, activeKey);
  return (
    <div className="common-tab-menu">
      <Nav variant="pills" activeKey={activeKey}>
        {tabs &&
          tabs.map((tab) => (
            <Nav.Item key={tab.id} className="item-all-streams">
              <Nav.Link
                eventKey={tab.id}
                className="allStreams d-flex"
                onClick={() => {
                  setActiveKey(tab.id);
                }}
              >
                {tab.name}
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
    </div>
  );
};

export default TabMenu;
