import { toast } from "react-toastify";

export const Toaster = ({ type, message }) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

// product image folder path 
export const getPathStorageFromUrl = (url) =>{
  const baseUrl = process.env.REACT_APP_PRODUCT_IMAGES_PATH;
  let imagePath = url.replace(baseUrl,"");
  const indexOfEndPath = imagePath.indexOf("?");
  imagePath = imagePath.substring(0,indexOfEndPath);
  imagePath = imagePath.replace("%2F","/");
  return imagePath;
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;