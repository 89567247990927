import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonTable from "../Table";
import TableFilterBar from "../TableFilterBar";

const ChildAttributes = () => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [childAttributes, setChildAttributes] = useState([]);
  useEffect(() =>{
    let dataList = [];
    [1, 2, 3, 4, 5].map((number, index) => {
        dataList.push({
          id: index + 1,
          name: "Hank M. Zakroff",
          description: "Hello world",
          slug: "Hank M. Zakroff",
          count: 0,
        });
    });
    setChildAttributes([...dataList]);
  }, []);
  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350, sorting: true },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      sorting: true,
    },
    { field: "slug", headerName: "Slug", width: 350 },
    { field: "count", headerName: "Count", width: 200 },
  ];
  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      selectValue = 100;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };
  return (
    <div className="child-attributes">
      <div className="child-heading">Hello</div>
      <TableFilterBar
        selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        <button
          className="searchButton searchButton-table"
          onClick={() => history.push("/attributes/addNewChildAttribute")}
        >
          Add New Child Attribute
        </button>
      </TableFilterBar>
      <CommonTable
        columns={columns}
        rows={childAttributes || []}
        className="categoryTable"
        rowsPerPage={rowsPerPage?.pageSize}
      />
    </div>
  );
};

export default ChildAttributes;
