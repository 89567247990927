import React, { useState, useEffect } from "react";
import "./Login.css";
import { Button, Modal } from "react-bootstrap";

import imageform from "../../Assets/Images/login-form.svg";
import imgBackground from "../../Assets/Images/login-bg.svg";
import imgPerson from "../../Assets/Images/login-person.svg";
import screen1 from "../../Assets/Images/login-screen1.svg";
import screen2 from "../../Assets/Images/login-screen2.svg";
import screen3 from "../../Assets/Images/login-screen3.svg";
import imgIcons from "../../Assets/Images/login-icons.svg";
import { Link, useHistory } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useDispatch } from "react-redux";
import {
  adminUser,
  setAdmin,
  setCustomer,
  setVendor,
} from "../../store/actions/actions";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Forgot from "../../../src/Assets/Images/passreset.png";

const viewHeight = window.outerHeight;
const auth = getAuth();

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState("");
  const [showing, setShowing] = useState(false);
  const [reset, setReset] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      setError("Enter your Email");
    }
    if (!password) {
      setError("Enter your password");
    }
    if (!username && !password) {
      setError("Enter your username and password");
    }

    let arr = [];
    const q = query(
      collection(db, "CustomerService"),
      where("email", "==", username),
      where("password", "==", password)
    );

    const querySnapshot = await getDocs(q);
    console.log("HHHHH", querySnapshot);

    querySnapshot.forEach((doc) => {
      console.log(doc, "gggggg");
      if (doc.exists) {
        arr.push(doc.data());
        console.log(doc.id, " => ", doc.data());
      }
    });
    if (arr.length) {
      let end = base64_encode(username);

      console.log("GGGGGG,FFFFF");
      dispatch(adminUser(arr[0]));
      dispatch(setCustomer(true));
      dispatch(setVendor(false));
      dispatch(setAdmin(false));
      if (arr[0].FirstName.length) {
        localStorage.setItem("ServiceUser", JSON.stringify(arr[0]));

        history.push("/inner");
      } else {
        history.push(`/customerRegister/${arr[0].userid}`);
      }
    } else {
      toast("This email is not registered as a Admin");
    }
  };
  useEffect(() => {
    dispatch(setAdmin(false));
    dispatch(setVendor(false));
    dispatch(setCustomer(false));
  }, []);

  return (
    <div className="login">
      <div className="left">
        <img className="background" src={imgBackground} alt="imgBackground" />
        <img className="person" src={imgPerson} alt="imgPerson" />
        <img className="screen1" src={screen1} alt="screen1" />
        <img className="screen2" src={screen2} alt="screen2" />
        <img className="screen3" src={screen3} alt="screen3" />
        <img className="icons" src={imgIcons} alt="imgIcons" />
      </div>
      <div className="wrapper">
        <img src={imageform} alt="imageform" />
        <form onSubmit={handleSubmit}>
          <h2> Customer Service</h2>

          <p className="error"> {error} </p>

          <label style={{ top: "50px" }} htmlFor="username">
            Username
          </label>
          <br />
          <input
            onChange={(event) => setUsername(event.target.value)}
            value={username}
            name="username"
            id="username"
            type="text"
          />
          <br />
          <label
            style={{ top: "120px" }}
            className="password"
            htmlFor="password"
          >
            Password
          </label>
          <br />
          <input
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            name="password"
            id="password"
            type="password"
          />
          <br />
          <button type="submit">
            <p>Login</p>
          </button>
          <p
            style={{ marginTop: 120, marginLeft: 60, color: "#3fad72" }}
            onClick={() => setShowing(true)}
          >
            Forget Password?
          </p>
        </form>
      </div>
      <ToastContainer />
      <Modal
        id="password-modal"
        class="date-modal"
        show={showing}
        onHide={() => setShowing(false)}
      >
        {/* <Modal.Header closeButton> */}
        {/* <Modal.Title>Forget Password</Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body>
          <div className="register-card2" style={{ height: viewHeight }}>
            <img src={Forgot} style={{ width: "70%" }} />
            <div style={{ float: "left", width: "100%" }}>
              <p
                style={{
                  fontSize: 17,
                  fontWeight: 800,
                  marginTop: 45,
                  marginLeft: 40,
                }}
              >
                Forget Password
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginLeft: "40px",
                    marginTop: "40px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                    Email
                  </p>
                  <input
                    className="register-field2"
                    type="text"
                    value={reset}
                    placeholder="Please enter your email"
                    onChange={(event) => setReset(event.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  float: "left",
                  width: "100%",
                  maxWidth: "100%",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <button
                  className="pass-change-button2"
                  onClick={async () => {
                    let arr = [];
                    const q = query(
                      collection(db, "CustomerService"),
                      where("email", "==", reset)
                    );

                    const querySnapshot = await getDocs(q);
                    console.log("HHHHH", querySnapshot);

                    querySnapshot.forEach((doc) => {
                      console.log(doc, "gggggg");
                      if (doc.exists) {
                        arr.push(doc.data());
                        console.log(doc.id, " => ", doc.data());
                      }
                    });
                    if (arr.length) {
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        data: {
                          email: reset,

                          name: "Admin",
                          message: `Please reset your password on this link https://sandstrip.com/#/resetPasswordAdmin/${arr[0].userid}`,
                        },
                      });

                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };

                      fetch(
                        "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
                        requestOptions
                      )
                        .then((response) => response.text())
                        .then((result) => {
                          setShowing(false);
                          toast("Passowrd reset Link in sent to your email.");
                        })
                        .catch((error) => console.log("error", error));
                    } else {
                      setShowing(false);
                      toast("Email is not Registered");
                    }
                  }}
                >
                  <p
                    style={{
                      marginTop: 10,
                      color: "#FFFFFF",
                      marginBottom: "12px",
                    }}
                  >
                    SEND
                  </p>
                </button>
              </div>
              <div
                style={{
                  float: "left",
                  width: "100%",
                  maxWidth: "100%",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              ></div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="primary"
            onClick={async () => {
              let arr = [];
              const q = query(
                collection(db, "AdminUsers"),
                where("email", "==", reset)
              );

              const querySnapshot = await getDocs(q);
              console.log("HHHHH", querySnapshot);

              querySnapshot.forEach((doc) => {
                console.log(doc, "gggggg");
                if (doc.exists) {
                  arr.push(doc.data());
                  console.log(doc.id, " => ", doc.data());
                }
              });
              if (arr.length) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  data: {
                    email: reset,

                    name: "Admin",
                    message: `Please reset your password on this link https://sandstrip.com/#/resetPasswordAdmin/${arr[0].userid}`,
                  },
                });

                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };

                fetch(
                  "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
                  requestOptions
                )
                  .then((response) => response.text())
                  .then((result) => {
                    setShowing(false);
                    toast("Passowrd reset Link in sent to your email.");
                  })
                  .catch((error) => console.log("error", error));
              } else {
                setShowing(false);
                toast("Email is not Registered");
              }
            }}
          >
            Done
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Login;
