import React, { Component } from "react";
import Switch from "react-switch";
import "./ToggleProfile.css";

class ToggleProfile extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
    this.props.onChange();
  }

  render() {
    return (
      <label className="toggle-profile">
        <Switch onChange={this.handleChange} checked={this.state.checked} />
        <p>Change Password</p>
      </label>
    );
  }
}

export default ToggleProfile;
