import React, { useState, useEffect } from "react";
import "./Listings.css";
import { Modal } from "react-bootstrap";

import ListingsTab from "../ListingsTab/ListingsTab";
import ProductCard from "../ProductCard/ProductCard";

import Button from "@material-ui/core/Button";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Videos from "../Videos/Videos";

import PurchaseCard from "../PurchaseCard/PurchaseCard";
import Payment from "../Payment/Payment";

import UserNotes from "../UserNotes/UserNotes";
import { Link } from "react-router-dom";
import Information from "../Information/Information";
import { useDispatch, useSelector } from "react-redux";
import { getUser, hide } from "../../store/actions/actions";
import UserVideoProfile from "../UserVideProfile/UserVideoProfile";
import VideoView from "../VideoView/VideoView";
import { BsEye } from "react-icons/bs";

import ReactPlayer from "react-player";

import { makeStyles } from "@material-ui/core/styles";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import VideopageTabs from "../VideopageTabs/VideopageTabs";

import iphone from "../../Assets/Images/iphone.png";
import { AiTwotoneSetting, AiFillPlayCircle } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import ProductDesign from "../ProductCard/ReProduct";
import Chat from "../Chat/Chat";
import UserChat from "../UserChat/UserChat";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

function Listings(props) {
  const userDetails = useSelector((state) => state.Reducer.userDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hide(false));
    dispatch(getUser(props.match.params.id));
  }, []);
  console.log("userDetails", userDetails);
  const [show, setShow] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [watch, setWatch] = useState(false);
  const [wish, setWish] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const [lis, setList] = useState(false);
  const [subs, setSubs] = useState(false);
  const [payment, setPayment] = useState(false);
  const [itemSave, setItemSave] = useState(false);
  const [showLis, setShowLis] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selected, setSelected] = useState("");
  const [selectedTab, setSelectedTab] = useState("1");

  const [item, setItem] = useState("");

  const handleSelectionTab = (id) => {
    var selectedId = selectedTab;

    if (selectedId === id) {
      setSelectedTab(id);
    } else setSelectedTab(id);
  };

  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };

  const updateSuspension = (userid) => {
    const users = doc(db, "SimpleUsers", userid);

    updateDoc(users, {
      suspension: false,
    });
  };

  return (
    <>
        <div style={{ float: "left", width: "85%", maxWidth: "100%" }}>
            <div className="search-user">
              <FiSearch className="usersearch-icon" />
              <input
                className="input-search"
                type="text"
                placeholder="Search..."
              />
            </div>

            <div className="not-details">
              <BsDot className="not-dot" />
              <BsFillEnvelopeFill className="message-icon" />

              <BsDot className="not-dot2" />
              <BsBellFill className="message-icon" />
            </div>
          </div>

          <div className="top-menulist">
            <div className="tab-nav" onClick={() => handleSelectionTab("1")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "1" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor:
                    selectedTab === "1" ? "rgba(42, 143, 86, 1)" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "1" ? "#3FAD72" : "white",
                  }}
                >
                  Video Upload
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => handleSelectionTab("2")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "2" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === "2" ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "2" ? "#3FAD72" : "white",
                  }}
                >
                  Video Watch
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => handleSelectionTab("3")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "3" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === "3" ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "3" ? "#3FAD72" : "white",
                  }}
                >
                  Wish List
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => handleSelectionTab("4")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "4" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === "4" ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "4" ? "#3FAD72" : "white",
                  }}
                >
                  Item Purchase
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => handleSelectionTab("5")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "5" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === "5" ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "5" ? "#3FAD72" : "white",
                  }}
                >
                  User Notes
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => handleSelectionTab("6")}>
              <div
                className="tabListing"
                style={{
                  backgroundColor:
                    selectedTab === "6" ? "white" : "rgba(42, 143, 86, 1)",
                  borderColor: selectedTab === "6" ? "#3FAD72" : "white",
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    color: selectedTab === "6" ? "#3FAD72" : "white",
                  }}
                >
                  Subscription
                </p>
              </div>
            </div>

            <div className="tab-nav" onClick={() => setShowLis(!showLis)}>
              <div
                className="tabListing"
                style={{
                  backgroundColor: "rgba(42, 143, 86, 1)",
                  borderColor: "rgba(42, 143, 86, 1)",
                  borderStyle: "solid",
                }}
              >
                <p>Lis</p>
              </div>
            </div>

            <div className="listingborder"></div>

            {showLis ? (
              <>
                <div
                  onClick={() => handleSelectionTab("7")}
                  className="tab-nav"
                >
                  <div
                    className="tabListing"
                    style={{
                      backgroundColor:
                        selectedTab === "7" ? "white" : "rgba(42, 143, 86, 1)",
                      borderColor: selectedTab === "7" ? "#3FAD72" : "white",
                      borderStyle: "solid",
                    }}
                  >
                    <p
                      className="nav-payment"
                      style={{
                        color: selectedTab === "7" ? "#3FAD72" : "white",
                      }}
                    >
                      Payment Details
                    </p>
                  </div>
                </div>

                <div
                  className="tab-nav"
                  onClick={() => handleSelectionTab("8")}
                >
                  <div
                    className="tabListing"
                    style={{
                      backgroundColor:
                        selectedTab === "8" ? "white" : "rgba(42, 143, 86, 1)",
                      borderColor: selectedTab === "8" ? "#3FAD72" : "white",
                      borderStyle: "solid",
                    }}
                  >
                    <p
                      style={{
                        color: selectedTab === "8" ? "#3FAD72" : "white",
                      }}
                    >
                      Listing
                    </p>
                  </div>
                </div>

                <div className="tab-nav">
                  <Link to="/adminchat">
                    <div
                      className="tabListing"
                      style={{
                        backgroundColor:
                          selectedTab === "9"
                            ? "white"
                            : "rgba(42, 143, 86, 1)",
                        borderColor: selectedTab === "9" ? "#3FAD72" : "white",
                        borderStyle: "solid",
                      }}
                    >
                      <p
                        style={{
                          color: selectedTab === "9" ? "#3FAD72" : "white",
                        }}
                      >
                        Message
                      </p>
                    </div>
                  </Link>
                </div>

                <div
                  className="tab-nav"
                  onClick={() => handleSelectionTab("10")}
                >
                  <div
                    className="tabListing"
                    style={{
                      backgroundColor:
                        selectedTab === "10" ? "white" : "rgba(42, 143, 86, 1)",
                      borderColor: selectedTab === "10" ? "#3FAD72" : "white",
                      borderStyle: "solid",
                    }}
                  >
                    <p
                      style={{
                        color: selectedTab === "10" ? "#3FAD72" : "white",
                      }}
                    >
                      Items Save
                    </p>
                  </div>
                </div>

                <div
                  className="tab-nav"
                  onClick={() => handleSelectionTab("11")}
                >
                  <div className="tabListing">
                    <p>-</p>
                  </div>
                </div>

                <div className="tab-nav">
                  <div className="tabListing">
                    <p>-</p>
                  </div>
                </div>

                <div className="tab-nav">
                  <div className="tabListing">
                    <p>-</p>
                  </div>
                </div>

                <div className="listingborder"></div>
              </>
            ) : null}
          </div>

          {/* {show ? <Information /> : null} */}

          <UserVideoProfile userDetails={userDetails} />

          <div className="video-detail-carding">
            <>
              {selectedTab === "1" && (
                <>
                  {userDetails && userDetails.userFeeds && userDetails.userFeeds.length && userDetails.userFeeds.map((item) => (
                    <div
                      className="video-carder"
                      style={{ height: selected.length ? 350 : "100%" }}
                    >
                      <div className="video1-border-mac-new">
                        <div
                          onClick={() => {
                            setItem(item);
                            handleShow();
                          }}
                          style={{
                            backgroundImage: `url(${
                              item.thumbnail ? item.thumbnail : iphone
                            })`,
                            height: "182px",
                            backgroundSize: "auto",
                            backgroundRepeat: "no",
                          }}
                        >
                          <div>
                            <img
                              className="video-uploader-user"
                              src={item?.ProfileImage}
                            />
                          </div>
                          <div className="video-length-mac2">
                            <span>2:34</span>
                          </div>
                          <div className="clearfix"></div>

                          <div className="setting-gear-mac">
                            <AiTwotoneSetting
                              className="video-setting-mac2"
                              onClick={() => {
                                handleSelection(item);
                              }}
                            />
                          </div>
                        </div>

                        {selected === item.videoid ? (
                          <>
                            <div className="video-desc-mac">
                              <p className="video-title-mac">{item.caption}</p>
                              <p className="video-date-mac">21 JAN 2021</p>
                            </div>

                            <div className="view-details-mac">
                              <button className="view-btn-mac">
                                576K <BsEye />
                              </button>
                            </div>

                            <div className="view-delete-mac">
                              <button className="delete-btn-mac">Delete</button>
                            </div>

                            <div className="video-hearts">
                              <GiHearts className="hearts" />
                              <p className="num-hearts">3.67k</p>
                            </div>

                            <div className="video-dislike">
                              <AiFillDislike className="dislike" />
                              <p className="num-hearts">1.3k</p>
                            </div>

                            <div className="video-dislike">
                              <BsChatFill className="chat" />
                              <p className="num-hearts">2.3k</p>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Body>
                      <ReactPlayer
                        className="vid-player"
                        playing
                        playIcon={
                          <AiFillPlayCircle
                            color="#3FAD72"
                            style={{
                              height: 100,
                              width: 100,
                            }}
                          />
                        }
                        light={item.thumbnail}
                        url={item.feedurl}
                      />
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </>
            {selectedTab === "3" && (
              <div style={{ margin: 40 }}>
                <PurchaseCard />
              </div>
            )}
            {selectedTab === "4" && (
              <div style={{ margin: 40 }}>
                <PurchaseCard />
              </div>
            )}
            {selectedTab === "6" && (
              // <div>
              //   <ProductDesign />
              //   <ProductDesign />
              // </div>
              <div style={{ margin: 40 }}>
                <p style={{ fontSize: 16 }}>This User has no product</p>
              </div>
            )}
            {selectedTab === "7" && <Payment />}
            {selectedTab === "10" && (
              <div style={{ margin: 40 }}>
                <PurchaseCard />
              </div>
            )}
            {selectedTab === "11" && <div></div>}
            {/* {selectedTab === "4" && (
              <div className="row">
                {userDetails.userOrders.map((item) => (
                  <ProductDesign />
                ))}
              </div>
            )} */}
          </div>
    </>
  );
}

export default Listings;
