import React, { useEffect, useRef, useState } from "react";
import "./Searches.css";
import { FiSearch } from "react-icons/fi";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import user from "../../Assets/Images/user1.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import ProgressSearch from "../ProgressSearch/ProgressSearch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "reactstrap";
import CustomDropdown from "../Dropdown";
import { useSelector } from "react-redux";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
function Searches() {
  const [age, setAge] = useState("");
  const catnames = useSelector((state) => state.Reducer.categories);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="search-page-result">
      {/* <div style={{ float: "left", width: "83%" }}>
        <div className="search-user">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>

        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div>
        <div className="not-image">
          <img src={user} className="not-image-user" alt="logo" />
          <p className="not-image-text">John Doe</p>
        </div>
        <GiHamburgerMenu className="hamburger" />
      </div> */}
      <div className="d-flex justify-content-between">
        <div className="page-title">Search Result</div>
        <div>
          <CustomDropdown
            option={catnames?.map((Catname) => Catname.ParentCategory) || []}
            placeholder="Select Categories"
            width="329px"
            borders={true}
            fullArrow={true}
            dropdownContentHeight={"500px"}
            handleChangeRowsPerPage={(value) => console.log("value", value)}
            searchBar={true}
            isActive={isActive}
            setIsActive={setIsActive}
            ref={ref}
          />
        </div>
      </div>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, index) => (
        <div key={index} className="row search-row">
          <div className="col-1">
            <img
              className="img-search image-fluid"
              src="https://pngimg.com/uploads/iphone_11/iphone_11_PNG42.png"
              alr="phone"
            />
          </div>
          <div className="col-2 table-border-right">
            <div className="align-self-center search-margin">
              <p className="product-name">iPhone</p>
            </div>
          </div>
          <div className="col-2 table-border-right">
            <div className="align-self-center d-flex search-margin-5">
              <img
                className="img-profile"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="profile"
              />
              <p className="profile-search-name align-self-center">John doe</p>
            </div>
          </div>
          <div className="col-1 table-border-right">
            <div className="align-self-center search-margin">
              <p className="search-table-price">$12,000</p>
            </div>
          </div>
          <div className="col-4 table-border-right">
            <div className="align-self-center search-margin">
              <p className="search-table-price">
                Street #2, Veals Town Bluff Parkway, USA
              </p>
            </div>
          </div>
          <div className="col-1">
            <div className="align-self-center search-margin-6">
              <Button variant="danger" className="search-delete">
                Delete
              </Button>
            </div>
          </div>
          <div className="col-1">
            <div className="align-self-center search-margin">
              <p className="search-result-count">1 searches</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Searches;
