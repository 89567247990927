import ImageUploading from "react-images-uploading";
import { AiFillPlusCircle } from "react-icons/ai";
import React from "react";

const BannerImageUploader = ({ value, setHandler }) => {
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex, "fazzy");
    // setImages(imageList);

    setHandler(imageList);
  };
  return (
    <ImageUploading onChange={onChange} value={value} dataURLKey="data_url">
      {({ onImageUpload, imageList }) => (
        // write your building UI
        <button className="saveyourAdd" onClick={onImageUpload}>
          Add
        </button>
      )}
    </ImageUploading>
  );
};
export default BannerImageUploader;
