import React, { useEffect } from "react";
import "./Message.css";
import { IoIosChatbubbles } from "react-icons/io";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { hide } from "../../store/actions/actions";
function Message({ hide }) {
  const dispatch = useDispatch();

  return (
    <>
      <div className="message-section">
        <Link to="ChatSupport/allchat" onClick={hide}>
          <p className="messages">Messages</p>
          <IoIosChatbubbles className="message-bubble" />
          <div className="text-numbers">
            <p className="new-number">55</p>
            <p className="new-text">New</p>
          </div>
          <div className="divider"></div>
          <div className="text-numbers2">
            <p className="new-number2">25</p>
            <p className="new-text2">Unread</p>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Message;
