import React, { useEffect, useState } from "react";
import "./CreateCoupon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { ImCross } from "react-icons/im";
import { useSelector } from "react-redux";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function CreateCoupon() {
  const allUsers = useSelector((state) => state.Reducer.users);
  const [userPerCoupon, setUserPerCoupon] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [customerPerCoupon, setCustomerPerCoupon] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [note, setNote] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [user, setUser] = useState("");
  const [options, setOptions] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [auto, setAuto] = useState(false);

  const createCoupon = async () => {
    // console.log(images);

    let pid = Math.floor(Math.random() * 1000000000);

    const data = {
      userPerCoupon: userPerCoupon,
      couponId: pid,
      coupanName: couponCode,
      customerPerCoupon: customerPerCoupon,
      note: note,
      userEmail: userEmail,
      startDate: startDate,
      endDate: endDate,
    };
    await setDoc(doc(db, "Coupon", `${pid}`), data);
    toast("Coupon Created Successfully");
    setUserPerCoupon("");
    setCustomerPerCoupon("");
    setCouponCode("");
    setNote("");
    setUserEmail([]);
    setStartDate(new Date());
    setEndDate(new Date());

    // dispatch(addProduct(data));
    // setShow(true);
  };

  useEffect(() => {
    let newArray = [];
    allUsers.map((item) => {
      newArray.push(item.email);
    });
    setUserEmail(newArray);

    // alert("The computer chose:" + words);
  }, []);

  const generateCoupon = () => {
    var word = [
      "break",
      "map",
      "jump",
      "began",
      "surface",
      "sleep",
      "thirty",
      "necessary",
      "prevent",
      "shaking",
      "steel",
      "design",
      "another",
      "morning",
      "somebody",
      "best",
      "has",
      "lack",
      "art",
      "needs",
      "danger",
      "lying",
      "someone",
      "warn",
      "handle",
      "shaking",
      "special",
      "in",
      "range",
      "unhappy",
      "leather",
      "kids",
      "laugh",
      "too",
      "example",
      "typical",
      "division",
      "quarter",
      "graph",
      "obtain",
      "army",
      "lying",
      "stone",
      "brave",
      "depend",
      "importance",
      "mental",
      "factor",
      "practical",
      "dirt",
      "did",
      "family",
      "mainly",
      "clothes",
      "alphabet",
      "bear",
      "twenty",
      "greatest",
      "ran",
      "prepare",
      "straw",
      "cloud",
      "curious",
      "plates",
      "does",
      "total",
      "ice",
      "wet",
      "driven",
      "object",
      "mathematics",
      "aside",
      "guess",
      "outside",
      "them",
      "suggest",
      "work",
      "center",
      "courage",
      "evening",
      "experiment",
      "equally",
      "arrangement",
      "day",
      "courage",
      "wood",
      "law",
      "composition",
      "pan",
      "national",
      "large",
      "chief",
      "your",
      "environment",
      "home",
      "each",
      "balance",
      "nine",
      "luck",
      "became",
      "failed",
      "indeed",
      "weather",
      "telephone",
      "hill",
      "hurried",
      "doll",
      "love",
      "slope",
      "deer",
      "better",
      "uncle",
      "mad",
      "bring",
      "man",
      "think",
      "sea",
      "service",
      "cabin",
      "travel",
      "has",
      "quite",
      "spell",
      "whatever",
      "automobile",
      "blew",
      "coal",
      "plane",
      "living",
      "construction",
      "master",
      "pile",
      "come",
      "recognize",
      "thumb",
      "direct",
      "yard",
      "couple",
      "draw",
      "member",
      "poet",
      "indeed",
      "organized",
      "occur",
      "just",
      "example",
      "top",
      "silver",
      "exist",
      "write",
      "surface",
      "product",
      "although",
      "cook",
      "curve",
      "silk",
      "late",
      "half",
      "love",
      "interior",
      "flower",
      "statement",
      "force",
      "silver",
      "value",
      "is",
      "job",
      "lost",
      "movie",
      "connected",
      "gravity",
      "result",
      "excited",
      "castle",
      "related",
      "away",
      "spirit",
      "card",
      "loud",
      "discussion",
      "think",
      "floating",
      "brain",
      "got",
      "ago",
      "gentle",
      "folks",
      "divide",
      "actually",
      "park",
      "kill",
      "applied",
      "compass",
      "studying",
      "sick",
      "terrible",
      "gray",
      "could",
      "extra",
      "spend",
    ];
    let words = word[Math.floor(Math.random() * word.length)];
    setCouponCode(words);
  };
  return (
    <>
      <div className="create-coupon">
        <h3>Create Coupon</h3>
      </div>
      <div className="coupon-select">
        <h5 className="coupon-heading">
          Coupon <span style={{ color: "red" }}>*</span>
        </h5>
        <select>
          <option value="A">Coupon A</option>
          <option value="B">Coupon B</option>
          <option value="C">Coupon C</option>
        </select>
      </div>

      <div className="coupon-select">
        <h5>
          Coupon Code <span style={{ color: "red" }}>*</span>
        </h5>
        <input
          className="coupon-code"
          type="text"
          value={couponCode}
          onChange={(event) => setCouponCode(event.target.value)}
        />
        <Form.Group className="mb-3 auto-coupon" controlId="formBasicCheckbox">
          <Form.Check
            checked={auto}
            onChange={(event) => setAuto(event.target.value)}
            type="checkbox"
            onClick={() => {
              if (!auto) {
                generateCoupon();
              }
            }}
            label="Use Auto Generation"
          />
        </Form.Group>
        <p className="coupon-desc">
          If you select and save the rule you will be able to generate multiple
          coupon codes.
        </p>
      </div>

      <div className="coupon-select2">
        <h5>Uses per Coupon</h5>
        <input
          className="coupon-code"
          type="text"
          value={userPerCoupon}
          onChange={(event) => {
            setUserPerCoupon(event.target.value);
          }}
        />
      </div>

      <div className="coupon-select3">
        <h5>Uses per Customer</h5>
        <input
          className="coupon-code"
          type="text"
          value={customerPerCoupon}
          onChange={(event) => setCustomerPerCoupon(event.target.value)}
        />
        <p className="coupon-desc2">
          Usage limit enforced for loggin in customers only.
        </p>
      </div>

      <div className="coupon-select5">
        <h5>From</h5>
        <DatePicker
          className="coupon-picker"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            console.log(date);
          }}
        />
      </div>

      <div className="coupon-select5">
        <h5>To</h5>
        <DatePicker
          className="coupon-picker"
          className="coupon-picker"
          selected={endDate}
          onChange={(date) => {
            setEndDate(date);
            console.log(date);
          }}
        />
      </div>

      <div className="coupon-select">
        <h5 className="coupon-heading2">Add Email</h5>
        <select
          className="email-options"
          onChange={(event) => setOptions(event.target.value)}
        >
          <option value="A">Add Emails</option>

          <option value="Single Email">Single Email</option>
          <option value="All Customer Email">All Customer Email</option>
        </select>
      </div>
      {options === "Single Email" ? (
        <div className="coupon-select2">
          <h5>Enter User Email</h5>
          <input
            className="coupon-code"
            type="text"
            value={emailInput}
            onChange={(event) => setEmailInput(event.target.value)}
          />
        </div>
      ) : null}

      {options === "All Customer Email" ? (
        <div className="coupon-select">
          <h5 className="coupon-heading2">Add Users</h5>
          <select
            className="email-options"
            onChange={(event) => {
              if (!selectedUsers.includes(event.target.value)) {
                let newArray = [...selectedUsers];
                newArray.push(event.target.value);
                setSelectedUsers(newArray);
                console.log(selectedUsers);
              }
            }}
          >
            {userEmail.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
      ) : null}

      {selectedUsers.length ? (
        <div
          style={{
            float: "left",
            marginLeft: 200,
            marginTop: 20,
            border: "1px solid #ccc",
            width: "38%",
            padding: 10,
          }}
        >
          {selectedUsers.map((item) => (
            <div
              style={{
                backgroundColor: "#3fad72",
                height: 30,
                //   width: 100,
                marginLeft: 10,
                float: "left",
                borderRadius: 5,
                marginBottom: 10,
              }}
            >
              <div>
                <p style={{ color: "white", padding: 5 }}>{item}</p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className="coupon-select2">
        <h5>{"    "}Enter Note</h5>
        <textarea className="coupon-codeArea" type="text" />
      </div>

      <div className="coupon-select">
        <h5 className="coupon-heading-feed">Public in RSS Feed</h5>
        <ToggleSwitch className="toggle-feed" />
      </div>

      <button className="save-btn" onClick={createCoupon}>
        Save
      </button>
      <button className="save-btn2">
        <Link to="/addRedeem">Redeem Products</Link>
      </button>
      <ToastContainer />
    </>
  );
}

export default CreateCoupon;
