import React, { useEffect, useState } from "react";
import "./Notification.css";
import ReactPlayer from "react-player";

import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import user1 from "../../Assets/Images/product.png";
import Iphone from "../../Assets/Images/iphone2.png";
import iphone from "../../Assets/Images/iphone.png";
// import { AiTwotoneSetting } from "react-icons/ai";
// import { GiHearts } from "react-icons/gi";
// import { AiFillDislike } from "react-icons/ai";
// import { BsChatFill } from "react-icons/bs";
// import { BsEye } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { Button, Form, Modal } from "react-bootstrap";

import { resolve } from "../../store/actions/actions";
import { AiTwotoneSetting, AiFillPlayCircle } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
// import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Notification() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const all = useSelector((state) => state.Reducer.feedreports);
  const [data, setData] = useState([]);
  useEffect(() => {
    let newArray = [];
    all.map((item) => {
      if (item.resolved) {
        newArray.push(item);
      }
    });
    setData(newArray);
  }, []);
  return (
    <>
      <div className="notification-section2">
        <p className="not-text">Resolved Reports</p>
        <div className="not-border"></div>
        {data.map((item) => (
          <>
            <div className="not-card">
              <img
                src={item.userDetails.ProfileImage}
                className="not-image-user2"
                alt="logo"
              />
              <ul className="not-post-details">
                <li className="not-react">{item.userDetails.firstName}</li>
                <li className="copyright">Reported a video</li>
                <li className="copyright">{item.details}</li>
              </ul>
              <img
                onClick={handleShow}
                src={item.feedDetails.thumbnail}
                className="not-phone"
                alt="logo"
              />
              <div style={{ float: "right" }}>
                <div className="view-details-mac">
                  <button className="view-btn-mac">Resolved</button>
                </div>
              </div>
            </div>
            <Modal className="modal-video" show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="not-image">
                  <Link
                    to={`listings/${item.feedDetails.userid}`}
                    style={{ color: "black" }}
                  >
                    <img
                      src={
                        item.feedDetails.ProfileImage
                          ? item.feedDetails.ProfileImage
                          : ""
                      }
                      className="not-image-user"
                      style={{ width: "35%" }}
                      alt="logo"
                    />
                    <p style={{ marginTop: 13, marginLeft: 70, width: 200 }}>
                      anoynomus
                    </p>
                  </Link>
                </div>

                <ReactPlayer
                  className="vid-player"
                  playing
                  playIcon={
                    <AiFillPlayCircle
                      color="#3FAD72"
                      style={{
                        height: 100,
                        width: 100,
                      }}
                    />
                  }
                  light={item.feedDetails.thumbnail}
                  url={item.feedDetails.feedurl}
                />
              </Modal.Body>
            </Modal>
          </>
        ))}
      </div>
    </>
  );
}

export default Notification;
