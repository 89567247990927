import React from "react";
import "./OrderList.css";
import { FiSearch } from "react-icons/fi";
import phone from "../../Assets/Images/phone.png";
import user from "../../Assets/Images/user1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function OrderList() {
  return (
    <div className="searchList">
      <div className="order-show">
        <p>Show</p>
        <select>
          <option>8</option>
          <option>7</option>
          <option>6</option>
        </select>
        <p style={{ marginLeft: 10 }}>Entries</p>
      </div>

      <select className="order-filter">
        <option>Filters</option>
        <option>7</option>
        <option>6</option>
      </select>

      <div className="order-user">
        <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." />
      </div>

      <div className="order-actions">
        <ul className="order-list">
          <li>Product</li>
          <li>User</li>
          <li>Stock Status</li>
          <li>Total Quantity</li>
          <li>Total Users</li>
          <li>Actions</li>
        </ul>
      </div>

      <div className="product-list">
        <div className="product">
          <img src={phone} className="phone-image" alt="logo" />
          <p className="product-phone">Iphone 12 Pro</p>
        </div>

        <div className="user-order">
          <img src={user} alt="logo" />
          <p>John Doe</p>
        </div>

        <p className="order-status">Out of stock</p>

        <p className="order-quantity">50</p>

        <p className="order-users">7</p>

        <div className="orderlist-btns">
          <button
            className="order1-btn"
            onClick={() => {
              toast("Notification Sent Successfully");
            }}
          >
            Send Notification
          </button>
          <button className="order1-btn2">View</button>
        </div>

        <div className="border-list1"></div>
      </div>

      <div className="product-list">
        <div className="product">
          <img src={phone} className="phone-image" alt="logo" />
          <p className="product-phone">Iphone 12 Pro</p>
        </div>

        <div className="user-order">
          <img src={user} alt="logo" />
          <p>John Doe</p>
        </div>

        <p className="order-status">Out of stock</p>

        <p className="order-quantity">50</p>

        <p className="order-users">7</p>

        <div className="orderlist-btns">
          <button
            className="order1-btn"
            onClick={() => {
              toast("Notification Sent Successfully");
            }}
          >
            Send Notification
          </button>
          <button className="order1-btn2">View</button>
        </div>

        <div className="border-list1"></div>
      </div>

      <div className="product-list">
        <div className="product">
          <img src={phone} className="phone-image" alt="logo" />
          <p className="product-phone">Iphone 12 Pro</p>
        </div>

        <div className="user-order">
          <img src={user} alt="logo" />
          <p>John Doe</p>
        </div>

        <p className="order-status">Out of stock</p>

        <p className="order-quantity">50</p>

        <p className="order-users">7</p>

        <div className="orderlist-btns">
          <button
            className="order1-btn"
            onClick={() => {
              toast("Notification Sent Successfully");
            }}
          >
            Send Notification
          </button>
          <button className="order1-btn2">View</button>
        </div>
        <ToastContainer />

        <div className="border-list1"></div>
      </div>

      <div className="prev-nxt">
        <p>Previous</p>
        <div className="prev-count">8</div>
        <p>Next</p>
      </div>
    </div>
  );
}

export default OrderList;
