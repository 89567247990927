import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import "./Breadcrumb.css";

const BreadcrumbMenu = ({
  path,
  activeTab,
  subTitle = null,
  isBack = true,
}) => {
  const history = useHistory();
  return (
    <div className="d-flex justify-content-between bread-crumb">
      <div className="d-flex align-items-center">
        {isBack ? (
          <FiArrowLeft
            className="mr-5 cursor-pointer"
            onClick={history.goBack}
          />
        ) : null}
        <div>
          <div className="page-title">{activeTab}</div>
          {subTitle ? <p className="sub-title">{subTitle}</p> : null}
        </div>
      </div>
      <div className="path-root">
        {path.length ? (
          <Breadcrumb>
            {path &&
              path.map((item, index) => {
                return (
                  <Breadcrumb.Item
                    key={index}
                    active={activeTab === item.title}
                    onClick={() => history.push(item.navLink)}
                  >
                    {item.title}
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb>
        ) : null}
      </div>
    </div>
  );
};

export default BreadcrumbMenu;
