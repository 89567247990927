import React from "react";
import { Link } from "react-router-dom";
import "./BannerButton2.css";

function BannerButton2() {
  return (
    <>
      <Link to="banners">
        <div className="banner-section2">
        <div className="order-numbers">
                <p className="banner-text2">Add Banner</p>
            </div>
          
        </div>
      </Link>
    </>
  );
}

export default BannerButton2;
