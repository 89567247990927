import React, { useState } from "react";
import ReactDOM from "react-dom";
import Map from "./Components/Map/Map";
import Navigation from "./Components/Navigation/Navigation";
import NavigationVendor from "./Components/Navigation/VendorNavigation";
import NavigationCus from "./Components/Navigation/CustomerNav";

import "./Responsive.css";
import "./App.css";
import Search from "./Components/Search/Search";
import Ship from "./Components/Shipping/Shipping";

import Message from "./Components/Message/Message";
import Cart from "./Components/Cart/Cart";
import Download from "./Components/Download/Download";
import Vendor from "./Components/Vendor/Vendor";
import Coupon from "./Components/Coupon/Coupon";
import Percentages from "./Components/Percentages/Percentages";
import Calender from "./Components/Calender/Calender";
import Marketplace from "./Components/Marketplace/Marketplace";
import EditProduct from "./Components/EditProduct/EditProduct";
import AddProduct from "./Components/AddProduct/AddProduct";
import EditProfile from "./Components/EditProfile/EditProfile";
import History from "./Components/History/History";
import Chat from "./Components/Chat/Chat";
import ChatSupport from "./Components/Chat/CustomerSupport";

import Searches from "./Components/Searches/Searches";

import Category from "./Components/Category/Category";
import Attribute from "./Components/Attribute/Attribute";
import AttributeInner from "./Components/AttributeInner/AttributeInner";
import { Menu } from "@material-ui/core";
import CreateCoupon from "./Components/CreateCoupon/CreateCoupon";
import VendorInfo from "./Components/VendorInfo/VendorInfo";
import UserProfile from "./Components/UserProfile/UserProfile";
import Section from "./Components/Section/Section";
import Welcome from "./Components/Welcome/Welcome";
import UserSection from "./Components/UserSection/UserSection";
import OrderInfo from "./Components/OrderInfo/OrderInfo";
import Verification from "./Components/Verification/Verification";
import OrderList from "./Components/OrderList/OrderList";
import Listings from "./Components/Listings/Listings";
import Videos from "./Components/Videos/Videos";
import VideoComments from "./Components/Videos/VideoComment";
import Notification from "./Components/Notification/Notification";
import AddUser from "./Components/AddUser/AddUser";
import UserProduct from "./Components/UserProducts/UserProducts";
import Inventory from "./Components/Inventory/Inventory";
import ProductAttribute from "./Components/ProductAttribute/ProductAttribute";
import Homebar from "./Components/Homebar/Homebar";
import {
  HashRouter,
  Route,
  Switch,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import ItemPurchase from "./Components/ItemPurchase/ItemPurchase";
import DataTable from "./Components/DataTable/DataTable";
import Serve from "./Components/Serve/Serve";
import DataForm from "./Components/DataForm/DataForm";
import EmployeeUser from "./Components/EmployeeUser/EmployeeUser";
import UserNotes from "./Components/UserNotes/UserNotes";
import Refund from "./Components/Refund/Refund";
import Analytics from "./Components/Analytics/Analytics";
import Analyticsto from "./Components/Analytics/toShowAnalytics";

import AdminChat from "./Components/AdminChat/AdminChat";
import Review from "./Components/Review/Review";
import ProjectDash from "./Components/ProjectDash/ProjectDash";
import DashNav from "./Components/DashNav/DashNav";
import DashInner from "./Components/DashInner/DashInner";
import DashInnerTo from "./Components/DashInner/DashInnerTo";

import Home from "./Components/Home/Home";
import NotificationVideos from "./Components/NotificationVideos/NotificationVideos";
import OrderHistory from "./Components/OrderHistory/OrderHistory";
import UserActivity from "./Components/UserActivity/UserActivity";
import OrderSingle from "./Components/OrderSingle/OrderSingle";
import UserChat from "./Components/UserChat/UserChat";
import Information from "./Components/Information/Information";
import { Provider, useSelector } from "react-redux";
import store from "./store/store";
import UpdateProduct from "./Components/AddProduct/UpdateProduct";
import SalesReport from "./Components/SalesReport/SalesReport";
import Revenue from "./Components/Revenue/Revenue";
import VendorService from "./Components/VendorService/VendorService";
import CustomerService from "./Components/CustomerService/CustomerService";
import MonthlyOrders from "./Components/MonthlyOrders/MonthlyOrders";
import MonthlyItems from "./Components/MonthlyItems/MonthlyItems";
import VendorMonthlyItems from "./Components/MonthlyItems/VendorMonthlyItems";
import Admin from "./Components/CustomerService/Admin";
import AttributeImage from "./Components/AttributeImage/AtrributeImage";
import MapReport from "./Components/MapReport/MapReport";
import AttSize from "./Components/AttSize/AttSize";
import CalenderEvents from "./Components/CalenderEvents/CalenderEvents";
import AllUser from "./Components/AllUser/AllUser";
import AllVerify from "./Components/AllVerify/AllVerify";
import AllVendor from "./Components/AllVendor/AllVendor";
// import TimeLine from "./Components/Timeline/TimeLine";
import DelTimeline from "./Components/DelTimeline/DelTimeline";
import BannerInner from "./Components/BannerInner/BannerInner";
import PerMarketplace from "./Components/Marketplace/PerCategory";
import Resolved from "./Components/Notification/Resolved";
import Register from "./Components/Register/Register";
import CustomerRegister from "./Components/Register/CustomerRegister";
import AdminRegister from "./Components/Register/AdminRegister";

import Banners from "./Components/Banner/Banner";
import Login from "./Components/Login/Login";
import VendorLogin from "./Components/Login/VendorLogin";
import CustomerServiceLogin from "./Components/Login/CustomerServiceLogin";
import RedeemProduct from "./Components/RedeemProduct/RedeemProduct";
import AddRedeemProducts from "./Components/AddProduct/AddRedeemProduct";
import Comment from "./Components/Comment/Comment";
import AllComments from "./Components/Comment/AllComments";
import ShowRedeem from "./Components/ShowRedeem/ShowRedeem";
import Calling from "./Components/History/Calling";
import CallHistory from "./Components/History/CallHistory";
import VCalling from "./Components/History/vendorCalling";
import VCallHistory from "./Components/History/vendorCallHistory";
import HomeAnalytics from "./Components/HomeAnalytics/HomeAnalytics";
import Pinned from "./Components/Pinned/Pinned";
import CustomerPassword from "./Components/ForgotPassword/CustomerForget";
import AdminPassword from "./Components/ForgotPassword/AdminForget";
import VendorPassword from "./Components/ForgotPassword/ForgotPassword";

import PendingProduct from "./Components/ShowRedeem/PendingProduct";
import ActiveProducts from "./Components/ShowRedeem/VendorActiveProducts";
import InActiveProducts from "./Components/ShowRedeem/VendorPendingProducts";
import Unpinned from "./Components/Pinned/Unpinned";
import Uninvestigate from "./Components/Pinned/Uninvestigate";
import Unsuspend from "./Components/Pinned/Unsuspend";
import VendorCategory from "./Components/Category/VendorCategory";
import VendorAttribute from "./Components/Attribute/VendorAttribute";
import AddVendorProduct from "./Components/AddProduct/AddProductVendor";
import Payment from "./Components/Payment/Payment";
import UserSupport from "./Components/UserSupport/UserSupport";
import EditOrder from "./Components/EditOrder/EditOrder";
import ManagePayments from "./Components/ManagePayments/ManagePayments";
import VendorSalesReport from "./Components/SalesReport/VendorSalesReport";
import VendorRevenue from "./Components/Revenue/VendorRevenue";
import VendorMonthlyOrders from "./Components/VendorMonthlyOrders/VendorMonthlyOrders";
import VendorSalesHistory from "./Components/SaleHistory/VendorSalesHistory";
import CustomerCare from "./Components/Chat/CustomerCare";
import RefundScreen from "./Components/RefundScreen/RefundScreen";
import ReturnScreen from "./Components/ReturnScreen/ReturnScreen";
import ScrollToTop from "./ScrollToTop";
import Header from "./Components/Header/Header";
import AddCategory from "./Components/Category/AddCategory";
import AttributeSlug from "./Components/Attribute/AttributeSlug.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiveVideos from "./Components/Videos/LiveVideos";
import LiveSchedule from "./Components/Videos/LiveSchedule.js";
import Loading from "./Components/Loading/Loading";
import PaymentCard from "./Components/Payment";
// import paymentMethods from "./Components/Payment/paymentMethods";
import ForgotPass from "./Components/NewForgotPassword/ForgotPass";
import Invoice from "./Components/Invoice";

const viewHeight = window.outerHeight;

const App = () => {
  const [content, setContent] = useState("");
  const vendorEnable = useSelector((state) => state.Reducer.vendorEnable);
  const adminEnable = useSelector((state) => state.Reducer.adminEnable);
  const cusEnable = useSelector((state) => state.Reducer.customerEnable);
  const profileComplete = useSelector((state) => state.Reducer.profileComplete);
  let loading = useSelector((state) => state.Reducer.loading);
  return (
    <div className="main-div">
      {loading ? <Loading /> : null}
      <HashRouter>
        <ToastContainer />
        <ScrollToTop />
        {vendorEnable && profileComplete && <NavigationVendor />}
        {adminEnable && profileComplete && <Navigation />}
        {cusEnable && profileComplete && <NavigationCus />}
        {(vendorEnable || adminEnable || cusEnable) && profileComplete ? (
          <>
            <div
              className="app-width"
              // style={{
              //   maxWidth: "1920px",
              // }}
            >
              <Route path="/Chat/:id" component={Chat} />
              <Route path="/ChatSupport/:id" component={ChatSupport} />
              <Route path="/ChatSupportCare/:id" component={CustomerCare} />
              <Switch>
                <div className="home-new">
                  {vendorEnable || adminEnable || cusEnable ? <Header /> : null}
                  <Route path="/" exact component={Home} />
                  {/* <Route path="/timeline" exact component={TimeLine} /> */}
                  <Route path="/deltimeline" exact component={DelTimeline} />
                  <Route
                    path="/addRedeem"
                    exact
                    component={AddRedeemProducts}
                  />
                  <Route path="/banners" exact component={Banners} />

                  <Route
                    path="/vendorDashboard"
                    exact
                    component={ProjectDash}
                  />
                  <Route path="/inner" exact component={DashInner} />
                  <Route path="/innerto/:id" exact component={DashInnerTo} />

                  <Route path="/notes/:id" exact component={UserNotes} />
                  <Route path="/category" exact component={Category} />
                  <Route path="/category/:type" exact component={AddCategory} />
                  <Route path="/All" exact component={Marketplace} />
                  <Route path="/percat/:id" exact component={PerMarketplace} />
                  <Route path="/employee" exact component={EmployeeUser} />
                  <Route path="/employee/:Add" exact component={AddUser} />
                  <Route path="/addproduct" exact component={AddProduct} />
                  <Route
                    path="/updateProduct"
                    exact
                    component={UpdateProduct}
                  />

                  <Route path="/inventory" exact component={Inventory} />
                  <Route path="/attributes" exact component={Attribute} />
                  <Route
                    path="/attributes/:type"
                    exact
                    component={AttributeSlug}
                  />
                  <Route path="/Listings/:id" exact component={Listings} />
                  <Route path="/CreateCoupon" exact component={CreateCoupon} />
                  <Route path="/newdash" exact component={ItemPurchase} />
                  <Route path="/AddExpense" exact component={DataForm} />
                  <Route path="/ViewExpense" exact component={DataTable} />
                  <Route path="/order" exact component={History} />
                  <Route path="/orderinfo" exact component={OrderInfo} />
                  <Route path="/regvendors" exact component={VendorInfo} />
                  <Route path="/refund/:id" exact component={Refund} />
                  <Route path="/verification" exact component={Verification} />
                  <Route path="/short-video" exact component={Videos} />
                  <Route path="/live-video" exact component={LiveVideos} />
                  <Route path="/live-schedule" exact component={LiveSchedule} />
                  <Route
                    path="/videos/:type/comments/:id"
                    exact
                    component={VideoComments}
                  />
                  <Route path="/notifications" exact component={Notification} />
                  <Route path="/resolved" exact component={Resolved} />

                  <Route
                    path="/notificationvideos"
                    exact
                    component={NotificationVideos}
                  />
                  <Route path="/usernotes" exact component={UserNotes} />
                  <Route
                    path="/orderhistory/:id"
                    exact
                    component={OrderHistory}
                  />
                  <Route path="/useractivity" exact component={UserActivity} />
                  <Route path="/ordersingle" exact component={OrderSingle} />
                  <Route path="/userchat" exact component={UserChat} />
                  <Route path="/userprofile" exact component={UserProfile} />
                  <Route path="/information" exact component={Information} />
                  <Route path="/productreviews" exact component={Review} />
                  <Route path="/adminChat/" exact component={AdminChat} />
                  <Route path="/sec" exact component={UserSection} />
                  <Route path="/attinner" exact component={AttributeInner} />
                  <Route path="/serve" exact component={Serve} />
                  <Route path="/salesreport" exact component={SalesReport} />
                  <Route path="/revenue" exact component={Revenue} />
                  <Route
                    path="/vendorservice"
                    exact
                    component={VendorService}
                  />
                  <Route
                    path="/customerservice"
                    exact
                    component={CustomerService}
                  />
                  <Route
                    path="/monthlyorders"
                    exact
                    component={MonthlyOrders}
                  />
                  <Route path="/monthlyitems" exact component={MonthlyItems} />
                  <Route path="/admins" exact component={Admin} />
                  <Route path="/searches" exact component={Searches} />
                  <Route
                    path="/productattribute"
                    exact
                    component={ProductAttribute}
                  />
                  <Route
                    path="/attributeimage"
                    exact
                    component={AttributeImage}
                  />
                  <Route path="/mapreport" exact component={MapReport} />
                  <Route path="/invoice" exact component={Invoice} />
                  <Route path="/idverify" exact component={Section} />
                  <Route path="/vendor" exact component={Vendor} />
                  <Route path="/attsize" exact component={AttSize} />
                  <Route path="/events" exact component={CalenderEvents} />
                  <Route path="/alluser" exact component={AllUser} />
                  <Route path="/allverify" exact component={AllVerify} />
                  <Route path="/allvendor" exact component={AllVendor} />
                  <Route path="/analytics" exact component={Analytics} />
                  <Route path="/analyticsto/:id" component={Analyticsto} />
                  <Route path="/bannerinner" exact component={BannerInner} />
                  <Route path="/searchList" exact component={OrderList} />
                  <Route
                    path="/redeemproduct"
                    exact
                    component={RedeemProduct}
                  />
                  <Route path="/comment/:id" exact component={Comment} />
                  <Route path="/allcomment" exact component={AllComments} />
                  <Route path="/showredeem" exact component={ShowRedeem} />
                  <Route path="/calls" exact component={Calling} />
                  <Route path="/callhistory" exact component={CallHistory} />
                  <Route path="/vcalls" exact component={VCalling} />
                  <Route path="/vcallhistory" exact component={VCallHistory} />
                  <Route
                    path="/homeanalytics"
                    exact
                    component={HomeAnalytics}
                  />
                  <Route path="/pinned" exact component={Pinned} />
                  <Route
                    path="/pendingproduct"
                    exact
                    component={PendingProduct}
                  />
                  <Route
                    path="/addproductVendor"
                    exact
                    component={AddVendorProduct}
                  />
                  <Route
                    path="/AllVendoractive"
                    exact
                    component={ActiveProducts}
                  />
                  <Route
                    path="/AllVendorpending"
                    exact
                    component={InActiveProducts}
                  />
                  <Route path="/unpinned" exact component={Unpinned} />
                  <Route
                    path="/uninvestigate"
                    exact
                    component={Uninvestigate}
                  />
                  <Route path="/unsuspend" exact component={Unsuspend} />
                  <Route
                    path="/vendorcategory"
                    exact
                    component={VendorCategory}
                  />
                  <Route
                    path="/vendorattribute"
                    exact
                    component={VendorAttribute}
                  />
                  <Route path="/ship" exact component={Ship} />
                  <Route path="/profile" exact component={EditProfile} />

                  <Route
                    path="/addvendorproduct"
                    exact
                    component={AddVendorProduct}
                  />
                  <Route path="/payment" exact component={Payment} />
                  <Route path="/payment-accept" exact component={PaymentCard} />
                  {/* <Route
                    path="/payment-method"
                    exact
                    component={paymentMethods}
                  /> */}
                  <Route path="/usersupport" exact component={UserSupport} />
                  <Route path="/editorder" exact component={EditOrder} />
                  <Route
                    path="/managepayments"
                    exact
                    component={ManagePayments}
                  />
                  <Route
                    path="/vendorsalesreport"
                    exact
                    component={VendorSalesReport}
                  />
                  <Route
                    path="/vendorrevenue"
                    exact
                    component={VendorRevenue}
                  />
                  <Route
                    path="/vendormonthlyorders"
                    exact
                    component={VendorMonthlyOrders}
                  />
                  <Route
                    path="/vendorsaleshistory"
                    exact
                    component={VendorSalesHistory}
                  />
                  <Route
                    path="/vendormonthltyitems"
                    exact
                    component={VendorMonthlyItems}
                  />
                  <Route path="/refundscreen" exact component={RefundScreen} />

                  <Route path="/returnscreen" exact component={ReturnScreen} />
                </div>
              </Switch>
            </div>
          </>
        ) : (
          <>
            <Route path="/Login" exact component={Login} />
            <Route path="/vendorLogin" exact component={VendorLogin} />
            <Route path="/forgot-password" exact component={ForgotPass} />
            <Route
              path="/resetPasswordAdmin/:id"
              exact
              component={AdminPassword}
            />
            <Route
              path="/resetPasswordCustomer/:id"
              exact
              component={CustomerPassword}
            />
            <Route
              path="/resetPasswordVendor/:id"
              exact
              component={VendorPassword}
            />
            <Route
              path="/customerServiceLogin"
              exact
              component={CustomerServiceLogin}
            />
            <Route path="/register/:id" exact component={Register} />
            <Route path="/adminRegister/:id" exact component={AdminRegister} />
            <Route
              path="/customerRegister/:id"
              exact
              component={CustomerRegister}
            />
          </>
        )}
      </HashRouter>
    </div>
  );
};

export default App;
