import React from "react";
import "./Progress3.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress3 = (props) => {
  const percentage = 26;

  return (
    <div>
      <CircularProgressbar
        value={props.percentage}
        text={`${props.percentage}%`}
      />
    </div>
  );
};

export default CircularProgress3;
