import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { adminUser, hide, setAdmin, setCustomer, setProfileComplete, setVendor } from "../../store/actions/actions";
import * as yup from "yup";
import { db } from "../../firebase/firebase";
import {
  doc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import profileCanvas from "../../Assets/Images/profile-canvas.svg";
import havause from "../../Assets/Images/havause.png";
import logo from "../../Assets/Images/logo.jpeg";
import eye from "../../Assets/Images/eye.svg";
import { LoadingButton } from "@mui/lab";
import { phoneRegExp, Toaster } from "../../utils/constant";
import { ref, getDownloadURL, getStorage, uploadBytesResumable } from "firebase/storage";
import "./Register.css";

const viewHeight = window.outerHeight;

const initState = {
  FirstName: "",
  LastName: "",
  username: "",
  // email: "",
  phone: "",
  profilePicture: "",
  password: "",
  confirmPassword: "",
};
function Register(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });


  useEffect(() => {
    dispatch(hide(true));
    const users = collection(
      db,
      "AdminUsers"
    );
    (async () =>{
      const getUser = await getDocs(
        query(users, where("userid", "==", `${props?.match?.params?.id}`))
      );
      const alreadyUser = getUser?.docs?.map((doc) => doc.data());
      setUserDetails(alreadyUser.length ? alreadyUser[0] : {});
    })()
  }, []);

  // const updateData = async () => {
  //   console.log(props.match.params.id);
  //   if (props.match.params.id) {
  //     if (
  //       first.length &&
  //       last.length &&
  //       password === confirm &&
  //       password.length
  //     ) {
  //       // // Signed in
  //       // setEmail("");
  //       // setLast("");
  //       // setFirst("");
  //       // setPassword("");
  //       // setConfirm("");

  //       const pRef = doc(db, "AdminUsers", `${props.match.params.id}`);
  //       let data = {
  //         FirstName: first,
  //         LastName: last,

  //         password: password,
  //       };
  //       await updateDoc(pRef, data);
  //       history.push("/");

  //       // ...

  //       // setLogin(true);
  //     } else {
  //       toast(
  //         password != confirm
  //           ? "Password does not match!"
  //           : "Some Error has occur please try again later."
  //       );
  //     }
  //   }
  // };

  const schema = yup.object().shape({
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    username: yup.string().required(),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required(),
    // email: yup.string().email("Please enter a valid email").required(),
    profilePicture: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required(),
  });

  const updateUsers = async (userDetails, resetForm) => {
    setLoading(true);
    const pRef = doc(
      db,
      "AdminUsers",
      `${props.match.params.id}`
    );
    const users = collection(
      db,
      "AdminUsers"
    );
    const userRecords = await getDocs(
      query(users, where("username", "==", userDetails.username))
    );
    if (userRecords?.docs?.map((doc) => doc.data())?.length) {
      setLoading(false);
      Toaster({
        type: "error",
        message: "Already use this username",
      });
      return;
    }
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/Havause/profileImage/${userDetails?.profilePicture?.name}`
    );
    const uploadTask = uploadBytesResumable(
      storageRef,
      userDetails?.profilePicture
    );
    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        // Handle unsuccessful uploads
        console.log("error:-", error);
        setLoading(false);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            delete userDetails.confirmPassword;
            let data = {
              ...userDetails,
              profilePicture: downloadURL,
              userid: `${props.match.params.id}`,
            };
            await updateDoc(pRef, data)
              .then(async () => {
                const getUser = await getDocs(
                  query(users, where("userid", "==", `${props?.match?.params?.id}`))
                );
                const alreadyUser = getUser?.docs?.map((doc) => doc.data());
                dispatch(setAdmin(true));
                dispatch(setVendor(false));
                dispatch(setCustomer(false));
                dispatch(adminUser(alreadyUser[0]));
                dispatch(setProfileComplete(true));
                localStorage.setItem("AdminUser", JSON.stringify(alreadyUser[0]));
                Toaster({
                  type: "success",
                  message: "Profile Completed Successfully.",
                });
                resetForm(initState);
                history.push("/");
              })
              .catch(() => {
                Toaster({
                  type: "error",
                  message: "Something went wrong!",
                });
              });
            setLoading(false);
          })
          .catch(() => {
            Toaster({
              type: "error",
              message: "Something went wrong!",
            });
            setLoading(false);
          });
      }
    );
  };


  const userForm = useFormik({
    initialValues: initState,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      updateUsers(values, resetForm);
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setFieldValue,
    touched,
    handleBlur,
  } = userForm;

  return (
    // <>
    //   <div className="register-card" style={{ height: viewHeight }}>
    //     <div className="dark-overlay" style={{ height: viewHeight }}></div>
    //     <img src={laptop} style={{ width: "70%", opacity: 0.4 }} />
    //     <div style={{ float: "left", width: "100%" }}>
    //     <div className="register-logo" style={{marginTop:"20px"}}>
    //         <img src={logo}/>
    //       </div>
    //       <p
    //         style={{
    //           fontSize: 28,
    //           fontWeight: 800,
    //           marginTop: 50,
    //           marginLeft: 40,
    //         }}
    //       >
    //         Complete Your Profile
    //       </p>

    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "space-between",
    //           width: "85%",
    //         }}
    //       >
    //         <div style={{ width: "100%", marginLeft: "40px" }}>
    //           <p style={{ marginBottom: "10px", fontWeight: "700" }}>
    //             First Name
    //           </p>
    //           <input
    //             className="register-field"
    //             type="text"
    //             placeholder="Name"
    //             value={first}
    //             onChange={(event) => setFirst(event.target.value)}
    //           />
    //         </div>

    //         <div
    //           style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
    //         >
    //           <p style={{ marginBottom: "10px", fontWeight: "700" }}>
    //             Last Name
    //           </p>
    //           <input
    //             className="register-field"
    //             type="text"
    //             placeholder="Name"
    //             value={last}
    //             onChange={(event) => setLast(event.target.value)}
    //           />
    //         </div>

    //         <div
    //           style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
    //         >
    //           <p style={{ marginBottom: "10px", fontWeight: "700" }}>
    //             Password
    //           </p>
    //           <input
    //             className="register-field"
    //             type="password"
    //             placeholder="New"
    //             value={password}
    //             onChange={(event) => setPassword(event.target.value)}
    //           />
    //         </div>

    //         <div
    //           style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
    //         >
    //           <p style={{ marginBottom: "10px", fontWeight: "700" }}>
    //             Confirm Password
    //           </p>
    //           <input
    //             className="register-field"
    //             type="password"
    //             placeholder="Confirm"
    //             value={confirm}
    //             onChange={(event) => setConfirm(event.target.value)}
    //           />
    //         </div>
    //       </div>
    //       {/* <div
    //         style={{
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "30px",
    //         }}
    //       >
    //         <input
    //           className="register-name"
    //           type="text"
    //           placeholder="First Name"
    //           value={first}
    //           onChange={(event) => setFirst(event.target.value)}
    //         />
    //         <input
    //           className="register-lastname"
    //           type="text"
    //           placeholder="Last Name"
    //           value={last}
    //           onChange={(event) => setLast(event.target.value)}
    //         />
    //       </div> */}

    //       {/* <div
    //         style={{
    //           float: "left",
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "30px",
    //         }}
    //       >
    //         <input
    //           className="register-password"
    //           type="text"
    //           placeholder="Password"
    //           value={password}
    //           onChange={(event) => setPassword(event.target.value)}
    //         />
    //       </div> */}

    //       {/* <div
    //         style={{
    //           float: "left",
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "30px",
    //         }}
    //       >
    //         <input
    //           className="register-confirm-password"
    //           type="text"
    //           placeholder="Confirm Password"
    //           value={confirm}
    //           onChange={(event) => setConfirm(event.target.value)}
    //         />
    //       </div> */}

    //       <div
    //         style={{
    //           float: "left",
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "30px",
    //         }}
    //       >
    //         <input
    //           className="register-check"
    //           type="checkbox"
    //           id="accept"
    //           name="accept"
    //         />
    //         <label className="register-label" for="accept">
    //           I accept{" "}
    //           <span style={{ color: "#000", fontWeight: 600 }}>
    //             Terms of Use
    //           </span>
    //         </label>
    //       </div>

    //       <div
    //         style={{
    //           float: "left",
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "30px",
    //         }}
    //       >
    //         <button
    //           className="register-button"
    //           onClick={() => {
    //             updateData();
    //           }}
    //         >
    //           <p
    //             style={{
    //               marginTop: 10,
    //               color: "#FFFFFF",
    //               marginBottom: "12px",
    //             }}
    //           >
    //             Update
    //           </p>
    //         </button>
    //       </div>
    //       <div
    //         style={{
    //           float: "left",
    //           width: "100%",
    //           maxWidth: "100%",
    //           textAlign: "center",
    //           marginTop: "10px",
    //         }}
    //       >
    //         {login ? (
    //           <button className="register-button">
    //             <Link to="/login">Login</Link>
    //           </button>
    //         ) : null}
    //       </div>
    //     </div>
    //   </div>
    //   <ToastContainer />
    // </>
    <div className="admin-login">
      <div className="login-container">
        <div className="row login-layout">
          <div className="col-md-7 login-canvas">
            <div className="d-flex justify-content-center align-items-center">
              <img src={havause} width={149} height={23} />
              <img src={logo} width={212} height={56} />
            </div>
            <div>
              <img src={profileCanvas} />
            </div>
          </div>
          <div className="col-md-5 px-5 complete-profile align-self-center">
            <div className="title">Complete Your Profile</div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="validationFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="FirstName"
                  value={values.FirstName}
                  placeholder="Enter First Name"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.FirstName && touched.FirstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.FirstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="LastName"
                  value={values.LastName}
                  placeholder="Enter Last Name"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.LastName && touched.LastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.LastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={values.username}
                  placeholder="Enter Username"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={values.phone}
                  autoComplete="new-phone-no"
                  placeholder="Enter Phone"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.phone && touched.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={userDetails.email}
                  placeholder="Enter Email"
                  className="CatInput"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // isInvalid={!!errors.email && touched.email}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback> */}
              </Form.Group>          
              <Form.Group
                className="mb-3 pass-field-new"
                controlId="validationPassword"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={passwordVisible.password ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  value={values.password}
                  placeholder="Enter Password"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
                <img
                  src={eye}
                  alt="eye"
                  className="eyeIcon"
                  onClick={() =>
                    setPasswordVisible({
                      ...passwordVisible,
                      password: !passwordVisible.password,
                    })
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3 pass-field-new"
                controlId="validationConfirmPassword"
              >
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type={passwordVisible.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  placeholder="Enter Confirm Password"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    !!errors.confirmPassword && touched.confirmPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
                <img
                  src={eye}
                  alt="eye"
                  className="eyeIcon"
                  onClick={() =>
                    setPasswordVisible({
                      ...passwordVisible,
                      confirmPassword: !passwordVisible.confirmPassword,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="profilePicUpload" controlId="validationProfilePicture">
                <Form.Label>Logo</Form.Label>
                <label className={`${(!!errors.profilePicture && touched.profilePicture) && 'is-invalid'} CatInput upload-Label`}>
                  {values?.profilePicture ? (
                    <div className="Upload-name">
                      {values?.profilePicture?.name}
                    </div>
                  ) : (
                    "Upload Image"
                  )}
                  <UploadIcon className="upload-icon" />
                  <Form.Control
                    type="file"
                    name="profilePicture"
                    placeholder="Upload Image"
                    className="upload-file-new"
                    onChange={(event) =>
                      setFieldValue(
                        "profilePicture",
                        event?.currentTarget?.files[0]
                      )
                    }
                    isInvalid={
                    !!errors.profilePicture && touched.profilePicture
                  }
                  />
                </label>
                <Form.Control.Feedback type="invalid">
                  {errors.profilePicture}
                </Form.Control.Feedback>
              </Form.Group>
              <LoadingButton
                type="submit"
                loading={loading}
                className="login-btn d-block add-user-btn"
              >
                Continue
              </LoadingButton>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
