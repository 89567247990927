import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { FaRegBell } from "react-icons/fa";
import { GrFormDown } from "react-icons/gr";
import Search from "../Search/Search";
import { IoIosLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import { ReactComponent as UserOutlineIcon } from "../../Assets/Images/user-outline.svg";
import { ReactComponent as NotificationIcon } from "../../Assets/Images/bell.svg";
import user from "../../Assets/Images/user1.jpg";
import "./Header.css";
import { setAdmin, setCustomer, setVendor } from "../../store/actions/actions";
import { useDispatch } from "react-redux";

const dropDownList = [
  { icon: <BiUserCircle />, label: "Edit Profile", path: "/profile" },
  { icon: <IoIosLogOut />, label: "Logout", path: "/login" },
];
const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    let user =
      localStorage.getItem("AdminUser") ||
      localStorage.removeItem("VendorUser") ||
      localStorage.removeItem("ServiceUser");
    setUserDetails(user ? JSON.parse(user) : {});
  }, []);
  return (
    <>
      <div className="app-header">
        <div className="mainHeader">
          <div className="row">
            <div className="col-11">
              <Search />
            </div>
            <div className="col-1 d-flex align-self-center iconSide">
              <div>
                {" "}
                <NotificationIcon className="bell" />
              </div>
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                {userDetails?.profilePicture ? (
                  <img
                    src={userDetails?.profilePicture || user}
                    alt="user-profile"
                    width={24}
                    height={24}
                  />
                ) : (
                  <UserOutlineIcon className="bell user-profile-outline" />
                )}
                {/* <img src={user} alt="user" className="bell user-profile-outline" /> */}
                <GrFormDown />
              </div>
            </div>
          </div>
        </div>
        {toggleMenu && (
          <div className="header-dropdown">
            {dropDownList &&
              dropDownList.map((menu, index) => (
                <Link to={menu.path} key={index} onClick={() =>{
                  if(menu.label === "Logout"){
                    dispatch(setAdmin(false));
                    dispatch(setVendor(false));
                    dispatch(setCustomer(false));
                  }
                    setToggleMenu(false)
                  }}>
                  {" "}
                  <span className="dropdownIcon">
                    {index === 0 && userDetails?.profilePicture ? (
                      <img
                        src={userDetails?.profilePicture || user}
                        alt="user-profile"
                        width={24}
                        height={24}
                      />
                    ) : (
                      menu.icon
                    )}
                  </span>
                  {menu.label}
                </Link>
              ))}
            {/* <Link href="#">
              {" "}
              <span className="dropdownIcon">
                <BiUserCircle />
              </span>
              Edit Profile
            </Link>
            <Link href="#">
              <span className="dropdownIcon">
                <IoIosLogOut />
              </span>
              Logout
            </Link>
            <Link href="#">
              <span className="dropdownIcon"></span>
              Missing Screen
            </Link> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
