import React from "react";
import "./Tabs.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import user from "../../Assets/Images/product.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import OrderMenu from "../OrderMenu/OrderMenu";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ allorders }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="All Order" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
          <Tab label="Cancelled" {...a11yProps(2)} />
          <Tab label="Add New" {...a11yProps(3)} />
          <Tab label="Return" {...a11yProps(4)} />
          <Tab label="Refund" {...a11yProps(5)} />
          <Tab label="Investigate" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="id-card">
          <ul className="history-content">
            <li>
              <select>
                <option value="A">Id</option>
              </select>
            </li>
            <li>Name</li>
            <li>Payment</li>
            <li>
              <select>
                <option value="A">Time Remaining</option>
              </select>
            </li>
            <li>Shipping</li>
            <li>Status</li>
            <li>Total</li>
            <li>Action</li>
          </ul>
        </div>

        {allorders.map((item) => (
          <div className="id-card">
            <p>#{item.OrderId}</p>
            <div>
              <img
                src={item.ProductList[0].Pimage}
                className="history-image"
                alt="logo"
              />
              <p className="user-name">Product</p>
              <p className="user-cash">null object</p>
              <div className="product-eta">
                <AiOutlineClockCircle className="clock" />
                <p className="eta">13 min</p>
              </div>
              <p className="delivery">Delivery</p>

              <p className="delivery2">{item.OrderStatus}</p>

              <p className="delivery3">${item.ServiceFees}</p>
              {/* <BiDotsVerticalRounded className="more-options" /> */}
              <OrderMenu />
            </div>
          </div>
        ))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {allorders.map((item) => {
          if (item.OrderStatus === "delivered") {
            return (
              <div className="id-card">
                <p>#{item.OrderId}</p>
                <div>
                  <img
                    src={item.ProductList[0].Pimage}
                    className="history-image"
                    alt="logo"
                  />
                  <p className="user-name">Product</p>
                  <p className="user-cash">Cash</p>
                  <div className="product-eta">
                    <AiOutlineClockCircle className="clock" />
                    <p className="eta">13 min</p>
                  </div>
                  <p className="delivery">Delivery</p>

                  <p className="delivery2">{item.OrderStatus}</p>

                  <p className="delivery3">${item.ServiceFees}</p>
                  {/* <BiDotsVerticalRounded className="more-options" /> */}
                  <OrderMenu />
                </div>
              </div>
            );
          }
        })}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {allorders.map((item) => {
          if (item.OrderStatus === "cancelled") {
            return (
              <div className="id-card">
                <p>#{item.OrderId}</p>
                <div>
                  <img
                    src={item.ProductList[0].Pimage}
                    className="history-image"
                    alt="logo"
                  />
                  <p className="user-name">Product</p>
                  <p className="user-cash">Cash</p>
                  <div className="product-eta">
                    <AiOutlineClockCircle className="clock" />
                    <p className="eta">13 min</p>
                  </div>
                  <p className="delivery">Delivery</p>

                  <p className="delivery2">{item.OrderStatus}</p>

                  <p className="delivery3">${item.ServiceFees}</p>
                  {/* <BiDotsVerticalRounded className="more-options" /> */}
                  <OrderMenu />
                </div>
              </div>
            );
          }
        })}
      </TabPanel>
    </div>
  );
}
