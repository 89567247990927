import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import "./Marketplace.css";
import { FiSearch } from "react-icons/fi";
import { BsFilter } from "react-icons/bs";
import product from "../../Assets/Images/product.png";
import EditProduct from "../EditProduct/EditProduct";
import EditMarket from "../EditMarket/EditMarket";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllProducts,
  getAllCategories,
  selectProduct,
} from "../../store/actions/actions";

function PerMarketplace(props) {
  const dispatch = useDispatch();
  const [pro, setPro] = useState([]);
  const [search, setSearch] = useState("z4tZeVD35u53rEq3LSvZ");
  const [catSearch, setCatSearch] = useState("");
  const [data, setData] = useState([]);
  const products = useSelector((state) => state.Reducer.products);
  const catnames = useSelector((state) => state.Reducer.categories);

  useEffect(() => {
    if (props.match.params.id != null && props.match.params.id != undefined) {
      setCatSearch(props.match.params.id);

      let newPro = [];
      products.map((item) => {
        if (item.Catid === props.match.params.id) {
          newPro.push(item);
        }
      });
      setData(newPro);
    }
  }, [data]);

  useEffect(() => {
    let newPro = [];
    products.map((item) => {
      if (item.Optionid === search) {
        newPro.push(item);
      }
    });
    setData(newPro);
  }, [search]);

  const getCatName = (item) => {
    let name = catnames.filter((data) => data.Catid === item.Catid);
    console.log(name, "GHGHGGHGHGH");
    return name[0].Catname;
  };

  return (
    <>
      <div style={{ width: "85%", float: "left" }}>
        <div className="products-heading">
          <h3>All Products</h3>
          <p>{data.length} Products</p>
        </div>
        <Col className="search-area">
          <div>
            <FiSearch className="search-icon2" />
            <input className="search-bar2" placeholder="Search" />
          </div>
        </Col>
        <div className="product-border"></div>

        <Form.Group
          className="mb-3 check-allproducts"
          controlId="formBasicCheckbox"
        >
          <Form.Check type="checkbox" label="Select All Products" />
        </Form.Group>
        <select
          className="category"
          value={catSearch}
          onChange={(event) => {
            setCatSearch(event.target.value);
            let newPro = [];
            products.map((item) => {
              if (item.Catid === event.target.value) {
                newPro.push(item);
              }
            });
            setData(newPro);
          }}
        >
          {catnames.map((item) => (
            <option value={item.Catid}>{item.Catname}</option>
          ))}
        </select>

        <select
          className="category2"
          value={search}
          onChange={(event) => {
            console.log(event.target.value, "bemo");
            setSearch(event.target.value);
          }}
        >
          <option value="z4tZeVD35u53rEq3LSvZ">Havazuse</option>
          <option value="CjRug8sVa2x79rZOmBmL">Havazuse Bemo</option>
          <option value="lxpkMyDz8aMzGvU6vxbN">Havazuse Plus</option>
        </select>
        <select className="category">
          <option value="A">More Filters</option>
          <option value="B">Public</option>
          <option value="C">Private</option>
        </select>

        {data.map((item) => (
          <div className="product-card">
            <Form.Group
              className="mb-3 check-allproducts2"
              controlId="formBasicCheckbox"
            >
              <Form.Check type="checkbox" />
            </Form.Group>
            <Link
              onClick={() => {
                dispatch(selectProduct(item));
              }}
              to="/updateProduct"
              style={{ color: "black", textDecoration: "none" }}
            >
              <img src={item.Pimages[0]} className="product-image" alt="logo" />
              <div className="product-desc">
                <p className="name">Product Name</p>
                <h6 className="product-name">{item.Pname}</h6>
              </div>
            </Link>
            <div className="product-desc">
              <Link to="productreviews">
                <button className="review-btn">Reviews & Comments</button>
                {/* <p className="name">Marketplace</p>
                <h6 className="product-name">Trendyol, Joom</h6>
                <p className="name">4 more places</p> */}
              </Link>
            </div>

            <div className="product-desc">
              <p className="name">Inventory</p>
              <h6 className="product-name">0 in stock for</h6>
              <p className="name">0 variants</p>
            </div>

            <div className="product-desc">
              <p className="name">Category Name</p>

              <h6 className="product-name">{getCatName(item)}</h6>
            </div>

            <div className="product-desc">
              <p className="name">Parent SKU</p>
              <h6 className="product-name">GM00001609982</h6>
            </div>

            {/* <button onClick={()=>setShow(true)} className="quick-btn">Quick Edit</button> */}

            <EditMarket item={item} />
          </div>
        ))}
      </div>
    </>
  );
}

export default PerMarketplace;
