import { FiSearch } from "react-icons/fi";
import ReactPlayer from "react-player";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import { ReactComponent as ThumbUpIcon } from "../../Assets/Images/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../Assets/Images/thumb_down.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/View.svg";
import { ReactComponent as ChatIcon } from "../../Assets/Images/chat_black.svg";
import { ReactComponent as PauseIcon } from "../../Assets/Images/puse-video.svg";
// import Profile from "../../Assets/Images";
import DummyProfile from "../../Assets/Images/dummyProfile.jpg";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import {
  collection,
  documentId,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import "./videoComment.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../../firebase/firebase";
import { Dropdown } from "react-bootstrap";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllShorts, getLikesDislikes } from "../../store/actions/actions";
import { Toaster } from "../../utils/constant";
import { async } from "@firebase/util";

const VideoComment = (props) => {
  const dispatch = useDispatch();
  const feeds = useSelector((state) => state.Reducer.feeds);
  const likesDislikes = useSelector((state) => state.Reducer.likesDislikes);
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState([]);
  const [select, setSelect] = useState("");
  const [item, setC] = useState({});
  const [search, setSearch] = useState("");
  const [inputShow, setInputShow] = useState(false);
  const [commentsReply, setCommentsReply] = useState([]);
  const [videoData, setVideoData] = useState({});
  const [videoPlay, setVideoPlay] = useState(false);
  const vendorEnable = useSelector((state) => state.Reducer.vendorEnable);
  const adminEnable = useSelector((state) => state.Reducer.adminEnable);
  const cusEnable = useSelector((state) => state.Reducer.customerEnable);
  let users = useSelector((state) => state.Reducer.users);
  let vendors = useSelector((state) => state.Reducer.vendors);
  let admins = useSelector((state) => state.Reducer.admins);
  let customerService = useSelector((state) => state.Reducer.customerService);
  const [seconds, setSeconds] = useState(0);
  const reactPlayerRef = useRef(null);

  const handleProgress = (secs) => {
    setSeconds(secs);
  };
  const onReady = useCallback(() => {
    const timeToStart = 7 * 60 + 12.6;
    reactPlayerRef.current.seekTo(timeToStart, "seconds");
  }, [reactPlayerRef.current]);

  const onClick = () => {
    setShow(true);
  };
  const getComments = async () => {
    const type = props?.match?.params?.type;
    let videoCollection = "VideosHistory";
    let videoIds = [];
    let videosData = [];
    let comments = [];
    let q = {};
    if (props?.match?.params?.id) {
      q = query(
        collection(db, "ProductComments"),
        where("initialCommit", "==", true),
        where("VideoId", "==", props.match.params.id)
      );
    } else {
      q = query(
        collection(db, "ProductComments"),
        where("initialCommit", "==", true)
      );
    }
    const querySnapshot = await getDocs(q);
    querySnapshot &&
      querySnapshot.forEach((doc) => {
        comments.push(doc.data());
        videoIds.push(doc.id);
      });
    if (type === "short") {
      videoCollection = "Feeds";
    } else if (type === "live") {
      videoCollection = "LiveHistory";
    } else {
      videoCollection = "LiveStreamHistory";
    }
    setComment([...comments]);
  };
  useEffect(async () => {
    getComments();
    dispatch(getAllShorts());
    dispatch(getLikesDislikes());
  }, []);

  const replayComment = useFormik({
    initialValues: {
      comment: "",
    },
    enableReinitialize: true,
    // validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const user =
        JSON.parse(localStorage.getItem("AdminUser")) ||
        JSON.parse(localStorage.getItem("VendorUser")) ||
        JSON.parse(localStorage.getItem("ServiceUser"));
      values = {
        ...values,
        repliedUserId: user.userid,
        id: uuidv4(),
        comments: 0,
        likes: 0,
        dislikes: 0,
        createdAt: new Date(),
      };
      const pRef = doc(db, "ProductComments", `${values?.id}`);
      await setDoc(pRef, values)
        .then(() => {
          getComments();
          resetForm({});
          Toaster({
            type: "success",
            message: "User Created Successfully!",
          });
        })
        .catch(() => {
          Toaster({
            type: "error",
            message: "Something went wrong!",
          });
        });
    },
  });
  const { handleSubmit, values, errors } = replayComment;
  const searchComment = () => {
    setSelect("");
    let newArray = [...comment];
    newArray.filter((item) => item.Comment == search);
    setComment(newArray);
  };
  const addInput = (id) => {
    setCommentsReply((comments) =>
      comments.map((commentData) =>
        id === commentData?.id
          ? {
              ...commentData,
              inputShow: !commentData?.inputShow,
              key: id,
            }
          : commentData
      )
    );
  };

  useEffect(() => {
    if (comment.length > 0) {
      const value = comment.map(comment => comment);
      commentData(value[0]);
    }
  }, [comment]);
  const commentData = (value) => {
    if (value) {
      const video = feeds.find((video) => {
        return value.VideoId === video.videoid;
      });
      setVideoData(video);
    } else {
      const video = feeds.find((video) => {
        return props.match.params.id === video.videoid;
      });
      setVideoData(video);
    }
    setCommentsReply([value]);
  };

  const loadMoreCommentCount = async (commentId) => {
    const users = collection(db, "ProductComments");
    // (async () => {
    //   const userRecords = await getDocs(
    //     query(users, where("commentId", "==", commentId))
    //   );
    //   const subData = userRecords?.docs?.map((doc) => doc.data());
    //   console.log("totalComment--->>>", subData.length);
    //   return subData.length;
    // })()

    const userRecords = await getDocs(
      query(users, where("commentId", "==", commentId))
    );
    const subData = userRecords?.docs?.map((doc) => doc.data());
    console.log("totalComment--->>>", subData.length);
    return subData.length;

    // return new Promise(async (resolve, reject) =>{
    //   debugger;
    //   const users = collection(db, "ProductComments");
    //   getDocs(
    //     query(users, where("commentId", "==", commentId))
    //   ).then((res) =>{
    //     const commentList = res?.docs?.map((doc) => doc.data());
    //     resolve(commentList.length);
    //   })
    //   .catch(e => reject(e))
    //   // const commentList = commentRecord?.docs?.map((doc) => doc.data());
    // })
  };

  const repliedIdData = async (commentId, index) => {
    const users = collection(db, "ProductComments");
    const userRecords = await getDocs(
      query(users, where("commentId", "==", commentId))
    );
    const subData = userRecords?.docs?.map((doc) => doc.data());
    const keyAdd = subData.map((member) => ({
      ...member,
      initialCommit: false,
    }));
    if (keyAdd.length) {
      const cloneComment = [...commentsReply];
      cloneComment.splice(index + 1, 0, ...keyAdd);
      setCommentsReply([
        ...new Map(cloneComment.map((item) => [item["id"], item])).values(),
      ]);
    }
  };

  const requestSearch = async (searchedVal) => {
    const type = props?.match?.params?.type;
    let videoCollection = "VideosHistory";
    let videoIds = [];
    let comments = [];
    let q = {};
    if (props?.match?.params?.id) {
      q = query(
        collection(db, "ProductComments"),
        where("initialCommit", "==", true),
        where("VideoId", "==", props.match.params.id)
      );
    } else {
      q = query(
        collection(db, "ProductComments"),
        where("initialCommit", "==", true)
      );
    }
    const querySnapshot = await getDocs(q);
    querySnapshot &&
      querySnapshot.forEach((doc) => {
        comments.push(doc.data());
        videoIds.push(doc.id);
      });
    if (type === "short") {
      videoCollection = "Feeds";
    } else if (type === "live") {
      videoCollection = "LiveHistory";
    } else {
      videoCollection = "LiveStreamHistory";
    }

    let filteredRows = [];
      const data = comments;

      if (searchedVal.length) {
        filteredRows =
          comments &&
          comments.length &&
          comments.filter((row) => {
            return row?.Comment?.toLowerCase()?.includes(
              searchedVal?.toLowerCase()
            );
          });
      } else {
        filteredRows = data;
      }
      setComment(filteredRows);
  };
  const renderMenu = (replay, index) => {
    // const users = collection(db, "ProductComments");

    // const userRecords = new Promise((resolve, reject) => {
    //   getDocs(
    //     query(users, where("commentId", "==", replay.id))
    //   ).then((results) => {
    //     resolve(results);
    //   })
    // }) ;
    // const subData = userRecords?.docs?.map((doc) => doc.data());

    return (
      <div
        className={`row replayCard ${
          replay?.initialCommit ? "" : "childComment"
        }`}
        key={index}
      >
        <div className="col-12 p-0">
          <div className="videoTitle d-flex">
            <img src={replay?.Image ? replay?.Image : DummyProfile } className="img profile-me" alt="no-img" />
            <div className="align-self-center">
              <p>{replay?.Name}</p>
              <div className="upload-date">
                {replay?.createdAt ? moment(
                  new Date(
                    replay?.createdAt?.seconds * 1000
                  ).toLocaleDateString("en-US")
                ).format("DD MMM YYYY") : null}
              </div>
            </div>
            <Dropdown drop={"start"} className="menuIcon-dropdown-start ">
              <Dropdown.Toggle style={{ all: "unset", cursor: "pointer" }}>
                <MenuIcon className="m-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="delete-item"
                  // onClick={() => {
                  //   dispatch(RemoveFeedLive(video.videoid));
                  // }}
                >
                  <DeleteIcon /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="video-text">{replay?.Comment}</div>
          <div className="d-flex social-icon mt-3">
            <div className="d-flex icon-like">
              <ThumbUpIcon className="cursor-pointer social" />
              <div>{replay?.likes}</div>
            </div>
            <div className="d-flex icon-like">
              <ThumbDownIcon className="cursor-pointer green-fill social" />
              <div>{replay?.dislikes}</div>
            </div>
            <div
              className="d-flex icon-like"
              onClick={() => repliedIdData(replay?.id, index)}
            >
              <ChatIcon className="cursor-pointer green-fill social" />
              <div>
                {replay?.comments}
                {/* {replay?.id ? loadMoreCommentCount(replay?.id) : null} */}
              </div>
            </div>
          </div>
          <p
            className="replay cursor-pointer m-1"
            onClick={() => addInput(replay?.id)}
          >
            Replay
          </p>
          {replay?.inputShow && replay.key === replay.id ? (
            <form onSubmit={handleSubmit}>
              <div className="searchSection w-75">
                <input
                  className="searchBar padding-left"
                  type="text"
                  name="replay"
                  onChange={async (e) => {
                    await replayComment.setValues({
                      Comment: e.target.value,
                      commentId: replay.id,
                      ProductId: replay.ProductId,
                      VideoId: replay.VideoId,
                      userId: replay.userId,
                    });
                  }}
                  // value={values.replay}
                  placeholder="Enter comment"
                />
                <button className="searchButton height-replay" type="submit">
                  Replay
                </button>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    );
    // commentsReply.forEach(async (replay, index) => (
    // ));
  };
  return (
    <>
      <div className="add-category-section videoComments">
        <BreadcrumbMenu
          path={
            props?.home
              ? [
                  { title: "Home", navLink: "/" },
                  { title: "Comments", navLink: "/allcomment" },
                ]
              : [
                  { title: "Short Video", navLink: "/videos" },
                  { title: "Comments", navLink: "/videos/comments" },
                ]
          }
          activeTab="Comments"
          isBack={false}
        />
        <div className="d-flex">
          <div className="searchSection d-flex">
            <FiSearch className="search-icon" />
            <input
              className="searchBar searchbar-table"
              placeholder="Search"
              type="text"
              onChange={(e) => requestSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {comment && comment.length ? (
              comment.map((comment, index) => (
                <div key={index} className="row videoCard">
                  <div
                    className="col-10 p-0"
                    onClick={() => {
                      // setVideoPlay(false);
                      commentData(comment);
                    }}
                  > 
                    <div className="col-8 p-0">
                      <div className="videoTitle d-flex">
                        <div className="img me-1">
                          <img
                            src={comment?.Image ? comment?.Image : DummyProfile}
                            className="video-image me-1"
                            alt={`no-imgs`}
                          />
                        </div>
                        <p>{comment?.Name}</p>
                      </div>
                      <p>{comment?.Name}</p>
                    </div>
                    <div className="video-text">{comment?.Comment || ""}</div>
                  </div>
                  <div className="video-comment-pause-icon col-2 p-0">
                    <div className="video-main">
                      {/* <video
                          width="100%"
                          height="auto"
                          className="video"
                          controls
                        >
                          <source
                            src="https://youtu.be/5fTVslrPsL0"
                            type="video/mp4"
                          />
                        </video>
                        <Dropdown drop={"start"} className="menuIcon-dropdown">
                          <Dropdown.Toggle
                            style={{ all: "unset", cursor: "pointer" }}
                          >
                            <MenuIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="delete-item"
                              // onClick={() => {
                              //   dispatch(RemoveFeedLive(video.videoid));
                              // }}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      <PauseIcon
                        className="PauseIcon cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoPlay((v) => !v);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="notFound">No comments Found</div>
            )}
          </div>
          {videoData?.feedurl && comment && comment.length ? (
            <div className="col-md-6 col-sm-12">
              <div className="viewVideoCard">
                <div className="video-background">
                  <ReactPlayer
                    ref={reactPlayerRef}
                    className="vid-player"
                    playing={videoPlay}
                    controls
                    width={"unset"}
                    playIcon={<span />}
                    // light={!videoPlay && videoData?.thumbnail ? videoData?.thumbnail : null}
                    url={videoData?.feedurl}
                  />
                  <Dropdown drop={"start"} className="menuIcon-dropdown">
                    <Dropdown.Toggle
                      style={{ all: "unset", cursor: "pointer" }}
                    >
                      <MenuIcon className="m-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="delete-item"
                        // onClick={() => {
                        //   dispatch(RemoveFeedLive(video.videoid));
                        // }}
                      >
                        <DeleteIcon /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="profile-thumb d-flex">
                    <img
                      src={videoData?.ProfileImage}
                      className="profile-photo"
                      alt={`user_pic_`}
                    />

                    <h6>Johan Deo</h6>
                  </div> */}
                  {/* <div className="video-timer d-flex justify-content-between">
                    <p>Lorem Ipsum is simply dummy text</p>
                    <p>00:30</p>
                  </div> */}
                </div>
                <div className="d-flex justify-content-between social-main">
                  <div className="d-flex social-icon">
                    <div className="d-flex icon-like">
                      <ThumbUpIcon className="cursor-pointer social" />
                      <div>{videoData?.likes}</div>
                    </div>
                    <div className="d-flex icon-like">
                      <ThumbDownIcon className="cursor-pointer green-fill social" />
                      <div>{videoData?.dislikes}</div>
                    </div>
                    <div className="d-flex icon-like">
                      <ViewIcon className="cursor-pointer social" />
                      <div>{videoData?.views}</div>
                    </div>
                  </div>
                  <div className="upload-date">{videoData?.createdAt ? moment(
                  new Date(
                    videoData?.createdAt * 1000
                  ).toLocaleDateString("en-US")
                ).format("DD MMM YYYY") : null}</div>
                </div>
                <div className="video-timer d-flex justify-content-between">
                  <p>{videoData?.caption || videoData?.LiveStreamTitle}</p>
                </div>
                {commentsReply && commentsReply.length
                  ? commentsReply.map((comment, index) =>
                      renderMenu(comment, index)
                    )
                  : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default VideoComment;
