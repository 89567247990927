import React, { useState } from "react";
import "./Attribute.css";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setAttribute } from "../../store/actions/actions";

function VendorAttribute() {
  const dispatch = useDispatch();
  const [data, setData] = useState(
    useSelector((state) => state.Reducer.attributes)
  );
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  return (
    <div className="attribute-section">
      <div className="attribute-heading">
        <h3>Attributes</h3>
        <p>
          Attribute lets you define extra product data such as size or color.
        </p>

        <h6 className="new-att">Add New Attribute</h6>
        <div className="name-att">
          <p className="edit-att">Name</p>
          <input
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <p className="att-desc">
            Name for the attribute (shown on the front-end)
          </p>
        </div>

        <div className="name-att">
          <p className="edit-att">Slug</p>
          <input
            type="text"
            value={slug}
            onChange={(event) => setSlug(event.target.value)}
          />
          <p className="att-desc">Unique slug/reference for the attribute</p>
        </div>

        <button
          className="attribute-submit"
          onClick={async () => {
            let newData = [...data];
            newData.push({
              name: name,
              slug: slug,
              childs: [],
            });
            setData(newData);
            let userid = Math.floor(Math.random() * 1000000000);

            await setDoc(doc(db, "ProductAttributes", `${userid}`), {
              name: name,
              slug: slug,
              childs: [],
              atid: userid,
            });
            setName("");
            setSlug("");
          }}
        >
          Submit
        </button>
      </div>

      <div className="att-card-border">
        <ul className="att-details">
          <li className="name-margin" style={{ width: 80 }}>
            Name
          </li>
          <li className="slug-margin">Slug</li>
          <li className="type-margin">Type</li>
          <li className="order-margin">Order by</li>
          <li className="terms-margin">Terms</li>
        </ul>

        {data.map((item) => (
          <div className="added-att">
            <ul className="att-details2">
              <li>
                <a style={{ width: 100 }} href="#">
                  {item.name ? item.name : "No Name"}
                </a>
                <p className="del-att">
                  Edit | <span style={{ color: "red" }}>Delete</span>
                </p>
              </li>
              <li className="size-att">{item.slug ? item.slug : "No Slug"}</li>
              <li className="select-att">Select</li>
              <li className="custom-att">Custom Ordering</li>
              <li className="term-att">-</li>
              <li>
                <Link
                  to={"/attinner"}
                  onClick={() => {
                    dispatch(setAttribute(item));
                  }}
                >
                  <AiOutlineSetting className="att-setting" />
                </Link>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VendorAttribute;
