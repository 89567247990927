import React, { useEffect, useState } from "react";
import "./Notification.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import ReactPlayer from "react-player";
import { Button, Form, Modal } from "react-bootstrap";

import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import user1 from "../../Assets/Images/product.png";
import Iphone from "../../Assets/Images/iphone2.png";
import iphone from "../../Assets/Images/iphone.png";
import { AiTwotoneSetting, AiFillPlayCircle } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { resolve } from "../../store/actions/actions";

function Notification() {
  const dispatch = useDispatch();
  const all = useSelector((state) => state.Reducer.feedreports);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    let newArray = [];
    all.map((item) => {
      if (!item.resolved) {
        newArray.push(item);
      }
    });
    setData(newArray);
  }, []);
  return (
    <>
      <div className="notification-section">
        <p className="not-text">Active Reports</p>
        <div className="not-border"></div>
        {data && data.length && data.map((item) => (
          <div className="not-card">
            <img
              src={item?.userDetails?.ProfileImage}
              className="not-image-user2"
              alt="logo"
            />
            <ul className="not-post-details">
              <li className="not-react">{item?.userDetails?.firstName}</li>
              <li className="copyright">Reported a video</li>
              <li className="copyright">{item?.details}</li>
            </ul>
            <img
              src={item?.feedDetails?.thumbnail}
              className="not-phone"
              alt="logo"
            />
            <div style={{ float: "right" }}>
              {/* <div
                className="view-details-mac"
                onClick={async () => {
                  const pRef = doc(db, "FeedReports", `${item.reportid}`);
                  dispatch(resolve(item.reportid));
                  await updateDoc(pRef, {
                    resolved: true,
                  });
                }}
              >
                <button className="view-btn-mac">Resolve</button>
              </div> */}
            </div>
          </div>
        ))}
      </div>

      <div className="posts-section">
        {data.map((item) => (
          <div className="video1-border-not">
            <div
              onClick={handleShow}
              style={{
                backgroundImage: `url(${item?.feedDetails?.thumbnail})`,
                height: "182px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="video-length-mac2">{/* <span>2:34</span> */}</div>
              <div className="clearfix"></div>

              <div className="setting-gear-mac">
                <AiTwotoneSetting className="video-setting-mac2" />
              </div>
            </div>

            <div className="video-desc-not">
              <p className="video-title-mac">Reported video</p>
              <p className="video-date-mac">21 JAN 2021</p>
            </div>

            <div style={{ float: "right" }}>
              <div className="view-details-mac">
                <button className="view-btn-mac">
                  {item.views} <BsEye />
                </button>
              </div>

              <div className="view-delete-mac">
                <button className="delete-btn-mac">Delete</button>
              </div>
            </div>

            <div>
              <div className="video-hearts">
                <GiHearts className="hearts" />
                <p className="num-hearts">{item?.feedDetails?.likes}</p>
              </div>

              <div className="video-dislike">
                <AiFillDislike className="dislike" />
                <p className="num-hearts">{item?.feedDetails?.dislikes}</p>
              </div>

              <div className="video-dislike">
                <BsChatFill className="dislike" style={{ color: "green" }} />
                <p className="num-hearts">0</p>
              </div>

              {/* <div className="video-comments">
                  <BsChatFill className="chat" />
                  <p className="num-hearts">2.3k</p>
                </div> */}
            </div>

            <div className="clearfix"></div>

            <div style={{ width: "100%", maxWidth: "100%" }}>
              <img
                src={item?.userDetails?.ProfileImage}
                className="not-image-user3"
                alt="logo"
              />

              <ul className="not-post-details2">
                <li className="not-react2">
                  Reported by {item?.userDetails?.firstName}
                </li>
                <li className="copyright2">{item?.details}</li>
                <li className="duration2">10 min ago</li>
              </ul>
            </div>
            <Modal className="modal-video" show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="not-image">
                  <Link
                    to={`listings/${item?.feedDetails?.userid}`}
                    style={{ color: "black" }}
                  >
                    <img
                      src={
                        item?.feedDetails?.ProfileImage
                          ? item?.feedDetails?.ProfileImage
                          : ""
                      }
                      className="not-image-user"
                      style={{ width: "35%" }}
                      alt="logo"
                    />
                    <p style={{ marginTop: 13, marginLeft: 70, width: 200 }}>
                      anoynomus
                    </p>
                  </Link>
                </div>

                <ReactPlayer
                  className="vid-player"
                  playing
                  playIcon={
                    <AiFillPlayCircle
                      color="#3FAD72"
                      style={{
                        height: 100,
                        width: 100,
                      }}
                    />
                  }
                  light={item?.feedDetails?.thumbnail}
                  url={item?.feedDetails?.feedurl}
                />
              </Modal.Body>
            </Modal>
          </div>
        ))}
      </div>
    </>
  );
}

export default Notification;
