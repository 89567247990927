import React from "react";
import "./Serve.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import phone from "../../Assets/Images/phone.png";

function Serve({ text }) {
  return (
    <>
      {/* <div className="search-user">
            <FiSearch className="usersearch-icon"/>
            <input className="input-search" type="text" placeholder="Search..."/>
        </div>

        <div className="not-details">
                
                        <BsDot className="not-dot"/>
                        <BsFillEnvelopeFill className="message-icon"/>
                   
                        <BsDot className="not-dot2"/>
                        <BsBellFill className="message-icon"/>
        </div>
        <div className="not-image">
            <img src={user} className="not-image-user" alt="logo" />
            <p className="not-image-text">John Doe</p>
        </div>
        <GiHamburgerMenu className="hamburger"/> */}

      <div>
        <div className="item-serve">
          <img src={phone} className="product-serve" alt="logo" />
        </div>
        <div className="serve-details">
          <p className="serve-name">Iphone 12 Blue 64GB</p>
          <p className="serve-sku">SKU #: 123456</p>
          <p className="serve-sku">{text}</p>
        </div>

        <div className="serve-order">
          <p className="serve-quantity">Order Quantity: 2</p>
          <p className="serve-amount">$1000</p>
        </div>
      </div>

      <div style={{ float: "right", width: "55.5%" }}>
        <div className="item-serve">
          <img src={phone} className="product-serve-live" alt="logo" />
        </div>
        <div className="serve-details-live">
          <p className="serve-name">Iphone 12 Blue 64GB</p>
          <p className="serve-sku">SKU #: 123456</p>
          <p className="serve-sku">{text}</p>
        </div>

        <div className="serve-order-live">
          <p className="serve-quantity">Order Quantity: 2</p>
          <p className="serve-amount">$1000</p>
        </div>
      </div>

      <div style={{ float: "right", width: "55.5%" }}>
        <div className="item-serve">
          <img src={phone} className="product-serve-live" alt="logo" />
        </div>
        <div className="serve-details-live">
          <p className="serve-name">Iphone 12 Blue 64GB</p>
          <p className="serve-sku">SKU #: 123456</p>
          <p className="serve-sku">{text}</p>
        </div>

        <div className="serve-order-live">
          <p className="serve-quantity">Order Quantity: 2</p>
          <p className="serve-amount">$1000</p>
        </div>
      </div>

      {/* <div>
        <div className="item-serve">
            <img src={phone} className="product-serve" alt="logo" />
        </div>
        <div className="serve-details">
            <p className="serve-name">Iphone 12 Blue 64GB</p>
            <p className="serve-sku">SKU #: 123456</p>
            <p className="serve-sku">Return</p>
        </div>

        <div className="serve-order">
            <p className="serve-quantity">Order Quantity: 2</p>
            <p className="serve-amount">$1000</p>
        </div>
</div>


<div>
        <div className="item-serve">
            <img src={phone} className="product-serve" alt="logo" />
        </div>
        <div className="serve-details">
            <p className="serve-name">Iphone 12 Blue 64GB</p>
            <p className="serve-sku">SKU #: 123456</p>
            <p className="serve-sku">Return</p>
        </div>

        <div className="serve-order">
            <p className="serve-quantity">Order Quantity: 2</p>
            <p className="serve-amount">$1000</p>
        </div>
</div> */}
    </>
  );
}

export default Serve;
