import React, { useState, useEffect } from "react";
import "./UserNotes.css";
import { FiSearch } from "react-icons/fi";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import user from "../../Assets/Images/user1.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import { db } from "../../firebase/firebase";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
function UserNotes(props) {
  const [userNotes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  const suser = localStorage.getItem("AdminUser");

  const sendNote = async () => {
    if (note.length) {
      let pid = Math.floor(Math.random() * 1000000000);

      const data = {
        userid: props.match.params.id,
        note: note,
        date: new Date().toLocaleString(),
      };

      await setDoc(doc(db, "UserNotes", `${pid}`), data);
      setNote("");

      getNotes();
    }
  };

  const getNotes = async () => {
    let nore = [];
    const citiesRef = collection(db, "UserNotes");

    const q = query(citiesRef, where("userid", "==", props.match.params.id));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      nore.push(doc.data());
    });

    if (nore.length) {
      setNotes(nore);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);
  return (
    <>
      {/* <div className="search-user">
        <FiSearch className="usersearch-icon"/>
        <input className="input-search" type="text" placeholder="Search..."/>
    </div> */}

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img src={user} className="not-image-user" alt="logo" />
        <p className="not-image-text">{JSON.parse(suser).displayName}</p>
      </div>
      {/* <GiHamburgerMenu className="hamburger"/> */}

      <div className="user-notes">
        <h3>User Notes</h3>
      </div>

      <div className="notes-box">
        <textarea
          className="user-textarea"
          type="text"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <button className="user-note-btn" onClick={sendNote}>
          Add Note
        </button>
      </div>
      {userNotes.map((item) => {
        return (
          <>
            <div className="user-msg1">
              <p>{item.note}</p>
            </div>
            <p className="by-admin">By admin | {item.date}</p>
          </>
        );
      })}
    </>
  );
}

export default UserNotes;
