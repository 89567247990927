import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import ReactPlayer from "react-player";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import user from "../../Assets/Images/user1.jpg";
import { Tooltip } from "@mui/material";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import { ReactComponent as ProductImg } from "../../Assets/Images/product.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { ReactComponent as ThumbUpIcon } from "../../Assets/Images/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../Assets/Images/thumb_down.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/View.svg";
import { ReactComponent as ChatIcon } from "../../Assets/Images/chat_black.svg";
import { ReactComponent as PauseIcon } from "../../Assets/Images/puse-video.svg";
import "./liveSchedule.css";
import {
  RemoveFeed,
  getAllLiveSchedules,
  RemoveFeedSchedule,
} from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TabMenu from "../NewTab";

const tabs = [
  { id: 1, name: "All Streams" },
  { id: 2, name: "My Streams" },
];

const LiveSchedule = () => {
  const [data, setData] = useState([]);
  const [videoPlay, setVideoPlay] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [item, setItem] = useState("");
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const schedules = useSelector((state) => state.Reducer.liveSchedules);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLiveSchedules());
  }, []);
  useEffect(() => {
    setData(schedules);
  }, [schedules]);
  console.log("data", data);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const actionPerform = (action) => {
    switch (action) {
      case "like":
        console.log("Like");
      case "unlike":
        console.log("unlike");
      default:
        return "";
    }
  };

  return (
    <>
      <div className="add-category-section live-schedule">
        <div className="d-flex justify-content-between align-items-center search-table-header">
          <div className="d-flex align-items-center">
            <BreadcrumbMenu
              path={[]}
              activeTab="Live Schedule"
              isBack={false}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center search-table-header">
          <TabMenu
            activeKey={activeKey}
            tabs={tabs}
            setActiveKey={setActiveKey}
          />
          {/* <Nav variant="pills" activeKey={activeKey}>
            <Nav.Item className="item-all-streams">
              <Nav.Link
                eventKey="first"
                className="allStreams d-flex"
                onClick={() => {
                  setActiveKey("first");
                }}
              >
                All Streams
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="item-all-streams">
              <Nav.Link
                eventKey="two"
                onClick={() => {
                  setActiveKey("two");
                }}
              >
                My Streams
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
          <div className="d-flex align-items-center">
            <div className="searchSection d-flex">
              <FiSearch className="search-icon" />
              <input
                className="searchBar searchbar-table"
                placeholder="Search"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="row">
          {data && data.length ? (
            data.map((video, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-12">
                <div className="video-cards">
                  <div className="video-main">
                    <ReactPlayer
                      className="vid-player"
                      width={"unset"}
                      height={264}
                      onClick={() => {
                        setVideoPlay(true);
                        setSelectedDetails(video);
                        handleShow();
                      }}
                      playIcon={
                        <PauseIcon
                          className="PauseIcon"
                          color="#3FAD72"
                          style={{
                            height: 50,
                            width: 50,
                          }}
                        />
                      }
                      light={video.thumbnail}
                      url={video.feedurl}
                    />
                    {/* <video
                      width="100%"
                      height="auto"
                      className="video"
                      controls
                    >
                      <source
                        src="https://youtu.be/5fTVslrPsL0"
                        type="video/mp4"
                      />
                    </video> */}
                    {/* <PauseIcon className="PauseIcon" /> */}
                    <div className="profile-thumb d-flex">
                      <div className="profile-photo">
                        <img
                          src={video.profilePic || user}
                          alt={`user_pic_${index}`}
                        />
                      </div>
                      <h6>{video?.uploaderName || ""}</h6>
                    </div>
                    <div className="video-timer">
                      <p>{video?.duration || "00:00"}</p>
                    </div>
                  </div>
                  <div className="generalMedia">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="d-flex m-0 p-0"> Dummy Video For Test</p>
                      <Dropdown
                        drop={"start"}
                        className="menuIcon-dropdown-start"
                      >
                        <Dropdown.Toggle
                          style={{ all: "unset", cursor: "pointer" }}
                        >
                          <MenuIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="product-item">
                            <ProductImg /> Product Detail
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="delete-item"
                            onClick={() => {
                              dispatch(RemoveFeedSchedule(video.videoid));
                            }}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="upload-date">21 JAN 2021</div>
                    <div
                      className="d-flex justify-content-around social-icon mx-auto"
                      style={{ width: "90%" }}
                    >
                      <div className="text-center icon-like">
                        <div>{video?.likes || "0"}</div>
                        <ThumbUpIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("like")}
                        />
                      </div>
                      <div className="text-center icon-like">
                        <div>{video?.dislikes || "0"}</div>
                        <ThumbDownIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("unlike")}
                        />
                      </div>
                      <div className="text-center icon-like">
                        <div>16</div>
                        <Tooltip title="User Message" placement="top">
                          <Link
                            to={`/videos/live-schedule/comments/${video?.videoid}`}
                          >
                            <ChatIcon className="cursor-pointer" />
                          </Link>
                        </Tooltip>
                      </div>
                      <div className="text-center icon-like">
                        <div>{video?.views || "0"}</div>
                        <ViewIcon
                          className="cursor-pointer"
                          onClick={() => actionPerform("views")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No videos found</div>
          )}
        </div>
      </div>
      <Modal className="modal-video" show={show} onHide={handleClose}>
        <Modal.Header className="video-modal-header" closeButton />
        <Modal.Body>
          {/* <div className="not-image">
            <Link to={`listings/${item.userid}`} style={{ color: "black" }}>
              <img
                src={item.ProfileImage ? item.ProfileImage : ""}
                className="not-image-user"
                style={{ width: "35%" }}
                alt="logo"
              />
              <p style={{ marginTop: 13, marginLeft: 70, width: 200 }}>
                anoynomus
              </p>
            </Link>
          </div> */}

          <ReactPlayer
            className="vid-player"
            playing={videoPlay}
            controls={true}
            // playsinline={true}
            width="100%"
            height={452}
            onPause={() => setVideoPlay(false)}
            playIcon={
              <PauseIcon
                className="PauseIcon"
                color="#3FAD72"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            }
            light={selectedDetails?.thumbnail}
            url={selectedDetails?.feedurl}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LiveSchedule;
     