import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Category.css";
import { db } from "../../firebase/firebase";
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { Link, useHistory } from "react-router-dom";
import {
  getAllCategories,
  getCategoryById,
  removeCategory,
} from "../../store/actions/actions";
import { AiFillPlusCircle } from "react-icons/ai";
// import { BreadcrumbItem, Button } from "react-bootstrap";
import { ReactComponent as PlusOutLine } from "../../Assets/Images/plus_outline.svg";
import { ReactComponent as PencilIcon } from "../../Assets/Images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TableFilterBar from "../TableFilterBar";
import { FiSearch } from "react-icons/fi";
import CommonTable from "../Table";
import Tooltip from "@mui/material/Tooltip";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
} from "@mui/material";
import { Toaster } from "../../utils/constant";

const toolTipProps = {
  disablePortal: true,
  popperOptions: {
    positionFixed: true,
    modifiers: {
      preventOverflow: {
        enabled: true,
        boundariesElement: "window", // where "window" is the boundary
      },
    },
  },
};

function Category() {
  const dispatch = useDispatch();
  const history = useHistory();
  const catnames = useSelector((state) => state.Reducer.categories);
  const product = useSelector((state) => state.Reducer.products);
  const [cat, setCat] = useState("");
  const [yes, setYes] = useState("Create Category");
  const [selected, setSelected] = useState("");
  const [edit, setEdit] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    record: {},
  });
  const [category, setCategory] = useState({ categories: [], list: [] });

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    let dataList = [];
    catnames &&
      catnames.length &&
      catnames.map((category, index) => {
        dataList.push({
          id: index + 1,
          name: category.Catname || "",
          description: category.description || "",
          slug: category.Catname || "",
          count: getCounts(category.Catid),
          Catid: category.Catid || "",
          parentCategory: category?.ParentCategory || "",
        });
      });
    setCategory({ categories: [...dataList], list: [...dataList] });
    dispatch(getCategoryById(null));
  }, [catnames]);

  const columns = [
    {
      field: "id",
      renderHeader: (params) => {
        // const onClick = async (e, type) => {
        //   e.stopPropagation(); // don't select this row after clicking
        // };
        return (
          <strong>
            {"#"}
            {/* <Checkbox color="success" onClick={onClick} /> */}
          </strong>
        );
      },

      width: 100,
      renderCell: (params) => {
        // const onClick = async (e, type) => {
        //   e.stopPropagation(); // don't select this row after clicking

        //   const api = params.api;
        //   const thisRow = {};

        //   api
        //     .getAllColumns()
        //     .filter((c) => c.field !== "__check__" && !!c)
        //     .forEach(
        //       (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
        //     );

        //   return alert(JSON.stringify(thisRow, null, 4));
        // };
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
            {/* <Checkbox color="success" onClick={onClick} /> */}
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350, sorting: true },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      sorting: true,
    },
    { field: "slug", headerName: "Slug", width: 350 },
    { field: "count", headerName: "Count", width: 200 },
    {
      field: "Action",
      headerName: "Actions",
      width: 215,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = async (e, type) => {
          e.stopPropagation();
          if (type === "remove") {
            setConfirmDialog({
              open: !confirmDialog.open,
              record: params?.row,
            });
          }
        };

        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link
              to={{
                pathname: "/category/addCategory",
                state: { data: params?.row, isAddMore: true },
              }}
            >
              <Tooltip title="Add" placement="top">
                <PlusOutLine />
              </Tooltip>
            </Link>
            <Link
              to={{
                pathname: "/category/editCategory",
                state: { data: params?.row, isEdit: true },
              }}
            >
              <Tooltip title="Edit" placement="top">
                <PencilIcon />
              </Tooltip>
            </Link>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon onClick={(e) => onClick(e, "remove")} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getCounts = (Catid) => {
    let newArray = product && product.length && product.filter((item) => item.Catid === Catid) || [];
    return newArray.length;
  };
  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.Catid) {
      setSelected("");
      setEdit("");
    } else {
      setEdit(item.Catname);
      setSelected(item.Catid);
    }
  };

  // return (
  //   <div className="add-category-section" style={{ maxWidth: 1920 }}>
  //     <div className="add-product-header">
  //       <h3>New Category</h3>
  //     </div>

  //     <div
  //       className="save-category-section"
  //       onClick={async () => {
  //         setYes("Creating...");
  //         let CatId = Math.floor(Math.random() * 1000000000);

  //         await setDoc(doc(db, "Categories", `${CatId}`), {
  //           Catid: `${CatId}`,
  //           Catname: cat,
  //           Image:
  //             "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
  //         });
  //         dispatch(
  //           addCategory({
  //             Catid: CatId,
  //             Catname: cat,
  //             Image:
  //               "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
  //           })
  //         );
  //         setYes("Done");
  //         setCat("");
  //         setYes("Create Category");
  //       }}
  //     >
  //       <button className="category-save">{yes}</button>
  //     </div>

  //     <div className="edit-name">
  //       <h5>
  //         Category Name <span style={{ color: "red" }}>*</span>
  //       </h5>
  //     </div>
  //     <input
  //       className="input-product"
  //       type="text"
  //       value={cat}
  //       onChange={(event) => setCat(event.target.value)}
  //     />
  //     <div className="clearfix"></div>

  //     <div className="edit-name">
  //       <h5>
  //         Parent Category <span style={{ color: "red" }}>*</span>
  //       </h5>
  //     </div>
  //     <select className="input-product">
  //       {catnames.map((item) => (
  //         <option value={item.Catid}>{item.Catname}</option>
  //       ))}
  //       <option value={"No Parent"}>{"No Parent"}</option>
  //     </select>

  //     <div className="added-categories">
  //       <div className="categories-border">
  //         <input className="cat-check" type="checkbox" />
  //         <ul className="cat-header">
  //           <li>Name</li>
  //           <li>Description</li>
  //           <li>Slug</li>
  //           <li className="count-list">Count</li>
  //           <li>Actions</li>
  //         </ul>
  //       </div>

  //       {catnames.map((item) => (
  //         <div className="categories-border33">
  //           {/* <input className="cat-check" type="checkbox" /> */}
  //           <ul className="cat-header22">
  //             {/* <input className="cat-check" type="checkbox" /> */}

  //             <li>
  //               {selected === item.Catid ? (
  //                 <>
  //                   <input
  //                     onChange={(event) => setEdit(event.target.value)}
  //                     style={{ backgroundColor: " #3fad72" }}
  //                     value={selected === item.Catid ? edit : item.Catname}
  //                   />
  //                 </>
  //               ) : (
  //                 <li style={{}}>{item.Catname}</li>
  //               )}
  //             </li>
  //             <li style={{}}>
  //               {item.description ? item.description : "description"}
  //             </li>

  //             <li style={{}}>{item.Catname}</li>
  //             <li className="count-num">
  //               <Link to={`percat/${item.Catid}`}>{getCounts(item.Catid)}</Link>
  //             </li>
  //           </ul>
  //           <ul className="cat-edit">
  //             {selected === item.Catid ? (
  //               <li
  //                 onClick={async () => {
  //                   dispatch(
  //                     updateCategory({
  //                       Catid: item.Catid,
  //                       Catname: edit,
  //                     })
  //                   );
  //                   const pRef = doc(db, "Categories", `${item.Catid}`);

  //                   await updateDoc(pRef, {
  //                     Catname: edit,
  //                   });
  //                   setSelected("");
  //                 }}
  //               >
  //                 Done
  //               </li>
  //             ) : (
  //               <li onClick={() => handleSelection(item)}>Edit</li>
  //             )}

  //             <li
  //               style={{ color: "red" }}
  //               onClick={async () => {
  //                 dispatch(removeCategory(item.Catid));
  //                 await deleteDoc(doc(db, "Categories", item.Catid));
  //               }}
  //             >
  //               Trash
  //             </li>
  //             <li
  //               style={{ color: "blue" }}
  //               onClick={() => {
  //                 handleSelection(item);
  //               }}
  //             >
  //               Add
  //             </li>
  //           </ul>

  //           <>
  //             {selected === item.Catid ? (
  //               <>
  //                 <div className="edit-namePP">
  //                   <h5 style={{ fontSize: 14 }}>Parent Category</h5>
  //                 </div>
  //                 <select className="cat-inputll">
  //                   {catnames.map((item) => (
  //                     <option value={item.Catid}>{item.Catname}</option>
  //                   ))}
  //                   <option value={"No Parent"}>{"No Parent"}</option>
  //                 </select>
  //               </>
  //             ) : null}
  //           </>
  //           {/* <div className="edit-namePP">
  //             <h5 style={{ fontSize: 14 }}>Child Category</h5>
  //           </div>
  //           <select className="cat-inputll">
  //             {catnames.map((item) => (
  //               <option value={item.Catid}>{item.Catname}</option>
  //             ))}
  //             <option value={"No Parent"}>{"No Parent"}</option>
  //           </select> */}
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );

  const requestSearch = (searchedVal) => {
    let filteredRows = [];
    const data = catnames;
    let dataList = [];
    data &&
      data.length &&
      data.map((category, index) => {
        dataList.push({
          id: index + 1,
          name: category.Catname || "",
          description: category.description || "",
          slug: category.Catname || "",
          count: getCounts(category.Catid),
          Catid: category.Catid || "",
          parentCategory: category?.ParentCategory || "",
        });
      });
    if (searchedVal.length) {
      filteredRows =
        dataList &&
        dataList.length &&
        dataList.filter((row) => {
          return (
            row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.slug.toLowerCase().includes(searchedVal.toLowerCase())
          );
        });
    } else {
      filteredRows = dataList;
    }
    setCategory({
      ...category,
      categories: filteredRows,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      selectValue = category?.categories?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };
  const onRemoveRecord = async () => {
    await deleteDoc(doc(db, "Categories", `${confirmDialog?.record?.Catid}`));
    dispatch(removeCategory(`${confirmDialog?.record?.Catid}`));
    setConfirmDialog({ open: !confirmDialog.open, record: {} });
    Toaster({
      type: "success",
      message: "Successfully deleted category.",
    });
  };
  return (
    <div className="add-category-section">
      <BreadcrumbMenu
        path={[
          { title: "Products", navLink: "/All" },
          { title: "Category", navLink: "/category" },
        ]}
        activeTab="Category"
        isBack={false}
      />
      <TableFilterBar
        selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        <div className="searchSection d-flex">
          <FiSearch className="search-icon" />
          <input
            className="searchBar searchbar-table"
            placeholder="Search"
            type="text"
            onChange={(e) => requestSearch(e.target.value)}
          />
          <button
            className="searchButton searchButton-table"
            onClick={() => history.push("category/addCategory")}
          >
            Add Category
          </button>
        </div>
      </TableFilterBar>
      <CommonTable
        columns={columns}
        rows={category?.categories || []}
        className="categoryTable"
        rowsPerPage={rowsPerPage?.pageSize}
      />
      <Dialog
        open={confirmDialog.open}
        onClose={() =>
          setConfirmDialog({ open: !confirmDialog.open, record: {} })
        }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this - {confirmDialog?.record?.name}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() =>
              setConfirmDialog({ open: !confirmDialog.open, record: {} })
            }
          >
            Cancel
          </Button>
          <Button onClick={() => onRemoveRecord()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Category;
