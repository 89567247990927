import React, { useEffect, useState } from "react";
import "./Welcome.css";
import user from "../../Assets/Images/user1.jpg";
import logout from "../../Assets/Images/logout.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdSignalCellularNull } from "react-icons/md";
function Welcome({ name }) {
  const users = useSelector((state) => state.Reducer.adminUser);
  const [usere, setUser] = useState(null);

  useEffect(() => {
    console.log(users, "USer Welcome");
    setUser(users);
  }, [users]);
  return (
    <div className="welcome-card">
      <img src={user} alt="logo" className="welcome-user" />
      <Link to="login">
        <img
          src={logout}
          style={{ height: 40, width: 40, float: "right", marginRight: 10 }}
          alt="logo"
        />
      </Link>

      <p className="welcome-user-text">Welcome Back! {users.FirstName}</p>
    </div>
  );
}

export default Welcome;
