import React, { useEffect, useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import { ReactComponent as PlusOutLine } from "../../Assets/Images/plus_outline.svg";
import { ReactComponent as PencilIcon } from "../../Assets/Images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { getAllAttributes, setAttribute } from "../../store/actions/actions";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TableFilterBar from "../TableFilterBar";
import Tooltip from "@mui/material/Tooltip";
import CommonTable from "../Table";
import "./Attribute.css";

function Attribute() {
  const dispatch = useDispatch();
  const attributes = useSelector((state) => state.Reducer.attributes);
  const history = useHistory();
  const [data, setData] = useState(
    useSelector((state) => state.Reducer.attributes)
  );
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    record: {},
  });
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [attributeList, setAttributesList] = useState([]);

  const onRemoveRecord = async () => {
    // await deleteDoc(doc(db, "Categories", `${confirmDialog?.record?.Catid}`));
    // dispatch(removeCategory(`${confirmDialog?.record?.Catid}`));
    // setConfirmDialog({ open: !confirmDialog.open, record: {} });
    // Toaster({
    //   type: "success",
    //   message: "Successfully deleted category.",
    // });
  };
  useEffect(() => {
    dispatch(getAllAttributes());
  }, []);

  useEffect(() => {
    let dataList = [];
    attributes &&
      attributes.length &&
      attributes.map((attribute, index) => {
        dataList.push({
          id: index + 1,
          name: attribute.name || "-",
          slug: attribute.slug || "-",
          childs: attribute.childs || [],
          // count: getCounts(attribute.Catid),
          atid: attribute.atid || "-",
        });
      });
    setAttributesList([...dataList]);
    // dispatch(getCategoryById(null));
  }, [attributes]);

  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350 },
    { field: "slug", headerName: "Slug", width: 350 },
    { field: "type", headerName: "Type", width: 165 },
    { field: "orderBy", headerName: "Order by", width: 210 },
    { field: "terms", headerName: "Terms", width: 160 },
    {
      field: "Action",
      headerName: "Actions",
      width: 210,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = async (e, type) => {
          e.stopPropagation();
          if (type === "remove") {
            setConfirmDialog({
              open: !confirmDialog.open,
              record: params?.row,
            });
          }
        };

        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link
              to={{
                pathname: "/attributes/addAttribute",
                state: { data: params?.row, isAddMore: true },
              }}
            >
              <Tooltip title="Add" placement="top">
                <PlusOutLine />
              </Tooltip>
            </Link>
            <Link
              to={{
                pathname: "/attributes/editAttribute",
                state: { data: params?.row, isEdit: true },
              }}
            >
              <Tooltip title="Edit" placement="top">
                <PencilIcon />
              </Tooltip>
            </Link>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon onClick={(e) => onClick(e, "remove")} />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      selectValue = attributeList?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };
  return (
    // <div className="attribute-section">
    //   <div className="attribute-heading">
    //     <h3>Attributes</h3>
    //     <p>
    //       Attribute lets you define extra product data such as size or color.
    //     </p>

    //     <h6 className="new-att">Add New Attribute</h6>
    //     <div className="name-att">
    //       <p className="edit-att">Name</p>
    //       <input
    //         type="text"
    //         value={name}
    //         onChange={(event) => setName(event.target.value)}
    //       />
    //       <p className="att-desc">
    //         Name for the attribute (shown on the front-end)
    //       </p>
    //     </div>

    //     <div className="name-att">
    //       <p className="edit-att">Slug</p>
    //       <input
    //         type="text"
    //         value={slug}
    //         onChange={(event) => setSlug(event.target.value)}
    //       />
    //       <p className="att-desc">Unique slug/reference for the attribute</p>
    //     </div>

    //     <button
    //       className="attribute-submit"
    //       onClick={async () => {
    //         let newData = [...data];
    //         newData.push({
    //           name: name,
    //           slug: slug,
    //           childs: [],
    //         });
    //         setData(newData);
    //         let userid = Math.floor(Math.random() * 1000000000);

    //         await setDoc(doc(db, "ProductAttributes", `${userid}`), {
    //           name: name,
    //           slug: slug,
    //           childs: [],
    //           atid: userid,
    //         });
    //         setName("");
    //         setSlug("");
    //       }}
    //     >
    //       Submit
    //     </button>
    //   </div>

    //   <div className="att-card-border">
    //     <ul className="att-details">
    //       <li className="name-margin" style={{ width: 80 }}>
    //         Name
    //       </li>
    //       <li className="slug-margin">Slug</li>
    //       <li className="type-margin">Type</li>
    //       <li className="order-margin">Order by</li>
    //       <li className="terms-margin">Terms</li>
    //     </ul>

    //     {data.map((item) => (
    //       <div className="added-att">
    //         <ul className="att-details2">
    //           <li>
    //             <a style={{ width: 100 }} href="#">
    //               {item.name ? item.name : "No Name"}
    //             </a>
    //             <p className="del-att">
    //               Edit | <span style={{ color: "red" }}>Delete</span>
    //             </p>
    //           </li>
    //           <li className="size-att">{item.slug ? item.slug : "No Slug"}</li>
    //           <li className="select-att">Select</li>
    //           <li className="custom-att">Custom Ordering</li>
    //           <li className="term-att">-</li>
    //           <li>
    //             <Link
    //               to={"/attinner"}
    //               onClick={() => {
    //                 dispatch(setAttribute(item));
    //               }}
    //             >
    //               <AiOutlineSetting className="att-setting" />
    //             </Link>
    //           </li>
    //         </ul>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="add-category-section attributes-section">
      <BreadcrumbMenu
        path={[
          { title: "Products", navLink: "/All" },
          { title: "Attribute", navLink: "/attributes" },
        ]}
        activeTab="Attribute"
        subTitle="Attribute lets you define extra product data such as size or color."
        isBack={false}
      />
      <TableFilterBar
        selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        <div className="searchSection d-flex">
          <button
            className="searchButton searchButton-table"
            onClick={() => history.push("attributes/addAttribute")}
          >
            Add New Attribute
          </button>
        </div>
      </TableFilterBar>
      <CommonTable
        columns={columns}
        rows={attributeList || []}
        className="attributeTable"
        rowsPerPage={rowsPerPage?.pageSize}
      />
      <Dialog
        open={confirmDialog.open}
        onClose={() =>
          setConfirmDialog({ open: !confirmDialog.open, record: {} })
        }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this - {confirmDialog?.record?.name}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() =>
              setConfirmDialog({ open: !confirmDialog.open, record: {} })
            }
          >
            Cancel
          </Button>
          <Button onClick={() => onRemoveRecord()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Attribute;
