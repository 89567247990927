import { db } from "../../firebase/firebase";
import {
  GET_ALL_PRODUCTS,
  GET_ALL_USERS,
  GET_ALL_CATEGORIES,
  GET_ALL_VENDOR,
  HIDE,
  SET_VENDOR,
  SELETECTED_PRODUCT,
  GET_ALL_ORDERS,
  GET_ALL_SHORTS,
  GET_USER,
  SHOW_LOADING,
  HIDE_LOADING,
  GET_ALL_LIVE_STREAMS,
  GET_ALL_LIVE_SCHEDULES,
  UPDATE_USER,
  DELETE_FEED,
  DELETE_FEED_LIVE,
  DELETE_FEED_SCHEDULE,
  GET_ALL_LIKES_DISLIKES,
  ADD_LIKES,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_HISTORY,
  USER_HIS,
  FEED_BACK_REPORT,
  GET_USER_MESSAGE,
  GET_SINGLE_USER,
  ADD_MSG,
  RESOLVE,
  ADDCAT,
  DELETECAT,
  EDITCAT,
  BANNERS,
  getAllSupportChat,
  GET_ATTRIBUTES,
  SET_ATT,
  ALL_CUSTOMER_SERVICE,
  ALL_ADMINS,
  GET_EVENTS,
  REDEEM,
  AdminUser,
  GET_REDEEM,
  GET_PENDING,
  GET_VENDOR_PRODUCTS,
  GET_VENDOR_PRODUCTS_PENDING,
  LOGOUT,
  ADMIN_ENABLE,
  SER_ENABLE,
  DELETE_PRODUCT,
  GETCATEGORYBYID,
  TABLE_MENU_ACTION,
  SET_COMPLETED_PROFILE,
} from "./actionTypes";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { GiTrumpet } from "react-icons/gi";

export const getAllProducts = () => async (dispatch) => {
  dispatch(showLoading());
  const products = collection(db, "Products");
  const newP = await getDocs(products);

  const productList = newP.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_PRODUCTS,
    payload: productList,
  });
  dispatch(hideLoading());
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_PRODUCT,
    payload: id,
  });
};
export const getAllProductsVendor = (id) => async (dispatch) => {
  let vendorP = [];
  const citiesRef = collection(db, "Products");
  const q = query(citiesRef, where("productByUid", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    vendorP.push(doc.data());
  });

  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_VENDOR_PRODUCTS,
    payload: vendorP,
  });
};

export const getAllProductsVendorPending = (id) => async (dispatch) => {
  let vendorP = [];
  const citiesRef = collection(db, "VendorPending");
  const q = query(citiesRef, where("productByUid", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    vendorP.push(doc.data());
  });

  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_VENDOR_PRODUCTS_PENDING,
    payload: vendorP,
  });
};

export const getAllPending = () => async (dispatch) => {
  const products = collection(db, "VendorPending");
  const newP = await getDocs(products);

  const productList = newP.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_PENDING,
    payload: productList,
  });
};
export const getAllRedemm = () => async (dispatch) => {
  const products = collection(db, "RedeemProducts");
  const newP = await getDocs(products);

  const productList = newP.docs.map((doc) => doc.data());
  console.log(productList, "GGGGGGGG");
  dispatch({
    type: REDEEM,
    payload: productList,
  });
};

export const getAllAttributes = () => async (dispatch) => {
  dispatch(showLoading());
  const products = collection(db, "ProductAttributes");
  const newP = await getDocs(products);
  const productList = newP.docs.map((doc) => doc.data());
  dispatch({
    type: GET_ATTRIBUTES,
    payload: productList,
  });
  dispatch(hideLoading());
};
export const getAllSupportUser = () => async (dispatch) => {
  let users = [];
  const products = collection(db, "CustomerSupportChat");
  const newP = await getDocs(products);
  const allUsers = collection(db, "SimpleUsers");

  const productList = newP.docs.map((doc) => {
    return {
      messages: doc.data().messages,
      userId: doc.id,
    };
  });

  productList.map(async (doc) => {
    const q2 = query(allUsers, where("userid", "==", doc.userId));
    const querySnapshot2 = await getDocs(q2);
    let userData = querySnapshot2.docs.map((doc) => doc.data())[0];
    users.push({
      user: userData,
      messages: doc.messages,
    });
  });

  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: getAllSupportChat,
    payload: users,
  });
};

export const getAllBanners = () => async (dispatch) => {
  const hava1 = doc(db, "Carousels", "Havause1");
  const hava2 = doc(db, "Carousels", "Havause2");
  const hava3 = doc(db, "Carousels", "HavauseSwiper");
  const plus1 = doc(db, "Carousels", "HavaPlus1");
  const plus2 = doc(db, "Carousels", "Havaplus2");
  const bemo1 = doc(db, "Carousels", "HavaBemo1");
  const bemo2 = doc(db, "Carousels", "HavaBemo2");

  const hava11 = await getDoc(hava1);
  const hava22 = await getDoc(hava2);
  const hava33 = await getDoc(hava3);
  const plus11 = await getDoc(plus1);
  const plus22 = await getDoc(plus2);
  const b1 = await getDoc(bemo1);
  const b2 = await getDoc(bemo2);

  let havazuse1 = hava11.data().Images;
  let havazuse2 = hava22.data().Images;
  let havazuse3 = hava33.data().Images;
  let bemoo1 = b1.data().Images;
  let bemoo2 = b2.data().Images;
  let pluss1 = plus11.data().Images;
  let pluss2 = plus22.data().Images;

  let document = {
    hava1: havazuse1,
    hava2: havazuse2,
    hava3: havazuse3,
    bemo1: bemoo1,
    bemo2: bemoo2,
    plus1: pluss1,
    plus2: pluss2,
  };
  dispatch({
    type: BANNERS,
    payload: document,
  });
};
export const addProduct = (product) => async (dispatch) => {
  dispatch({
    type: ADD_PRODUCT,
    payload: product,
  });
};
export const updateProduct = (product) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT,
    payload: product,
  });
};
export const setAttribute = (product) => async (dispatch) => {
  dispatch({
    type: SET_ATT,
    payload: product,
  });
};
export const getAllUsers = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "SimpleUsers");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_USERS,
    payload: UsersList,
  });
  dispatch(hideLoading());
};

export const getAllCategories = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "Categories");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());

  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_CATEGORIES,
    payload: UsersList,
  });
  dispatch(hideLoading());
};

export const getUserMessages = (userId) => async (dispatch) => {
  const users = doc(db, "CustomerSupportChat", userId);
  const newUsers = await getDoc(users);

  // const querySnapshot = await getDocs(q);
  // let newData = querySnapshot.docs.map((doc) => doc.data());
  dispatch({
    type: GET_USER_MESSAGE,
    payload: newUsers.data() === undefined ? [] : newUsers.data().messages,
  });
};
export const getSingleUSer = (userId) => async (dispatch) => {
  const users = doc(db, "SimpleUsers", userId);
  const newUsers = await getDoc(users);

  // const querySnapshot = await getDocs(q);
  // let newData = querySnapshot.docs.map((doc) => doc.data());
  dispatch({
    type: GET_SINGLE_USER,
    payload: newUsers.data(),
  });
};
export const addMessage = (messsage) => async (dispatch) => {
  dispatch({
    type: ADD_MSG,
    payload: messsage,
  });
};
export const addCategory = (cat) => async (dispatch) => {
  dispatch({
    type: ADDCAT,
    payload: cat,
  });
};
export const getCategoryById = (catId, locationState) => async (dispatch) => {
  let newUsers = null;
  if (catId) {
    try {
      const users = doc(db, "Categories", `${catId}`);
      newUsers = await getDoc(users);
      newUsers = newUsers?.data() || {};
      newUsers.isEdit = locationState?.isEdit || false;
      newUsers.isAddMore = locationState?.isAddMore || false;
    } catch (e) {
      console.log("error", e);
    }
  }
  dispatch({
    type: GETCATEGORYBYID,
    payload: newUsers ? newUsers : {},
  });
};
export const removeCategory = (cat) => async (dispatch) => {
  dispatch({
    type: DELETECAT,
    payload: cat,
  });
};
export const updateCategory = (cat, categories) => async (dispatch) => {
  const index = categories?.findIndex(
    (category) => category.Catid == cat?.Catid?.toString()
  );
  if (index >= 0) categories[index] = cat;
  dispatch({
    type: EDITCAT,
    payload: categories,
  });
};

export const adminUser = (admin) => async (dispatch) => {
  dispatch({
    type: AdminUser,
    payload: admin,
  });
};

export const resolve = (messsage) => async (dispatch) => {
  dispatch({
    type: RESOLVE,
    payload: messsage,
  });
};

export const getAllFeedbackReports = () => async (dispatch) => {
  let userDetails = [];

  const products = collection(db, "Feeds");
  const allUsers = collection(db, "SimpleUsers");

  const users = collection(db, "FeedReports");
  const newUsers = await getDocs(users);

  newUsers.docs.map(async (doc) => {
    const q = query(products, where("videoid", "==", doc.data().feedid));
    const q2 = query(allUsers, where("userid", "==", doc.data().userid));

    const querySnapshot = await getDocs(q);
    const querySnapshot2 = await getDocs(q2);
    let userData = querySnapshot2.docs.map((doc) => doc.data());

    let newData = querySnapshot.docs.map((doc) => doc.data());
    userDetails.push({
      feedDetails: newData[0],
      userDetails: userData[0],
      reason: doc.data().reason,
      details: doc.data().details,
      reportid: doc.data().reportid,
      resolved: doc.data().resolved ? doc.data().resolved : false,
    });
    dispatch({
      type: FEED_BACK_REPORT,
      payload: userDetails,
    });
  });

  // const userData = await getDocs(
  //   query(users, where("userid", "==", userid))
  // );
};

export const getAllVendors = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "VendorUsers");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_VENDOR,
    payload: UsersList,
  });
  dispatch(hideLoading());
};
export const getAllAdmins = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "AdminUsers");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: ALL_ADMINS,
    payload: UsersList,
  });
  dispatch(hideLoading());
};
export const getAllCustomerService = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "CustomerService");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: ALL_CUSTOMER_SERVICE,
    payload: UsersList,
  });
  dispatch(hideLoading());
};
export const getAllevents = () => async (dispatch) => {
  const docRef = doc(db, "Events", "allevents");
  const docSnap = await getDoc(docRef);
  console.log(docSnap.data(), "HHHHHH");
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_EVENTS,
    payload: docSnap.data().events,
  });
};

export const updateUser = (userid, data) => async (dispatch) => {
  const users = doc(db, "SimpleUser", "userid");
  await updateDoc(users, data);

  // const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: UPDATE_USER,
    payload: "UsersList",
  });
};

export const getAllOrders = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "Orders");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_ORDERS,
    payload: UsersList,
  });
  dispatch(hideLoading());
};
export const getAllShorts = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "Feeds");
  const newUsers = await getDocs(users);

  const UsersList = newUsers?.docs?.map((doc) => doc.data());
  //   console.log(cityList, "GGGGGGGG");
  dispatch({
    type: GET_ALL_SHORTS,
    payload: UsersList,
  });
  dispatch(hideLoading());
};

export const getAllHistory = () => async (dispatch) => {
  const users = collection(db, "Histroy");
  const products = collection(db, "Products");
  const newUsers = await getDocs(users);
  let userDetails;
  newUsers.docs.map(async (doc) => {
    userDetails = await getDocs(
      query(products, where("Pid", "==", doc.data().Pid))
    );
  });

  // const produts = userDetails.docs.map((doc) => doc.data());
  console.log(userDetails, "KKKKKKKK");

  dispatch({
    type: GET_PRODUCT_HISTORY,
    payload: "UsersList",
  });
};

export const RemoveFeed = (id) => async (dispatch) => {
  await deleteDoc(doc(db, "Feeds", id));
  dispatch({
    type: DELETE_FEED,
    payload: id,
  });
};

export const RemoveFeedLive = (id) => async (dispatch) => {
  await deleteDoc(doc(db, "LiveStream", id));

  dispatch({
    type: DELETE_FEED_LIVE,
    payload: id,
  });
};
export const RemoveFeedSchedule = (id) => async (dispatch) => {
  await deleteDoc(doc(db, "LiveStream", id));
  dispatch({
    type: DELETE_FEED_SCHEDULE,
    payload: id,
  });
};
export const addLikesDisLikes =
  (data, userid, videoId, likesDislikes, videoList, collectionType) =>
  async (dispatch) => {
    try {
      let countLikes = 0;
      let countDisLikes = 0;
      let type = { collectionType: "Feeds", history: "VideosHistory" };
      let likesDisLikesList = likesDislikes;
      let videoDetails = videoList;
      // const likesDislikesCollection = collection(db, "LikesDislikes");
      if (collectionType === "short") {
        type = {
          collectionType: "Feeds",
          history: "VideosHistory",
          constType: GET_ALL_SHORTS,
        };
      } else {
        type = {
          collectionType: "LiveStream",
          history: "LiveStreamHistory",
          constType: GET_ALL_LIVE_STREAMS,
        };
      }

      const q = query(
        collection(db, "LikesDislikes"),
        where("userId", "==", `${userid}`),
        where("VideoId", "==", `${videoId}`)
      );

      const userFeeds = await getDocs(q);
      const existUsers = userFeeds.docs.map((doc) => doc.data());
      console.log(
        "userFeeds",
        userFeeds.docs.map((doc) => doc.data())
      );
      const likes = await getDocs(
        query(
          collection(db, "LikesDislikes"),
          where("VideoId", "==", `${videoId}`),
          where("Like", "==", true)
        )
      );
      let totalLikes = likes.docs.map((doc) => doc.data())?.length;
      const disLikes = await getDocs(
        query(
          collection(db, "LikesDislikes"),
          where("VideoId", "==", `${videoId}`),
          where("Dislike", "==", true)
        )
      );
      let totalDisLikes = disLikes.docs.map((doc) => doc.data())?.length;
      if (data.Like) {
        countLikes = totalLikes + 1;
        countDisLikes = totalDisLikes;
      } else {
        countLikes = totalLikes;
        countDisLikes = totalDisLikes + 1;
      }
      const userIndex = videoList?.findIndex(
        (user) => user.videoid === videoId
      );
      if (userIndex >= 0) {
        videoDetails[userIndex]["likes"] = countLikes;
        videoDetails[userIndex]["dislikes"] = countDisLikes;
      }
      if (existUsers.length) {
        // const pRef = doc(db, "LikesDislikes", `${userid}`);
        // update LikesDislikes collection
        await updateDoc(
          doc(db, "LikesDislikes", `${existUsers && existUsers[0]?.userId}`),
          data
        );
        const index = likesDislikes?.findIndex(
          (user) => user.userId === userid && user.VideoId === videoId
        );
        likesDislikes[index] = data;
      } else {
        // add LikesDislikes collection
        await setDoc(doc(db, "LikesDislikes", `${userid}`), data);
        likesDisLikesList.push(data);
      }
      // update video collections
      const record = { dislikes: countDisLikes, likes: countLikes };
      updateDoc(doc(db, type.collectionType, `${videoId}`), record);
      // updateDoc(doc(db, type.history, `${videoId}`), record)
      // .then((res) => {
      //   debugger
      // }).catch(e => {
      //   console.log("e", e);
      //   debugger
      // })

      // let respo = await setDoc(dbRef, { dislikes: countDisLikes, likes: countLikes }, { merge: true });

      // let respo = await dbRef.update({ dislikes: countDisLikes, likes: countLikes });
      // debugger

      // await updateDoc(doc(db, type.history, `${videoId}`), { dislikes: countDisLikes, likes: countLikes });
      dispatch({
        type:
          collectionType === "short"
            ? GET_ALL_SHORTS
            : collectionType === "live"
            ? GET_ALL_LIVE_STREAMS
            : GET_ALL_LIVE_SCHEDULES,
        payload: videoDetails,
      });
      dispatch({
        type: GET_ALL_LIKES_DISLIKES,
        payload: likesDisLikesList,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

export const getLikesDislikes = () => async (dispatch) => {
  dispatch(showLoading());
  const likesDislikes = collection(db, "LikesDislikes");
  const newLikesDislikes = await getDocs(likesDislikes);

  const LikesDislikesList = newLikesDislikes.docs.map((doc) => doc.data());

  dispatch({
    type: GET_ALL_LIKES_DISLIKES,
    payload: LikesDislikesList,
  });
  dispatch(hideLoading());
};
export const getAllLive = () => async (dispatch) => {
  dispatch(showLoading());
  const users = collection(db, "LiveStream");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  console.log(UsersList, "UsersList");
  dispatch({
    type: GET_ALL_LIVE_STREAMS,
    payload: UsersList,
  });
  dispatch(hideLoading());
};

export const getAllLiveSchedules = () => async (dispatch) => {
  const users = collection(db, "LiveStream");
  const newUsers = await getDocs(users);

  const UsersList = newUsers.docs.map((doc) => doc.data());
  console.log(UsersList, "GGGGGGGG");

  let schedules = [];
  UsersList.map((item) => {
    if (item.LiveStreamStartTime) {
      schedules.push(item);
    }
  });
  dispatch({
    type: GET_ALL_LIVE_SCHEDULES,
    payload: schedules,
  });
};
export const hide = (value) => async (dispatch) => {
  dispatch({
    type: HIDE,
    payload: value,
  });
};
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
export const setProfileComplete = (value) => async (dispatch) => {
  dispatch({
    type: SET_COMPLETED_PROFILE,
    payload: value,
  });
};
export const setVendor = (value) => async (dispatch) => {
  dispatch({
    type: SET_VENDOR,
    payload: value,
  });
};

export const setAdmin = (value) => async (dispatch) => {
  dispatch({
    type: ADMIN_ENABLE,
    payload: value,
  });
};
export const setCustomer = (value) => async (dispatch) => {
  dispatch({
    type: SER_ENABLE,
    payload: value,
  });
};
export const selectProduct = (product) => async (dispatch) => {
  dispatch({
    type: SELETECTED_PRODUCT,
    payload: product,
  });
};
export const showLoading = () => async (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
};
export const hideLoading = () => async (dispatch) => {
  dispatch({
    type: HIDE_LOADING,
  });
};
export const userProductHistory = (userid) => async (dispatch) => {
  const prod = collection(db, "Products");
  const pHistory = collection(db, "Histroy");
  const userHistory = await getDocs(
    query(pHistory, where("UserId", "==", userid))
  );
  const usersHistory = userHistory.docs.map((doc) => doc.data().Pid);

  const q = query(prod, where("Pid", "in", usersHistory));
  const querySnapshot = await getDocs(q);
  let newData = querySnapshot.docs.map((doc) => doc.data());
  console.log(newData, "HRERERER");

  dispatch({
    type: USER_HIS,
    payload: newData,
  });
};

export const getUserByVideoHistory = async (userId, collectionName) => {
  const videoHistory = collection(db, collectionName);
  const history = await getDocs(
    query(videoHistory, where("userid", "==", userId))
  );
  const userVideoHistory = history.docs.map((doc) => doc.data());
  return userVideoHistory;
};

export const getUser = (userid) => async (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
  const users = collection(db, "SimpleUsers");
  const feeds = collection(db, "Feeds");
  const orders = collection(db, "Orders");
  const localProducts = (db, "localProducts");
  const wallet = collection(db, "Wallets");
  const wishlist = collection(db, "Wishlist");
  const videoHistory = collection(db, "VideosHistory");
  const subs = collection(db, "Subscriptions");
  const pHistory = collection(db, "Histroy");

  // const userlocalProducts = await getDocs(
  //   query(users, where("userid", "==", userid))UsersList
  // );
  const userDetails = await getDocs(
    query(users, where("userid", "==", userid))
  );

  const userProductHistory = await getDocs(
    query(users, where("UserId", "==", userid))
  );

  const productUserHistory = await getDocs(
    query(pHistory, where("UserId", "==", userid))
  );
  const userFeeds = await getDocs(query(feeds, where("userid", "==", userid)));
  const wal = await getDocs(query(feeds, where("Userid", "==", userid)));
  const wish = await getDocs(query(feeds, where("UserId", "==", userid)));
  const subscriptions = await getDocs(
    query(feeds, where("userId", "==", userid))
  );
  const history = await getDocs(
    query(videoHistory, where("userid", "==", userid))
  );

  const userOrders = await getDocs(
    query(orders, where("UserId", "==", userid))
  );
  const uOrders = userOrders.docs.map((doc) => doc.data());
  const userHistory = productUserHistory.docs.map((doc) => doc.data());
  const uDetails = userDetails.docs.map((doc) => doc.data());
  const uFeeds = userFeeds.docs.map((doc) => doc.data());
  const uWallet = wal.docs.map((doc) => doc.data());
  const uWish = wish.docs.map((doc) => doc.data());
  const userVideoHistory = history.docs.map((doc) => doc.data());
  const userSubscription = subscriptions.docs.map((doc) => doc.data());
  console.log(userHistory, "LLLLLL");
  let userObject = {
    userDetails: uDetails[0],
    userFeeds: uFeeds,
    userWallet: uWallet[0],
    userOrders: uOrders,
    userVideoHistory: userVideoHistory,
    userSubscription: userSubscription,
  };

  dispatch({
    type: GET_USER,
    payload: userObject,
  });
  dispatch({
    type: HIDE_LOADING,
  });
};

export const tableMenuIcon = (payload) => async (dispatch) => {
  dispatch({
    type: TABLE_MENU_ACTION,
    payload,
  });
};
