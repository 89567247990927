import React, { PropTypes, Component } from "react";

const Content = () => {
  const handleClick = (e) => {
    console.log("Hellooww world");
  };

  return (
    <div className="product-upload-btn2">
      <div className="add-media" onClick={handleClick}>
        <i className="plus icon"></i>
        <input type="file" id="file" style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default Content;
