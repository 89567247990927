import React from "react";
import "./TrackPop.css";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Timeline from "../Timeline/Timeline";
import DelPop from "../DelPop/DelPop";
import Location from "../Location/Location";
import { GrClose } from "react-icons/gr";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function TrackPop({ orderEvent, setOrderEvent }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setOrderEvent(!orderEvent);
  };

  // const open = Boolean(anchorEl);
  // const id = open ? "track-pop" : undefined;

  return (
    <div>
      <button
        className="print-btn2"
        // aria-describedby={id}
        variant="contained"
        color="primary"
        // onClick={handleClick}
      >
        Track Order
      </button>
      <Popover
        id={"track-pop"}
        open={orderEvent?.trackOrder}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <GrClose className="close-pop" onClick={handleClose} />
        <Typography className={classes.typography}>
          <p className="track-order">Order Details</p>
          <p className="track-code">
            Your Order Code:
            <span style={{ color: "rgba(61, 193, 98, 1)" }}>#123456</span>
          </p>

          <div className="g-map">
            <Location />
          </div>
          <div className="order-item">
            <p className="item-count">1 ITEM</p>
          </div>
          <div className="order-item2">
            <p className="item-count">$20</p>
          </div>

          {/* <DelPop /> */}
          {/* <button className="del-status">Delivery Status</button> */}
        </Typography>
        <div>
          <Timeline />
        </div>
      </Popover>
    </div>
  );
}
