import React from "react";
import AddImage from "../AddImage/AddImage";
import "./VendorInfo.css";
import { Form } from 'react-bootstrap';
import card from "../../Assets/Images/card.jpg";



function VendorInfo() {
    return (
        <>
        <div className="create-coupon">
            <h3>Registered Vendors</h3>
        </div>

<div className="vend-forms">
        <div className="coupon-select">
                <h5>First Name</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="John"/>
            </div>  

            <div className="coupon-select">
                <h5>Last Name</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="Doe"/>
            </div>  

            <div className="coupon-select-email">
                <h5>Email</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="johb@abc.com"/>
            </div> 

            <div className="coupon-select-business">
                <h5>Businesss Name</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="Tech Wolf"/>
            </div>  

            <div className="coupon-select-password">
                <h5>Password</h5>
                <input className="coupon-code vendor-1" type="password" placeholder="123456789"/>
            </div> 

            <div className="coupon-select-country">
                <h5>Country</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="USA"/>
            </div> 

            <div className="coupon-select-city">
                <h5>City</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="New York"/>
            </div> 

            <div className="coupon-select-street">
                <h5>Street Address</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="H#6, St#11, Avenue"/>
            </div>  

            <div className="coupon-select-post">
                <h5>Postal Code</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="46000"/>
            </div>  

            <div className="coupon-select-phone">
                <h5>Phone Number</h5>
                <input className="coupon-code vendor-1" type="text" placeholder="+000111222"/>
            </div> 

             <div className="coupon-select-card">
                <h5>ID Card</h5>
                <AddImage className="add-id"/>
                <div className="id-box">
                <img src={card} className="card-image" alt="logo" />
                </div>
            </div> 

              <div className="coupon-select-choice">
                <h5>Your Choice</h5>
                <textarea className="coupon-area vendor-1" type="text" placeholder="Tell Us What You Want To Do"/>
            </div> 

            <div className="hava-versions">
                <Form.Group className="mb-3 auto-coupon" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Havazuse" />
                </Form.Group>

                <Form.Group className="mb-3 auto-coupon" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Havazuse PLUS" />
                </Form.Group>

                <Form.Group className="mb-3 auto-coupon" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Havazuse BEMO" />
                </Form.Group>
            </div>

            {/* <button className="reg-vendor">Update</button> */}

            <ul className="vendor-btns">
               <li><button className="approve">Approve</button></li>
               <li><button className="deny">Deny</button></li>
               <li><button className="need">Info Needed</button></li>
           </ul>
 </div>

        </>  
    );
}

export default VendorInfo;