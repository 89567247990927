import React, { useState } from "react";
import "./MonthlyItems.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";

import SaleHistory from "../SaleHistory/SaleHistory";
import { useSelector } from "react-redux";
import ItemGraph from "../ItemGraph/ItemGraph";
import { getAuth } from "firebase/auth";

function VendorMonthlyItems() {
  const user = useSelector((state) => state.Reducer.adminUser);
  const auth = getAuth();
  const suser = localStorage.getItem("AdminUser");

  const userCurrent = auth.currentUser;
  return (
    <>
      <div className="search-user">
        {/* <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." /> */}
      </div>

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img src={user} className="not-image-user" alt="logo" />
        <p className="not-image-text">{JSON.parse(suser).displayName}</p>
      </div>
      {/* <GiHamburgerMenu className="hamburger" /> */}

      <h3 className="revenue">Monthly Items Sold</h3>

      <div className="active-members-box">
        <h2 className="num-members">0</h2>
        <p className="mem-text">Items Sold</p>
      </div>

      <div className="active-members-box">
        <h2 className="num-members">0</h2>
        <p className="mem-text">In Transit</p>
      </div>

      <div className="active-members-box">
        <h2 className="num-members">0%</h2>
        <p className="mem-text">Return</p>
      </div>

      <div className="active-members-box">
        <h2 className="num-members">0</h2>
        <p className="mem-text">Damaged</p>
      </div>

      <div
        style={{ float: "left", width: "80%", marginTop: 20, marginLeft: 20 }}
      >
        <ItemGraph />
      </div>
    </>
  );
}

export default VendorMonthlyItems;
