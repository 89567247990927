import React, { useEffect } from "react";
import "./ProjectDash.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import user from "../../Assets/Images/user1.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsFilter } from "react-icons/bs";
import BarChart from "../BarChart/BarChart";
import PieChartNew from "../PieChartNew/PieChartNew";
import { BiNotepad } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import { PieChart } from "react-minimal-pie-chart";
import calender from "../../Assets/Images/calender.jpg";
import { useDispatch } from "react-redux";
import { setVendor } from "../../store/actions/actions";

function ProjectDash() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVendor(true));
  }, []);

  return (
    <>
      <div className="search-user">
        <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." />
      </div>

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img src={user} className="not-image-user" alt="logo" />
        <p className="not-image-text">John Doe</p>
      </div>
      <GiHamburgerMenu className="hamburger" />

      {/* <div className="dash-chart">
            + Add Chart
        </div>

        <div className="dash-border-bottom"></div> */}

      <div className="sale-section-new">
        <p className="gross-text">GROSS SALE IN THIS MONTH</p>
        <p className="gross-text2-dash">$68,980</p>
        <div className="growth-sale">
          <p>
            32%
            <MdArrowDropUp />
          </p>
        </div>

        <div className="growth-reduce">
          <p>
            32%
            <MdArrowDropDown />
          </p>
        </div>
      </div>

      <div className="profit-section-new">
        <p className="gross-text3">REVENUE</p>
        <p className="gross-text2-dash">$68,980</p>
        <div className="growth-sale">
          <p>
            32%
            <MdArrowDropUp />
          </p>
        </div>

        <div className="growth-reduce">
          <p>
            32%
            <MdArrowDropDown />
          </p>
        </div>
      </div>

      <div className="profit-section-new">
        <p className="gross-text3">PROFIT</p>
        <p className="gross-text2-dash">$68,980</p>
        <div className="growth-sale">
          <p>
            32%
            <MdArrowDropUp />
          </p>
        </div>

        <div className="growth-reduce">
          <p>
            32%
            <MdArrowDropDown />
          </p>
        </div>
      </div>

      <div className="profit-section-new">
        <p className="gross-text3">ITEMS</p>
        <p className="gross-text2-dash">5</p>
        <div className="growth-sale">
          <p>
            32%
            <MdArrowDropUp />
          </p>
        </div>

        <div className="growth-reduce">
          <p>
            32%
            <MdArrowDropDown />
          </p>
        </div>
      </div>

      <div className="profit-section-new">
        <p className="gross-text3">ORDERS</p>
        <p className="gross-text2-dash">10</p>
        <div className="growth-sale">
          <p>
            32%
            <MdArrowDropUp />
          </p>
        </div>

        <div className="growth-reduce">
          <p>
            32%
            <MdArrowDropDown />
          </p>
        </div>
      </div>

      {/* <div className="dashboard-att-new">
            <div className="dash-icon3">
                <BiNotepad className="dollar-icon"/>
            </div>
            <div className="dash-amount2">
                <p className="money"> 5 Items</p>
                <p className="money-detail">Sold in this month</p>
            </div>
        </div>

        <div className="dashboard-att-new">
            <div className="dash-icon4">
                <FaShoppingCart className="dollar-icon"/>
            </div>
            <div className="dash-amount2">
                <p className="money">10 orders</p>
                <p className="money-detail">Received in this month</p>
            </div>
        </div> */}

      <div className="dash-chart1">
        <h6 className="dash-tasks">Completed Tasks</h6>
        <p className="dash-filter">
          <BsFilter /> 1 Filter
        </p>
        <h4 className="dash-number-tasks">100</h4>
        <p className="dash-count">Task count</p>
      </div>

      <div className="dash-chart2">
        <h6 className="dash-tasks">Incomplete Tasks</h6>
        <p className="dash-filter">
          <BsFilter /> 1 Filter
        </p>
        <h4 className="dash-number-tasks">18</h4>
        <p className="dash-count">Task count</p>
      </div>

      <div className="dash-chart2">
        <h6 className="dash-tasks">Overdue Tasks</h6>
        <p className="dash-filter">
          <BsFilter /> 1 Filter
        </p>
        <h4 className="dash-number-tasks">4</h4>
        <p className="dash-count">Task count</p>
      </div>

      <div className="dash-chart2">
        <h6 className="dash-tasks">Total Tasks</h6>
        {/* <p className="dash-filter"><BsFilter/> 1 Filter</p> */}
        <h4 className="dash-number-tasks2">118</h4>
        <p className="dash-count">Task count</p>
      </div>

      <div className="piechart-result-section-new">
        <div className="cost-break">
          <p className="cost-heading">COST BREAKDOWN</p>
          <h5 className="mark-text-new">MARKETING</h5>
          <h5 className="mark-numbers">$45,897</h5>
          <h5 className="mark-text2-new">SALES</h5>
          <h5 className="mark-numbers">$98,450</h5>
        </div>
        <div className="pie-chart-section-new">
          <PieChart
            className="piechart-result"
            data={[
              { title: "One", value: 12, color: "#3FAD72" },
              { title: "Two", value: 67, color: "blue" },
            ]}
          />
        </div>
      </div>

      <div className="cross-section-new">
        <p className="cost-heading">UP/CROSS SELL</p>
        <p className="cross-revenue-dash">REVENUE</p>
        <p className="cross-amount">$12,987</p>

        <p className="cross-revenue-dash">% OF REVENUE</p>
        <p className="cross-amount2">34%</p>

        <div className="cross-border"></div>

        <p className="cost-heading2">CHURN</p>

        <p className="cross-revenue2">TOTAL</p>
        <p className="cross-amount3-dash">26</p>

        <div className="cross-border"></div>

        <p className="cross-revenue2">RATE</p>
        <p className="cross-amount3-dash">3%</p>

        <div className="cross-border"></div>

        <p className="cross-revenue2">REVEUE</p>
        <p className="cross-amount3-dash">$34,890</p>
      </div>

      <div className="barchart-section">
        {/* <h6 className="dash-tasks">Incomplete task by section</h6>
            <p className="dash-filter"><BsFilter/> 2 Filters</p> */}
        <BarChart className="barchart-dash" />
      </div>

      <div className="piechart-section">
        <h6 className="dash-tasks">All tasks by completion status</h6>
        <p className="dash-filter2">
          <BsFilter /> 1 Filter
        </p>
        <PieChartNew />
      </div>

      <div className="calender-section">
        <img className="calender-image" src={calender} alt="logo" />
      </div>
    </>
  );
}

export default ProjectDash;
