import React, { useEffect } from "react";
import "./AllVendor.css";
import user from "../../Assets/Images/user1.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllVendors } from "../../store/actions/actions";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

function AllVendor() {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.Reducer.vendors);
  const suser = localStorage.getItem("AdminUser");

  useEffect(() => {
    dispatch(getAllVendors());
  }, []);
  return (
    <>
      <div className="alluser-header">
        <div className="search-user">
          {/* <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." /> */}
        </div>

        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div>
        <div className="not-image">
          <img src={user} className="not-image-user" alt="logo" />
          <p className="not-image-text">{JSON.parse(suser).displayName}</p>
        </div>
      </div>
      <div className="vendor-heading">
        <h3 className="user-text">Vendors</h3>
      </div>
      <div className="allvendor-section">
        <div className="search-user-account">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>
        <h5 className="vendors active">Vendors</h5>
        {vendors.map((item) => (
          <div className="left">
            <img src={user} className="vendor-image" alt="logo" />
            <p className="vendor-name">{item.storeName}</p>
            <p className="vendor-email">john@doe.com</p>
            <div className="vendor-detail">
              <Link to="regvendors">
                <button className="vendor-button4">View Application</button>
              </Link>

              <button className="vendor-button">Approve</button>

              <button className="vendor-button3">Info Needed</button>

              <button className="vendor-button2">Deny</button>

              {/* <BsThreeDotsVertical className="more" /> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AllVendor;
