import React, { useState, useEffect, useRef } from "react";
import AddImage from "../AddImage/AddImage";
import "./AddUser.css";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import card from "../../Assets/Images/card.jpg";
import {
  doc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { database, db } from "../../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { ReactComponent as UploadIcon } from "../../Assets/Images/file_upload.svg";
import generator from "generate-password";
import CustomDropdown from "../Dropdown";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Toaster } from "../../utils/constant";
import {
  ref,
  uploadString,
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import { sendMailService } from "../../utils/sendEmailServices";

const userInitValue = {
  FirstName: "",
  LastName: "",
  email: "",
  role: "",
  profilePicture: null,
  isSendEmail: false,
};

function AddUser() {
  const history = useHistory();
  const [username, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailEnable, setEmailEnable] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const roleRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!roleRef?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [roleRef]);
  const schema = yup.object().shape({
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    email: yup.string().email("Please enter a valid email").required(),
    role: yup.string().required(),
  });

  const userForm = useFormik({
    initialValues: userInitValue,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const password2 = generator.generate({
        length: 7,
        numbers: true,
      });
      values.password = password2;
      if (values.isSendEmail) {
        const raw = JSON.stringify({
          data: {
            email: values?.email,
            name: "Admin",
            message:
              values?.role === "Admin"
                ? `Please Login on this link  https://sandstrip.com/#/Login/ using this Username :${values?.email}, Password : ${password2}`
                : values?.role === "Vendor"
                ? `Please Login on this link  https://sandstrip.com/#/vendorLogin/ using this Username :${values?.email}, Password : ${password2}`
                : values?.role === "Customer Service"
                ? `Please Login on this link  https://sandstrip.com/#/customerServiceLogin/ using this Username :${values?.email}, Password : ${password2}`
                : "",
          },
        });
        await sendMailService(raw)
          .then((response) => response.text())
          .then(async () => {
            await addUsertofirestore(values, resetForm);
          });
      } else {
        await addUsertofirestore(values, resetForm);
      }
    },
  });

  // useEffect(() => {
  //   let newArray = [];
  //   countries.map((item) => {
  //     newArray.push({
  //       label: item.name,
  //       value: item.name,
  //     });
  //   });
  //   setCountries(newArray);
  // }, []);

  const addUsertofirestore = async (userDetails, resetForm) => {
    setLoading(true);
    let userid = Math.floor(Math.random() * 100000000);
    const pRef = doc(
      db,
      userDetails?.role === "Admin"
        ? "AdminUsers"
        : userDetails?.role === "Vendor"
        ? "VendorUsers"
        : userDetails?.role === "Customer Service"
        ? "CustomerService"
        : "",
      `${userid}`
    );
    const users = collection(
      db,
      userDetails?.role === "Admin"
        ? "AdminUsers"
        : userDetails?.role === "Vendor"
        ? "VendorUsers"
        : userDetails?.role === "Customer Service"
        ? "CustomerService"
        : "SimpleUsers"
    );
    const userRecords = await getDocs(
      query(users, where("email", "==", userDetails.email))
    );
    if (userRecords?.docs?.map((doc) => doc.data())?.length) {
      setLoading(false);
      Toaster({
        type: "error",
        message: "Already use this email",
      });
      return;
    }
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/Havause/profileImage/${userDetails?.profilePicture?.name}`
    );
    const uploadTask = uploadBytesResumable(
      storageRef,
      userDetails?.profilePicture
    );
    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        // Handle unsuccessful uploads
        console.log("error:-", error);
        setLoading(false);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            let data = {
              ...userDetails,
              profilePicture: downloadURL,
              userid,
            };
            await setDoc(pRef, data)
              .then(() => {
                Toaster({
                  type: "success",
                  message: "User Created Successfully!",
                });
                resetForm(userInitValue);
                history.push("/employee");
              })
              .catch(() => {
                Toaster({
                  type: "error",
                  message: "Something went wrong!",
                });
              });
            setLoading(false);
          })
          .catch(() => {
            Toaster({
              type: "error",
              message: "Something went wrong!",
            });
            setLoading(false);
          });
      }
    );

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   data: {
    //     email: userDetails?.email,
    //     name: "Admin",
    //     message:
    //       userDetails?.role === "Admin"
    //         ? `Please Login on this link  https://sandstrip.com/#/Login/ using this Username :${userDetails?.email}, Password : ${password2}`
    //         : userDetails?.role === "Vendor"
    //         ? `Please Login on this link  https://sandstrip.com/#/vendorLogin/ using this Username :${userDetails?.email}, Password : ${password2}`
    //         : userDetails?.role === "Customer Service"
    //         ? `Please Login on this link  https://sandstrip.com/#/customerServiceLogin/ using this Username :${userDetails?.email}, Password : ${password2}`
    //         : "",
    //   },
    // });

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then(async (result) => {
    //     console.log(result);
    //     // setUsername("");
    //     // setEmail("");
    //     // setLast("");
    //     // setFirst("");
    //     // setPassword("");

    //     const pRef = doc(
    //       db,
    //       userDetails?.role === "Admin"
    //         ? "AdminUsers"
    //         : userDetails?.role === "Vendor"
    //         ? "VendorUsers"
    //         : userDetails?.role === "Customer Service"
    //         ? "CustomerService"
    //         : "",
    //       `${userid}`
    //     );
    //     // storage
    //     //   .ref("Havause/profileImage")
    //     //   .child(values.profilePicture)
    //     //   .getDownloadURL()
    //     //   .then(async (url) => {
    //     //     let data = {
    //     //       ...userDetails,
    //     //       password: password2,
    //     //       profilePicture: url,
    //     //       userid: userid,
    //     //     };
    //     //     await setDoc(pRef, data);
    //     //     Toaster({
    //     //       type: "success",
    //     //       message: "User Created Successfully!",
    //     //     });
    //     //   });
    //   })
    //   .catch(() => {
    //     Toaster({
    //       type: "error",
    //       message: "Something went wrong!",
    //     });
    //   });
  };

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
  } = userForm;
  return (
    // <>
    //   <div className="create-coupon">
    //     <h3>Add New User</h3>
    //   </div>

    //   <div className="vend-forms">
    //     {/* <div className="add-user-select">
    //       <h5>Username</h5>
    //       <input
    //         className="coupon-code vendor-1"
    //         type="text"
    //         placeholder="John88"
    //         onChange={(e) => setUsername(e.target.value)}
    //         value={username}
    //       />
    //     </div> */}

    //     <div className="coupon-select-new">
    //       <h5>Email</h5>
    //       <input
    //         className="coupon-code vendor-1"
    //         type="text"
    //         placeholder="john@abc.com"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //     </div>

    //     {/* <div className="coupon-select-email">
    //       <h5>First Name</h5>
    //       <input
    //         className="coupon-code vendor-1"
    //         type="text"
    //         placeholder="John"
    //         value={first}
    //         onChange={(e) => setFirst(e.target.value)}
    //       />
    //     </div> */}

    //     {/* <div className="coupon-select-business">
    //       <h5>Last Name</h5>
    //       <input
    //         className="coupon-code vendor-1"
    //         type="text"
    //         placeholder="Doe"
    //         value={last}
    //         onChange={(e) => setLast(e.target.value)}
    //       />
    //     </div> */}

    //     {/* <div className="coupon-select-password">
    //       <h5>Password</h5>
    //       <input
    //         className="coupon-code vendor-1"
    //         type="password"
    //         placeholder="123456789"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //     </div> */}

    //     <div style={{ right: 40 }} className="coupon-select-password">
    //       <h5>Role </h5>
    //       <select
    //         defaultValue={"None"}
    //         className="coupon-code vendor-1"
    //         style={{ height: 30 }}
    //         value={role}
    //         onChange={(e) => setRole(e.target.value)}
    //         placeholder="Select Role"

    //         //   onChange={(event) => setCat(event.target.value)}
    //       >
    //         {/* <option value={"User"}>User</option> */}
    //         <option value={""}>Select Role</option>

    //         <option value={"Admin"}>Admin</option>
    //         <option value={"Vendor"}>Vendor</option>
    //         {/* <option value={"Vendor Service"}>Vendor Service</option> */}
    //         <option value={"Customer Service"}>Customer Service</option>
    //       </select>
    //     </div>
    //     <div className="hava-versions">
    //       <Form.Group
    //         className="mb-3 auto-coupon check-responsive"
    //         controlId="formBasicCheckbox"
    //       >
    //         <Form.Check
    //           onChange={(e) => {
    //             console.log(e.target.value);
    //             setEmailEnable(e.target.value);
    //           }}
    //           value={emailEnable}
    //           type="checkbox"
    //           label="Send the new user an email about their account"
    //         />
    //       </Form.Group>
    //     </div>

    //     <button onClick={addUsertofirestore} className="reg-vendor">
    //       Add New User
    //     </button>
    //   </div>
    //   <ToastContainer />
    // </>

    <div className="add-category-section add-user">
      <BreadcrumbMenu
        path={[
          { title: "Users", navLink: "/employee" },
          { title: "Add User", navLink: "/employee/Add" },
        ]}
        activeTab={"Add User"}
      />

      <div className="row">
        <div className="col-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                First Name <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                value={values.FirstName}
                placeholder="Enter First Name"
                className="CatInput"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.FirstName && touched.FirstName}
              />
              <Form.Control.Feedback type="invalid">
                First name is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Last Name <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                value={values.LastName}
                placeholder="Enter Last Name"
                className="CatInput"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.LastName && touched.LastName}
              />
              <Form.Control.Feedback type="invalid">
                Last name is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Email <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={values.email}
                placeholder="Enter Email"
                className="CatInput"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.email && touched.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="cat-drop mb-3">
              <Form.Group className="mb-3" controlId="validationParentCategory">
                <Form.Label>
                  Role <span className="asterisk">*</span>
                </Form.Label>
                <CustomDropdown
                  option={["Admin", "Vendor", "Customer Service"]}
                  placeholder="Select Role"
                  width="100%"
                  borders={true}
                  fullArrow={true}
                  dropdownContentHeight={"auto !important"}
                  error={!!errors.role && touched.role}
                  name="role"
                  selectedValue={values.role}
                  onBlur={handleBlur}
                  handleChangeRowsPerPage={async (value) =>
                    userForm.setFieldValue("role", value)
                  }
                  searchBar={true}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  ref={roleRef}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={`${
                    !!errors.role && touched.role ? "d-block" : "d-none"
                  } mb-3`}
                >
                  Role is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="validationCategoryName">
                <Form.Label>Profile Image</Form.Label>
                <label className="CatInput upload-Label">
                  {values?.profilePicture ? (
                    <div className="Upload-name">
                      {values?.profilePicture?.name}
                    </div>
                  ) : (
                    "Upload Image"
                  )}
                  <UploadIcon className="upload-icon" />
                  <Form.Control
                    type="file"
                    name="profilePicture"
                    placeholder="Upload Image"
                    className="upload-file-new"
                    onChange={(event) =>
                      setFieldValue(
                        "profilePicture",
                        event?.currentTarget?.files[0]
                      )
                    }
                    // isInvalid={!!errors.profilePicture }
                  />
                </label>
                {/* <Form.Control.Feedback type="invalid">
                  Profile picture is required
                  </Form.Control.Feedback> */}
              </Form.Group>
            </div>
            <Form.Group className="my-3" controlId="userSendEmailCheckbox">
              <Form.Check
                type="checkbox"
                label="Send the new user an email about their account"
                className="add_user_check"
                name="isSendEmail"
                onChange={handleChange}
              />
            </Form.Group>
            <LoadingButton
              type="submit"
              loading={loading}
              className="add-new-category d-block add-user-btn"
            >
              Add User
            </LoadingButton>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
