import React, { useEffect } from "react";
import "./OrderSingle.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/product.png";
import { FaBox } from "react-icons/fa";
import order from "../../Assets/Images/order.jpg";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";
import { AiOutlineCreditCard } from "react-icons/ai";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { hide } from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";

function OrderSingle() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hide(false));
  }, []);
  return (
    <>
      <div className="search-user">
        <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." />
      </div>

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img src={user} className="not-image-user" alt="logo" />
        <p className="not-image-text">John Doe</p>
      </div>
      <GiHamburgerMenu className="hamburger" />

      <div className="order-single-info">
        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="order-checkout-section">
          <h6 className="guest-check">Guest Checkout</h6>
          <AiOutlineMail className="guest-email" />
          <p className="order-user-email">john@doe.com</p>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Shipping Information</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineUser className="guest-email" />
            <p className="order-user-email2">John Doe</p>
          </div>
          <BiMap className="guest-email" />
          <p className="order-user-email3">
            73245 Scarletwood San Jose, CA 91259
          </p>
          <div style={{ float: "left", width: "100%" }}>
            <FiPhone className="guest-email" />
            <p className="order-user-email">408-027-8153</p>
          </div>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Payment</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineCreditCard className="guest-email" />
            <p className="order-user-email2">1234 5678 9100 1122</p>
            <span className="card-expiry">12/22</span>
            <span className="cvv-code">123</span>
          </div>
          <AiOutlineCheckSquare className="guest-email" />
          <p className="order-user-email4">Use Shipping Address</p>
        </div>

        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub">Subtotal:</p>
          <p className="order-sub-amount">$500</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub2">Taxes:</p>
          <p className="order-sub-amount2">Free</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub3">Total price:</p>
          <p className="order-sub-amount3">$500</p>
        </div>
      </div>

      <div className="order-single-info">
        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="order-checkout-section">
          <h6 className="guest-check">Guest Checkout</h6>
          <AiOutlineMail className="guest-email" />
          <p className="order-user-email">john@doe.com</p>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Shipping Information</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineUser className="guest-email" />
            <p className="order-user-email2">John Doe</p>
          </div>
          <BiMap className="guest-email" />
          <p className="order-user-email3">
            73245 Scarletwood San Jose, CA 91259
          </p>
          <div style={{ float: "left", width: "100%" }}>
            <FiPhone className="guest-email" />
            <p className="order-user-email">408-027-8153</p>
          </div>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Payment</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineCreditCard className="guest-email" />
            <p className="order-user-email2">1234 5678 9100 1122</p>
            <span className="card-expiry">12/22</span>
            <span className="cvv-code">123</span>
          </div>
          <AiOutlineCheckSquare className="guest-email" />
          <p className="order-user-email4">Use Shipping Address</p>
        </div>

        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub">Subtotal:</p>
          <p className="order-sub-amount">$500</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub2">Taxes:</p>
          <p className="order-sub-amount2">Free</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub3">Total price:</p>
          <p className="order-sub-amount3">$500</p>
        </div>
      </div>

      <div className="order-single-info">
        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="product-order">
          <img src={order} className="" alt="logo" />
        </div>

        <div className="order-checkout-section">
          <h6 className="guest-check">Guest Checkout</h6>
          <AiOutlineMail className="guest-email" />
          <p className="order-user-email">john@doe.com</p>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Shipping Information</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineUser className="guest-email" />
            <p className="order-user-email2">John Doe</p>
          </div>
          <BiMap className="guest-email" />
          <p className="order-user-email3">
            73245 Scarletwood San Jose, CA 91259
          </p>
          <div style={{ float: "left", width: "100%" }}>
            <FiPhone className="guest-email" />
            <p className="order-user-email">408-027-8153</p>
          </div>
        </div>

        <div className="order-checkout-section2">
          <h6 className="guest-check">Payment</h6>
          <div style={{ float: "left", width: "100%" }}>
            <AiOutlineCreditCard className="guest-email" />
            <p className="order-user-email2">1234 5678 9100 1122</p>
            <span className="card-expiry">12/22</span>
            <span className="cvv-code">123</span>
          </div>
          <AiOutlineCheckSquare className="guest-email" />
          <p className="order-user-email4">Use Shipping Address</p>
        </div>

        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub">Subtotal:</p>
          <p className="order-sub-amount">$500</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub2">Taxes:</p>
          <p className="order-sub-amount2">Free</p>
        </div>
        <div style={{ float: "left", width: "100%" }}>
          <p className="order-sub3">Total price:</p>
          <p className="order-sub-amount3">$500</p>
        </div>
      </div>
    </>
  );
}

export default OrderSingle;
