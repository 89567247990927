import React from 'react';
import AddImage from '../AddImage/AddImage';
import './EditProduct.css';


function EditProduct() {
    return (
        <div className="edit-section">
        <div className="edit-heading">
        <h3>Edit Products</h3>
        </div>
        <div className="attention">
            <h6 className="attn-heading">Attention Please!</h6>
            <p className="warning">The operations make here will will affect all product variants</p>
        </div>
        <div className="add-image-section">
            <p className="add-image">Product Images</p>
            {/* <AddImage className="image1-upload"/>
            <AddImage/> 
            <AddImage/> */}
            <p className="add-name">Product Name</p>
            <input className="name-field" label="Name"/>

            <p className="add-name">Product Description</p>
            <textarea className="name-field2" label="Name"/>

        </div>
        <div className="add-image-section">
            <h5 className="price-heading">Price</h5>
            <p className="add-name">Purchase Price</p>
            <input className="name-field" label="Name"/>

            <div className="compare-prices">
            <p className="add-name">Selling Price</p>
            <input className="selling-field" label="Name"/>
            </div>

            <div className="compare-prices">
            <p className="add-name">Compare at Price</p>
            <input className="selling-field" label="Name"/>
            </div>
        </div>
        <button className="save-change-btn">Save Changes</button>
        </div>
    );
}

export default EditProduct;