import React from "react";
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { BsFilter } from "react-icons/bs";
import "./RevenueChart.css"
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
class RevenueChart extends React.Component {
  state = {
    dataBar: {
      labels: ["Maintenance", "Staff", "etc", "etc"],
      datasets: [
        {
          label: "Expenses",
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            "blue",
            "blue",
            "blue",
            "blue",
          ],
          borderWidth: 2,
          borderColor: [
            "blue",
            "blue",     
            "blue",            
            "blue",          
        ]
        }
      ]
    },
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    }
  }


  render() {
    return (
      <MDBContainer>
        <h3 className="mt-5">Expenses</h3>
        <p className="dash-filter-expense">Per Month</p>
        <Bar className="dash-barchart" data={this.state.dataBar} options={this.state.barChartOptions} />
      </MDBContainer>
    );
  }
}

export default RevenueChart;