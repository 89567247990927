import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BannerImage } from "../ProductImage/BannerImages";
import AliceCarousel from "react-alice-carousel";
import ImageUploading from "react-images-uploading";
import { AiFillMinusCircle } from "react-icons/ai";
import { doc, updateDoc } from "firebase/firestore";

import "./Banner.css";
import BannerImageUploader from "./BannerUploader";
import { db } from "../../firebase/firebase";
const Banners = ({ params }) => {
  const products = useSelector((state) => state.Reducer.products);
  const catnames = useSelector((state) => state.Reducer.categories);
  const [cat, setCat] = useState("");

  const [imageshava1, setImagesHava1] = useState([]);
  const [imageshava2, setImagesHava2] = useState([]);
  const [imageshava3, setImagesHava3] = useState([]);
  const [imagesbemo1, setImagesBemo1] = useState([]);
  const [imagesbemo2, setImagesBemo2] = useState([]);
  const [imagesplus2, setImagesPlus2] = useState([]);
  const [imagesplus1, setImagesPlus1] = useState([]);

  const banners = useSelector((state) => state.Reducer.banners);

  useEffect(() => {
    setImagesHava1(banners.hava1);
    setImagesHava2(banners.hava2);
    setImagesHava3(banners.hava3);
    setImagesBemo2(banners.bemo2);
    setImagesBemo1(banners.bemo1);
    setImagesPlus2(banners.plus2);
    setImagesPlus1(banners.plus1);
  }, []);
  return (
    <>
      <div>
        <div className="mobile1">
          <div className="speaker">
            <p style={{ fontWeight: "bold", fontSize: 13, margin: 0, textAlign: "center" }}>
              HAVAZUSE
            </p>
          </div>

          <div className="products-scroll2">
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imageshava1.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imageshava1];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesHava1(finalArray);

                      const washingtonRef = doc(db, "Carousels", "Havause1");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    {" "}
                    remove
                  </button>
                  <BannerImageUploader
                    value={imageshava1}
                    setHandler={async (values) => {
                      let newArray = [...imageshava1];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesHava1(newArray);
                      const washingtonRef = doc(db, "Carousels", "Havause1");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}

              {/*  */}
            </AliceCarousel>
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imageshava2.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imageshava2];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesHava2(finalArray);

                      const washingtonRef = doc(db, "Carousels", "Havause2");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    {" "}
                    remove
                  </button>
                  <BannerImageUploader
                    value={imageshava2}
                    setHandler={async (values) => {
                      let newArray = [...imageshava2];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesHava2(newArray);
                      const washingtonRef = doc(db, "Carousels", "Havause2");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}

              {/*  */}
            </AliceCarousel>
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imageshava3.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imageshava3];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesHava3(finalArray);

                      const washingtonRef = doc(
                        db,
                        "Carousels",
                        "HavauseSwiper"
                      );
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    {" "}
                    remove
                  </button>
                  <BannerImageUploader
                    value={imageshava3}
                    setHandler={async (values) => {
                      let newArray = [...imageshava3];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesHava3(newArray);
                      const washingtonRef = doc(
                        db,
                        "Carousels",
                        "HavauseSwiper"
                      );
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}

              {/*  */}
            </AliceCarousel>
          </div>
        </div>

        {/* <div className="mobile1">
          <div className="speaker">
            <p style={{ fontWeight: "bold", fontSize: 13, margin: 0, textAlign: "center" }}>BEMO</p>
          </div>

          <div className="products-scroll2">
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imagesbemo1.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imagesbemo1];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesBemo1(finalArray);

                      const washingtonRef = doc(db, "Carousels", "HavaBemo1");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    remove
                  </button>
                  <BannerImageUploader
                    value={imagesbemo1}
                    setHandler={async (values) => {
                      let newArray = [...imagesbemo1];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesBemo1(newArray);
                      const washingtonRef = doc(db, "Carousels", "HavaBemo1");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}
            </AliceCarousel>
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imagesbemo2.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imagesbemo2];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesBemo2(finalArray);

                      const washingtonRef = doc(db, "Carousels", "HavaBemo2");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    remove
                  </button>
                  <BannerImageUploader
                    value={imagesbemo2}
                    setHandler={async (values) => {
                      let newArray = [...imagesbemo2];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesBemo2(newArray);
                      const washingtonRef = doc(db, "Carousels", "HavaBemo2");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
        <div className="mobile1">
          <div className="speaker">
            <p style={{ fontWeight: "bold", fontSize: 13, margin: 0, textAlign: "center" }}>PLUS</p>
          </div>

          <div className="products-scroll2">
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imagesplus1.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imagesplus1];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesPlus1(finalArray);

                      const washingtonRef = doc(db, "Carousels", "HavaPlus1");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    remove
                  </button>
                  <BannerImageUploader
                    value={imagesplus1}
                    setHandler={async (values) => {
                      let newArray = [...imagesplus1];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesPlus1(newArray);
                      const washingtonRef = doc(db, "Carousels", "HavaPlus1");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}
            </AliceCarousel>
            <AliceCarousel
              autoPlay
              autoPlayInterval="30000"
              disableButtonsControls
              // disableDotsControls
              mouseTracking
            >
              {imagesplus2.map((item) => (
                <div>
                  <img src={item.image} className="sliderimg" alt="" />
                  <button
                    className="saveyour"
                    onClick={async () => {
                      let newArray = [...imagesplus2];
                      let finalArray = newArray.filter(
                        (itemm) => itemm.image != item.image
                      );
                      setImagesPlus1(finalArray);

                      const washingtonRef = doc(db, "Carousels", "HavaPlus2");
                      await updateDoc(washingtonRef, {
                        Images: finalArray,
                      });
                    }}
                  >
                    remove
                  </button>
                  <BannerImageUploader
                    value={imagesplus2}
                    setHandler={async (values) => {
                      let newArray = [...imagesplus2];
                      newArray.push({
                        image: values[0].data_url,
                        category: cat,
                      });

                      setImagesPlus1(newArray);
                      const washingtonRef = doc(db, "Carousels", "HavaPlus2");
                      await updateDoc(washingtonRef, {
                        Images: newArray,
                      });
                    }}
                  />
                  <select
                    className="saveyourCat"
                    onChange={(event) => {
                      setCat(event.target.value);
                    }}
                  >
                    <option>{item.category}</option>

                    {catnames.map((item) => (
                      <option value={item.Catname}>{item.Catname}</option>
                    ))}
                  </select>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Banners;
