import React, { useState } from "react";
import "./ProductAttribute.css";
import { FaUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { Form } from "react-bootstrap";
import AttributeImage from "../AttributeImage/AtrributeImage";
import Counter from "../Counter/Counter";
import AttSize from "../AttSize/AttSize";
import { useSelector } from "react-redux";
import { AiFillMinusCircle } from "react-icons/ai";

function ProductAttribute() {
  const [show, setShow] = useState(false);
  const [childArray, setChildArray] = useState([]);
  const [childArray2, setChildArray2] = useState([]);
  const [create2, setCreate2] = useState([]);
  const [value2, setValue2] = useState("");
  const [child2, setChild2] = useState("");
  const [itemS2, setItemS2] = useState(null);

  const [value, setValue] = useState("");
  const [create, setCreate] = useState([]);
  const [child, setChild] = useState("");
  const [itemS, setItemS] = useState(null);
  const [data, setData] = useState(
    useSelector((state) => state.Reducer.attributes)
  );

  return (
    <div className="add-product-section">
      <div className="add-product-header">
        <h3>Product Attributes</h3>
        <div className="new-user">
          <FaUserCircle className="user-icon" />
          <select className="user-select">
            <option value="A">admin</option>
            <option value="B">admin</option>
            <option value="C">admin</option>
          </select>
        </div>

        <div className="save-product-section">
          <button
            className="product-save"
            onClick={() => {
              setCreate([]);
              setChildArray([]);
              setChild("");
              setValue("");
            }}
          >
            Save
          </button>

          {/* <div className="product-back">
            <BiArrowBack className="back-arrow" />
            <p className="back-text">Back</p>
          </div> */}
        </div>
      </div>
      <div className="attribute-select">
        <select onChange={(event) => setValue(event.target.value)}>
          <option value={"Custom"}>Product Attribute</option>
          {data.map((item) => (
            <option value={item.name}>{item.name}</option>
          ))}
        </select>

        <button
          onClick={() => {
            console.log(value, data);

            if (!childArray.length) {
              if (value.length) {
                let newData = data.filter((item) => item.name === value);
                setChildArray(newData[0].childs);
                setItemS(newData[0]);

                let newArray = [...create];
                newArray.push({
                  name: value,
                });
                setCreate(newArray);
              }
            }
          }}
        >
          Add
        </button>
      </div>
      <div className="attribute-bottom"></div>
      {childArray.length ? (
        <div className="attribute-name">
          <select onChange={(event) => setChild(event.target.value)}>
            <option value={"Custom"}>Product Attribute</option>
            {childArray.map((item) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </select>

          {/* <p>Name:</p>
             <input type="text"/> */}
          <button
            className="child"
            onClick={() => {
              if (child.length) {
                let employees = [...create];
                // important to create a copy, otherwise you'll modify state outside of setState call

                employees[employees.length - 1].child = child;
                setCreate(employees);
                setChildArray([]);
                setValue("");

                setChild("");
                // setChild("");
              }
            }}
          >
            Add Child
          </button>

          <Form.Group
            className="mb-3 att-visible"
            controlId="formBasicCheckbox"
          >
            <Form.Check type="checkbox" label="Visible on the product page" />
          </Form.Group>
        </div>
      ) : (
        <div className="attribute-name"></div>
      )}

      <div className="attribute-value">
        {/* <p>Value(s):</p>
                <textarea type="text"/> */}

        {create.map((item) => (
          <>
            <div
              style={{
                float: "left",
                width: "25%",
                marginRight: 20,
                marginBottom: 20,
              }}
            >
              <AiFillMinusCircle
                onClick={() => {
                  console.log("Clicked");

                  let newArray = create.filter(
                    (itemm) => itemm.name !== item.name
                  );
                  console.log("Clicked", newArray);

                  setCreate(newArray);
                  setChildArray([]);
                }}
                style={{
                  marginLeft: 10,
                  fontSize: 25,
                  color: "red",
                  float: "right",
                }}
              />
              <div className="att-type">
                <p>{item.name}</p>
              </div>
              <AttributeImage />

              <Counter />
              {item.child ? (
                <div className="att-type">{item.child}</div>
              ) : (
                <div className="att-type">empty</div>
              )}
              <input className="price-input" placeholder="Price" type="text" />
            </div>
          </>
        ))}
      </div>

      <div className="more-att-btn">
        <button
          onClick={() => {
            setShow(!show);
            setChildArray2([]);
            setCreate2([]);
            setValue2("");
            setChild2("");
          }}
        >
          Add More Attributes
        </button>
      </div>
      {show ? (
        <>
          <div className="attribute-select">
            <select onChange={(event) => setValue2(event.target.value)}>
              <option value={"Custom"}>Product Attribute</option>
              {data.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            </select>

            <button
              onClick={() => {
                console.log(value, data);

                if (!childArray2.length) {
                  if (value2.length) {
                    let newData = data.filter((item) => item.name === value2);
                    console.log(newData, "More");
                    setChildArray2(newData[0].childs);
                    setItemS2(newData[0]);

                    let newArray = [...create2];
                    newArray.push({
                      name: value2,
                    });
                    setCreate2(newArray);
                  }
                }
              }}
            >
              Add
            </button>
          </div>
          <div className="attribute-bottom"></div>
          {childArray2.length ? (
            <div className="attribute-name">
              <select onChange={(event) => setChild2(event.target.value)}>
                <option value={"Custom"}>Product Attribute</option>
                {childArray2.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </select>

              {/* <p>Name:</p>
             <input type="text"/> */}
              <button
                className="child"
                onClick={() => {
                  if (child2.length) {
                    let employees = [...create2];
                    // important to create a copy, otherwise you'll modify state outside of setState call

                    employees[employees.length - 1].child = child2;
                    setCreate2(employees);
                    setChildArray2([]);
                    setValue2("");

                    setChild2("");
                    // setChild("");
                  }
                }}
              >
                Add Child
              </button>

              <Form.Group
                className="mb-3 att-visible"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Visible on the product page"
                />
              </Form.Group>
            </div>
          ) : (
            <div className="attribute-name"></div>
          )}

          <div className="attribute-value">
            {/* <p>Value(s):</p>
                <textarea type="text"/> */}

            {create2.map((item) => (
              <>
                <div
                  style={{
                    float: "left",
                    width: "25%",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <AiFillMinusCircle
                    onClick={() => {
                      console.log("Clicked");

                      let newArray = create2.filter(
                        (itemm) => itemm.name !== item.name
                      );
                      console.log("Clicked", newArray);

                      setCreate2(newArray);
                      setChildArray2([]);
                    }}
                    style={{
                      marginLeft: 10,
                      fontSize: 25,
                      color: "red",
                      float: "right",
                    }}
                  />
                  <div className="att-type">
                    <p>{item.name}</p>
                  </div>
                  <AttributeImage />

                  <Counter />
                  {item.child ? (
                    <div className="att-type">{item.child}</div>
                  ) : (
                    <div className="att-type">empty</div>
                  )}
                  <input
                    className="price-input"
                    placeholder="Price"
                    type="text"
                  />
                </div>
              </>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ProductAttribute;
