import ImageUploading from "react-images-uploading";
import { AiFillPlusCircle } from "react-icons/ai";
import React from "react";
import { AiFillCamera } from "react-icons/ai";

const ImageUploader = ({ value, setHandler }) => {
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex, "fazzy");
    // setImages(imageList);

    setHandler(imageList);
  };
  return (
    <ImageUploading onChange={onChange} value={value} dataURLKey="data_url">
      {({ onImageUpload, imageList }) => (
        // write your building UI

        <AiFillCamera className="cam-icon" onClick={onImageUpload} />
      )}
    </ImageUploading>
  );
};
export default ImageUploader;
