import React from "react";
import "./Progress.css";
import Progress from "react-progressbar";
import CircularProgress2 from "../CircularProgress/Progress2";

function ProgressSection() {
  return (
    <>
      <div className="progress-home">
        {/* <ProgressBar className="progress1"
                               completed={60} />

                <ProgressBar className="progress1" completed={90} />

                <ProgressBar className="progress1" completed={50} />

                <ProgressBar className="progress1" completed={30} /> */}
        <div style={{ marginLeft: "10px", marginTop: "35px" }}>
          <Progress className="home-progress" completed={100} />
          <p className="num-progress">-567</p>
        </div>

        <div style={{ marginLeft: "10px", marginTop: "35px" }}>
          <Progress className="home-progress1" completed={100} />
          <p className="num-progress2">-44</p>
        </div>

        <div style={{ marginLeft: "10px", marginTop: "35px" }}>
          <Progress className="home-progress3" completed={100} />
          <p className="num-progress3">15</p>
        </div>

        <div style={{ marginLeft: "10px", marginTop: "35px" }}>
          <Progress className="home-progress4" completed={100} />
          <p className="num-progress4">-553</p>
        </div>

        <div style={{ marginTop: "50px" }}>
          <CircularProgress2 />
          <p className="progress-text">Total Visits</p>

          <div>
            <div className="progress-box1">
              <div className="box-circle"></div>
              <p className="stat-text">New Users</p>
              <p className="stat-num">74</p>
            </div>

            <div className="progress-box2">
              <div className="box-circle2"></div>
              <p className="stat-text">Live Users</p>
              <p className="stat-num">157</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressSection;
