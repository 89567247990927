import React from "react";
import "./CircularProgress.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = (props) => {
  const percentage = 48;

  return (
    <div>
      <CircularProgressbar
        className="progress1"
        value={props.percentage}
        text={`${props.percentage}%`}
      />
    </div>
  );
};

export default CircularProgress;
