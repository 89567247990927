import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import denim from "../../Assets/Images/denim.png";
import RefundMenu from "../RefundMenu/RefundMenu";
import iphone from "../../Assets/Images/phone.png";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TableFilterBar from "../TableFilterBar";
import CommonTable from "../Table";
import { Dropdown, Form } from "react-bootstrap";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hide, tableMenuIcon } from "../../store/actions/actions";
import { AiOutlineUser } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";
import TrackPop from "../TrackPop/TrackPop";
import DelPop from "../DelPop/DelPop";
import { db } from "../../firebase/firebase";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import "./Refund.css";
function Refund(props) {
  const users = useSelector((state) => state.Reducer.adminUser);
  const tableMenu = useSelector((state) => state.Reducer.tableMenu);
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [oderList, setOrderList] = useState({ users: [], list: [] });
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [orderEvent, setOrderEvent] = useState({ orderStatus: false, trackOrder: false });

  useEffect(async () => {
    const orders = collection(db, "Orders");
    const userOrders = await getDocs(
      query(orders, where("UserId", "==", props.match.params.id))
    );
    const uOrders = userOrders.docs.map((doc) => doc.data());
    setOrders(uOrders);
    console.log(uOrders, "HGHGHGH");
    dispatch(hide(false));
  }, []);
  useEffect(() => {
    let dataList = [];
    console.log("orders",orders);
    orders &&
      orders.length &&
      orders.map((user, index) => {
        dataList.push({
          id: index + 1,
          name: `${user?.ProductList[0]?.Pname || ""}`,
          price: user?.ProductList[0].Price || "",
          status: user.OrderStatus || "",
          color: user.ProductList[0].Color || null,
          catId: user.ProductList[0].Catid || null,
          Pid: user.ProductList[0].Pid || null,
          profilePicture: user?.ProductList[0]?.Pimage || "",
          userId: user?.UserId || ""
        });
      });
    setOrderList({ users: [...dataList], list: [...dataList] });
  }, [orders]);

  const columns = [
    {
      field: "id",
      renderHeader: () => {
        return <strong>{"#"}</strong>;
      },

      width: 60,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.id}
          </div>
        );
      },
    },
    {
      field: "Items",
      headerName: "Product",
      width: 300,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            <img
              className="user-profile-pic"
              src={params?.row?.profilePicture || iphone}
              alt={params?.row?.name || iphone}
              width={26}
              height={35}
            />
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Order Total",
      width: 200,
      sorting: true,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center user">
            ${params?.row?.price || 0}
          </div>
        );
      },
    },
    { field: "status", headerName: "Status", width: 175 },
    {
      field: "Total",
      headerName: "Status",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        return <div className="d-flex align-items-center user">Restock</div>;
      },
    },
    {
      field: "Details",
      headerName: "Actions",
      width: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="More Option" placement="top">
            <MenuIcon
              onClick={
                () =>
                  dispatch(
                    tableMenuIcon({
                      open: tableMenu?.open ? false : true,
                      data: params?.row,
                    })
                  )
              }
            />
          </Tooltip>
        );
      },
    },
  ];
  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      selectValue = oderList?.users?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };
  return (
    <>
      {/* <div className="search-user">
        <div style={{ width: 200 }}></div>
      </div>

      <div className="not-details2">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />
      </div>
      <div className="not-image">
        <img
          src={users.profilePicture ? users.profilePicture : user}
          className="not-image-user"
          alt="logo"
        />
        <p className="not-image-text">{users.FirstName}</p>
      </div>

      <div className="refund-heading">
        <h5>Order Items</h5>
        <div className="refund-border"></div>
      </div>

      <button className="refund-order-btn">
        <DelPop />
      </button>
      <button className="refund-track-btn">
        <TrackPop />
      </button>

      <div className="product-refund-section">
        <div className="refund-section">
          <ul className="refund-options">
            <li>Product</li>
            <li>Order Total</li>
            <li>Status</li>
            <li>Action</li>
          </ul>
        </div>

        {orders.map((item) => (
          <div className="refund-product-details2">
            <div className="refund-details">
              <img
                className="refund-image"
                src={item.ProductList[0].Pimage}
                alt="logo"
              />
              <div className="refund-denim">
                <p style={{ fontWeight: 700 }}>{item.ProductList[0].Pname}</p>
                <p style={{ color: "#888" }}>nan</p>
                <p style={{ fontWeight: 700 }}>1x</p>
              </div>
              <p className="refund-amount">${item.ProductList[0].Price}</p>
              <p className="refund-status">{item.OrderStatus}</p>
              <p className="refund-action">Restock</p>
              <RefundMenu />
            </div>
          </div>
        ))}
      </div>

      {orders.length ? (
        <>
          <div className="refund-section2">
            <p className="reason">Reason for refund</p>
            <textarea className="refund-text" type="text" />
            <p className="refund-reason">Your Customer won't see</p>
          </div>

          <div className="refund-section2">
            <p className="reason">Summary</p>
            <p className="refund-subtotal">
              Item Subtotal
              <br />
              <span style={{ fontSize: 8, color: "#888" }}>2 Items</span>
            </p>
            <p className="refund-subtotal-amount">$65</p>
            <p className="refund-subtotal-tax">Tax</p>
            <p className="refund-subtotal-amount">$6</p>
            <p className="refund-subtotal-tax">Shipping</p>
            <p className="refund-subtotal-amount">0.00</p>
            <p className="refund-subtotal-tax">Refunding Total</p>
            <p className="refund-subtotal-amount">$71.00</p>
            <p className="refund-total-amount">Refund Amount</p>
            <p className="payment-method">Shopify Payments</p>
            <p className="payment-method">(xxxx xxxx xxxxxx 1234)</p>
            <p className="refund-available">$150 available for refund</p>
            <p className="refund-subtotal-amount2">$71.00</p>

            <div className="clearfix"></div>

            <div className="order-checkout-section-refund">
              <h6 className="guest-check">Adrress</h6>
              <div style={{ float: "left", width: "100%" }}>
                <AiOutlineUser className="guest-email" />
                <p className="order-user-email2">John Doe</p>
              </div>
              <BiMap className="guest-email" />
              <p className="order-user-email3">
                73245 Scarletwood San Jose, CA 91259
              </p>
              <div style={{ float: "left", width: "100%" }}>
                <FiPhone className="guest-email" />
                <p className="order-user-email">408-027-8153</p>
              </div>
            </div>

            <button className="refund-btn2">Refund $71.00</button>
            <p className="refund-condition">Lorem Ipsum</p>

            <button className="refund-btn3">Store Credit</button>
            <input
              className="credit-input"
              type="text"
              placeholder="Enter Credit"
            />

            <Form.Group
              className="mb-3 refund-visible"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                type="checkbox"
                label="Send a notification to customer"
              />
            </Form.Group>
          </div>
        </>
      ) : null} */}

      <div className="add-category-section refundItems">
        <BreadcrumbMenu
          path={[
            { title: "Home", navLink: "/" },
            { title: "Refund Items", navLink: "/refundscreen" },
          ]}
          activeTab="Refund Items"
          isBack={false}
        />
        <TableFilterBar
          selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <div className="searchSection d-flex">
            <button className="searchButton searchButton-table me-1" onClick={() =>setOrderEvent({orderStatus: true})}>
              Order Status
            </button>
            <button className="searchButton searchButton-table" onClick={() =>setOrderEvent({trackOrder: true})}>
              Track Order
            </button>
          </div>
        </TableFilterBar>
        <div className="row">
          <div className="col-lg-9 col-md-9">
            <CommonTable
              columns={columns}
              rows={oderList.users || []}
              className="usersTable"
              rowsPerPage={rowsPerPage?.pageSize}
            />
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="reasonFrom">
              <Form className="reasonForRefund mb-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="refund-label">
                    Reason For Refund
                  </Form.Label>
                  <Form.Control
                    className="refund-textarea"
                    as="textarea"
                    rows={4}
                    placeholder="Enter Reason For Refund"
                  />
                </Form.Group>
                Your Customer Won’t See
              </Form>
              <div className="reasonForRefund">
                <p className="reasonSummary mb-3">Summary</p>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="d-flex Summary-text m-0">Item Subtotal</p>
                  <p className="d-flex Summary-text m-0">$65</p>
                </div>
                2 Items
                <div className="d-flex justify-content-between align-items-center margin-bottom__reason margin-top__reason">
                  <p className="d-flex Summary-text m-0">Tax</p>
                  <p className="d-flex Summary-text m-0">$6</p>
                </div>
                <div className="d-flex justify-content-between align-items-center margin-bottom__reason">
                  <p className="d-flex Summary-text m-0">Shipping</p>
                  <p className="d-flex Summary-text m-0">0.00</p>
                </div>
                <div className="d-flex justify-content-between align-items-center margin-bottom__reason">
                  <p className="d-flex Summary-text m-0">Refunding Total</p>
                  <p className="d-flex Summary-text m-0">$71.00</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="d-flex Summary-text m-0">Tax</p>
                  <p className="d-flex Summary-text m-0">$6</p>
                </div>
                <hr style={{ backgroundColor: "#DEE2E6", opacity: "1" }} />
                <p className="reasonSummary mb-3">Refund Amount</p>
                <div className="d-flex justify-content-between align-items-center ">
                  <p className="d-flex Summary-text m-0">Shopify Payments</p>
                </div>
                (xxxx xxxx xxxxxx 1234)
                <div className="d-flex justify-content-between align-items-center margin-top__reason margin-bottom__reason">
                  <p className="d-flex Summary-text m-0">
                    $150 Available For Refund
                  </p>
                </div>
                <p className="reasonSummary mb-3">$71.00</p>
                <div className="d-flex justify-content-between align-items-center margin-bottom__reason">
                  <div className="d-flex Summary-text m-0">
                    <FormControlLabel
                      control={<Checkbox color="success" />}
                      label="Send a notification to customer"
                    />
                  </div>
                </div>
                <Form className="formControl-input">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="refund-label">Credit</Form.Label>
                    <Form.Control
                      className="refund-textarea"
                      type="text"
                      placeholder="Enter Credit"
                    />
                  </Form.Group>
                </Form>
                <div className="searchSection">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <button className="searchButton searchButton-table me-1">
                        Refund $71.00
                      </button>
                    </div>
                    <div className="col-6 ps-0">
                      <button className="searchButton searchButton-table">
                        Store Credit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       {orderEvent?.orderStatus && <DelPop  orderEvent={orderEvent} setOrderEvent = {setOrderEvent}/>}
       {orderEvent?.trackOrder && <TrackPop orderEvent={orderEvent} setOrderEvent = {setOrderEvent}/>}
      </div>
    </>
  );
}

export default Refund;
