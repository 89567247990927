import React, { useState } from "react";
import "./RedeemProduct.css";
import AliceCarousel from "react-alice-carousel";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
function RedeemProduct() {
  const data = useSelector((state) => state.Reducer.redeem);
  const [redeem, setRedeem] = useState([
    {
      image:
        "https://www.androidheadlines.com/wp-content/uploads/2021/10/galaxy-s22-ultra-new-camera-design-render-3-scaled.jpg",
      name: "Samsumg Galaxy S22",
    },
    {
      image:
        "https://cdn.pocket-lint.com/r/s/1200x630/assets/images/155378-phones-review-hands-on-samsung-galaxy-s21-ultra-image1-luae09ici4.JPG",
      name: "abcdddddddd",
    },
    {
      image:
        "https://fdn.gsmarena.com/imgroot/reviews/21/samsung-galaxy-note20-ultra-vs-s21-ultra/lifestyle/-1200w5/gsmarena_001.jpg",
      name: "abcdddddddd",
    },
  ]);

  return (
    <>
      <AliceCarousel
        autoPlay
        autoPlayInterval="30000"
        disableButtonsControls
        // disableDotsControls
        mouseTracking
      >
        {redeem.map((item) => (
          <div className="image-container-redeem">
            <img src={item.Pimages} className="" alt="" />
            <div className="image-container-space"></div>
            <h5 className="redeem-name">{item.Pname}</h5>
            <div className="points-block">
              <AiFillStar className="points-icon" />
              <p className="points-text">{item.Points} Points</p>
            </div>
            <div className="redeem-btn"></div>
          </div>
        ))}

        {/*  */}
      </AliceCarousel>

      <div className="redeem-product1">
        <div className="redeem-card">
          <img
            src={
              "https://www.androidheadlines.com/wp-content/uploads/2021/10/galaxy-s22-ultra-new-camera-design-render-3-scaled.jpg"
            }
          />
        </div>
        <h6 className="redeem-name2">Samsung Galaxy S22</h6>
        <div className="redeem-btn2">
          <button>Redeem</button>
        </div>
        <div className="points-block2">
          <AiFillStar className="points-icon" />
          <p className="points-text">100 Points</p>
          <p className="redeem-congrats2">Congratulations</p>
        </div>
      </div>
    </>
  );
}

export default RedeemProduct;
