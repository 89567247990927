import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import loginCanvas from "../../Assets/Images/login-canvas.svg";
import havause from "../../Assets/Images/havause.png";
import logo from "../../Assets/Images/logo.jpeg";
import eye from "../../Assets/Images/eye.svg";
import { useHistory } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useDispatch } from "react-redux";
import {
  adminUser,
  setAdmin,
  setCustomer,
  setProfileComplete,
  setVendor,
} from "../../store/actions/actions";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { Toaster } from "../../utils/constant";
import "./Login.css";

const viewHeight = window.outerHeight;

const auth = getAuth();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!username) {
  //     setError("Enter your Email");
  //   }
  //   if (!password) {
  //     setError("Enter your password");
  //   }
  //   if (!username && !password) {
  //     setError("Enter your username and password");
  //   }

  //   let arr = [];
  //   const q = query(
  //     collection(db, "AdminUsers"),
  //     where("email", "==", username),
  //     where("password", "==", password)
  //   );

  //   const querySnapshot = await getDocs(q);
  //   console.log("HHHHH", querySnapshot);

  //   querySnapshot.forEach((doc) => {
  //     console.log(doc, "gggggg");
  //     if (doc.exists) {
  //       arr.push(doc.data());
  //       console.log(doc.id, " => ", doc.data());
  //     }
  //   });
  //   if (arr.length) {
  //     let end = base64_encode(username);

  //     console.log("GGGGGG,FFFFF");
  //     dispatch(adminUser(arr[0]));
  //     dispatch(setAdmin(true));
  //     dispatch(setVendor(false));
  //     dispatch(setCustomer(false));

  //     if (arr[0].FirstName.length) {
  //       localStorage.setItem("AdminUser", JSON.stringify(arr[0]));
  //       history.push("/");
  //     } else {
  //       history.push(`/adminRegister/${arr[0].userid}`);
  //     }
  //   } else {
  //     toast("This email is not registered as a Admin");
  //   }
  // };
  useEffect(() => {
    dispatch(setAdmin(false));
    dispatch(setVendor(false));
    dispatch(setCustomer(false));
  }, []);

  const schema = yup.object().shape({
    username: yup.string().email("Please enter a valid email").required(),
    password: yup.string().required(),
  });

  const userForm = useFormik({
    initialValues: { username: "", password: "" },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      let arr = [];
      const q = query(
        collection(db, "AdminUsers"),
        where("email", "==", values.username),
        where("password", "==", values.password)
      );

      const querySnapshot = await getDocs(q);
      console.log("HHHHH", querySnapshot);

      querySnapshot.forEach((doc) => {
        console.log(doc, "gggggg");
        if (doc.exists) {
          arr.push(doc.data());
          console.log(doc.id, " => ", doc.data());
        }
      });
      if (arr.length) {
        let end = base64_encode(values.username);

        console.log("GGGGGG,FFFFF");
        dispatch(adminUser(arr[0]));
        dispatch(setAdmin(true));
        dispatch(setVendor(false));
        dispatch(setCustomer(false));

        if (arr[0]?.FirstName?.length) {
          dispatch(setProfileComplete(true));
          localStorage.setItem("AdminUser", JSON.stringify(arr[0]));
          history.push("/");
        } else {
          dispatch(setProfileComplete(false));
          history.push(`/adminRegister/${arr[0].userid}`);
        }
        resetForm({ username: "", password: "" });
      } else {
        Toaster({
          type: "error",
          message: "This email is not registered as a Admin",
        });
      }
      setLoading(false);
    },
  });
  const { handleSubmit, values, errors, handleChange, touched, handleBlur } =
    userForm;

  return (
    // <div className="login">
    //   <div className="left">
    //     <img className="background" src={imgBackground} alt="imgBackground" />
    //     <img className="person" src={imgPerson} alt="imgPerson" />
    //     <img className="screen1" src={screen1} alt="screen1" />
    //     <img className="screen2" src={screen2} alt="screen2" />
    //     <img className="screen3" src={screen3} alt="screen3" />
    //     <img className="icons" src={imgIcons} alt="imgIcons" />
    //   </div>
    //   <div className="wrapper">
    //     <img src={imageform} alt="imageform" />
    //     <form onSubmit={handleSubmit}>
    //       <h2>Login Admin</h2>

    //       <p className="error"> {error} </p>

    //       <label style={{ top: "50px" }} htmlFor="username">
    //         Username
    //       </label>
    //       <br />
    //       <input
    //         onChange={(event) => setUsername(event.target.value)}
    //         value={username}
    //         name="username"
    //         id="username"
    //         type="text"
    //       />
    //       <br />
    //       <label
    //         style={{ top: "120px" }}
    //         className="password"
    //         htmlFor="password"
    //       >
    //         Password
    //       </label>
    //       <br />
    //       <input
    //         onChange={(event) => setPassword(event.target.value)}
    //         value={password}
    //         name="password"
    //         id="password"
    //         type="password"
    //       />
    //       <br />
    //       <button type="submit">
    //         <p>Login</p>
    //       </button>
    //       <p
    //         style={{ marginTop: 120, marginLeft: 60, color: "#3fad72" }}
    //         onClick={() => setShowing(true)}
    //       >
    //         Forget Password?
    //       </p>
    //     </form>
    //   </div>
    //   <ToastContainer />

    //   <Modal
    //     id="password-modal"
    //     class="date-modal"
    //     show={showing}
    //     onHide={() => setShowing(false)}
    //   >
    //     {/* <Modal.Header closeButton> */}
    //     {/* <Modal.Title>Forget Password</Modal.Title> */}
    //     {/* </Modal.Header> */}
    //     <Modal.Body>
    //       <div className="register-card2" style={{ height: viewHeight }}>
    //         <img src={Forgot} style={{ width: "70%" }} />
    //         <div style={{ float: "left", width: "100%" }}>
    //           <p
    //             style={{
    //               fontSize: 17,
    //               fontWeight: 800,
    //               marginTop: 45,
    //               marginLeft: 40,
    //             }}
    //           >
    //             Forget Password
    //           </p>

    //           <div
    //             style={{
    //               display: "flex",
    //               flexDirection: "column",
    //               justifyContent: "space-between",
    //               width: "85%",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 width: "100%",
    //                 marginLeft: "40px",
    //                 marginTop: "40px",
    //                 marginBottom: "20px",
    //               }}
    //             >
    //               <p style={{ marginBottom: "10px", fontWeight: "700" }}>
    //                 Email
    //               </p>
    //               <input
    //                 className="register-field2"
    //                 type="text"
    //                 value={reset}
    //                 placeholder="Please enter your email"
    //                 onChange={(event) => setReset(event.target.value)}
    //               />
    //             </div>
    //           </div>

    //           <div
    //             style={{
    //               float: "left",
    //               width: "100%",
    //               maxWidth: "100%",
    //               textAlign: "center",
    //               marginTop: "30px",
    //             }}
    //           >
    //             <button
    //               className="pass-change-button2"
    //               onClick={async () => {
    //                 let arr = [];
    //                 const q = query(
    //                   collection(db, "AdminUsers"),
    //                   where("email", "==", reset)
    //                 );

    //                 const querySnapshot = await getDocs(q);
    //                 console.log("HHHHH", querySnapshot);

    //                 querySnapshot.forEach((doc) => {
    //                   console.log(doc, "gggggg");
    //                   if (doc.exists) {
    //                     arr.push(doc.data());
    //                     console.log(doc.id, " => ", doc.data());
    //                   }
    //                 });
    //                 if (arr.length) {
    //                   var myHeaders = new Headers();
    //                   myHeaders.append("Content-Type", "application/json");

    //                   var raw = JSON.stringify({
    //                     data: {
    //                       email: reset,

    //                       name: "Admin",
    //                       message: `Please reset your password on this link https://sandstrip.com/#/resetPasswordAdmin/${arr[0].userid}`,
    //                     },
    //                   });

    //                   var requestOptions = {
    //                     method: "POST",
    //                     headers: myHeaders,
    //                     body: raw,
    //                     redirect: "follow",
    //                   };

    //                   fetch(
    //                     "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
    //                     requestOptions
    //                   )
    //                     .then((response) => response.text())
    //                     .then((result) => {
    //                       setShowing(false);
    //                       toast("Passowrd reset Link in sent to your email.");
    //                     })
    //                     .catch((error) => console.log("error", error));
    //                 } else {
    //                   setShowing(false);
    //                   toast("Email is not Registered");
    //                 }
    //               }}
    //             >
    //               <p
    //                 style={{
    //                   marginTop: 10,
    //                   color: "#FFFFFF",
    //                   marginBottom: "12px",
    //                 }}
    //               >
    //                 SEND
    //               </p>
    //             </button>
    //           </div>
    //           <div
    //             style={{
    //               float: "left",
    //               width: "100%",
    //               maxWidth: "100%",
    //               textAlign: "center",
    //               marginTop: "10px",
    //             }}
    //           ></div>
    //         </div>
    //       </div>
    //     </Modal.Body>
    //     {/* <Modal.Footer>
    //       <Button
    //         variant="primary"
    //         onClick={async () => {
    //           let arr = [];
    //           const q = query(
    //             collection(db, "AdminUsers"),
    //             where("email", "==", reset)
    //           );

    //           const querySnapshot = await getDocs(q);
    //           console.log("HHHHH", querySnapshot);

    //           querySnapshot.forEach((doc) => {
    //             console.log(doc, "gggggg");
    //             if (doc.exists) {
    //               arr.push(doc.data());
    //               console.log(doc.id, " => ", doc.data());
    //             }
    //           });
    //           if (arr.length) {
    //             var myHeaders = new Headers();
    //             myHeaders.append("Content-Type", "application/json");

    //             var raw = JSON.stringify({
    //               data: {
    //                 email: reset,

    //                 name: "Admin",
    //                 message: `Please reset your password on this link https://sandstrip.com/#/resetPasswordAdmin/${arr[0].userid}`,
    //               },
    //             });

    //             var requestOptions = {
    //               method: "POST",
    //               headers: myHeaders,
    //               body: raw,
    //               redirect: "follow",
    //             };

    //             fetch(
    //               "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
    //               requestOptions
    //             )
    //               .then((response) => response.text())
    //               .then((result) => {
    //                 setShowing(false);
    //                 toast("Passowrd reset Link in sent to your email.");
    //               })
    //               .catch((error) => console.log("error", error));
    //           } else {
    //             setShowing(false);
    //             toast("Email is not Registered");
    //           }
    //         }}
    //       >
    //         Done
    //       </Button>
    //     </Modal.Footer> */}
    //   </Modal>
    // </div>
    <div className="admin-login">
      <div className="login-container">
        <div className="row login-layout">
          <div className="col-md-7 login-canvas">
            <div className="d-flex justify-content-center align-items-center">
              <img src={havause} width={149} height={23} />
              <img src={logo} width={212} height={56} />
            </div>
            <div>
              <img src={loginCanvas} />
            </div>
          </div>
          <div className="col-md-5 px-5 align-self-center">
            <div className="title">Login Admin</div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="validationCategoryName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  value={values.username}
                  placeholder="Enter Username"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 pass-field-new"
                controlId="validationCategoryName"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  placeholder="Enter Password"
                  className="CatInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
                <img
                  src={eye}
                  alt="eye"
                  className="eyeIcon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </Form.Group>
              <LoadingButton
                type="submit"
                loading={loading}
                className="login-btn d-block add-user-btn"
              >
                Login
              </LoadingButton>
            </Form>
            <p
              className="forgetPassword"
              onClick={() => history.push("/forgot-password")}
            >
              Forgot Password?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
