import React from 'react';
import './UserSupport.css';
import { MdDownloadForOffline } from "react-icons/md";
import { BiSupport } from "react-icons/bi";

function UserButton() {
    return (
        <>
        <div className="download-section">
            {/* <FaDownload className="cart-icon"/> */}
        <BiSupport className="cart-icon"/>
            <div className="order-numbers-support">
                <p className="order-text-support">User Support</p>
            </div>
             {/* <div className="download-border">
             <p className="border-text">Lorem</p>
                </div> */}
                
        </div>

        </>
    );
}

export default UserButton;