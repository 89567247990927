import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Pinned.css";
import Pin from "../../Assets/Images/product.png";
import {
  BsFillChatDotsFill,
  BsFillPhoneVibrateFill,
  BsFillEyeFill,
} from "react-icons/bs";
import { useDispatch } from "react-redux";
import { hide } from "../../store/actions/actions";

function Uninvestigate() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hide(false));
  }, []);
  return (
    <>
      <button className="pin-btn-new">
        <Link to="/usersupport">Back</Link>
      </button>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          marginLeft: 20,
        }}
      >
        <div style={{ marginLeft: "50px", marginTop: "50px", width: "100%" }}>
          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>
              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>  
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          marginLeft: 20,
        }}
      >
        <div style={{ marginLeft: "50px", marginTop: "50px", width: "100%" }}>
          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>

          <div className="pin-card">
          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <img className="pin-image" src={Pin} />
              <p className="pinned-user">John Doe</p>
              <p className="pinned-class">Customer</p>
            </div>
          </Link>
            <div style={{ textAlign: "center" }}>
              <Link to="/usernotes">
                <BsFillChatDotsFill className="pinn-icon1" />
              </Link>

              <Link to="/calls">
                <BsFillPhoneVibrateFill className="pinn-icon2" />
              </Link>

              <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
                <BsFillEyeFill className="pinn-icon3" />
              </Link>
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Link to="/ChatSupport/allchat">
                <button className="pinn-btn1">Message</button>
              </Link>

              <button className="pinn-btn2">Uninvestigate</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Uninvestigate;
