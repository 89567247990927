import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./VideopageTabs.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GiStarShuriken } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import video from "../../Assets/Images/video.png";
import iphone from "../../Assets/Images/iphone.png";
import phone from "../../Assets/Images/phone.png";
import { AiTwotoneSetting } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import AddImage from "../AddImage/AddImage";
import camera from "../../Assets/Images/camera.png";
import ProductCard from "../ProductCard/ProductCard";
import { FiSearch } from "react-icons/fi";
import { Button, Form, Modal } from "react-bootstrap";
import VideoMenu from "../VideoMenu/VideoMenu";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ feeds }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selected, setSelected] = useState("");
  const [item, setItem] = useState("");
  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.videoid) {
      setSelected("");
    } else setSelected(item.videoid);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Short Video" {...a11yProps(0)} />
          <Tab label="Live" {...a11yProps(1)} />
          <Tab label="" {...a11yProps(2)} />
          <Tab label="" {...a11yProps(3)} />
          <Tab label="" {...a11yProps(4)} />
          <Tab label="" {...a11yProps(5)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={1}>
        {feeds.map((item) => (
          <div className="video-detail-carder">
            <div
              onClick={() => {
                setItem(item);
                handleShow();
              }}
              className="video1-border-mac-new"
            >
              <div
                style={{
                  backgroundImage: `url(${
                    item.thumbnail ? item.thumbnail : iphone
                  })`,
                  height: "182px",
                  backgroundSize: "auto",
                  backgroundRepeat: "no",
                }}
              >
                <div>
                  <img
                    className="video-uploader-user"
                    src={item.ProfileImage}
                  />
                </div>
                <div className="video-length-mac2">
                  <span>2:34</span>
                </div>
                <div className="clearfix"></div>

                <div className="setting-gear-mac">
                  <AiTwotoneSetting
                    className="video-setting-mac2"
                    onClick={() => {
                      handleSelection(item);
                    }}
                  />
                </div>
              </div>

              {selected === item.videoid ? (
                <>
                  <div className="video-desc-mac">
                    <p className="video-title-mac">{item.caption}</p>
                    <p className="video-date-mac">21 JAN 2021</p>
                  </div>

                  <div className="view-details-mac">
                    <button className="view-btn-mac">
                      576K <BsEye />
                    </button>
                  </div>

                  <div className="view-delete-mac">
                    <button className="delete-btn-mac">Delete</button>
                  </div>

                  <div className="video-hearts">
                    <GiHearts className="hearts" />
                    <p className="num-hearts">3.67k</p>
                  </div>

                  <div className="video-dislike">
                    <AiFillDislike className="dislike" />
                    <p className="num-hearts">1.3k</p>
                  </div>

                  <div className="video-dislike">
                    <BsChatFill className="chat" />
                    <p className="num-hearts">2.3k</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ))}
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <ReactPlayer className="vid-player" url={item.feedurl} />
          </Modal.Body>
        </Modal>
      </TabPanel>

      <TabPanel value={value} index={0}>
        {feeds.map((item) => (
          <div className="video-detail-carder">
            <div
              onClick={() => {
                setItem(item);
                handleShow();
              }}
              className="video1-border-mac-new"
            >
              <div
                style={{
                  backgroundImage: `url(${
                    item.thumbnail ? item.thumbnail : iphone
                  })`,
                  height: "182px",
                  backgroundSize: "auto",
                  backgroundRepeat: "no",
                }}
              >
                <div>
                  <img
                    className="video-uploader-user"
                    src={item.ProfileImage}
                  />
                </div>
                <div className="video-length-mac2">
                  <span>2:34</span>
                </div>
                <div className="clearfix"></div>

                <div className="setting-gear-mac">
                  <AiTwotoneSetting
                    className="video-setting-mac2"
                    onClick={() => {
                      handleSelection(item);
                    }}
                  />
                </div>
              </div>

              {selected === item.videoid ? (
                <>
                  <div className="video-desc-mac">
                    <p className="video-title-mac">{item.caption}</p>
                    <p className="video-date-mac">21 JAN 2021</p>
                  </div>

                  <div className="view-details-mac">
                    <button className="view-btn-mac">
                      576K <BsEye />
                    </button>
                  </div>

                  <div className="view-delete-mac">
                    <button className="delete-btn-mac">Delete</button>
                  </div>

                  <div className="video-hearts">
                    <GiHearts className="hearts" />
                    <p className="num-hearts">3.67k</p>
                  </div>

                  <div className="video-dislike">
                    <AiFillDislike className="dislike" />
                    <p className="num-hearts">1.3k</p>
                  </div>

                  <div className="video-dislike">
                    <BsChatFill className="chat" />
                    <p className="num-hearts">2.3k</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ))}
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <ReactPlayer className="vid-player" url={item.feedurl} />
          </Modal.Body>
        </Modal>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="video-detail-card">
          <div className="video1-border-mac-new">
            <div
              style={{
                backgroundImage: `url(${iphone})`,
                height: "182px",
                backgroundSize: "auto",
                backgroundRepeat: "no",
              }}
            >
              <div className="video-length-mac2">
                <span>2:34</span>
              </div>
              <div className="clearfix"></div>

              <div className="setting-gear-mac">
                <AiTwotoneSetting className="video-setting-mac2" />
              </div>
            </div>

            <div className="video-desc-mac">
              <p className="video-title-mac">Iphone 12 Pro</p>
              <p className="video-date-mac">21 JAN 2021</p>
            </div>

            <div className="view-details-mac">
              <button className="view-btn-mac">
                576K <BsEye />
              </button>
            </div>

            <div className="view-delete-mac">
              <button className="delete-btn-mac">Delete</button>
            </div>

            <div className="video-hearts">
              <GiHearts className="hearts" />
              <p className="num-hearts">3.67k</p>
            </div>

            <div className="video-dislike">
              <AiFillDislike className="dislike" />
              <p className="num-hearts">1.3k</p>
            </div>

            <div className="video-dislike">
              <BsChatFill className="chat" />
              <p className="num-hearts">2.3k</p>
            </div>
          </div>
        </div>{" "}
      </TabPanel>
    </div>
  );
}
