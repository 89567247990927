import React from "react";
import { Button } from "react-bootstrap";
import "./NewList.css";
import user from "../../Assets/Images/user1.jpg";
import { FaNewspaper } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsArrowLeftShort } from "react-icons/bs";

function NewList(props) {
  return (
    <>
      <div>
        <BsArrowLeftShort
          className="back-arrow"
          style={{ float: "left" }}
          onClick={props.backHandler}
        />
      </div>
      <div className="main">
        <ul className="main2">
          <li>Name</li>
          <li>Address</li>

          <li>Email</li>
          <li>Phone Number</li>
        </ul>
        <div className="mainBorder"></div>

        <>
          <div className="mainDetail" style={{ height: 80 }}>
            <img src={user} className="mainImage" alt="logo" />
            <p className="mainName">John </p>
            <p className="mainEmail">house no 40404</p>
            <p className="mainEmail">John@gmail.com</p>
            <p className="mainNum">330303030</p>

            <div className="plus-btn">
              <Link to="verification">
                <button className="plus-button">Save</button>
              </Link>
            </div>
          </div>
          <div className="mainBorder"></div>

          <div className="mainDetail" style={{ height: 80 }}>
            <img src={user} className="mainImage" alt="logo" />
            <p className="mainName">John </p>
            <p className="mainEmail">house no 40404</p>
            <p className="mainEmail">John@gmail.com</p>
            <p className="mainNum">330303030</p>

            <div className="plus-btn">
              <Link to="verification">
                <button className="plus-button">Save</button>
              </Link>
            </div>
          </div>
          <div className="mainBorder"></div>
        </>
      </div>
    </>
  );
}

export default NewList;
