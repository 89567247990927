import React from "react";
import ImageUploader from "react-images-upload";
import "./AddImage.css";

class AddImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  render() {
    return (
      <div>
        {this.props.imageUrl ? (
          <div className="fileContainer">
            <img
              src={this.props.imageUrl}
              style={{ width: "100%", height: "100%", resize: "contain" }}
            />
          </div>
        ) : (
          <ImageUploader
            withIcon={false}
            buttonText="Add New Image"
            onChange={this.onDrop}
            withPreview={true}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
        )}
      </div>
    );
  }
}

export default AddImage;
