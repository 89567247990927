import React, { useState } from "react";

import ReactDOM from "react-dom";
import "./Map.css";
import MapPercent from "../MapPercent/MapPercent";
import WorldMap, { CountryContext } from "react-svg-worldmap";
const data = [
  { country: "cn", value: 1389618778 }, // china
  { country: "in", value: 1311559204 }, // india
  { country: "us", value: 331883986 }, // united states
  { country: "id", value: 264935824 }, // indonesia
  { country: "pk", value: 210797836 }, // pakistan
  { country: "br", value: 210301591 }, // brazil
  { country: "ng", value: 208679114 }, // nigeria
  { country: "bd", value: 161062905 }, // bangladesh
  { country: "ru", value: 141944641 }, // russia
  { country: "mx", value: 127318112 }, // mexico
];
function Map() {
  const [selected, onSelect] = useState("");
  const [number, setNumber] = useState(0);
  const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
  const clickAction = ({ countryName, countryCode, countryValue }) => {
    onSelect(countryName);
    let random = Math.floor(Math.random() * 10);
    setNumber(random);
  };
  return (
    <>
      <div className="map-section">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="map-percent-section">
            <h4 style={{ color: "#3fad72" }}>Growth by Country</h4>
            <h2 style={{ color: "blue" }}>{selected}</h2>

            <h2 style={{ color: "#3fad72", marginBottom: 60 }}>{number}</h2>

            <div className="percent-seperator"></div>
            <MapPercent />
          </div>
          <div style={{ marginleft: 50 }}>
            <WorldMap
              onClickFunction={clickAction}
              color="#3fad72"
              value-suffix="people"
              size="xl"
              data={data}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Map;
