
import React from "react";
import { Link } from "react-router-dom";
import "./Homebar.css";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";

function Homebar() {
    return (

        <>

<Link to="/salesreport">

        <div className="dashboard-att-bar">
           <div className="dash-icon">
                <AiFillDollarCircle className="dollar-icon"/>
            </div>

            <div className="dash-amount">
                <p className="money-bar">$23,789</p>
                <p className="money-detail-bar">Gross sales in this month</p>
            </div>
        </div>

        </Link>

<Link to="/revenue">
        <div className="dashboard-att-bar">
            <div className="dash-icon2">
                <AiFillDollarCircle className="dollar-icon"/>
            </div>
            <div className="dash-amount">
                <p className="money-bar">$12,453</p>
                <p className="money-detail-bar">Earning in this month</p>
            </div>
        </div>

        </Link>

<Link to="/monthlyitems">
        <div className="dashboard-att-bar">
            <div className="dash-icon3">
                <BiNotepad className="dollar-icon"/>
            </div>
            <div className="dash-amount">
                <p className="money-bar">5 Items</p>
                <p className="money-detail-bar">Sold in this month</p>
            </div>
        </div>
    </Link>

<Link to="/monthlyorders">
        <div className="dashboard-att-bar">
            <div className="dash-icon4">
                <FaShoppingCart className="dollar-icon"/>
            </div>
            <div className="dash-amount">
                <p className="money-bar">10 orders</p>
                <p className="money-detail-bar">Received in this month</p>
            </div>
        </div>

        </Link>

        </>

    );
}

export default Homebar;