import React from 'react';
import './DashNav.css';
import logo from '../../Assets/Images/logo.jpeg';
import { Col } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";
import { FiBox } from "react-icons/fi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import SettingMenu from '../SettingMenu/SettingMenu';
import ProductMenu from '../ProductMenu/ProductMenu';
import { AiFillDatabase } from "react-icons/ai";
import DataTable from '../DataTable/DataTable';
import UserMenu from '../UserMenu/UserMenu';
import user from '../../Assets/Images/product.png';


function DashNav() {
    return (
        <Col className="nav-area">
 
        <div className="sand-logo">
            <a href="#" className="logo"><img src={logo} className="logo-image" alt="logo" /></a>        
        </div>

        <div style={{float:"left"}}>
            <img src={user} className="nav-user" alt="logo" />
            <p className="user-hello">Hello,</p>
            <p className="username-hello">Steven Taylor</p>
        </div>   

      <div className="nav1">
          <AiOutlineHome className="icon-1"/><p className="home">Home</p>
      </div>

      <div className="nav2">
          <ProductMenu/>
          {/* <FiBox className="icon-1"/><p className="home">Products</p> */}
      </div>

      <div className="nav2">
          <AiOutlineShoppingCart className="icon-1"/><p className="home">Selling</p>
      </div>

      <div className="nav2">
          <SettingMenu/>
      </div>

      <div className="nav2">
          <AiFillDatabase className="icon-1"/><p className="home">Data Table</p>
      </div>

      <div className="nav2">
          <UserMenu/>
      </div>

        </Col>
    );
}

export default DashNav;