import React, { useState } from "react";
import "./ItemPurchase.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from '../../Assets/Images/user1.jpg';
import ListingsTab from '../ListingsTab/ListingsTab';
import ProductCard from '../ProductCard/ProductCard';
import { GiStarShuriken } from "react-icons/gi";
import { RiEditCircleFill } from "react-icons/ri";
import Button from '@material-ui/core/Button';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PurchaseCard from "../PurchaseCard/PurchaseCard";
import { Link } from "react-router-dom";

function ItemPurchase() {
    const [videoUpload,setVideoUpload] = useState(true)
    // const [show,setShow]=useState(false);
    return (
        <>

        <div className="search-user">
            <FiSearch className="usersearch-icon"/>
            <input className="input-search" type="text" placeholder="Search..."/>
        </div>

        <div className="not-details">
                
                        <BsDot className="not-dot"/>
                        <BsFillEnvelopeFill className="message-icon"/>
                   
                        <BsDot className="not-dot2"/>
                        <BsBellFill className="message-icon"/>
        </div>
        <div className="not-image">
            <img src={user} className="not-image-user" alt="logo" />
            <p className="not-image-text">John Doe</p>
        </div>
        <GiHamburgerMenu className="hamburger"/>

<div>
        <div className="tab-nav">
            <div className={!videoUpload  ? "tab1 active-tab" : "tab1"} onClick = {()=>setVideoUpload(true)}>
                <p>Video Upload</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Video Watch</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Wish List</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1  ">
                <p>Item Purchase</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
            <Link to="/notes" style = {{textDecoration:"none",color:"white",textAlign:"center",margin:"5px",fontSize:18}} >
{/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
User Notes
</Link>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Subscription</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Lis</p>
            </div>
        </div>

        <div className="tab1-border"></div>


        <div className="tab-nav">
            <div className="tab1">
                <p className="nav-payment">Payment Details</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Listing</p>
            </div>
        </div>

        <div className="tab-nav">
        <div className="tab1">
        <Link to="Chat" style = {{textDecoration:"none",color:"white",textAlign:"center",alignSelf:"center",margin:"25px",fontSize:18}} >
{/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
Messages
</Link>
</div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>Items Save</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>-</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>-</p>
            </div>
        </div>

        <div className="tab-nav">
            <div className="tab1">
                <p>-</p>
            </div>
        </div>

        <div className="tab1-border"></div>
        </div>
       
        <div className="user-videos-tab">
        <div className="video-card2">
            <p className="video-views">Total Views: 576K</p>
            <p className="video-points"><GiStarShuriken className="star-points"/> 454 Points</p>
            <img src={user} className="video-user" alt="logo" />
            <div className="videouser-info">
            <RiEditCircleFill className="edit-btn"/>
                <h3>@john78</h3>
                <p>Active</p>
            </div>
            <div className="name-details">
                <p className="first-name">First Name:</p>
                <p className="user-name-video">John</p>
            </div>
            <div className="name-details">
                <p className="first-name">Last Name:</p>
                <p className="user-name-video">Doe</p>
            </div>
            <div className="name-details">
                <p className="first-name">Email:</p>
                <p className="user-name-video2">johndoe@xyz.com</p>
            </div>
            <div className="name-details">
                <p className="first-name">Contact:</p>
                <p className="user-name-video3">+123 456 789</p>
            </div>
            <div className="name-details">
                <p className="first-name">From:</p>
                <p className="user-name-video4">USA</p>
            </div>
            <div className="name-details">
                <p className="first-name">Havazues balance:</p>
                <p className="user-name-video5">6,907$</p>
            </div>
            <Link to="/orderhistory">
            <button className="user-activities">Activities</button>
            </Link>
                    <button className="user-contact">
                    <Link to="/Chat" style = {{textDecoration:"none",color:"white",textAlign:"center",alignSelf:"center",margin:"25px",fontSize:18}} >
{/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
Contact
</Link>
                    </button>
                
            <button className="user-suspend">Suspension</button>
            <button className="user-date">1 Jan 2021<span className="date-border"></span>
            31 Jan 2021</button>
            <button className="user-delete">Delete</button>

            <div className="video-border"></div>

            <div className="user-followers">
                <p className="followers">Media Followers:</p>
                <p className="followers-num">7,890</p>
            </div>
            <div className="user-followers">
                <p className="followers">Media Following:</p>
                <p className="followers-num">500</p>
            </div>
            <div className="user-followers-store">
                <p className="followers">Store Following:</p>
                <p className="followers-num">500</p>
            </div>

            <button className="user-verification">Havazuse plus verification</button>

        </div>

        <div className="video-detail-card">
            <ProductCard/>
        </div>
        </div>
        </>
    );
}

export default ItemPurchase;