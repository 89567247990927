import React from "react";
import "./DelPop.css";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DelTimeline from "../DelTimeline/DelTimeline";
import { GrClose } from "react-icons/gr";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

 function DelPop({orderEvent,setOrderEvent}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setOrderEvent(!orderEvent);
  };

  //  openMap = Boolean(anchorEl);
  // const id = open ? "del-pop" : undefined;
  return (
    <div style={{ float: "left", width: "100%" }}>
      {/* <button
        className="del-status"
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Order Status
      </button> */}
      <Popover
        id={"del-pop"}
        open={orderEvent.orderStatus}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
              <GrClose className="close-pop" onClick={handleClose}/>
        <Typography className={classes.typography}>
          <p className="track-order">Order Details</p>
          <p className="track-code">
            Your Order Code:
            <span style={{ color: "rgba(61, 193, 98, 1)" }}>#123456</span>
          </p>
          <div className="order-item">
            <p className="item-count">3 ITEMS</p>
          </div>
          <div className="order-item2">
            <p className="item-count">$200</p>
          </div>
          <DelTimeline />

          <div style={{ marginTop: 50 }}></div>
        </Typography>
      </Popover>
    </div>
  );
}
export default DelPop;
