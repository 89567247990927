import React, { useEffect, useState } from "react";
import "./Vendor.css";
import user from "../../Assets/Images/user1.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllVendors } from "../../store/actions/actions";
import { Button, Modal } from "react-bootstrap";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import generator from "generate-password";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function Vendor() {
  const dispatch = useDispatch();
  const [id, setId] = useState(false);
  const [des, setDes] = useState("");
  const [reason, setReason] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const vendors = useSelector((state) => state.Reducer.vendors);

  const handleSelectionMultiple = (item) => {
    const users = doc(db, "VendorUsers", item.userid);

    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.userid)) {
      selectedIdss = selectedIds.filter((_id) => _id !== item.userid);
      updateDoc(users, {
        approved: false,
      });
    } else {
      selectedIdss.push(item.userid);
      setSelectedIds(selectedIdss);

      updateDoc(users, {
        approved: true,
      });
      const password2 = generator.generate({
        length: 7,
        numbers: true,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        data: {
          email: item.email,

          name: "Admin",
          message: `Please Login on this link  https://sandstrip.com/#/vendorLogin/ using this Username :${item.email}, Password : ${password2}`,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast("Email send Successfully!");
        })
        .catch((error) => console.log("error", error));
    }
  };

  useEffect(() => {
    let newData = [];

    vendors.map((item) => {
      if (item.suspension) {
        newData.push(item.userid);
      }
    });
    setSelectedIds(newData);
  }, []);

  useEffect(() => {
    dispatch(getAllVendors());
  }, []);
  return (
    <>
      <div className="vendor-heading">
        <h3 className="user-text">Vendors</h3>
        <Link to="allvendor">
          <p className="view-all">View All</p>
        </Link>
      </div>
      <div className="vendor-section">
        <div className="search-user-account">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>
        <h5 className="vendors active">Vendors</h5>
        {vendors.map((item) => (
          <div className="left">
            <img src={user} className="vendor-image" alt="logo" />
            <p className="vendor-name">{item.storeName}</p>
            <p className="vendor-email">john@doe.com</p>

            <div className="vendor-detail">
              <Link to="regvendors">
                <button className="vendor-button4">View Application</button>
              </Link>

              <button
                className="vendor-button"
                style={{
                  backgroundColor: selectedIds.includes(item.userid)
                    ? "gold"
                    : "#3fad72",
                }}
                onClick={() => {
                  handleSelectionMultiple(item);
                }}
              >
                Approve
              </button>

              <button className="vendor-button3" onClick={() => setId(true)}>
                Info Needed
              </button>

              <button className="vendor-button2" onClick={() => setId(true)}>
                Deny
              </button>

              {/* <BsThreeDotsVertical className="more" /> */}
            </div>
            <Modal class="date-modal" show={id} onHide={() => setId(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Reason</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{}}>
                  <div>
                    <textarea
                      type="text"
                      style={{ height: 150, width: "100%" }}
                      value={reason}
                      onChange={(event) => setReason(event.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={async () => {
                    let userid = Math.floor(Math.random() * 1000000000);

                    await setDoc(doc(db, "Reason", `${userid}`), {
                      email: item.email,
                      reasonId: userid,
                      reason: reason,
                    });
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                      data: {
                        email: item.email,

                        name: "Admin",
                        message: reason,
                      },
                    });

                    var requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow",
                    };

                    fetch(
                      "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
                      requestOptions
                    )
                      .then((response) => response.text())
                      .then((result) => console.log(result))
                      .catch((error) => console.log("error", error));
                    setReason("");
                    setId(false);
                  }}
                >
                  Done
                </Button>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        ))}
      </div>
    </>
  );
}

export default Vendor;
