import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "./Analytics.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import { PieChart } from "react-minimal-pie-chart";
import ToggleProfile from "../ToggleProfile/ToggleProfile";
import ProfileUploader from "../Home/ProfileUploader";
import Graph from "../Graph/Graph";
import logouts from "../../Assets/Images/logout.png";

import {
  hide,
  getAllOrders,
  getAllShorts,
  getUser,
  getAllCategories,
  getAllProducts,
  getAllLive,
  getAllLiveSchedules,
  getAllUsers,
  getAllHistory,
  getAllFeedbackReports,
  getAllBanners,
  getAllSupportUser,
  getAllAttributes,
  setVendor,
  getAllAdmins,
  getAllCustomerService,
  getAllevents,
  adminUser,
  getAllProductsVendor,
  getAllProductsVendorPending,
  logout,
  setAdmin,
  setCustomer,
} from "../../store/actions/actions";
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../../firebase/firebase";
import { getDatabase, ref, child, get, onValue } from "firebase/database";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  getDoc,
} from "firebase/firestore";
import profile from "../../Assets/Images/product.png";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import logo from "../../Assets/Images/logo.jpeg";

const auth = getAuth();
const userSignin = auth.currentUser;
const suser = localStorage.getItem("VendorUser");

function Analytics() {
  const [value, onChange] = useState(new Date());
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const [images, setImages] = useState([]);
  const [pro, setPro] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [owner, setOwner] = useState("");
  const [store, setStore] = useState("");
  const [email, setEmail] = useState("");

  const suser = localStorage.getItem("VendorUser");
  const [showing, setShowing] = useState(false);

  const handleClose = () => setShowing(false);
  const handleShow = () => {
    console.log("ssss");
    setShowing(true);
  };

  useEffect(() => {
    dispatch(getAllProductsVendor(JSON.parse(suser).userid));
    dispatch(getAllProductsVendorPending(JSON.parse(suser).userid));
  }, []);

  const reg = useSelector((state) => state.Reducer.adminUser);
  const history = useHistory();

  useEffect(async () => {
    localStorage.removeItem("AdminUser");
    localStorage.removeItem("ServiceUser");

    dispatch(hide(false));
    if (localStorage.getItem("VendorUser")) {
      dispatch(setVendor(true));
      dispatch(setAdmin(false));
      dispatch(setCustomer(false));
      const pRef = doc(db, "VendorUsers", `${JSON.parse(suser).userid}`);
      const snap = await getDoc(pRef);
      dispatch(adminUser(snap.data()));

      setPro(snap.data().profilePicture);
      setFirst(snap.data().FirstName);
      setLast(snap.data().LastName);
      setPassword(snap.data().password);
      setPhone(snap.data().phone);
      setAddress(snap.data().address);
      setOwner(snap.data().owner);
      setStore(snap.data().storeName);
      setEmail(snap.data().email);

      console.log(reg, "Anay");
    } else {
      history.push("/vendorLogin");
    }
  }, []);

  const updateUserProfile = async () => {
    const pRef = doc(db, "VendorUsers", `${JSON.parse(suser).userid}`);

    let data = {
      profilePicture: pro ? pro : "",
      FirstName: first,
      LastName: last,
      password: password,
      phone: phone,
      address: address,
      owner: owner,
    };
    await updateDoc(pRef, data);
    const snap = await getDoc(pRef);

    dispatch(adminUser(snap.data()));
    setPro(snap.data().profilePicture);
    setPassword(snap.data().password);
    setFirst(snap.data().FirstName);
    setLast(snap.data().LastName);
    setPhone(snap.data().phone);
    setAddress(snap.data().address);
    setOwner(snap.data().owner);
    setStore(snap.data().storeName);

    handleClose();
  };

  useEffect(async () => {
    dispatch(setVendor(true));
    if (localStorage.getItem("VendorUser")) {
    } else {
      history.push("/vendorLogin");
    }
    dispatch(hide(false));
  }, []);

  return (
    <>
      <div className="analytics-logo">
        <img src={logo} />
      </div>

      <div className="not-details">
        <BsDot className="not-dot" />
        <BsFillEnvelopeFill className="message-icon" />

        <BsDot className="not-dot2" />
        <BsBellFill className="message-icon" />

        <img
          onClick={() => {
            dispatch(logout());
            history.push("/vendorLogin");
          }}
          src={logouts}
          style={{
            height: 20,
            width: 20,
            float: "right",
            marginRight: 10,
            marginTop: 5,
            marginLeft: 25,
          }}
          alt="logo"
        />
      </div>

      <div className="userprofile-section">
        <img
          src={pro ? pro : user}
          className="profile-image-user"
          alt="logo"
          onClick={handleShow}
        />
        <ul className="profile-user-details">
          <li className="welcome-dash">Welcome to Dashboard</li>
          <p className="user-dash">{reg.storeName}</p>
          {/* <li className="user-dash">Last Login: 2:20 (September 17, 2021)</li> */}
        </ul>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div class="vendor-user-details">
            <p className="vendor-email-user">Email</p>
            <p className="vendor-email-text">{reg.email}</p>
          </div>

          <div class="vendor-user-details2">
            <p className="vendor-email-user">Phone Number</p>
            <p className="vendor-email-text">{reg.phone}</p>
          </div>

          <div class="vendor-user-details2">
            <p className="vendor-email-user">Address</p>
            <p className="vendor-email-text">{reg.address}</p>
          </div>

          <div class="vendor-user-details2">
            <p className="vendor-email-user">Owner Name</p>
            <p className="vendor-email-text">{reg.owner}</p>
          </div>
        </div>
      </div>

      <Modal class="date-modal" show={showing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="pic-section">
              <img src={pro ? pro : user} />
              <ProfileUploader
                value={images}
                setHandler={async (values) => {
                  let newImage = values[0].data_url;

                  setPro(newImage);
                }}
              />
              <p>{reg.FirstName}</p>
            </div>

            <div className="details-section">
              <div className="profile-fields">
                <input
                  className="email-field"
                  type="text"
                  placeholder="Email"
                  value={email}
                  contentEditable={false}
                />

                <input
                  className="email-field"
                  type="text"
                  placeholder="First Name"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                />

                <input
                  className="email-field"
                  type="text"
                  placeholder="Last Name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                />
                <input
                  className="email-field"
                  type="text"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  className="email-field"
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  className="email-field"
                  type="text"
                  placeholder="Owner"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                />

                <ToggleProfile
                  onChange={() => {
                    setChange(!change);
                  }}
                />

                {change ? (
                  <>
                    <input
                      className="email-field"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <input
                      className="email-field"
                      type="password"
                      placeholder="Confirm Password"
                      value={passwordC}
                      onChange={(e) => setPasswordC(e.target.value)}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateUserProfile}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="dashboard-att">
        <div className="dash-icon">
          <AiFillDollarCircle className="dollar-icon" />
        </div>
        <Link to="/vendorsalesreport">
          <div className="dash-amount">
            <p className="money-analytics">$0</p>
            <p className="money-analytics-detail">Gross sales in this month</p>
          </div>
        </Link>
      </div>

      <div className="dashboard-att">
        <div className="dash-icon2">
          <AiFillDollarCircle className="dollar-icon" />
        </div>
        <Link to="/vendorrevenue">
          <div className="dash-amount">
            <p className="money">$0</p>
            <p className="money-detail">Earning in this month</p>
          </div>
        </Link>
      </div>

      <div className="dashboard-att">
        <div className="dash-icon3">
          <BiNotepad className="dollar-icon" />
        </div>
        <Link to="/vendormonthlyitems">
          <div className="dash-amount">
            <p className="money"> 0 Items</p>
            <p className="money-detail">Sold in this month</p>
          </div>
        </Link>
      </div>

      <div className="dashboard-att">
        <div className="dash-icon4">
          <FaShoppingCart className="dollar-icon" />
        </div>
        <Link to="/vendormonthlyorders">
          <div className="dash-amount">
            <p className="money">0 current orders</p>
            {/* <p className="money-detail">Received in this month</p> */}
          </div>
        </Link>
      </div>

      <div className="analytics-section">
        <div className="sale-section">
          <p className="gross-text">GROSS SALE IN THIS MONTH</p>
          <p className="gross-text2">$0</p>
          <div className="growth-sale">
            <p>
              0%
              <MdArrowDropUp />
            </p>
          </div>

          <div className="growth-reduce">
            <p>
              0%
              <MdArrowDropDown />
            </p>
          </div>
        </div>

        <div className="profit-section">
          <p className="gross-text3">REVENUE</p>
          <p className="gross-text2">$68,980</p>
          <div className="growth-sale">
            <p>
              32%
              <MdArrowDropUp />
            </p>
          </div>

          <div className="growth-reduce">
            <p>
              32%
              <MdArrowDropDown />
            </p>
          </div>
        </div>

        <div className="profit-section">
          <p className="gross-text3">PROFIT</p>
          <p className="gross-text2">$68,980</p>
          <div className="growth-sale">
            <p>
              32%
              <MdArrowDropUp />
            </p>
          </div>

          <div className="growth-reduce">
            <p>
              32%
              <MdArrowDropDown />
            </p>
          </div>
        </div>

        <div className="profit-section">
          <p className="gross-text3">COST</p>
          <p className="gross-text2">$68,980</p>
          <div className="growth-sale">
            <p>
              32%
              <MdArrowDropUp />
            </p>
          </div>

          <div className="growth-reduce">
            <p>
              32%
              <MdArrowDropDown />
            </p>
          </div>
        </div>

        <div className="piechart-result-section">
          <div className="cost-break">
            <p className="cost-heading">COST BREAKDOWN</p>
            <h5 className="mark-text">MARKETING</h5>
            <h5 className="mark-numbers">$45,897</h5>
            <h5 className="mark-text2">SALES</h5>
            <h5 className="mark-numbers">$98,450</h5>
          </div>
          <div className="pie-chart-section">
            <PieChart
              className="piechart-result"
              data={[
                { title: "One", value: 12, color: "blue" },
                { title: "Two", value: 67, color: "#3FAD72" },
              ]}
            />
          </div>
        </div>

        <div className="cross-section">
          <p className="cost-heading">UP/CROSS SELL</p>
          <p className="cross-revenue">REVENUE</p>
          <p className="cross-amount">$12,987</p>

          <p className="cross-revenue">% OF REVENUE</p>
          <p className="cross-amount2">34%</p>

          <div className="cross-border"></div>

          <p className="cost-heading2">CHURN</p>

          <p className="cross-revenue2">TOTAL</p>
          <p className="cross-amount3">26</p>

          <div className="cross-border"></div>

          <p className="cross-revenue2">RATE</p>
          <p className="cross-amount3">3%</p>

          <div className="cross-border"></div>

          <p className="cross-revenue2">REVEUE</p>
          <p className="cross-amount3">$34,890</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <div className="graph-section">
            <Calendar
              className="calender-inner"
              onChange={onChange}
              value={value}
            />
          </div>

          <div className="graph-section">
            <p className="cost-heading">SALES REVENUE</p>
            <Graph />
          </div>
        </div>

        {/* <div className="graph-section">
        <Calendar
            className="calender-inner"
            onChange={onChange}
            value={value}
          />
        </div>

        <div className="graph-section">
          <p className="cost-heading">SALES REVENUE</p>
          <Graph />
        </div> */}
      </div>
    </>
  );
}

export default Analytics;
