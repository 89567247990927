import React from 'react';
import "./NotificationVideos.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from '../../Assets/Images/user1.jpg';
import user1 from '../../Assets/Images/product.png';
import Iphone from '../../Assets/Images/iphone2.png';
import iphone from '../../Assets/Images/iphone.png';
import { AiTwotoneSetting } from "react-icons/ai";
import { GiHearts } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";

function NotificationVideos() {
    return (

        <>



        <div className="posts-section">
            <div className="search-user2">
                <FiSearch className="usersearch-icon"/>
                <input className="input-search" type="text" placeholder="Search..."/>
            </div>

            <div className="video1-border-not">
          <div
            style={{
              backgroundImage: `url(${iphone})`,
              height: "182px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="video-length-mac2">
              <span>2:34</span>
            </div>
            <div className="clearfix"></div>

            <div className="setting-gear-mac">
              <AiTwotoneSetting className="video-setting-mac2" />
            </div>
          </div>

          <div className="video-desc-not">
            <p className="video-title-mac">Iphone 12 Pro</p>
            <p className="video-date-mac">21 JAN 2021</p>
          </div>

<div style={{float:"right", maxWidth:"100%"}}>
          <div className="view-details-mac">
            <button className="view-btn-mac">
              576K <BsEye />
            </button>
          </div>

          <div className="view-delete-mac">
            <button className="delete-btn-mac">Delete</button>
          </div>
          </div>

<div className="impressions">
          <div className="video-hearts">
            <GiHearts className="hearts" />
            <p className="num-hearts">3.67k</p>
          </div>

          <div className="video-dislike">
            <AiFillDislike className="dislike" />
            <p className="num-hearts">1.3k</p>
          </div>

          <div className="video-dislike">
            <BsChatFill style={{color:"green"}} className="dislike" />
            <p className="num-hearts">1.3k</p>
          </div>
          </div>

          {/* <div className="video-dislike">
            <BsChatFill className="chat" />
            <p className="num-hearts">2.3k</p>
          </div> */}

            <div className="clearfix"></div>
          
          <img src={user1} className="not-image-user3" alt="logo" />
             
             <ul className="not-post-details2">
                     <li className="not-react2">Rose reacted to your post</li>
                     <li className="copyright2">This video is copyright</li>
                     <li className="duration2">10 min ago</li>
                 </ul>
        </div>

      
        <div className="video1-border-not">
          <div
            style={{
              backgroundImage: `url(${iphone})`,
              height: "182px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="video-length-mac2">
              <span>2:34</span>
            </div>
            <div className="clearfix"></div>

            <div className="setting-gear-mac">
              <AiTwotoneSetting className="video-setting-mac2" />
            </div>
          </div>

          <div className="video-desc-not">
            <p className="video-title-mac">Iphone 12 Pro</p>
            <p className="video-date-mac">21 JAN 2021</p>
          </div>

<div style={{float:"right", maxWidth:"100%"}}>
          <div className="view-details-mac">
            <button className="view-btn-mac">
              576K <BsEye />
            </button>
          </div>

          <div className="view-delete-mac">
            <button className="delete-btn-mac">Delete</button>
          </div>
          </div>

<div className="impressions">
          <div className="video-hearts">
            <GiHearts className="hearts" />
            <p className="num-hearts">3.67k</p>
          </div>

          <div className="video-dislike">
            <AiFillDislike className="dislike" />
            <p className="num-hearts">1.3k</p>
          </div>

          <div className="video-dislike">
            <BsChatFill style={{color:"green"}} className="dislike" />
            <p className="num-hearts">1.3k</p>
          </div>
          </div>

          {/* <div className="video-dislike">
            <BsChatFill className="chat" />
            <p className="num-hearts">2.3k</p>
          </div> */}

            <div className="clearfix"></div>
          
          <img src={user1} className="not-image-user3" alt="logo" />
             
             <ul className="not-post-details2">
                     <li className="not-react2">Rose reacted to your post</li>
                     <li className="copyright2">This video is copyright</li>
                     <li className="duration2">10 min ago</li>
                 </ul>
        </div>
        </div>

        </>

    );
}

export default NotificationVideos;