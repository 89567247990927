import React from "react";
import "./SalesReport.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import { FaCoins } from "react-icons/fa";
import { SiConvertio } from "react-icons/si";
import SaleHistory from "../SaleHistory/VendorSalesHistory";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

function VendorSalesReport() {
  const orders = useSelector((state) => state.Reducer.orders);
  const auth = getAuth();
  const suser = localStorage.getItem("AdminUser");

  const userCurrent = auth.currentUser;
  return (
    <>
      <div className="search-section">
        <div className="search-user">
          {/* <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." /> */}
        </div>

        {/* <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div> */}
        {/* <div className="not-image">
          <img src={user} className="not-image-user" alt="logo" /> */}
          {/* <p className="not-image-text">{JSON.parse(suser).displayName}</p> */}
        {/* </div> */}
      </div>

      <h4 className="monthly-sales">Monthly Sales Report</h4>

      <div className="sales-period-box">
        <p className="date-period">Date Period:</p>
        <p className="date-from">10/09/2021 - 10/10/2021</p>
        <div className="sales-border-bottom"></div>

        <p className="date-period">Duration: 30 days</p>
        <div className="sales-border-bottom"></div>
      </div>

      <div className="ad-spend-box">
        <FaCoins className="coins-ad" />
        <div className="amount-ads">
          <p className="total-spending">Total spend on Ads</p>
          <p className="total-spending-amount">$2,323</p>
        </div>
      </div>
      <div className="sales-period-box2">
        <p className="page-views">Page Views</p>
        <p className="total-view-amount">9,323</p>
        <p className="conversion-rate-views">+2.15% (8,991)</p>
      </div>

      <div className="ad-spend-box2">
        <SiConvertio className="coins-ad" />
        <div className="amount-ads">
          <p className="total-spending">Conversion Rate</p>
          <p className="total-spending-amount">44.99%</p>
          <p className="conversion-rate">+2.11% (42.79%)</p>
        </div>
      </div>
      <div className="sales-period-box3">
        <p className="page-views">Page Clicks</p>
        <p className="total-view-amount">6,042</p>
        <p className="conversion-rate-views">+3.15% (8,991)</p>
      </div>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          marginTop: 20,
          marginLeft: 20,
        }}
      >
        <SaleHistory allorders={orders} />
      </div> */}
    </>
  );
}

export default VendorSalesReport;
