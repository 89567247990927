import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { db } from "../../firebase/firebase";
import { useFormik } from "formik";
import { BsCaretDownFill } from "react-icons/bs";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import {
  addCategory,
  getCategoryById,
  updateCategory,
} from "../../store/actions/actions";
import { useHistory, useLocation } from "react-router-dom";
import "./Category.css";
import { Toaster } from "../../utils/constant";
import CustomDropdown from "../Dropdown";
import { LoadingButton } from "@mui/lab";
const categoryInitValue = {
  Catname: "",
  ParentCategory: "",
  description: "",
};

const AddCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const catnames = useSelector((state) => state.Reducer.categories);
  const product = useSelector((state) => state.Reducer.products);
  const getOneCategory = useSelector((state) => state.Reducer.getOneCategory);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const schema = yup.object().shape({
    Catname: yup.string().required(),
    ParentCategory: yup.string().required(),
  });

  const categoryForm = useFormik({
    initialValues: {
      Catname: "",
      ParentCategory: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      let CatId = Math.floor(Math.random() * 1000000000);
      if (getOneCategory?.isEdit) {
        setLoading(true);
        dispatch(
          updateCategory({ ...values }, catnames, getOneCategory?.Catid)
        );
        const pRef = doc(db, "Categories", `${getOneCategory?.Catid}`);
        await updateDoc(pRef, values);
        Toaster({
          type: "success",
          message: "Successfully updated category.",
        });
        setLoading(false);
      } else {
        setLoading(true);
        await setDoc(doc(db, "Categories", `${CatId}`), {
          ...values,
          Catid: `${CatId}`,
          Image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
        });
        dispatch(
          addCategory({
            ...values,
            Catid: CatId,
            Image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
          })
        );
        Toaster({
          type: "success",
          message: "Successfully created category.",
        });
        setLoading(false);
      }
      dispatch(getCategoryById(null));
      history.push("/category");
      resetForm(categoryInitValue);
    },
  });

  useEffect(() => {
    if (location?.state?.isEdit || location?.state?.isAddMore) {
      dispatch(getCategoryById(location?.state?.data?.Catid, location?.state));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    const editValue = getOneCategory && Object.keys(getOneCategory || {});
    if (editValue?.length) {
      if (getOneCategory.isEdit) {
        categoryForm.setValues({
          Catname: getOneCategory.Catname,
          ParentCategory: getOneCategory.ParentCategory,
          description: getOneCategory.description,
          Catid: getOneCategory.Catid,
        });
      } else {
        categoryForm.setValues({
          ParentCategory: getOneCategory.Catname,
          description: getOneCategory.description,
        });
      }
    }
  }, [getOneCategory]);

  const { handleSubmit, values, errors, handleChange } = categoryForm;
  return (
    <div className="add-category-section">
      <BreadcrumbMenu
        path={[
          { title: "Products", navLink: "/All" },
          { title: "Category", navLink: "/category" },
          getOneCategory?.isEdit
            ? { title: "Edit Category", navLink: "/category/addCategory" }
            : { title: "Add Category", navLink: "/category/addCategory" },
        ]}
        activeTab={getOneCategory?.isEdit ? "Edit Category" : "Add Category"}
      />
      <div className="row">
        <div className="col-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>
                Category Name <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Catname"
                value={values.Catname}
                placeholder="Enter Category"
                className="CatInput"
                onChange={handleChange}
                isInvalid={!!errors.Catname}
              />
              <Form.Control.Feedback type="invalid">
                Category name is required
              </Form.Control.Feedback>
            </Form.Group>
            <div className="cat-drop mb-3">
              <Form.Group className="mb-3" controlId="validationParentCategory">
                <Form.Label>
                  Parent Category <span className="asterisk">*</span>
                </Form.Label>
                <CustomDropdown
                  option={catnames?.map((Catname) => Catname.Catname) || []}
                  placeholder="Select Parent Category"
                  width="100%"
                  borders={true}
                  fullArrow={true}
                  dropdownContentHeight={"auto !important"}
                  error={!!errors.ParentCategory}
                  name="ParentCategory"
                  selectedValue={values.ParentCategory}
                  handleChangeRowsPerPage={async (value) =>
                    categoryForm.setFieldValue("ParentCategory", value)
                  }
                  searchBar={true}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  ref={ref}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={`${
                    !!errors.ParentCategory ? "d-block" : "d-none"
                  } mb-3`}
                >
                  Parent category is required
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId="validationParentCategory">
                <Form.Label>
                  Parent Category <span className="asterisk">*</span>
                </Form.Label>
                <Form.Select
                  placeholder="Select Parent Category"
                  className="CatInput"
                  name="ParentCategory"
                  value={values.ParentCategory}
                  onChange={handleChange}
                  isInvalid={!!errors.ParentCategory}
                >
                  <option value="">Select Category</option>
                  {catnames?.map((item, index) => (
                    <option key={index} value={item.Catname}>
                      {item.Catname}
                    </option>
                  ))}
                  <option value={"No Parent"}>{"No Parent"}</option>
                </Form.Select>
                <div className="Catdown">
                  <BsCaretDownFill />
                </div>
                <Form.Control.Feedback type="invalid" className="mb-3">
                  Parent category is required
                </Form.Control.Feedback>
              </Form.Group> */}
            </div>
            <Form.Group className="mb-3" controlId="validationCategoryName">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={values.description}
                placeholder="Enter description"
                className="CatInput"
                onChange={handleChange}
                style={{ height: "100px" }}
              />
            </Form.Group>
            <LoadingButton
              type="submit"
              loading={loading}
              className="add-new-category d-block add-user-btn"
            >
              {getOneCategory?.isEdit ? "Edit Category" : "Add Category"}
            </LoadingButton>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
