import React, {useState} from "react"
import WorldMap from "react-world-map";
import ReactDOM from "react-dom";
import "./Record.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import RevenueGraph from "../RevenueGraph/RevenueGraph";
import RecordGraph from "../Record Graph/RecordGraph";
import Map from "../Map/Map";
import MapCard from "../MapCard/MapCard";
import BarCard from "../BarCard/BarCard";

function Record() {
    const [selected, onSelect] = useState(null);

    return (
        <>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", float:"left", width:"82%"}}>
                <div className="frequency-section-new marg-left">
                    
                    <h5 className="record-frequency">Traces by frequency</h5>
                    <div style={{float:"left", width:"100%", marginLeft:"30px"}}>
                    <div className="frequency1">
                        <div>
                        <p className="frequency-text">Lorem Ipsum</p>
                        </div>
                        <div className="freq-duration">
                            <p className="duration1">Duration</p>
                            <strong><p>63.78</p></strong>
                        </div>
                    </div>

                    <div className="frequency1">
                        <div>
                        <p className="frequency-text">Lorem Ipsum</p>
                        </div>
                        <div className="freq-duration">
                            <p className="duration1">Duration</p>
                            <strong><p>63.78</p></strong>
                        </div>
                    </div>

                    <div className="frequency1">
                        <div>
                        <p className="frequency-text">Lorem Ipsum</p>
                        </div>
                        <div className="freq-duration">
                            <p className="duration1">Duration</p>
                            <strong><p>63.78</p></strong>
                        </div>
                    </div>

                   
                    </div>
                    <div className="border-bottom-map"></div>
                    <div className="view-all-map">
                        <p>VIEW TRACES</p>
                        <AiOutlineArrowRight/>
                    </div>
                </div>

                <div className="frequency-section-new">
                <h5 className="record-frequency">Network Success Rate</h5>
                <p className="revenue-change">Change Over Time</p>
                <p className="revenue-change1">+7.05%</p>
                    <RecordGraph style={{marginLeft:"0"}}/>
                </div>
            </div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", float:"left", width:"82%"}}>
                <div className="frequency-section marg-left">
                <h5 className="record-frequency">Network Response Latency</h5>
                <p className="map-change1">Below</p>
                <p className="map-change2">Between</p>
                <p className="map-change3">Above</p>
                    <MapCard/>
                </div>

                <div className="frequency-section2">
                <h5 className="record-frequency">Network Response MIME types</h5>

                    <div className="card-area">
                    <BarCard width="300px"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Record;