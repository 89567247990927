import React, { useEffect, forwardRef, useState } from "react";
import { GrFormDown } from "react-icons/gr";
import { BsCaretDownFill } from "react-icons/bs";
import "./Dropdown.css";
const CommonDropdown = forwardRef(
  (
    {
      option,
      placeholder = null,
      width,
      fullArrow = false,
      borders = false,
      justifyContent,
      dropdownContentHeight = "auto",
      handleChangeRowsPerPage,
      selectedValue = null,
      error = false,
      searchBar = false,
      setIsActive,
      isActive,
      onBlur,
    },
    ref
  ) => {
    const [selected, setIsSelected] = useState(selectedValue);
    const [widthCss, setWidthCss] = useState("300px");
    const [justifyContentCss, setJustifyContentCss] = useState("left");
    useEffect(() => {
      if (selectedValue) setIsSelected(selectedValue);
      if (width && width.length) {
        setWidthCss(width);
      }
      if (justifyContent && justifyContent.length) {
        setJustifyContentCss(justifyContent);
      }
    }, [selectedValue]);

    const filterFunction = () => {
      let input, filter, a, i, div;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("span");
      for (i = 0; i < a.length; i++) {
        const txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    };
    return (
      <div className="custom-dropdown" style={{ width: widthCss }} ref={ref}>
        <div
          onClick={() => {
            setIsActive(!isActive);
          }}
          className="dropdown-btn"
          style={{ border: error ? "1px solid #dc3545" : "1px solid #dee2e6" }}
          onBlur={onBlur}
        >
          {selected ? (
            selected
          ) : (
            <p className="custom-placeholder">{placeholder}</p>
          )}
          {fullArrow ? (
            <BsCaretDownFill
              className="full_arrow"
              style={{ transform: isActive ? "rotate(180deg)" : "none" }}
            />
          ) : (
            <GrFormDown
              className="arrow"
              style={{ transform: isActive ? "rotate(180deg)" : "none" }}
            />
          )}
          <span
            className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
          />
        </div>
        <div
          className="dropdown-content"
          style={{
            display: isActive ? "block" : "none",
            height: dropdownContentHeight,
            overflowY: dropdownContentHeight !== "auto" ? "scroll" : "hidden",
          }}
          id="myDropdown"
        >
          {searchBar ? (
            <input
              type="text"
              placeholder="Search.."
              id="myInput"
              onChange={filterFunction}
            />
          ) : null}

          {option && option.length
            ? option.map((item) => {
                return (
                  <span
                    onClick={(e) => {
                      handleChangeRowsPerPage(e.target.textContent);
                      setIsSelected(e?.target?.textContent);
                      setIsActive(!isActive);
                    }}
                    style={{
                      justifyContent: justifyContentCss,
                      border: borders ? "1px solid #DEE2E6" : "none",
                    }}
                    className={selectedValue === item ? "item_active" : "item"}
                  >
                    {item}
                  </span>
                );
              })
            : null}
        </div>
      </div>
    );
  }
);

export default CommonDropdown;
