import React from "react";
import "./Cart.css";
import { BsCart2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux'

function Cart() {
  const ordersLength = useSelector(state=>state.Reducer.orders)
  return (
    <>
      <div className="cart-section">
        <Link to="order">
          {/* <MdShoppingCart className="cart-icon" /> */}
          <BsCart2 className="order-icon2"/>
          <div className="order-numbers">
            <p className="order-number">{ordersLength.length} Orders</p>
          </div>
          {/* <div className="order-border">
            <p className="border-text">Lorem</p>
          </div> */}
        </Link>
      </div>
    </>
  );
}

export default Cart;
