import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import AddAttribute from "./AddAttributes";
import AddNewChild from "./AddNewChild";
import ChildAttributes from "./ChildAttributes";

const AttributeSlug = () => {
  const location = useLocation();
  const [pathName, setPathName] = useState("addAttribute");
  const [tabName, setTabName] = useState([
    {
      title: "Add Attribute",
      navLink: `/`,
    },
  ]);

  useEffect(() => {
    console.log("location", location);
    const pathAccess = location?.pathname?.split("/");
    const pathname = pathAccess[pathAccess.length - 1];
    if (pathAccess) setPathName(pathname);
    if (pathname === "addAttribute")
      setTabName({
        title: "Add Attribute",
        navLink: `/attributes/${pathname}`,
      });
    else if (pathname === "editAttribute")
      setTabName({
        title: "Edit Attribute",
        navLink: `/attributes/${pathname}`,
      });
    else if (pathname === "addNewChildAttribute")
      setTabName([
        { title: "Child Attribute", navLink: `/attributes/childAttributes` },
        {
          title: "Add New Child Attribute",
          navLink: `/attributes/${pathname}`,
        },
      ]);
    else if (pathname === "childAttributes")
      setTabName({
        title: "Child Attribute",
        navLink: `/attributes/${pathname}`,
      });
  }, [location]);

  const renderComponent = () => {
    const pageSlug = pathName || "addAttribute";
    switch (pageSlug) {
      case "addAttribute":
      case "editAttribute":
        return <AddAttribute />;
      case "addNewChildAttribute":
        return <AddNewChild />;
      case "childAttributes":
        return <ChildAttributes />;
      default:
        return <AddAttribute />;
    }
  };
  return (
    <div className="add-attribute add-category-section">
      <BreadcrumbMenu
        path={[
          { title: "Products", navLink: "/All" },
          { title: "Attribute", navLink: "/attributes" },
        ].concat(tabName)}
        activeTab={
          Array.isArray(tabName)
            ? tabName[tabName.length - 1]?.title
            : tabName?.title
        }
      />
      {renderComponent()}
    </div>
  );
};

export default AttributeSlug;
