import React from "react";
import "./Review.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
// import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/user1.jpg";
import { FaBox } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { GoNote } from "react-icons/go";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { RiDeleteBin6Line } from "react-icons/ri";
import product from "../../Assets/Images/phone.png";

function Review() {
  return (
    <div className="product-reviews">
      <div className="search-user">
        <FiSearch className="usersearch-icon" />
        <input className="input-search" type="text" placeholder="Search..." />
      </div>

      {/* <div className="not-details">
                    
                            <BsDot className="not-dot"/>
                            <BsFillEnvelopeFill className="message-icon"/>
                    
                            <BsDot className="not-dot2"/>
                            <BsBellFill className="message-icon"/>
            </div>
            <div className="not-image">
                <img src={user} className="not-image-user" alt="logo" />
                <p className="not-image-text">John Doe</p>
            </div>
            <GiHamburgerMenu className="hamburger"/> */}

      <div className="review-header">
        <p className="review-all">All(2)</p>
        <div className="review-products">
          <FaBox className="review-icon" />
          <p className="review-text">Product Reviews</p>
        </div>
      </div>

      <div className="review-section">
        <div className="review-show">
          <p>Show</p>
          <select>
            <option>10</option>
            <option>25</option>
          </select>
        </div>

        <div className="review-entries">
          <p>Entries</p>
          <select>
            <option>Choose Store....</option>
          </select>
        </div>

        <div className="review-list">
          <FaMinusCircle className="review-minus" />
          <GoNote className="review-userimg" />
          <p className="review-author">Author</p>
          <p className="review-comment">Comment</p>
          <p className="review-author">Rating</p>
          <p className="review-author">Store</p>
          <p className="review-author">Product</p>
          <p className="review-dated">Dated</p>
          <p className="review-author">Actions</p>
        </div>

        <div className="review-border"></div>

        <div className="review-list1">
          <AiFillCheckCircle className="review-tick" />
          <BiUserCircle className="review-user-image" />
          <div className="review-author2">
            <p>John Doe</p>
            <p>John@doe.com</p>
          </div>
          <p className="review-comment2">testing store review</p>

          <div className="review-rating">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <p className="review-store">avi_store</p>
          <div className="product-pic-review">
            <img src={product} className="review-product-dash" alt="logo" />
            <p className="review-product">Iphone 12</p>
          </div>
          <p className="review-date">
            September 19, 2021 <br />
            6:21 PM
          </p>

          <div className="review-cross">
            <TiDeleteOutline />
          </div>

          <div className="review-del">
            <RiDeleteBin6Line />
          </div>
        </div>

        <div className="review-list2">
          <AiFillCheckCircle className="review-tick" />
          <BiUserCircle className="review-user-image" />
          <div className="review-author2">
            <p>John Doe</p>
            <p>John@doe.com</p>
          </div>
          <p className="review-comment2">testing store review</p>

          <div className="review-rating">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <p className="review-store">avi_store</p>
          <div className="product-pic-review">
            <img src={product} className="review-product-dash" alt="logo" />
            <p className="review-product">Iphone 12</p>
          </div>
          <p className="review-date">
            September 19, 2021 <br />
            6:21 PM
          </p>

          <div className="review-cross">
            <TiDeleteOutline />
          </div>

          <div className="review-del">
            <RiDeleteBin6Line />
          </div>
        </div>

        <div className="review-border"></div>

        <div className="review-list">
          <FaMinusCircle className="review-minus" />
          <GoNote className="review-userimg" />
          <p className="review-author">Author</p>
          <p className="review-comment">Comment</p>
          <p className="review-author">Rating</p>
          <p className="review-author">Store</p>
          <p className="review-author">Product</p>
          <p className="review-dated">Dated</p>
          <p className="review-author">Actions</p>
        </div>

        <p className="review-footer">Showing 1 to 2 of 2 entries</p>

        <div className="review-pages">
          <p className="review-prev">Previous</p>
          <div className="review-numbers">1</div>
          <p className="review-next">Next</p>
        </div>
      </div>
    </div>
  );
}

export default Review;
