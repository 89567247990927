import React, { useEffect, useState, forwardRef } from "react";
// import { Button } from "react-bootstrap";
import "./AllUser.css";
import user from "../../Assets/Images/user1.jpg";
import { FaNewspaper } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, updateUser } from "../../store/actions/actions";
import DatePicker from "react-date-picker";
import { Button, Modal } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../firebase/firebase";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";

import Dynamic from "../DynamicModal/DynamicModal";

import { sendPasswordResetEmail, getAuth } from "firebase/auth";
const auth = getAuth();
const userSignin = auth.currentUser;
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    {value}
  </button>
));
function AllUser() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [dynamic, setDynamic] = useState(false);
  const users = useSelector((state) => state.Reducer.users);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const suser = localStorage.getItem("AdminUser");
  const [item, setItem] = useState(null);

  const dispatch = useDispatch();

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectionMultiple = (item) => {
    const users = doc(db, "SimpleUsers", item.userid);

    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.userid)) {
      setButtonTitle("Unsuspend");
      setTitle("Activate Account");
      setText("Do you want to reactivate this account ?");
      setDynamic(true);
    } else {
      setButtonTitle("Suspend");
      setTitle("Suspend Account");
      setText("After you Sespend an account,it will no longer in use.");
      setDynamic(true);
    }
    setItem(item);
  };

  useEffect(() => {
    let newData = [];

    users.map((item) => {
      if (item.suspension) {
        newData.push(item.userid);
      }
    });
    setSelectedIds(newData);
  }, []);

  const resetPassword = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast("Password reset email sent");
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);

        // ..
      });
  };
  return (
    <>
      <div className="alluser-header">
        <div className="search-user">
          {/* <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." /> */}
        </div>

        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div>
        <div className="not-image">
          <img src={user} className="not-image-user" alt="logo" />
          <p className="not-image-text">{JSON.parse(suser).displayName}</p>
        </div>
      </div>
      <div style={{ float: "left", width: "69%", marginBottom: "15px" }}>
        <h3 className="user-text-full">Users Account</h3>
      </div>
      <div className="allusers-section">
        {/* <div>
                <Button className="customized">Customized</Button>
            </div> */}
        <div className="search-user-account">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>
        <div className="border-bottom"></div>

        <ul className="user-list-home">
          <li>Name</li>
          <li>Email</li>
          <li>Actions</li>
        </ul>
        <div className="border-bottom"></div>

        {users.map((item) => (
          <>
            <div className="user-details">
              <Link to={`listings/${item.userid}`} style={{ color: "black" }}>
                <div className="circle-name"></div>
                <img
                  src={item.ProfileImage}
                  className="user-image"
                  alt="logo"
                />
                <p className="user-name-homeStyle">{item.firstName}</p>
              </Link>
              <p className="user-emailAddress">
                {item.email ? item.email : "No Email"}
              </p>
              <div style={{ position: "relative" }}>
                <Link to={`listings/${item.userid}`}>
                  <button className="user-button">
                    <FaNewspaper className="notes" /> User Notes
                  </button>
                </Link>

                <button
                  className={"user-suspension"}
                  style={{
                    backgroundColor: selectedIds.includes(item.userid)
                      ? "red"
                      : "#f6c262",
                  }}
                  onClick={() => handleSelectionMultiple(item)}
                >
                  Suspension
                </button>

                <Link to={`orderhistory/${item.userid}`}>
                  <button className="user-button-bigg">Activities</button>
                </Link>

                <button
                  className="user-button-bigg2"
                  onClick={() => {
                    setButtonTitle("Delete");
                    setTitle("Delete Account");
                    setText(
                      "After you delete an account,it's permanently deleted."
                    );
                    setItem(item);

                    setDynamic(true);
                  }}
                >
                  Delete
                </button>

                <Link to={`refund/${item.userid}`}>
                  <button className="user-button-bigg3">History</button>
                </Link>

                <button
                  className="user-button-bigg3-new"
                  onClick={() => {
                    setButtonTitle("Send");
                    setTitle("Reset Password");
                    setText(
                      "Do you want to send a change password link to the user's account?."
                    );
                    setItem(item);

                    setDynamic(true);
                  }}
                >
                  Reset
                </button>
              </div>

              {/* <Link to="orderhistory">
                  <button className="user-button-bigg">Activities</button>
                </Link>

                <button className="user-button-del-full">Delete</button>

                <Link to="refund">
                  <button className="user-button-history-full">History</button>
                </Link>

                <button className="user-button-bigg3-full">Reset</button>
              </div> */}

              <div>
                <Link to="Chat">
                  <button className="user-button2">
                    <BiEnvelope class="notes" /> Message User
                  </button>
                </Link>

                <button className="user-button4" onClick={handleShow}>
                  01 Jan 21 | 31 Jan 21
                </button>
                <Modal class="date-modal" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Date</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="date-modal-from">
                      <h5>From</h5>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>

                    <div className="date-modal-to">
                      <h5>To</h5>
                      <DatePicker />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="border-bottom"></div>
          </>
        ))}
      </div>

      <Dynamic
        show={dynamic}
        buttonTitle={buttonTitle}
        account={item.email}
        text={text}
        close={() => {
          setDynamic(false);
        }}
        saveChanges={async () => {
          const users = doc(db, "SimpleUsers", item.userid);

          if (title === "Delete Account") {
          }
          if (title === "Reset Password") {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              data: {
                email: item.email,
                name: "Admin",
                message: `Please reset your password on this link  https://sandstrip.com/#/resetPassword/${item.userid}`,
              },
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            fetch(
              "https://us-central1-havazuse-376c7.cloudfunctions.net/sendEmail",
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {})
              .catch((error) => console.log("error", error));
          }
          if (title === "Activate Account") {
            console.log("SSSS");
            var selectedIdss = [...selectedIds]; // clone state

            selectedIdss = selectedIds.filter((_id) => _id !== item.userid);
            updateDoc(users, {
              suspension: false,
            });
            setSelectedIds(selectedIdss);

            setDynamic(false);
          }
          if (title === "Suspend Account") {
            console.log("SSSAAAS");

            var selectedIdss = [...selectedIds]; // clone state

            selectedIdss.push(item.userid);
            updateDoc(users, {
              suspension: true,
            });
            setSelectedIds(selectedIdss);

            setDynamic(false);
          }
        }}
      />
    </>
  );
}

export default AllUser;
