import React from "react";
import "./CircularProgress.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress2 = (props) => {
  const percentage = 78;

  return (
    <div>
      <CircularProgressbar
        className="progress2"
        value={percentage}
        text={`${percentage}%`}
      />
    </div>
  );
};

export default CircularProgress2;
