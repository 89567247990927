import React from "react";
import { Link } from "react-router-dom";
import "./ReturnBtn.css";

function ReturnBtn() {
  return (
    <>
      <Link to="/allcomment">
        <div className="banner-section">
          <div className="order-numbers">
            <h2 className="banner-text">Return Request</h2>
            <p>2022</p>
          </div>
          
        </div>
      </Link>
    </>
  );
}

export default ReturnBtn;
