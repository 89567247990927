import React from 'react';
import "./UserChat.css";
import user from '../../Assets/Images/product.png';

function UserChat() {
    return (

        <>
        <div className="chat-intro2">
            <img src={user} className="user-chat-image2" alt="logo" />
            <h6>Jane Doe</h6>
            <p>Lorem Ipsum</p>
            <p className="online">Active</p>
        </div>

        </>

    );
}

export default UserChat;