import React, { useState } from "react";

import "./Section.css";
import user from "../../Assets/Images/user1.jpg";
import { FaNewspaper } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

function Users() {
  const [id, setId] = useState(false);
  const users = useSelector((state) => state.Reducer.users);
  return (
    <>
      <div className="user-plus">
        <h3 className="plus-text">Users plus id verification</h3>
        <Link to="allverify">
          <p className="view-all">View All</p>
        </Link>
      </div>
      <div className="users-section">
        <div className="search-user-account">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>
        <ul className="user-list-plus">
          <li>Name</li>
          <li>Email</li>
          <li>Phone</li>
        </ul>
        <div className="border-bottom"></div>

        {users.map((item) => {
          if (
            item.IdVerification === true &&
            item.SelfieVerification === true
          ) {
            return (
              <>
                <div className="user-details">
                  <div className="circle-name"></div>
                  <img
                    src={item.ProfileImage}
                    className="user-image"
                    alt="logo"
                  />
                  <p className="user-name-plus">{item.firstName}</p>
                  <p className="user-email">
                    {item.email ? item.email : "No Email"}
                  </p>
                  <div className="plus-btn">
                    <button className="plus-button" onClick={() => setId(true)}>
                      Verify
                    </button>
                  </div>
                </div>
                <div className="border-bottom"></div>
                <Modal class="date-modal" show={id} onHide={() => setId(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Plus Verfication</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{}}>
                        <h5>User ID</h5>
                        <img src={item.userCard} />
                      </div>

                      <div>
                        <h5>User Face Front</h5>
                        <img src={item.userSelfie} />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setId(false)}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            );
          }
        })}
      </div>
    </>
  );
}

export default Users;
