import React, { useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Checkbox, Pagination, PaginationItem } from "@mui/material";
import { ReactComponent as ArrowIcon } from "../../Assets/Images/tab_arrow.svg";
import "./Table.css";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { tableMenuIcon } from "../../store/actions/actions";

const CommonTable = ({
  rowsPerPage,
  rows,
  columns,
  className,
  rowCheck = false,
  disableSelectionOnClick = true,
}) => {
  const tableMenu = useSelector((state) => state.Reducer.tableMenu);
  console.log("tableMenu",tableMenu);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      tableMenuIcon({
        open: false,
        data: {},
      })
    );
  }, []);
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageCountSize = useGridSelector(
      apiRef,
      gridPaginatedVisibleSortedGridRowIdsSelector
    );

    return (
      <>
        {rows.length ? (
          <p className="table-record-list">
            Showing {pageCountSize[0]} to{" "}
            {pageCountSize[pageCountSize.length - 1]} of {rows.length} entries
          </p>
        ) : null}
        {rows.length ? (
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            renderItem={(props2) => (
              <PaginationItem {...props2} disableRipple />
            )}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        ) : null}
      </>
    );
  }
  const CheckboxButton = (props) => {
    return <Checkbox color="success" {...props} />;
  };
  const CustomUnsortedIcon = (props) => {
    return <ArrowIcon {...props} />;
  };
  const onSelectMenu = (e) => {
    switch (e) {
      case "returnAndRefund":
        history.push(`/refund/${tableMenu?.data?.userId}`);
        return;
      case "fulfill":
        history.push("/orderinfo");
        return;
      default:
        return null;
    }
  };
  return (
    <div style={{ height: "calc(100vh - 310px)", width: "100%" }}>
      {tableMenu?.open && (
        <Dropdown
          drop={"start"}
          show={tableMenu?.open}
          className="menuIcon-dropdown-start"
          onSelect={onSelectMenu}
        >
          <Dropdown.Menu>
            <Dropdown.Item className="product-item" eventKey="reorder">
              Reorder
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="fulfill">
              Fulfill
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="cancel">
              Cancel
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="outOfStock">
              Out of Stock
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="markAsDelivered">
              Mark as delivered
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="shipped">
              Shipped
            </Dropdown.Item>
            <Dropdown.Item className="product-item" eventKey="returnAndRefund">
              Return and Refund
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <DataGrid
        rows={rows}
        autoHeight
        columns={columns || []}
        className={`${className} common-table`}
        pageSize={rowsPerPage}
        showCellRightBorder={true}
        components={{
          Pagination: CustomPagination,
          BaseCheckbox: CheckboxButton,
          ColumnUnsortedIcon: CustomUnsortedIcon,
          ColumnSortedAscendingIcon: CustomUnsortedIcon,
          ColumnSortedDescendingIcon: CustomUnsortedIcon,
        }}
        disableColumnMenu
        headerHeight={52}
        checkboxSelection={rowCheck}
        disableSelectionOnClick={disableSelectionOnClick}
        showColumnRightBorder
        rowsPerPageOptions={[rowsPerPage]}
      />
    </div>
  );
};

export default CommonTable;
