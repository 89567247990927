import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import "./ShowRedeem.css";
import { FiSearch } from "react-icons/fi";
import { BsFilter } from "react-icons/bs";
import product from "../../Assets/Images/product.png";
import EditProduct from "../EditProduct/EditProduct";
import EditMarket from "../EditMarket/EditMarket";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllProducts,
  getAllCategories,
  selectProduct,
} from "../../store/actions/actions";
const viewHeight = window.outerHeight;

function ShowRedeem() {
  const dispatch = useDispatch();
  const [pro, setPro] = useState([]);
  const [search, setSearch] = useState("z4tZeVD35u53rEq3LSvZ");
  const [catSearch, setCatSearch] = useState("");
  const [data, setData] = useState([]);
  const products = useSelector((state) => state.Reducer.redeem);
  const catnames = useSelector((state) => state.Reducer.categories);
  const [real, setReal] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectionMultiple = (item) => {
    var selectedIdss = [...selectedIds]; // clone state
    if (selectedIdss.includes(item.userid)) {
      selectedIdss = selectedIds.filter((_id) => _id !== item.Pid);
    } else {
      selectedIdss.push(item.Pid);
    }
    setSelectedIds(selectedIdss);
  };
  useEffect(() => {
    let newPro = [];
    console.log(products, "products");

    setData(products);
  }, [search]);

  const getCatName = (item) => {
    let name = catnames.filter((data) => data.Catid === item.Catid);
    console.log(name[0], "fazzy");
    return name[0] != undefined ? name[0].Catname : "Unknown";
  };

  const onChangeSearch = () => {
    let newA = products.filter((product) =>
      product.Pname.toLowerCase().includes(real.toLowerCase())
    );
    console.log(newA, "trtrtrt");
    setData(newA);
  };

  return (
    <>
      <div
        style={{
          width: "85%",
          float: "left",
          height: viewHeight,
          overflowX: "scroll",
        }}
      >
        <div className="products-heading">
          <h3>Redeem Products</h3>
          <p>{data.length} Products</p>
        </div>
        <Col className="search-area">
          <div>
            <FiSearch className="search-icon2" onClick={onChangeSearch} />
            <input
              className="search-bar2"
              placeholder="Search"
              value={real}
              onChange={(event) => setReal(event.target.value)}
            />
          </div>
        </Col>
        <div className="product-border"></div>

        <Form.Group
          className="mb-3 check-allproducts"
          controlId="formBasicCheckbox"
        >
          <Form.Check
            checked={selectAll}
            type="checkbox"
            label="Select All Products"
            onClick={() => {
              setSelectAll(!selectAll);
              if (selectAll) {
                setSelectedIds([]);
              } else {
                let newArray = [];
                data.map((item) => {
                  newArray.push(item.Pid);
                });
                setSelectedIds(newArray);
              }
            }}
          />
        </Form.Group>

        
        <Link to="/All">
          <div>
            <button className="pending-btn">All Products</button>
          </div>
        </Link>

        <div>
          <button className="pending-btn">Pending Products</button>
        </div>

        {data.map((item) => (
          <div className="product-card">
            <Form.Group
              className="mb-3 check-allproducts2"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                type="checkbox"
                checked={selectedIds.includes(item.Pid) ? true : false}
                onClick={() => handleSelectionMultiple(item)}
              />
            </Form.Group>

            <Link
              onClick={() => {
                dispatch(selectProduct(item));
              }}
              to="/updateProduct"
              style={{ color: "black", textDecoration: "none" }}
            >
              <img src={item.Pimages[0]} className="product-image" alt="logo" />
              <div className="product-desc">
                <p className="name">Product Name</p>
                <h6 className="product-name">{item.Pname}</h6>
              </div>
            </Link>
            <div className="product-desc">
              <Link to="productreviews">
                <button className="review-btn">Reviews & Comments</button>
                {/* <p className="name">Marketplace</p>
                <h6 className="product-name">Trendyol, Joom</h6>
                <p className="name">4 more places</p> */}
              </Link>
            </div>

            <div className="product-desc">
              <p className="name">Inventory</p>
              <h6 className="product-name">0 in stock for</h6>
              <p className="name">0 variants</p>
            </div>

            <div className="product-desc">
              <p className="name">Location</p>

              <h6 className="product-name">New York</h6>
            </div>

            <div className="product-desc">
              <p className="name">Category Name</p>

              <h6 className="product-name">{getCatName(item)}</h6>
            </div>

            <div className="product-desc">
              <p className="name">Parent SKU</p>
              <h6 className="product-name">GM00001609982</h6>
            </div>

            {/* <button onClick={()=>setShow(true)} className="quick-btn">Quick Edit</button> */}
            <div>
              <EditMarket item={item} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ShowRedeem;
