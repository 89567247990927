import React from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./Search.css";

function Search() {
  return (
    <div className="searchSection">
      <FiSearch className="search-icon" />
      <input className="searchBar" placeholder="Search" type="text" />
      <Link to={"/searches"}>
        <button className="searchButton">Use Word Searches</button>
      </Link>
    </div>
  );
}

export default Search;
