import React from "react";
import { Button } from "react-bootstrap";
import "./AllVerify.css";
import user from "../../Assets/Images/user1.jpg";
import { FaNewspaper } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

function AllVerify() {
  const users = useSelector((state) => state.Reducer.users);
  return (
    <>
      <div className="alluser-header">
        <div className="search-user">
          {/* <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." /> */}
        </div>

        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div>
        <div className="not-image">
          <img src={user} className="not-image-user" alt="logo" />
          <p className="not-image-text">John Doe</p>
        </div>
      </div>
      <div>
        <h3 className="plus-text">Users plus id verification</h3>
      </div>
      <div className="users-section-allverify">
        <div className="search-user-account">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>
        <ul className="user-list-plus">
          <li>Name</li>
          <li>Email</li>
          <li>Phone</li>
        </ul>
        <div className="border-bottom"></div>

        {users.map((item) => {
          if (
            item.IdVerification === true &&
            item.SelfieVerification === true
          ) {
            return (
              <>
                <div className="user-details">
                  <div className="circle-name"></div>
                  <img
                    src={item.ProfileImage}
                    className="user-image"
                    alt="logo"
                  />
                  <p className="user-name-plus">{item.firstName}</p>
                  <p className="user-email">
                    {item.email ? item.email : "No Email"}
                  </p>
                  <div className="plus-btn">
                    <Link to="verification">
                      <button className="plus-button">Verify</button>
                    </Link>
                  </div>
                </div>
                <div className="border-bottom"></div>
              </>
            );
          }
        })}
      </div>
    </>
  );
}

export default AllVerify;
