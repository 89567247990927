import React, {useState} from "react"
import WorldMap, { CountryContext } from "react-svg-worldmap";
import ReactDOM from "react-dom";
import "./MapCard.css";
import MapPercent from "../MapPercent/MapPercent";

// const React, { useState } = require('react');
// const ReactDOM = require('react-dom');
// const WorldMap = require('react-world-map');
const data = [
  { country: "cn", value: 1389618778 }, // china
  { country: "in", value: 1311559204 }, // india
  { country: "us", value: 331883986 }, // united states
  { country: "id", value: 264935824 }, // indonesia
  { country: "pk", value: 210797836 }, // pakistan
  { country: "br", value: 210301591 }, // brazil
  { country: "ng", value: 208679114 }, // nigeria
  { country: "bd", value: 161062905 }, // bangladesh
  { country: "ru", value: 141944641 }, // russia
  { country: "mx", value: 127318112 }, // mexico
];
function MapCard() {
    const [selected, onSelect] = useState("");
  const [number, setNumber] = useState(0);
  const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
  const clickAction = ({ countryName, countryCode, countryValue }) => {
    onSelect(countryName);
    let random = Math.floor(Math.random() * 10);
    setNumber(random);
  };
  return (
    <>
    <div className="map-section2">
    <WorldMap
              onClickFunction={clickAction}
              color="#3fad72"
              value-suffix="people"
              size="sm"
              data={data}
            />
      </div>
    </>
  );
}

export default MapCard;