import React, { useEffect, useState } from "react";
import "./Chat.css";
import { BsFillChatDotsFill } from "react-icons/bs";
import user from "../../Assets/Images/product.png";
import { IoIosArrowUp } from "react-icons/io";
import chat from "../../Assets/Images/chat.png";
import { GoPrimitiveDot } from "react-icons/go";
import { GrAttachment } from "react-icons/gr";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { RiSendPlane2Fill } from "react-icons/ri";
import UserCards from "../UserCards/UserCards";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import calling from "../../Assets/Images/calling.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleUSer,
  getUserMessages,
  hide,
  addMessage,
} from "../../store/actions/actions";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");

  const userChat = useSelector((state) => state.Reducer.singleUser);
  const userMessages = useSelector((state) => state.Reducer.userMessages);

  const dispatch = useDispatch();

  const sendMessges = async () => {
    if (userMessages.length) {
      dispatch(
        addMessage({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        })
      );
      const chatMessages = doc(
        db,
        "CustomerSupportChat",
        props.match.params.id
      );

      await updateDoc(chatMessages, {
        messages: arrayUnion({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        }),
      });
      setMsg("");
    } else {
      dispatch(
        addMessage({
          reciverId: props.match.params.id,
          senderId: "Admin",
          message: msg,
        })
      );
      const chatMessages = doc(
        db,
        "CustomerSupportChat",
        props.match.params.id
      );

      await setDoc(chatMessages, {
        messages: [
          {
            reciverId: props.match.params.id,
            senderId: "Admin",
            message: msg,
          },
        ],
      });
      setMsg("");
    }
  };

  useEffect(() => {
    dispatch(getSingleUSer(props.match.params.id));
    dispatch(getUserMessages(props.match.params.id));

    dispatch(hide(true));
  }, []);
  return (
    <>
      <div className="chat-section">
        <div className="active-section">
          <img src={calling} />
          <div className="chat">
            <BsFillChatDotsFill className="chat-icon" />
            <h6>QuickChat</h6>
          </div>

          <div className="search-area-product">
            <div>
              <FiSearch className="search-icon-chat" />
              <input className="search-bar-chat" placeholder="Search" />
            </div>
          </div>

          <Link to="/listings/7brAGglGWiU58qTeDUaf0Np97y43">
            <div className="chat-intro">
              <img
                src={userChat.ProfileImage}
                className="user-chat-image"
                alt="logo"
              />
              <h6>{userChat.firstName}</h6>
              <p>Lorem Ipsum</p>
              {/* <p className="online">Active</p> */}
            </div>
          </Link>

          <div className="active-chats">
            <h6>Active Conversations</h6>
          </div>
          <div className="online-chats">
            <p className="num-chats">0</p>

            <IoIosArrowUp className="collapse-icon" />
          </div>
          <Link
            to="/sec"
            color="black"
            style={{ textDecoration: "none", color: "black", marginTop: 10 }}
            onClick={() => dispatch(hide(false))}
          >
            <div className="active-image active3">
              <img
                src={userChat.ProfileImage}
                className="active-user1"
                alt="logo"
              />
              <p>{userChat.firstName}</p>
            </div>
          </Link>
        </div>
        <div className="chat-section2">
          <div style={{ overflowY: "scroll", height: 500 }}>
            {userMessages.map((item) => {
              return (
                <div className="receiver">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC_GTSOMv5MWxOHBLROf2ExOHFKpZpaDqQRw&usqp=CAU"
                    }
                    className="receiver-image"
                    alt="logo"
                  />
                  <GoPrimitiveDot className="online-dot" />
                  <div className="receiver-chat-border">
                    <p>{item.message}</p>
                  </div>
                  <p className="chat-ago">9h ago</p>
                </div>
              );
            })}
          </div>
          <div className="send-button-border">
            <GrAttachment className="attach" />
            <input
              className="message-input"
              type="text"
              value={msg}
              onChange={(event) => setMsg(event.target.value)}
            />
            <HiOutlineEmojiHappy className="emoji" />
            <button className="chat-send-btn" onClick={sendMessges}>
              Send
              <RiSendPlane2Fill className="send-icon" />
            </button>
          </div>
        </div>

        <div className="usercards-section">
          <UserCards />
        </div>
      </div>
    </>
  );
}

export default Chat;
