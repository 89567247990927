import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hide } from "../../store/actions/actions";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../firebase/firebase";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import laptop from "../../../src/Assets/Images/warehouse.jpeg";
import logo from "../../../src/Assets/Images/logo1.jpg";

const viewHeight = window.outerHeight;

function Register(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    dispatch(hide(true));
  }, []);

  const updateData = async () => {
    console.log(props.match.params.id);
    if (props.match.params.id) {
      if (
        first.length &&
        last.length &&
        password === confirm &&
        password.length
      ) {
        const pRef = doc(db, "CustomerService", `${props.match.params.id}`);
        let data = {
          FirstName: first,
          LastName: last,

          password: password,
        };
        await updateDoc(pRef, data);
        history.push("/inner");
      } else {
        toast(
          password != confirm
            ? "Password does not match!"
            : "Some Error has occur please try again later."
        );
      }
    }
  };
  return (
    <>
      <div className="register-card" style={{ height: viewHeight }}>
        <div className="dark-overlay" style={{ height: viewHeight }}></div>
        <img src={laptop} style={{ width: "70%", opacity: 0.4 }} />
        <div style={{ float: "left", width: "100%" }}>
        <div className="register-logo" style={{marginTop:"20px"}}>
            <img src={logo}/>
          </div>
          <p
            style={{
              fontSize: 28,
              fontWeight: 800,
              marginTop: 50,
              marginLeft: 40,
            }}
          >
            Complete Your Profile
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "85%",
            }}
          >
            <div style={{ width: "100%", marginLeft: "40px" }}>
              <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                First Name
              </p>
              <input
                className="register-field"
                type="text"
                placeholder="Name"
                value={first}
                onChange={(event) => setFirst(event.target.value)}
              />
            </div>

            <div
              style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
            >
              <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                Last Name
              </p>
              <input
                className="register-field"
                type="text"
                placeholder="Name"
                value={last}
                onChange={(event) => setLast(event.target.value)}
              />
            </div>

            <div
              style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
            >
              <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                Password
              </p>
              <input
                className="register-field"
                type="password"
                placeholder="New"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <div
              style={{ width: "100%", marginLeft: "40px", marginTop: "20px" }}
            >
              <p style={{ marginBottom: "10px", fontWeight: "700" }}>
                Confirm Password
              </p>
              <input
                className="register-field"
                type="password"
                placeholder="Confirm"
                value={confirm}
                onChange={(event) => setConfirm(event.target.value)}
              />
            </div>
          </div>
          {/* <div
          style={{
            width: "100%",
            maxWidth: "100%",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <input
            className="register-name"
            type="text"
            placeholder="First Name"
            value={first}
            onChange={(event) => setFirst(event.target.value)}
          />
          <input
            className="register-lastname"
            type="text"
            placeholder="Last Name"
            value={last}
            onChange={(event) => setLast(event.target.value)}
          />
        </div> */}

          {/* <div
          style={{
            float: "left",
            width: "100%",
            maxWidth: "100%",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <input
            className="register-password"
            type="text"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div> */}

          {/* <div
          style={{
            float: "left",
            width: "100%",
            maxWidth: "100%",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <input
            className="register-confirm-password"
            type="text"
            placeholder="Confirm Password"
            value={confirm}
            onChange={(event) => setConfirm(event.target.value)}
          />
        </div> */}

          <div
            style={{
              float: "left",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <input
              className="register-check"
              type="checkbox"
              id="accept"
              name="accept"
            />
            <label className="register-label" for="accept">
              I accept{" "}
              <span style={{ color: "#000", fontWeight: 600 }}>
                Terms of Use
              </span>
            </label>
          </div>

          <div
            style={{
              float: "left",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <button
              className="register-button"
              onClick={() => {
                updateData();
              }}
            >
              <p
                style={{
                  marginTop: 10,
                  color: "#FFFFFF",
                  marginBottom: "12px",
                }}
              >
                Update
              </p>
            </button>
          </div>
          <div
            style={{
              float: "left",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {login ? (
              <button className="register-button">
                <Link to="/login">Login</Link>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Register;
