import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import ReactPlayer from "react-player";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import { ReactComponent as MenuIcon } from "../../Assets/Images/dot-menu.svg";
import { ReactComponent as ThumbUpIcon } from "../../Assets/Images/thumb_up.svg";
import { ReactComponent as ThumbDownIcon } from "../../Assets/Images/thumb_down.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/View.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/delete.svg";
import { ReactComponent as ProductImg } from "../../Assets/Images/product.svg";
import { ReactComponent as ChatIcon } from "../../Assets/Images/chat_black.svg";
import { ReactComponent as PauseIcon } from "../../Assets/Images/puse-video.svg";
import { useSelector, useDispatch } from "react-redux";
import CustomDropdown from "../Dropdown";
import { Tooltip } from "@mui/material";
import user from "../../Assets/Images/user1.jpg";
import { getAllLive, RemoveFeedLive } from "../../store/actions/actions";
import { Dropdown } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const LiveVideos = () => {
  const [selectedDetails, setSelectedDetails] = useState({});
  const [videoPlay, setVideoPlay] = useState(false);
  const dispatch = useDispatch();
  const live = useSelector((state) => state.Reducer.liveStreams);
  const [liveVideos, setLiveVideos] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const roleRef = useRef();
  useEffect(() => {
    dispatch(getAllLive());
  }, []);
  useEffect(() => {
    setLiveVideos(live);
  }, [live]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!roleRef?.current?.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [roleRef]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const actionPerform = (action) => {
    switch (action) {
      case "like":
        console.log("Like");
      case "unlike":
        console.log("unlike");
      default:
        return "";
    }
  };

  return (
    <div className="add-category-section short-videos">
      <div className="d-flex justify-content-between align-items-center search-table-header">
        <div className="d-flex align-items-center">
          <BreadcrumbMenu path={[]} activeTab="Live" isBack={false} />
        </div>
        <div className="d-flex video-filter">
          <CustomDropdown
            option={["Admin", "Vendor", "Customer Service"]}
            placeholder="Select Role"
            width="100%"
            borders={true}
            fullArrow={true}
            dropdownContentHeight={"auto !important"}
            name="role"
            selectedValue="Filter"
            // handleChangeRowsPerPage={async (value) =>
            //   userForm.setFieldValue("role", value)
            // }
            searchBar={true}
            isActive={isActive}
            setIsActive={setIsActive}
            ref={roleRef}
          />
          <div className="searchSection d-flex">
            <FiSearch className="search-icon" />
            <input
              className="searchBar searchbar-table"
              placeholder="Search"
              type="text"
              // onChange={(e) => requestSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {liveVideos && liveVideos.length ? (
          liveVideos.map((video, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-12">
              <div className="video-cards">
                <div className="video-main">
                  <ReactPlayer
                    className="vid-player"
                    playing
                    width={"unset"}
                    height={264}
                    onClick={() => {
                      setVideoPlay(true);
                      setSelectedDetails(video);
                      handleShow();
                    }}
                    playIcon={
                      <PauseIcon
                        className="PauseIcon"
                        color="#3FAD72"
                        style={{
                          height: 50,
                          width: 50,
                        }}
                      />
                    }
                    light={video.thumbnail}
                    url={video.feedurl}
                  />
                  <div className="profile-thumb d-flex">
                    <div className="profile-photo">
                      <img
                        src={video.ProfileImage || user}
                        alt={`user_pic_${index}`}
                      />
                    </div>
                    <h6>{video?.Name || ""}</h6>
                  </div>
                  <div className="video-timer">
                    <p>{video?.duration || "00:00"}</p>
                  </div>
                </div>
                <div className="generalMedia">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex m-0 p-0">{video.LiveStreamTitle || ''}</p>
                    <Dropdown
                      drop={"start"}
                      className="menuIcon-dropdown-start"
                    >
                      <Dropdown.Toggle
                        style={{ all: "unset", cursor: "pointer" }}
                      >
                        <MenuIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="product-item">
                          <ProductImg /> Product Detail
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="delete-item"
                          onClick={() => {
                            dispatch(RemoveFeedLive(video.videoid));
                          }}
                        >
                          <DeleteIcon /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="upload-date">{video?.createdAt ? moment(
                  new Date(
                    video?.createdAt * 1000
                  ).toLocaleDateString("en-US")
                ).format("DD MMM YYYY") : ''}</div>
                  <div
                    className="d-flex justify-content-around social-icon mx-auto"
                    style={{ width: "90%" }}
                  >
                    <div className="text-center icon-like">
                      <div>{video?.likes || "0"}</div>
                      <ThumbUpIcon
                        className="cursor-pointer"
                        onClick={() => actionPerform("like")}
                      />
                    </div>
                    <div className="text-center icon-like">
                      <div>{video?.dislikes || "0"}</div>
                      <ThumbDownIcon
                        className="cursor-pointer"
                        onClick={() => actionPerform("unlike")}
                      />
                    </div>
                    <div className="text-center icon-like">
                      <div>{video?.comments || 0}</div>
                      <Tooltip title="User Message" placement="top">
                        <Link to={`/videos/live/comments/${video?.videoid}`}>
                          <ChatIcon
                            className="cursor-pointer"
                            onClick={() => actionPerform("comment")}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                    <div className="text-center icon-like">
                      <div>{video?.views || "0"}</div>
                      <ViewIcon
                        className="cursor-pointer"
                        onClick={() => actionPerform("views")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No videos found</div>
        )}
      </div>
      <Modal className="modal-video" show={show} onHide={handleClose}>
        <Modal.Header className="video-modal-header" closeButton />

        <Modal.Body>
          <div className="model-user-info d-flex">
            {/* <Link to={`listings/${selectedDetails.userid}`} style={{ color: "black" }}>
             
            </Link> */}
            {/* <div className="profile-pic">
              <img
                src={
                  selectedDetails?.ProfileImage
                    ? selectedDetails?.ProfileImage
                    : user
                }
                alt="logo"
              />
            </div>
            <p>{selectedDetails?.uploaderName || ""}</p> */}
          </div>

          <ReactPlayer
            className="vid-player"
            playing={videoPlay}
            controls={true}
            width="100%"
            height={452}
            onPause={() => setVideoPlay(false)}
            playIcon={
              <PauseIcon
                className="PauseIcon"
                color="#3FAD72"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            }
            light={selectedDetails?.thumbnail}
            url={selectedDetails?.feedurl}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LiveVideos;
