import React, { useEffect, useState } from "react";
import CircularProgress3 from "../../Progress3/Progress3";
import CircularProgress from "../CircularProgress/CircularProgress";
import CircularProgress2 from "../CircularProgress/Progress2";
import "./Percentages.css";
import circular from "../../Assets/Images/circular.jpg";

const Percentages = (props) => {
  const [totalLikes, setTotalLikes] = useState(0);
  const [totaldisLikes, setTotalDisLikes] = useState(0);
  const [totalViews, setTotalViews] = useState(0);

  useEffect(() => {
    let disLikes = [];
    let likes = [];
    let views = [];
    if (props.feeds) {
      props.feeds.map((item) => {
        disLikes.push(item.dislikes);
        likes.push(item.likes);
        views.push(item.views);
      });
    }
    setTotalLikes(likes.reduce((a, b) => a + b, 0));
    setTotalDisLikes(disLikes.reduce((a, b) => a + b, 0));
    setTotalViews(views.reduce((a, b) => a + b, 0));
  }, [props.feeds]);
  return (
    <>
      <div
        className="percentages-section"
        onClick={() => {
          console.log(
            props.feeds,
            "Rerererer",
            [1, 2, 3, 4].reduce((a, b) => a + b, 0)
          );
        }}
      >
        <div>
          <p className="percent-left">Feeds</p>
          <p className="percent-right">Views</p>
        </div>
        <CircularProgress percentage={totalViews / 100} />
        <div>
          <p className="percent-left2">Total</p>
          <p className="percent-right2">{totalViews}</p>
        </div>
        <div className="percent-border"></div>

        <div>
          <p className="percent-left">Feeds</p>
          <p className="percent-right">Likes</p>
        </div>
        <CircularProgress2 percentage={totalLikes / 100} />
        <div>
          <p className="percent-left2">Total</p>
          <p className="percent-right2">{totalLikes}</p>
        </div>
        <div className="percent-border"></div>
        <div>
          <p className="percent-left">Feeds</p>
          <p className="percent-right">Dislikes</p>
        </div>
        <div style={{ marginTop: 10 }}>
          <CircularProgress percentage={totaldisLikes / 100} />
        </div>
        <div>
          <p className="percent-left2">Total</p>
          <p className="percent-right2">{totaldisLikes}</p>
        </div>
        <div className="percent-border"></div>

        <div>
          <p className="percent-left">Total</p>
          <p className="percent-right">Feeds</p>
        </div>
        <CircularProgress3 percentage={props.feeds.length / 100} />
        <div>
          <p className="percent-left2">Total</p>
          <p className="percent-right2">{props.feeds.length}</p>
        </div>
      </div>
    </>
  );
};

export default Percentages;
