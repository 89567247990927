import React, { useState } from "react";
import "./ManagePayments.css";
import { AiTwotoneLock } from "react-icons/ai";
import visacard from "../../Assets/Images/visacard.jpg";
import { CgArrowsV } from "react-icons/cg";
import applecard from "../../Assets/Images/applecard.jpg";
import applecash from "../../Assets/Images/applecash.jpg";
import PaymentToggle from "../PaymentToggle/PaymentToggle";

function ManagePayments() {
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <h3 className="payment-heading">Manage Payments</h3>
        <div className="connect-sect">
         <button className="process-payment">Process Payment</button>
        </div>
      </div>
      <h6 style={{ marginTop: "50px", marginLeft: "50px", float: "left" }}>
        Payment Methods
      </h6>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
          marginTop: "50px",
        }}
      >
        <div className="card1-details">
          <img src={visacard} />
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Visa</p>
            <p style={{ fontSize: "12px" }}>....6366</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>User Name</p>
            <p style={{ fontSize: "12px" }}>fazzy</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Address</p>
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>

          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Phone</p>
            <p style={{ fontSize: "12px" }}>01002233445</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="default-toggle">
            <PaymentToggle
              value={one}
              handleChange={() => {
                setOne(true);
                setTwo(false);
                setThree(false);
                setFour(false);
              }}
            />
            <p>Set as default</p>
          </div>
        </div>
      </div>
      <div className="pay-bb"></div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
          marginTop: "50px",
        }}
      >
        <div className="card1-details">
          <img src={applecard} />
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Visa</p>
            <p style={{ fontSize: "12px" }}>....6366</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>User Name</p>
            <p style={{ fontSize: "12px" }}>fazzy</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Address</p>
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Phone</p>
            <p style={{ fontSize: "12px" }}>01002233445</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="default-toggle">
            <PaymentToggle
              value={two}
              handleChange={() => {
                setOne(false);
                setTwo(true);
                setThree(false);
                setFour(false);
              }}
            />
            <p>Set as default</p>
          </div>
        </div>
      </div>

      <div className="pay-bb"></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
          marginTop: "50px",
        }}
      >
        <div className="card1-details">
          <img src={visacard} />
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Visa</p>
            <p style={{ fontSize: "12px" }}>....6366</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>User Name</p>
            <p style={{ fontSize: "12px" }}>fazzy</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Address</p>
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>

          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Phone</p>
            <p style={{ fontSize: "12px" }}>01002233445</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="default-toggle">
            <PaymentToggle
              value={three}
              handleChange={() => {
                setOne(false);
                setTwo(false);
                setThree(true);
                setFour(false);
              }}
            />
            <p>Set as default</p>
          </div>
        </div>
      </div>

      <div className="pay-bb"></div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
          marginTop: "50px",
        }}
      >
        <div className="card1-details">
          <img src={applecash} />
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Visa</p>
            <p style={{ fontSize: "12px" }}>....6366</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>User Name</p>
            <p style={{ fontSize: "12px" }}>fazzy</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Address</p>
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>

          <div className="card-num-detail">
            <p style={{ fontWeight: "600" }}>Phone</p>
            <p style={{ fontSize: "12px" }}>01002233445</p>{" "}
            {/* <p style={{ fontSize: "12px" }}>fazzy syed</p>{" "}
            <p style={{ fontSize: "12px" }}>1024 Peshawar</p>{" "} */}
          </div>
          <div className="default-toggle">
            <PaymentToggle
              value={four}
              handleChange={() => {
                setOne(false);
                setTwo(false);
                setThree(false);
                setFour(true);
              }}
            />
            <p>Set as default</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagePayments;
