import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import "./OrderMenu.css";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import AddUser from '../AddUser/AddUser';
import { AiFillDatabase } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";



export default function OrderMenu() {

  const [anchorEl, setAnchorEl] = React.useState(null);

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>

    <div style={{cursor:"pointer"}} onClick={handleClick}>
      <BiDotsVerticalRounded className="user-dots" aria-controls="order-menu" 
      aria-haspopup="true"/>
      </div>
      <Menu
        id="order-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
          <MenuItem className="return-menu" onClick={handleClose}>

            <Link to="" style = {{textDecoration:"none",color:"white",textAlign:"center",alignSelf:"center"}} >
             Reorder
          </Link>
          </MenuItem>
    
        <MenuItem className="return-menu" onClick={handleClose}>

        <Link to="/orderinfo" style = {{textDecoration:"none",color:"white",textAlign:"center",alignSelf:"center"}} >
            Fulfill
        </Link>
        </MenuItem>

        <MenuItem className="return-menu" onClick={handleClose}>
            Cancel
        </MenuItem>

        <MenuItem className="return-menu" onClick={handleClose}>
            Out of Stock
        </MenuItem>

        <MenuItem className="return-menu" onClick={handleClose}>
            Mark as delivered
        </MenuItem>



      </Menu>

      
     
    </div>
  );
}
