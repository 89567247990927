import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { GiStarShuriken } from "react-icons/gi";
import { RiEditCircleFill } from "react-icons/ri";
import user from "../../Assets/Images/user1.jpg";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";

const UserVideoProfile = ({ userDetails }) => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="user-videos-tab">
      <div className="video-card2">
        <p className="video-views">Total Views: 0</p>
        <p className="video-points">
          <GiStarShuriken className="star-points" />{userDetails?.points || 0} Points
        </p>
        <img
          src={userDetails?.userDetails?.ProfileImage}
          className="video-user"
          alt="logo"
        />
        <div className="videouser-info">
          <RiEditCircleFill
            className="edit-btn"
            onClick={() => setEdit(!edit)}
          />
          <h3>@{userDetails?.userDetails?.firstName}</h3>
          <p>Active</p>
        </div>
        <div className="name-details">
          <p className="first-name">First Name:</p>
          {edit ? (
            <input className="user-name-input" type="text" />
          ) : (
            <p className="user-name-video">
              {userDetails?.userDetails?.firstName}
            </p>
          )}
        </div>
        <div className="name-details">
          <p className="first-name">Last Name:</p>

          {edit ? (
            <input className="user-name-input" type="text" />
          ) : (
            <p className="user-name-video">
              {userDetails?.userDetails?.lastName}
            </p>
          )}
        </div>
        <div className="name-details">
          <p className="first-name">Email:</p>

          {edit ? (
            <input className="user-name-input" type="text" />
          ) : (
            <p className="user-name-video2">
              {userDetails?.userDetails?.email
                ? userDetails?.userDetails?.email
                : "anonymous"}
            </p>
          )}
        </div>

        <div className="name-details">
          <p className="first-name">Contact:</p>

          <input className="user-name-input" type="text" />
        </div>
        <div className="name-details">
          <p className="first-name">From:</p>
          <input className="user-name-input" type="text" />
        </div>
        <div className="name-details">
          <p className="first-name">Havazues balance:</p>
          <input className="user-name-input" type="text" />
        </div>

        <Link to="/orderhistory">
          <button className="user-activities">Activities</button>
        </Link>

        <Link to="/Chat">
          <button className="user-contact">Contact</button>
        </Link>

        <button className="user-suspend">Suspension</button>
        {/* <button className="user-date">
          1 Jan 2021<span className="date-border"></span>
          31 Jan 2021
        </button> */}

        <Button className="user-date" onClick={handleShow}>
          1 Jan 2021<span className="date-border"></span>
          31 Jan 2021
        </Button>

        <Modal class="date-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-modal-from">
              <h5>From</h5>
              <DatePicker />
            </div>

            <div className="date-modal-to">
              <h5>To</h5>
              <DatePicker />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal class="date-modal" show={id} onHide={() => setId(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Plus Verfication</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{}}>
                <h5>User ID</h5>
                <img src={userDetails?.userDetails?.userCard} />
              </div>

              <div>
                <h5>User Face Front</h5>
                <img src={userDetails?.userDetails?.userSelfie} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setId(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <button className="user-delete">Delete</button>

        <div className="video-border"></div>

        <div className="user-followers">
          <p className="followers">Media Followers:</p>
          <p className="followers-num">7,890</p>
        </div>
        <div className="user-followers">
          <p className="followers">Media Following:</p>
          <p className="followers-num">500</p>
        </div>
        <div className="user-followers-store">
          <p className="followers">Store Following:</p>
          <p className="followers-num">500</p>
        </div>

        <button className="user-verification" onClick={() => setId(true)}>
          Havazuse plus verification
        </button>
      </div>
    </div>
  );
};

export default UserVideoProfile;
