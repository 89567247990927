import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/product.png";
import iphone from "../../Assets/Images/iphone.png";
import { FiSearch } from "react-icons/fi";
// import { Tooltip } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
// import {
//   getFirestore,
//   collection,
//   getDocs,
//   query,
//   where,
//   updateDoc,
//   doc,
//   deleteDoc,
// } from "firebase/firestore";
// import { db } from "../../firebase/firebase";
import { userProductHistory } from "../../store/actions/actions";
import BreadcrumbMenu from "../Breadcrumb/Breadcrumb";
import TableFilterBar from "../TableFilterBar";
import CommonTable from "../Table";
import "./OrderHistory.css";

const columns = [
  {
    field: "id",
    renderHeader: () => {
      return <strong>{"#"}</strong>;
    },

    width: 60,
    renderCell: (params) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          {params?.id}
        </div>
      );
    },
  },
  {
    field: "Items",
    headerName: "Items",
    width: 300,
    sorting: true,
    renderCell: (params) => {
      return (
        <div className="d-flex align-items-center user">
          <img
            className="user-profile-pic"
            src={params?.row?.Pimages[0] || iphone}
            alt={params?.row?.name || iphone}
            width={26}
            height={35}
          />
          {params?.row?.Pname}
        </div>
      );
    },
  },
  { field: "Time", headerName: "Date/Time", width: 300, sorting: true },
  { field: "Quantity", headerName: "Quantity", width: 175 },
  {
    field: "Total",
    headerName: "Total",
    width: 190,
    sortable: true,
  },
  {
    field: "Details",
    headerName: "Details",
    width: 400,
    sortable: true,
  },
];

const dateSet = (item) => {
  var dateString = item;
  dateString = new Date(dateString).toUTCString();
  dateString = dateString.split(" ").slice(0, 4).join(" ");
  return dateString;
};

function OrderHistory(props) {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.Reducer.userHistory);
  const [rowsPerPage, setRowsPerPage] = useState({ pageSize: 10, all: false });
  const [productList, setProductList] = useState({products: [], list: []});
 

  const handleChangeRowsPerPage = (value) => {
    let selectValue = parseInt(value, 10);
    let allValue = false;
    if (value === "All") {
      allValue = true;
      selectValue = productList?.products?.length;
    }
    setRowsPerPage({ pageSize: selectValue, all: allValue });
  };

  useEffect(() => {
    dispatch(userProductHistory(props.match.params.id));
  }, []);

  useEffect(() =>{
    let productDetails = [];
    console.log("products",products);
    products && products.length && products.map((product, index) =>{
      productDetails.push({
        id: index + 1,
        Pname: product?.Pname,
        dateTime: "Tue Apr 09, 12:00 AM",
        Total: product?.Price,
        Pimages: product?.Pimages,
        Quantity: 1,
        Details: product?.description
      })
    });
    setProductList({products: [...productDetails], list: [...productDetails]});
  }, [products]);

  const requestSearch = (searchedVal) => {
    let filteredRows = [];
    if (searchedVal.length) {
      filteredRows =
        productList &&
        productList.products &&
        productList.products.length &&
        productList.products.filter((row) => {
          return (
            row?.Pname?.toString()?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
            row?.Total?.toString()?.toLowerCase()?.includes(searchedVal.toLowerCase())
          );
        });
    } else {
      filteredRows = productList?.list;
    }
    setProductList({
      ...productList,
      products: filteredRows,
    });
  };

  const getDateRangeData = () => {

  }

  return (
    <>
      {/* <div style={{ float: "left", width: "85%" }}>
        <div className="search-user">
          <FiSearch className="usersearch-icon" />
          <input className="input-search" type="text" placeholder="Search..." />
        </div>

        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />
        </div>
      </div>

      <h3 className="order-history">Activities</h3>

      <div className="history-section">
        <ul>
          <li>Items</li>
          <li>Date/Time</li>
          <li>Quantity</li>
          <li>Total</li>
          <li>Details</li>
        </ul>

        <div className="history-bottom"></div>
        {products.map((item) => (
          <>
            <div className="iphone-hitory">
              <img
                src={item.Pimages[0]}
                className="history-product"
                alt="logo"
              />
              <p className="history-name">{item.Pname}</p>
              <p className="history-date">12-Sep-21, 12:00 AM</p>
              <p className="history-num">1</p>
              <p className="history-total">{item.Price}</p>
              <p className="history-detail">{item.description}</p>
            </div>
            <div className="history-bottom"></div>
          </>
        ))}
      </div> */}
      <div className="add-category-section userActivity">
        <BreadcrumbMenu
          path={[
            { title: "Home", navLink: "/" },
            { title: "Activities", navLink: "/useractivity" },
          ]}
          activeTab="Activities"
          isBack={false}
        />
        <TableFilterBar
          selectValue={rowsPerPage.all ? "All" : rowsPerPage?.pageSize}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <div className="searchSection d-flex">
            <FiSearch className="search-icon" />
            <input
              className="searchBar searchbar-table"
              placeholder="Search"
              type="text"
              onChange={(e) => requestSearch(e.target.value)}
            />
          </div>
        </TableFilterBar>
        <CommonTable
          columns={columns}
          rows={productList?.products || []}
          className="usersTable"
          rowsPerPage={rowsPerPage?.pageSize}
        />
      </div>
    </>
  );
}

export default OrderHistory;
