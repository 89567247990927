import React from 'react';
import './Coupon.css';
import coupon from '../../Assets/Images/coupon.png';
import { Link } from "react-router-dom";

function Coupon() {
    return (
        <div className="coupon-section">
            <Link to = "CreateCoupon">
            <div className="coupon-inner">
            <div className="coupon-content">
            <img src={coupon} className="coupon-image" alt="logo" />   
            <p className="coupon-text">Create Coupons</p>       
            </div>  
            </div>
            </Link>
        </div>
    );
}

export default Coupon;