import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Category.css";
import { db } from "../../firebase/firebase";
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  addCategory,
  removeCategory,
  updateCategory,
} from "../../store/actions/actions";
import { AiFillPlusCircle } from "react-icons/ai";
import { BreadcrumbItem } from "react-bootstrap";

function VendorCategory() {
  const dispatch = useDispatch();
  const catnames = useSelector((state) => state.Reducer.categories);
  const product = useSelector((state) => state.Reducer.products);
  const [cat, setCat] = useState("");
  const [yes, setYes] = useState("Create Category");
  const [selected, setSelected] = useState("");
  const [edit, setEdit] = useState("");

  const getCounts = (Catid) => {
    let newArray = product.filter((item) => item.Catid === Catid);
    return newArray.length;
  };
  const handleSelection = (item) => {
    console.log(item, "GTGTGTGT");
    var selectedId = selected;

    if (selectedId === item.Catid) {
      setSelected("");
      setEdit("");
    } else {
      setEdit(item.Catname);
      setSelected(item.Catid);
    }
  };

  return (
    <div className="add-category-section" style={{ maxWidth: 1920 }}>
      <div className="add-product-header">
        <h3>New Category</h3>
      </div>

      <div
        className="save-category-section"
        onClick={async () => {
          setYes("Creating...");
          let CatId = Math.floor(Math.random() * 1000000000);

          await setDoc(doc(db, "Categories", `${CatId}`), {
            Catid: `${CatId}`,
            Catname: cat,
            Image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
          });
          dispatch(
            addCategory({
              Catid: CatId,
              Catname: cat,
              Image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqfCoVYTv5uAc5nlrNpoC_lUuM2okfPw7w-ri-XQoF-sf6SVlrcHgnVTNdW4DjqTaJFYk&usqp=CAU",
            })
          );
          setYes("Done");
          setCat("");
          setYes("Create Category");
        }}
      >
        <button className="category-save">{yes}</button>
      </div>

      <div className="edit-name">
        <h5>
          Category Name <span style={{ color: "red" }}>*</span>
        </h5>
      </div>
      <input
        className="input-product"
        type="text"
        value={cat}
        onChange={(event) => setCat(event.target.value)}
      />
      <div className="clearfix"></div>

      <div className="edit-name">
        <h5>
          Parent Category <span style={{ color: "red" }}>*</span>
        </h5>
      </div>
      <select className="input-product">
        {catnames.map((item) => (
          <option value={item.Catid}>{item.Catname}</option>
        ))}
        <option value={"No Parent"}>{"No Parent"}</option>
      </select>

      <div className="added-categories">
        <div className="categories-border">
          <input className="cat-check" type="checkbox" />
          <ul className="cat-header">
            <li>Name</li>
            <li>Description</li>
            <li>Slug</li>
            <li className="count-list">Count</li>
            {/* <li>Actions</li> */}
          </ul>
        </div>

        {catnames.map((item) => (
          <div className="categories-border33">
            {/* <input className="cat-check" type="checkbox" /> */}
            <ul className="cat-header22">
              {/* <input className="cat-check" type="checkbox" /> */}

              <li>
                {selected === item.Catid ? (
                  <>
                    <input
                      onChange={(event) => setEdit(event.target.value)}
                      style={{ backgroundColor: " #3fad72" }}
                      value={selected === item.Catid ? edit : item.Catname}
                    />
                  </>
                ) : (
                  <li style={{}}>{item.Catname}</li>
                )}
              </li>
              <li style={{}}>
                {item.description ? item.description : "description"}
              </li>

              <li style={{}}>{item.Catname}</li>
              <li className="count-num">
                <Link to={`percat/${item.Catid}`}>{getCounts(item.Catid)}</Link>
              </li>
            </ul>
            {/* <ul className="cat-edit">
              {selected === item.Catid ? (
                <li
                  onClick={async () => {
                    dispatch(
                      updateCategory({
                        Catid: item.Catid,
                        Catname: edit,
                      })
                    );
                    const pRef = doc(db, "Categories", `${item.Catid}`);

                    await updateDoc(pRef, {
                      Catname: edit,
                    });
                    setSelected("");
                  }}
                >
                  Done
                </li>
              ) : (
                <li onClick={() => handleSelection(item)}>Edit</li>
              )}

              <li
                style={{ color: "red" }}
                onClick={async () => {
                  dispatch(removeCategory(item.Catid));
                  await deleteDoc(doc(db, "Categories", item.Catid));
                }}
              >
                Trash
              </li>
              <li
                style={{ color: "blue" }}
                onClick={() => {
                  handleSelection(item);
                }}
              >
                Add
              </li>
            </ul> */}

            <>
              {selected === item.Catid ? (
                <>
                  <div className="edit-namePP">
                    <h5 style={{ fontSize: 14 }}>Parent Category</h5>
                  </div>
                  <select className="cat-inputll">
                    {catnames.map((item) => (
                      <option value={item.Catid}>{item.Catname}</option>
                    ))}
                    <option value={"No Parent"}>{"No Parent"}</option>
                  </select>
                </>
              ) : null}
            </>
            {/* <div className="edit-namePP">
              <h5 style={{ fontSize: 14 }}>Child Category</h5>
            </div>
            <select className="cat-inputll">
              {catnames.map((item) => (
                <option value={item.Catid}>{item.Catname}</option>
              ))}
              <option value={"No Parent"}>{"No Parent"}</option>
            </select> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default VendorCategory;
