import React from "react";
import "./Information.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from '../../Assets/Images/user1.jpg';

function Information() {
    return(

        <>
        <div className="search-user">
            <FiSearch className="usersearch-icon"/>
            <input className="input-search" type="text" placeholder="Search..."/>
        </div>

        <div className="not-details">
                
                        <BsDot className="not-dot"/>
                        <BsFillEnvelopeFill className="message-icon"/>
                   
                        <BsDot className="not-dot2"/>
                        <BsBellFill className="message-icon"/>
        </div>
        <div className="not-image">
            <img src={user} className="not-image-user" alt="logo" />
            <p className="not-image-text">John Doe</p>
        </div>
        <GiHamburgerMenu className="hamburger"/>

        <div className="info-form">
            <h5>Information</h5>
            <div className="info-bottom"></div>

            <div className="info-name-field">
                <p>* Name</p>
                <input className="info-firstname" type="text"/>
                <input type="text"/>
            </div>

            <div className="info-name-field">
                <p>* Country</p>
                <select className="info-country">
                    <option>United States</option>
                    <option>England</option>
                </select>
            </div>

            <div className="info-name-field">
                <p>* Address</p>
                <input className="info-country" type="text"/>

            </div>

            <div className="info-name-field">
                <p>Address Line 2</p>
                <textarea className="info-address2"/>
            </div>

            <div className="info-name-field">
                <p>* City</p>
                <input className="info-country" type="text"/>

            </div>

            <div className="info-name-field">
                <p>* State/Province</p>
                <select className="info-country">
                    <option>New York</option>
                    <option>Los Angeles</option>
                </select>
            </div>

            <div className="info-name-field">
                <p>* ZIP/Postal Code</p>
                <input className="info-zip" type="text"/>

            </div>

            <div className="info-name-field">
                <p>Phone Number</p>
                <input className="info-country" type="text"/>

            </div>

            <div className="info-name-field">
                <p>* Email</p>
                <input className="info-country" type="text"/>

            </div>

            <button className="info-save">SAVE</button>
            <p className="info-cancel">Cancel</p>
            
        </div>

        </>

    );
}

export default Information;