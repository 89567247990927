import React from "react";
import "./MapPercent.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactSpeedometer from "react-d3-speedometer";

const MapPercent = (props) => {
  const percentage = 48;

  return (
    <div style={{ marginLeft: 20 }}>
      <ReactSpeedometer
        labelFontSize={7}
        value={100}
        height={200}
        width={200}
        needleColor="red"
        startColor="green"
        segments={5}
        endColor="blue"
      />
    </div>
  );
};

export default MapPercent;
