import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "./DashInner.css";
import { FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../Assets/Images/product.png";
import { BsFillBellFill } from "react-icons/bs";
import { BsCalendar } from "react-icons/bs";
import { FaRegChartBar } from "react-icons/fa";
import background from "../../Assets/Images/banner.gif";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import { CgDanger } from "react-icons/cg";
import { FaFacebook } from "react-icons/fa";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { RiInstagramFill } from "react-icons/ri";
import cover from "../../Assets/Images/cover.jpg";
import { Link } from "react-router-dom";
import Serve from "../Serve/Serve";
import { useDispatch, useSelector } from "react-redux";
import {
  hide,
  getAllOrders,
  getAllShorts,
  getUser,
  getAllCategories,
  getAllProducts,
  getAllLive,
  getAllLiveSchedules,
  getAllUsers,
  getAllHistory,
  getAllFeedbackReports,
  getAllBanners,
  getAllSupportUser,
  getAllAttributes,
  setVendor,
  getAllAdmins,
  getAllCustomerService,
  getAllevents,
  adminUser,
  getAllRedemm,
  getAllPending,
  setCustomer,
  setAdmin,
} from "../../store/actions/actions";
import NewList from "../NewList/NewList";
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../../firebase/firebase";
import { getDatabase, ref, child, get, onValue } from "firebase/database";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { doc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import ProfileUploader from "../Home/ProfileUploader";
import { Button, Modal } from "react-bootstrap";
import ToggleProfile from "../ToggleProfile/ToggleProfile";
import logout from "../../Assets/Images/logout.png";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import Graph from "../Graph/Graph";

const auth = getAuth();
const userSignin = auth.currentUser;
function DashInner() {
  const [value, onChange] = useState(new Date());
  const [showing, setShowing] = useState(false);
  const [change, setChange] = useState(false);
  const [images, setImages] = useState([]);
  const [pro, setPro] = useState("");

  const reg = useSelector((state) => state.Reducer.adminUser);
  const history = useHistory();
  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [box4, setBox4] = useState(false);
  const [b1, setB1] = useState(true);
  const [b2, setB2] = useState(true);
  const [b3, setB3] = useState(true);
  const [b4, setB4] = useState(true);
  const [text, setText] = useState("");
  const [newW, setNewW] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");

  const suser = localStorage.getItem("ServiceUser");

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShowing(false);
  const handleShow = () => {
    console.log("ssss");
    setShowing(true);
  };

  const updateUserProfile = async () => {
    let id = JSON.parse(suser).userid;
    const pRef = doc(db, "CustomerService", `${id}`);
    let data = {
      profilePicture: pro,
      FirstName: first,
      LastName: last,
      password: password,
    };
    await updateDoc(pRef, data);

    const snap = await getDoc(pRef);

    dispatch(adminUser(snap.data()));
    setPro(snap.data().profilePicture);
    setPassword(snap.data().password);
    setFirst(snap.data().FirstName);
    setLast(snap.data().LastName);

    handleClose();
  };

  useEffect(async () => {
    // dispatch(setVendor(true));
    localStorage.removeItem("VendorUser");
    localStorage.removeItem("AdminUser");

    if (localStorage.getItem("ServiceUser")) {
      dispatch(setCustomer(true));
      dispatch(setAdmin(false));
      dispatch(setVendor(false));
      dispatch(getAllBanners());
      dispatch(getAllevents());
      dispatch(getAllPending());
      dispatch(getAllAdmins());
      dispatch(getAllCustomerService());
      dispatch(getAllUsers());
      dispatch(getAllOrders());
      dispatch(getAllShorts());
      dispatch(hide(false));
      dispatch(getAllFeedbackReports());
      dispatch(getAllOrders());
      dispatch(getAllCategories());
      dispatch(getAllProducts());
      dispatch(getAllRedemm());

      dispatch(getAllLive());
      dispatch(getAllLiveSchedules());
      dispatch(getAllHistory());
      dispatch(getAllSupportUser());
      dispatch(getAllAttributes());

      let id = JSON.parse(suser).userid;
      const pRef = doc(db, "CustomerService", `${id}`);
      const snap = await getDoc(pRef);

      dispatch(adminUser(snap.data()));

      setPro(snap.data().profilePicture);
      setFirst(snap.data().FirstName);
      setLast(snap.data().LastName);
      setPassword(snap.data().password);
    } else {
      history.push("/customerServiceLogin");
    }
    dispatch(hide(false));
  }, []);

  return (
    <>
      <Modal class="date-modal" show={showing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="pic-section">
              <img src={pro ? pro : user} />
              <ProfileUploader
                value={images}
                setHandler={async (values) => {
                  let newImage = values[0].data_url;

                  setPro(newImage);
                }}
              />
              <p>{reg.FirstName}</p>
            </div>

            <div className="details-section">
              <div className="profile-fields">
                <input
                  className="email-field"
                  type="text"
                  placeholder="Email"
                  value={JSON.parse(suser).email}
                />

                <input
                  className="email-field"
                  type="text"
                  placeholder="First Name"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                />

                <input
                  className="email-field"
                  type="text"
                  placeholder="Last Name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                />

                <ToggleProfile
                  onChange={() => {
                    setChange(!change);
                  }}
                />

                {change ? (
                  <>
                    <input
                      className="email-field"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <input
                      className="email-field"
                      type="password"
                      placeholder="Confirm Password"
                      value={passwordC}
                      onChange={(e) => setPasswordC(e.target.value)}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateUserProfile}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ float: "left", width: "85%" }}>
        <div className="not-details">
          <BsDot className="not-dot" />
          <BsFillEnvelopeFill className="message-icon" />

          <BsDot className="not-dot2" />
          <BsBellFill className="message-icon" />

          <Link to="customerServiceLogin">
            <img
              src={logout}
              style={{
                height: 20,
                width: 20,
                float: "right",
                marginRight: 10,
                marginTop: 5,
                marginLeft: 25,
              }}
              alt="logo"
            />
          </Link>
        </div>
        <div className="not-image">
          <img
            src={pro ? pro : user}
            className="not-image-user"
            alt="logo"
            onClick={handleShow}
          />
          <p className="not-image-text">{reg.FirstName}</p>
        </div>
      </div>

      <div className="dash-bell">
        <BsFillBellFill className="dash-bell-icon" />
      </div>
      <div style={{ float: "left" }}>
        <h6 className="dash-alerts">Live Alerts</h6>
        <p className="alerts-option">Rideway | Export to Mail</p>
      </div>

      <button className="due-alerts">
        <FaRegChartBar className="due-chart" />
        Live ALerts
      </button>
      <button className="due-alerts2">
        <BsCalendar className="due-calendar" />
        Due Alerts
      </button>

      <ul className="dash-subnav">
        <li>
          {" "}
          <Link
            to="/vcalls"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: 8,
              listStyle: "none",
            }}
          >
            LIVE CALL{" "}
          </Link>
        </li>
        <li style={{ backgroundColor: "red" }}>
          {" "}
          <Link
            to="notifications"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: 10,
              listStyle: "none",
              backgroundColor: "red",
            }}
          >
            ACTIVE REPORTS{" "}
          </Link>
        </li>
        <li style={{ backgroundColor: "green" }}>
          {" "}
          <Link
            to="resolved"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: 8,
              listStyle: "none",
            }}
          >
            RESOLVED REPORTS{" "}
          </Link>
        </li>

        <li style={{ backgroundColor: "blue" }}>RECENT REPORTS</li>
        <li style={{ backgroundColor: "purple" }} className="dash-active">
          <Link
            to="/ChatSupportCare/allchat"
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: 8,
              listStyle: "none",
            }}
          >
            {/* <MenuItem  className="return-menu" onClick={handleClose}>Users</MenuItem> */}
            LIVE CHAT
          </Link>
        </li>
        <li style={{ backgroundColor: "black" }}>LIVE ALERTS</li>

        <li
          style={{
            textDecoration: "none",
            color: "#888",
            listStyle: "none",
            backgroundColor: "brown",
          }}
        >
          {" "}
          <Link
            to="notificationvideos"
            style={{ textDecoration: "none", color: "#fff", listStyle: "none" }}
          >
            FLAGGED
          </Link>
        </li>

        <li style={{ backgroundColor: "cyan", color: "black" }}>
          <Link to="/pendingproduct">PENDING PRODUCTS</Link>
        </li>

        <li style={{ backgroundColor: "pink", color: "black" }}>
          <Link to="/">USER</Link>
        </li>
      </ul>

      <div className="dash-subnav-bottom"></div>

      <div className="profile-card">
        <img src={cover} className="profile-cover" alt="logo" />
        <img
          src={pro ? pro : user}
          className="profile-image"
          alt="logo"
          onClick={handleShow}
        />
        <ul className="profile-details">
          <li>{reg.FirstName}, 28</li>
          <li className="profile-designation">Lorem Ipsum</li>
          <button className="profile-btn">Serve</button>

          {/* <ul className="product-options">
            <li
              onClick={() => {
                setText("Return");
                setShow(true);
              }}
            >
              Return
            </li>
            <li
              onClick={() => {
                setText("Refund");
                setShow(true);
              }}
              className="refund-btn"
            >
              Refund
            </li>
            <li
              onClick={() => {
                setText("Reorder");
                setShow(true);
              }}
              className="reorder-btn"
            >
              Reorder
            </li>
            <li
              onClick={() => {
                setText("Track Order");
                setShow(true);
              }}
              className="track-btn"
            >
              Track Order
            </li>
            <li
              onClick={() => {
                setText("Investigation");
                setShow(true);
              }}
              className="i-btn"
            >
              Investigation
            </li>
          </ul> */}
        </ul>
      </div>
      <div style={{ float: "left" }}></div>

      <div className="dash-banner">
        <img src={background} className="background-banner" alt="logo" />
        <p className="enterprise">Alerts filtered by: An Enterprise</p>
        <button className="gen-report">Generate Report</button>
        <button className="prev-report">Preview Reports</button>
      </div>

      {newW ? (
        <>
          {show ? (
            <Serve text={text} />
          ) : (
            <>
              {b1 ? (
                <div
                // onClick={() => {
                //   setNewW(false);
                // }}
                // className={box1 ? "dash-operations2" : "dash-operations"}
                >
                  <div className="request-button2">
                    <p className="request-text">Performed</p>
                    <p className="request-num">1012</p>
                  </div>
                </div>
              ) : null}

              {b2 ? (
                <div
                // onClick={() => {
                //   setNewW(false);
                // }}
                // className={box2 ? "dash-operations2" : "dash-operations"}
                >
                  <Link to="/unpinned">
                    <div className="request-button3">
                      <p className="request-text">Pinned</p>
                      <p className="request-num">202</p>
                    </div>
                  </Link>
                </div>
              ) : null}
              {b3 ? (
                <div
                // onClick={() => {
                //   setNewW(false);

                //   //     setB1(false);
                //   //     setB2(false);
                //   //     setB3(true);
                //   //     setB4(false);
                //   //     setBox3(true)
                // }}
                // className={box3 ? "dash-operations2" : "dash-operations"}
                >
                  <Link to="/uninvestigate">
                    <div className="request-button4">
                      <p className="request-text">Investigate</p>
                      <p className="request-num">20</p>
                    </div>
                  </Link>
                </div>
              ) : null}

              {b4 ? (
                <div
                // onClick={() => {
                //   setNewW(false);

                //   // 	setB1(false);
                //   // 	setB2(false);
                //   // 	setB3(false);
                //   // 	setB4(true);
                //   //     setBox4(true)
                // }}
                // className={box4 ? "dash-operations2" : "dash-operations"}
                >
                  <Link to="/unsuspend">
                    <div className="request-button5">
                      <p className="request-text">Suspended</p>
                      <p className="request-num">10</p>
                    </div>
                  </Link>

                  <ul className="product-options">
                    <li
                      onClick={() => {
                        setText("Return");
                        // setShow(true);
                      }}
                    >
                      Return
                    </li>
                    <li
                      onClick={() => {
                        setText("Refund");
                        // setShow(true);
                      }}
                      className="refund-btn"
                    >
                      Refund
                    </li>
                    <li
                      onClick={() => {
                        setText("Reorder");
                        // setShow(true);
                      }}
                      className="reorder-btn"
                    >
                      Reorder
                    </li>
                    <li
                      onClick={() => {
                        setText("Track Order");
                        // setShow(true);
                      }}
                      className="track-btn"
                    >
                      Track Order
                    </li>
                    <li
                      onClick={() => {
                        setText("Investigation");
                        // setShow(true);
                      }}
                      className="i-btn"
                    >
                      Investigation
                    </li>
                  </ul>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        <NewList
          backHandler={() => {
            setNewW(true);
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="dashboard-att">
            <div className="dash-amount-inner">
              <p className="money-analytics-inner">$0</p>
              <p className="money-analytics-detail-inner">
                Monthly Contribution
              </p>
            </div>
            <div className="dash-icon2-inner">
              {/* <AiFillDollarCircle className="dollar-icon" /> */}
              <p className="dash-figure">401k</p>
            </div>
          </div>

          <div className="dashboard-att">
            <div className="dash-amount-inner">
              <p className="money-analytics-inner">$0</p>
              <p className="money-analytics-detail-inner">
                Weekly Contribution
              </p>
            </div>
            <div className="dash-icon3-inner">
              {/* <AiFillDollarCircle className="dollar-icon" /> */}
              <p className="dash-figure">401k</p>
            </div>
          </div>

          <div className="dashboard-att-inner">
            <div className="dash-amount-inner">
              <p className="money-analytics-inner">$0</p>
              <p className="money-analytics-detail-inner">Daily Contribution</p>
            </div>
            <div className="dash-icon4-inner">
              {/* <AiFillDollarCircle className="dollar-icon" /> */}
              <p className="dash-figure">401k</p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <div className="graph-section-inner">
          <p className="cost-heading">Monthly Record</p>
          <Graph />
        </div>

        <div className="calender-section-inner">
          <Calendar
            className="calender-inner"
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
    </>
  );
}

export default DashInner;
